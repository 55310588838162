export const routes = {
    dashboard: 'dashboard',
    gateways: 'gateways',
    layouts: 'layouts',
    deviceDefinitions: 'deviceDefinitions',
    users: 'users',
    loggedOut: 'loggedOut',
    userNotAuthorized: 'userNotAuthorized',
    oktaError: 'oktaError'
};

export const extensions = {
    available: 'available',
    commissioned: 'commissioned',
    enrolled: 'enrolled',
    create: 'create',
    list: 'list',
    internal: 'internal',
    external: 'external',
    add: 'add'
};

export const queryStringKeys = {
    azureDeviceId: 'azureDeviceId',
    serialNumber: 'serialNumber'
};
