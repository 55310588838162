import { Button } from '@mui/material';
import { csvLineSeparator } from '../../constants/formatting';
import { useLanguage } from '../../hooks/useLanguage';
import { AcceptedFileTypes } from '../../types/fileOperationTypes';

const ExportToFileButton = <T,>({
    data,
    fileName,
    formatCsvData
}: {
    data: T;
    fileName: string;
    formatCsvData?: (data: T) => string[];
}) => {
    const { utilities } = useLanguage();
    const exportToJson = () => {
        downloadFile(JSON.stringify(data), `${fileName}.json`, AcceptedFileTypes.json);
    };

    const exportToCsv = () => {
        if (formatCsvData) {
            const csvData = formatCsvData(data);
            downloadFile(csvData.join(csvLineSeparator), `${fileName}.csv`, AcceptedFileTypes.csv);
        }
    };

    const downloadFile = (data: BlobPart, fileName: string, fileType: string) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        a.remove();
    };

    return (
        <>
            <Button variant="contained" onClick={exportToJson}>
                {formatCsvData ? utilities.exportJsonButton : utilities.exportButton}
            </Button>
            {formatCsvData && (
                <Button variant="contained" onClick={exportToCsv}>
                    {utilities.exportCsvButton}
                </Button>
            )}
        </>
    );
};

export default ExportToFileButton;
