import { faCalendarMinus, faPen } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeInternalUserRole } from '../../../api/internalUserApi';
import { pageSize } from '../../../constants/styling';
import { getPslNameFromId } from '../../../helperFunctions';
import { useUserRoleEnum } from '../../../hooks/enumHooks';
import { useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { PslResponse } from '../../../types/pslTypes';
import { UserAssetMappingResponse } from '../../../types/userAssetMappingTypes';
import { LoadInternalUser } from '../../../types/userTypes';
import SearchBar from '../../common/searchBar';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';

const InternalUsersList = ({
    internalUsers,
    psls,
    setInternalUsers
}: {
    internalUsers: LoadInternalUser[];
    psls: PslResponse[];
    setInternalUsers: (users: LoadInternalUser[]) => void;
}) => {
    const { internalUserListScreen, sortableTable } = useLanguage();
    const navigate = useNavigate();
    const { convertUserRole } = useUserRoleEnum();
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const columns: GridColDef[] = [
        {
            field: 'name',
            valueGetter: (params) => {
                return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
            headerName: internalUserListScreen.nameColumn,
            flex: 1
        },
        {
            field: 'email',
            headerName: internalUserListScreen.emailColumn,
            flex: 1
        },
        {
            field: 'psls',
            valueGetter: (params) =>
                params.value
                    .map((p: UserAssetMappingResponse) => getPslNameFromId(psls, p.assetId))
                    .join(', '),
            headerName: internalUserListScreen.pslColumn,
            flex: 1
        },
        {
            field: 'userRole',
            valueFormatter: (params) => convertUserRole(params.value),
            headerName: internalUserListScreen.roleColumn,
            flex: 1
        }
    ];

    const pagedInternalUsers = useMemo(
        () => internalUsers.slice(page * pageSize, page * pageSize + pageSize),
        [page, internalUsers]
    );

    const onDeleteSuccess = (body: string, _: undefined) => {
        setInternalUsers(internalUsers.filter((u) => u.id !== body));
    };
    const {
        executeRequest: deleteRequest,
        loading: deleteLoading,
        error: deleteError
    } = useRequest(removeInternalUserRole, onDeleteSuccess);

    const deleteRow = (rowId: GridRowId) => {
        deleteRequest(rowId.toString());
    };

    const editRow = (rowId: GridRowId) => {
        navigate(rowId.toString());
    };

    const internalUsersToDisplay = pagedInternalUsers.filter(
        (eu) =>
            eu.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            eu.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <VerticalStack spacing={3} paddingTop={8}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <Button variant="contained" onClick={() => navigate(extensions.add)}>
                    {internalUserListScreen.addUserButton}
                </Button>
            </Stack>
            {deleteError && <Alert severity="error">{deleteError}</Alert>}
            <SortableTable
                columns={columns}
                rows={internalUsersToDisplay}
                rowActions={[
                    { label: sortableTable.editButton, onClick: editRow, icon: faPen },
                    {
                        label: internalUserListScreen.removeUserActionButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                pagedData={{
                    page,
                    setPage,
                    pageSize,
                    rowNumber: page * pageSize + 2 * pageSize,
                    isLoading: deleteLoading
                }}
            />
        </VerticalStack>
    );
};

export default InternalUsersList;
