import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { getGateway, updateGateway } from '../../../api/gatewayApi';
import { dateDataFormat } from '../../../constants/formatting';
import { useLoad, useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions, routes } from '../../../pages';
import { Timezone } from '../../../types/gatewayTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import GatewayForm from './gatewayForm';

const EditGatewayPage = () => {
    const { gatewayId } = useParams();
    const { editGatewayScreen } = useLanguage();
    const idError =
        gatewayId !== undefined && uuidValidate(gatewayId)
            ? undefined
            : `${editGatewayScreen.idError}${gatewayId}`;

    return gatewayId && !idError ? (
        <EditGatewayPageLoad gatewayId={gatewayId} />
    ) : (
        <Alert severity="error">{idError}</Alert>
    );
};

const EditGatewayPageLoad = ({ gatewayId }: { gatewayId: string }) => {
    const { editGatewayScreen } = useLanguage();
    const navigate = useNavigate();
    const gatewayLoadFunction = useCallback(() => getGateway(gatewayId), [gatewayId]);

    const returnToCommissionedList = () => {
        navigate(`/${routes.gateways}/${extensions.commissioned}`);
    };

    const {
        data: gatewayData,
        loading: gatewayLoading,
        error: gatewaysError
    } = useLoad(gatewayLoadFunction);
    const {
        layoutsSource: { data: layoutsData, loading: layoutsLoading, error: layoutsError }
    } = useDataSource();
    const {
        executeRequest,
        loading: updateLoading,
        error: updateError
    } = useRequest(updateGateway, returnToCommissionedList);

    const loading = gatewayLoading || layoutsLoading || updateLoading;
    const error = gatewaysError || layoutsError || updateError;
    return (
        <DefaultPageLayout pageHeader={editGatewayScreen.header} loading={loading} error={error}>
            {gatewayData && layoutsData && (
                <GatewayForm
                    defaultValues={
                        gatewayData.enableRtsForwarding
                            ? gatewayData
                            : {
                                  ...gatewayData, // If RTS forwarding is currently disabled for this gateway, then timezone and spudDate are null - use default timezone and spudDate
                                  timezone: Timezone.Central,
                                  spudDate: dayjs().format(dateDataFormat)
                              }
                    }
                    onSubmit={({ azureDeviceId, serialNumber, ...gateway }) =>
                        // remove fields which can't be updated before sending update to backend
                        executeRequest({ gateway, gatewayId })
                    }
                    submitButtonText={editGatewayScreen.saveButton}
                    onCancel={returnToCommissionedList}
                    layouts={layoutsData}
                    isEdit
                />
            )}
        </DefaultPageLayout>
    );
};

export default EditGatewayPage;
