import { Alert, Button, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { getGatewayFullStatus } from '../../../api/gatewayStatusApi';
import { dateAndTimeDisplayFormat } from '../../../constants/formatting';
import { sxBoldWeight } from '../../../constants/styling';
import { useConnectionStatusEnum, useModuleRuntimeStatusEnum } from '../../../hooks/enumHooks';
import { useLoad } from '../../../hooks/requestHooks';
import { useGatewayConnectedStatusThreshold } from '../../../hooks/useGatewayConnectedStatusThreshold';
import { useLanguage } from '../../../hooks/useLanguage';
import { ConnectionStatus } from '../../../types/gatewayStatusTypes';
import LoadingDisplay from '../../common/loadingDisplay';
import SimpleTextLabel from '../../common/simpleTextLabel';
import VerticalStack from '../../common/verticalStack';

const SingleGatewayStatusSidebar = ({
    gatewayId,
    setSelectedGateway
}: {
    gatewayId: string;
    setSelectedGateway: (gatewayId?: string) => void;
}) => {
    const { gatewayFullStatusPanel, dateText } = useLanguage();
    const { convertConnectionStatus } = useConnectionStatusEnum();
    const { convertModuleRuntimeStatus } = useModuleRuntimeStatusEnum();
    const { isGatewayOnline } = useGatewayConnectedStatusThreshold();

    const gatewayFullStatusLoad = useCallback(() => getGatewayFullStatus(gatewayId), [gatewayId]);
    const { loading, data: gatewayFullStatus, error } = useLoad(gatewayFullStatusLoad);

    return (
        <Stack>
            {loading && <LoadingDisplay />}
            {error && (
                <Stack spacing={2}>
                    <Button
                        sx={{ marginLeft: 'auto' }}
                        variant="contained"
                        onClick={() => setSelectedGateway(undefined)}
                    >
                        {gatewayFullStatusPanel.closeButton}
                    </Button>
                    <Alert severity="error">{error}</Alert>
                </Stack>
            )}
            {!loading && !error && gatewayFullStatus && (
                <VerticalStack spacing={4}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="action">
                            {gatewayFullStatus.gatewayName}
                            {gatewayFullStatusPanel.header}
                        </Typography>
                        <Button variant="contained" onClick={() => setSelectedGateway(undefined)}>
                            {gatewayFullStatusPanel.closeButton}
                        </Button>
                    </Stack>

                    {/* Azure Device ID */}
                    <SimpleTextLabel
                        label={gatewayFullStatusPanel.azureDeviceIdLabel}
                        content={gatewayFullStatus.azureDeviceId}
                    />

                    {/* Status Created Date */}
                    <SimpleTextLabel
                        label={gatewayFullStatusPanel.createdDateLabel}
                        content={`${dayjs(gatewayFullStatus.createdDate).format(
                            dateAndTimeDisplayFormat
                        )}${dateText.utc}`}
                    />

                    {/* Gateway Commissioned Date */}
                    <SimpleTextLabel
                        label={gatewayFullStatusPanel.gatewayCommissionedDateLabel}
                        content={`${dayjs(gatewayFullStatus.gatewayCommissionedDate).format(
                            dateAndTimeDisplayFormat
                        )}`}
                    />

                    {/* Connection Status */}
                    <SimpleTextLabel
                        label={gatewayFullStatusPanel.connectionStatusLabel}
                        content={convertConnectionStatus(
                            isGatewayOnline(gatewayFullStatus.createdDate)
                                ? ConnectionStatus.Connected
                                : ConnectionStatus.Disconnected
                        )}
                    />

                    {/* Config Module Status */}
                    <VerticalStack>
                        <Typography sx={sxBoldWeight}>
                            {gatewayFullStatusPanel.configModuleStatusLabel}
                        </Typography>
                        <SimpleTextLabel
                            label={gatewayFullStatusPanel.hasConfigurationLabel}
                            content={
                                gatewayFullStatus.hasConfiguration
                                    ? gatewayFullStatusPanel.trueLabel
                                    : gatewayFullStatusPanel.falseLabel
                            }
                            isBolded={false}
                        />
                        <SimpleTextLabel
                            label={gatewayFullStatusPanel.latLabel}
                            content={gatewayFullStatus.coordinate.gpsLat.toString()}
                            isBolded={false}
                        />
                        <SimpleTextLabel
                            label={gatewayFullStatusPanel.longLabel}
                            content={gatewayFullStatus.coordinate.gpsLong.toString()}
                            isBolded={false}
                        />
                        {gatewayFullStatus.gpsMode && (
                            <SimpleTextLabel
                                label={gatewayFullStatusPanel.gpsModeLabel}
                                content={gatewayFullStatus.gpsMode}
                                isBolded={false}
                            />
                        )}
                    </VerticalStack>

                    {/* Device Connection Statuses */}
                    <VerticalStack>
                        <Typography sx={sxBoldWeight}>
                            {gatewayFullStatusPanel.deviceConnectionStatusesLabel}
                        </Typography>
                        {gatewayFullStatus.deviceConnectionStatuses.map(
                            (deviceConnectionStatus) => (
                                <Stack key={deviceConnectionStatus.deviceExternalId}>
                                    <SimpleTextLabel
                                        label={deviceConnectionStatus.name}
                                        content={convertConnectionStatus(
                                            deviceConnectionStatus.status
                                        )}
                                        isBolded={false}
                                    />
                                </Stack>
                            )
                        )}
                    </VerticalStack>

                    {/* Module Connection Statuses */}
                    <VerticalStack>
                        <Typography sx={sxBoldWeight}>
                            {gatewayFullStatusPanel.moduleConnectionStatusesLabel}
                        </Typography>
                        {gatewayFullStatus.moduleConnectionStatuses.map(
                            (moduleConnectionStatus) => (
                                <Stack key={moduleConnectionStatus.moduleId}>
                                    <SimpleTextLabel
                                        label={moduleConnectionStatus.moduleId}
                                        content={convertModuleRuntimeStatus(
                                            moduleConnectionStatus.moduleRuntimeStatus
                                        )}
                                        isBolded={false}
                                    />
                                </Stack>
                            )
                        )}
                    </VerticalStack>
                </VerticalStack>
            )}
        </Stack>
    );
};

export default SingleGatewayStatusSidebar;
