import { Stack, Typography } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';

const SimpleTextLabel = ({
    label,
    content,
    isBolded
}: {
    label: string;
    content: string;
    isBolded?: boolean;
}) => {
    const { punctuation } = useLanguage();

    return (
        <Stack direction="row" spacing={1}>
            {/* If isBolded is not supplied (default) or supplied as true, bold label */}
            <Typography
                sx={{ fontWeight: isBolded === undefined || isBolded ? 'bold' : 'regular' }}
            >
                {label}
                {punctuation.labelDelimiter}
            </Typography>
            <Typography>{content}</Typography>
        </Stack>
    );
};

export default SimpleTextLabel;
