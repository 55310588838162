import { baseApiUrl } from '../environmentVariables';
import {
    GatewayStatusCondensedResponse,
    GatewayStatusFullResponse
} from '../types/gatewayStatusTypes';
import { httpRequest } from './sharedApi';

const gatewayStatusUrl = `${baseApiUrl}/gatewayStatus`;

export const getGatewayStatusCondensedResponses = () =>
    httpRequest<undefined, GatewayStatusCondensedResponse[]>({
        url: `${gatewayStatusUrl}/condensedStatuses`
    });

export const getGatewayFullStatus = (gatewayId: string) =>
    httpRequest<undefined, GatewayStatusFullResponse>({
        url: `${gatewayStatusUrl}/fullStatus/${gatewayId}`
    });
