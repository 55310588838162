import { Typography } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';
import DefaultPageLayout from '../common/defaultPageLayout';

const NotAuthorizedDisplay = () => {
    const { lifecycle } = useLanguage();
    return (
        <DefaultPageLayout loading={false} pageHeader={lifecycle.notAuthorizedHeader}>
            <Typography>{lifecycle.notAuthorizedExplanation}</Typography>
        </DefaultPageLayout>
    );
};

export default NotAuthorizedDisplay;
