import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../../hooks/useLanguage';
import { LocalMoxaModbusRegister } from '../../../../types/deviceDefinitionTypes';
import UnsavedChangesIndicator from '../../../common/unsavedChangesIndicator';
import RegisterFormInputs from './registerFormInputs';

const RegisterForm = ({
    isEdit,
    handleClose,
    updateRegister,
    defaultValues,
    registers
}: {
    isEdit: boolean;
    handleClose: () => void;
    updateRegister: (registerData: LocalMoxaModbusRegister) => void;
    defaultValues: LocalMoxaModbusRegister;
    registers: LocalMoxaModbusRegister[];
}) => {
    const { registerForm, editRegisterForm, addRegisterForm } = useLanguage();
    const form = useForm<LocalMoxaModbusRegister>({
        defaultValues: {
            ...defaultValues,
            // need to explicitly specify values or the object does not include them
            dataSize: defaultValues.dataSize !== undefined ? defaultValues.dataSize : null,
            slope: defaultValues.slope !== undefined ? defaultValues.slope : null,
            interceptOffset:
                defaultValues.interceptOffset !== undefined ? defaultValues.interceptOffset : null,
            pointSourceMax:
                defaultValues.pointSourceMax !== undefined ? defaultValues.pointSourceMax : null,
            pointSourceMin:
                defaultValues.pointSourceMin !== undefined ? defaultValues.pointSourceMin : null,
            pointTargetMax:
                defaultValues.pointTargetMax !== undefined ? defaultValues.pointTargetMax : null,
            pointTargetMin:
                defaultValues.pointTargetMin !== undefined ? defaultValues.pointTargetMin : null,
            uomClass: defaultValues.uomClass || '',
            unitType: defaultValues.unitType || '',
            dataUnit: defaultValues.dataUnit || ''
        }
    });

    const onSubmit = (registerData: LocalMoxaModbusRegister) => {
        if (Object.is(registerData.dataSize, NaN)) {
            registerData.dataSize = undefined;
        }
        updateRegister(registerData);
        handleClose();
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    return form.handleSubmit(onSubmit)(e);
                }}
            >
                <Stack spacing={3}>
                    <Typography variant="h2">
                        {isEdit ? editRegisterForm.header : addRegisterForm.header}
                    </Typography>
                    <RegisterFormInputs registers={registers}/>
                    <Stack spacing={2}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" justifyContent="end" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {registerForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {isEdit ? registerForm.updateButton : registerForm.addButton}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default RegisterForm;
