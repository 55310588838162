import { useLanguage } from './useLanguage';

export function useStringConverters() {
    const { conversionErrors } = useLanguage();

    const convertInt = (value: string) => {
        if (Object.is(parseInt(value), NaN)) {
            throw new Error(conversionErrors.integerFailed);
        }
        return parseInt(value);
    };

    const convertBool = (value: string) => {
        if (value.toLowerCase() !== 'true' && value.toLowerCase() !== 'false') {
            throw new Error(conversionErrors.booleanFailed);
        }
        return value.toLowerCase() === 'true';
    };

    const convertFloat = (value: string) => {
        if (Object.is(parseFloat(value), NaN)) {
            throw new Error(conversionErrors.floatFailed);
        }
        return parseFloat(value);
    };

    return { convertInt, convertBool, convertFloat };
}
