import { MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import { useProtocolEnum } from '../../../hooks/enumHooks';
import {
    DeviceDefinitionCreateRequest,
    DeviceDefinitionResponse
} from '../../../types/deviceDefinitionTypes';
import { FormElement } from '../../common/formElement';
import { formFieldId, formLabelId } from '../../common/formInputRenderFunctions';
import { checkFieldUniqueness } from '../../../helperFunctions';
import { RequiredStatus } from '../../../types/displayTypes';
import AssetInfoEditor from '../../common/assetInfoEditor';
import { mediumString } from '../../../constants/validation';
import { protocols } from '../../../constants/enums';

export const DeviceDefinitionFormInputs = ({
    deviceDefinitions,
    isEdit
}: {
    deviceDefinitions: DeviceDefinitionResponse[];
    isEdit?: boolean;
}) => {
    const { deviceDefinitionForm, form: formText } = useLanguage();
    const { validateProtocol, protocolDisplay } = useProtocolEnum();
    const form = useFormContext<DeviceDefinitionCreateRequest>();

    const currentPslId = form.watch('assetInfo.pslId');
    const validateName = (name: string) => {
        const nameUnique = checkFieldUniqueness(
            deviceDefinitions.filter((dd) => dd.assetInfo.pslId === currentPslId),
            (dd) => dd.name,
            name,
            form.formState.defaultValues?.name
        );

        return nameUnique || deviceDefinitionForm.duplicateNameError;
    };

    return (
        <Stack spacing={2} maxWidth="33%">
            <FormElement<DeviceDefinitionCreateRequest>
                fieldName="name"
                label={deviceDefinitionForm.nameFieldLabel}
                tooltipTitle={deviceDefinitionForm.nameTooltip}
            >
                <OutlinedInput
                    {...form.register('name', {
                        required: formText.required,
                        maxLength: { value: mediumString, message: formText.tooLong },
                        validate: validateName
                    })}
                    id={formFieldId('name')}
                />
            </FormElement>
            <FormElement<DeviceDefinitionCreateRequest>
                fieldName="manufacturer"
                label={deviceDefinitionForm.manufacturerFieldLabel}
                tooltipTitle={deviceDefinitionForm.manufacturerTooltip}
                requiredStatus={RequiredStatus.Optional}
            >
                <OutlinedInput
                    {...form.register('manufacturer', {
                        maxLength: { value: mediumString, message: formText.tooLong }
                    })}
                    id={formFieldId('manufacturer')}
                />
            </FormElement>
            <FormElement<DeviceDefinitionCreateRequest> fieldName="assetInfo" label="">
                <Controller
                    control={form.control}
                    name="assetInfo"
                    render={({ field }) => (
                        <AssetInfoEditor
                            assetInfo={field.value}
                            onAssetInfoChange={field.onChange}
                            disabled={isEdit}
                        />
                    )}
                />
            </FormElement>
            <FormElement<DeviceDefinitionCreateRequest>
                fieldName="protocol"
                label={deviceDefinitionForm.protocolSelectorLabel}
                tooltipTitle={deviceDefinitionForm.protocolSelectorTooltip}
            >
                <Controller
                    control={form.control}
                    name="protocol"
                    rules={{ validate: validateProtocol }}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={field.onChange}
                            labelId={formLabelId(field.name)}
                        >
                            {protocols.map((p) => (
                                <MenuItem key={p} value={p}>
                                    {protocolDisplay[p]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
        </Stack>
    );
};
