import DefaultPageLayout from '../../common/defaultPageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from '../../../hooks/useLanguage';
import { Alert } from '@mui/material';
import {
    createErrorMessage,
    useDisplayHttpStatusCode,
    useLoad,
    useRequest
} from '../../../hooks/requestHooks';
import {
    getInternalUserAndMappings,
    updateUserRoleAndMappings
} from '../../../api/internalUserApi';
import { useCallback, useMemo, useState } from 'react';
import { InternalUser, LoadInternalUser, SaveInternalUser } from '../../../types/userTypes';
import InternalUserForm from './internalUserForm';
import { extensions } from '../../../pages';
import { ApiResult } from '../../../types/apiTypes';
import { compareAssets } from '../../../helperFunctions';
import { UserRole } from '../../../types/authorizationTypes';
import { useDataSource } from '../../../hooks/useDataSource';
import { PslResponse } from '../../../types/pslTypes';

const EditInternalUserAccessPage = () => {
    const { userId } = useParams();
    const { editInternalUserAccessScreen } = useLanguage();

    return userId !== undefined ? (
        <InternalUserLoad userId={userId} />
    ) : (
        <Alert severity="error">{`${editInternalUserAccessScreen.idError}${userId}`}</Alert>
    );
};

const InternalUserLoad = ({ userId }: { userId: string }) => {
    const { editInternalUserAccessScreen } = useLanguage();
    const [error, setError] = useState<string>();
    const {
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();

    const loadUser = useCallback(() => getInternalUserAndMappings(userId), [userId]);
    const { data, loading, error: getError } = useLoad(loadUser);

    const displayError = getError || pslsError || error;

    return (
        <DefaultPageLayout
            pageHeader={editInternalUserAccessScreen.header}
            loading={loading || pslsLoading}
            error={displayError}
        >
            {data && psls && (
                <EditInternalUserPageLoad data={data} psls={psls} setError={setError} />
            )}
        </DefaultPageLayout>
    );
};

const EditInternalUserPageLoad = ({
    data,
    psls,
    setError
}: {
    data: LoadInternalUser;
    psls: PslResponse[];
    setError: (error: string) => void;
}) => {
    const navigate = useNavigate();
    const displayHttpStatusCode = useDisplayHttpStatusCode();
    const defaultValues = useMemo(() => {
        return {
            id: data.id,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            userRole: data.userRole,
            psls: data.psls
                .map((p) => ({ id: p.id, assetId: p.assetId, assetType: p.assetType }))
                .sort(compareAssets)
        };
    }, [data]);

    const onSetSuccess = (_: { updateUserRole: boolean; userAndAssets: SaveInternalUser }) => {
        navigate(`../${extensions.internal}`);
    };
    const onSetFailure = (result: ApiResult<undefined>) => {
        const displayError = createErrorMessage(result, displayHttpStatusCode(result.status));
        setError(displayError);
    };
    const { executeRequest, loading } = useRequest(
        updateUserRoleAndMappings,
        onSetSuccess,
        onSetFailure
    );

    const onSubmit = (user: InternalUser) => {
        // assets to add --> assets not in default values, but in user
        const assetsToAdd = user.psls
            .filter((p) => !defaultValues.psls.map((p) => p.assetId).includes(p.assetId))
            .map((p) => ({ assetId: p.assetId, assetType: p.assetType }));

        // assets to delete --> assets not in user, but in default values, where id is not undefined
        // If user's role is being changed to Admin, then backend automatically handles asset mapping deletion
        const assetsToDelete =
            user.userRole !== UserRole.Admin
                ? defaultValues.psls
                      .filter((p) => !user.psls.map((p) => p.assetId).includes(p.assetId))
                      // psls loaded into default values will have defined ids, as they're derived from the
                      // user mapping response
                      .map((p) => ({ assetId: p.assetId, assetType: p.assetType, id: p.id }))
                : [];

        // update user role --> user role != default user role
        const updateUserRole = user.userRole !== defaultValues.userRole;

        executeRequest({
            updateUserRole,
            userAndAssets: {
                id: user.id,
                userRole: user.userRole,
                assetsToAdd,
                assetsToDelete
            }
        });
    };

    return (
        <InternalUserForm
            defaultValues={defaultValues}
            psls={psls}
            submitLoading={loading}
            onSubmit={onSubmit}
            isEdit
        />
    );
};

export default EditInternalUserAccessPage;
