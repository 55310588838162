import { UserInteraction } from '../types/authorizationTypes';
import { useUser } from './useUser';

export const useAuthorization = (interaction?: UserInteraction) => {
    const user = useUser();

    // if user role is greater than or equal to the supplied role, they have access
    const visibility =
        interaction?.visible && user && user.userRole < interaction.visible ? 'hidden' : 'visible';
    const disabled =
        interaction?.enable && user && user.userRole < interaction.enable ? true : false;

    return { visibility, disabled };
};
