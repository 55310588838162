import { Box, Button, Stack } from '@mui/material';
import { ClusterWithLeaves } from '../../../types/gatewayStatusTypes';

const MapClusterPopup = ({
    cluster,
    handleClose,
    setSelectedGateway
}: {
    cluster: ClusterWithLeaves;
    handleClose: () => void;
    setSelectedGateway: (gatewayId?: string) => void;
}) => {
    return (
        <Stack spacing={4} marginTop={3} marginBottom={3}>
            <Stack spacing={1}>
                {cluster.leaves.map((leaf) => (
                    <Box key={leaf.id} justifyContent="flex-start">
                        <Button onClick={() => setSelectedGateway(leaf.id.toString())}>
                            {leaf.name}
                        </Button>
                    </Box>
                ))}
            </Stack>
            <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                    maxWidth: (theme) => theme.spacing(2),
                    alignSelf: 'center'
                }}
            >
                Close
            </Button>
        </Stack>
    );
};

export default MapClusterPopup;
