import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLanguage } from './useLanguage';
import { HttpStatusCode, ApiResult } from '../types/apiTypes';

export const useDisplayHttpStatusCode = () => {
    const { httpErrors } = useLanguage();
    return useMemo(
        () => (status: HttpStatusCode) => {
            switch (status) {
                case HttpStatusCode.BackendUnreachable:
                    return httpErrors.backendUnreachable;
                case HttpStatusCode.BadRequest:
                    return httpErrors.badRequest;
                case HttpStatusCode.Unauthorized:
                    return httpErrors.unauthorized;
                case HttpStatusCode.Forbidden:
                    return httpErrors.forbidden;
                case HttpStatusCode.NotFound:
                    return httpErrors.notFound;
                case HttpStatusCode.Conflict:
                    return httpErrors.conflict;
                case HttpStatusCode.ServerError:
                    return httpErrors.serverError;
                default:
                    return status < 400 ? httpErrors.success : httpErrors.unknown;
            }
        },
        [httpErrors]
    );
};

export const createErrorMessage = <T,>(result: ApiResult<T>, errorMessage: string) => {
    return result.status !== HttpStatusCode.BackendUnreachable
        ? `(${result.status}) ${errorMessage} ${result.errorCode}`
        : errorMessage;
};

// Abstraction for loading data from the backend when component first renders
// Note: the function passed into this hook MUST NOT change
// between renders - wrap in useCallback if anonymous
export const useLoad = <ResultType,>(
    loadFunction: () => (authToken: string) => Promise<ApiResult<ResultType>>
) => {
    const { executeRequest, data, setData, loading, error } = useRequest<undefined, ResultType>(
        loadFunction
    );

    useEffect(() => {
        executeRequest(undefined);
    }, [executeRequest]);

    return { data, setData, loading, error };
};

// Abstraction to wrap up common logic around making requests.
// Specifically,
// - Handles loading state
// - Handles error state
// Intended to incorporate stateful logic to allow easy writing of deterministic components.
// Returns:
// - method to kick off that request (like when the page loads or a user clicks a button)
// - data, loading, and error state that can be watched from the component

// Note: the functions passed into this hook MUST NOT change
// between renders - wrap in useCallback if anonymous
export const useRequest = <RequestParameters, ResultType>(
    // function which represents the request to be made
    requestFunction: (
        params: RequestParameters
    ) => (authToken: string) => Promise<ApiResult<ResultType>>,
    // called when request completes
    successCallbackFunction?: (body: RequestParameters, data?: ResultType) => void,
    // called when request fails
    failureCallbackFunction?: (result: ApiResult<ResultType>) => void
) => {
    const [data, setData] = useState<ResultType>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [status, setStatus] = useState<number>();
    const displayHttpStatusCode = useDisplayHttpStatusCode();
    const { authState } = useOktaAuth();
    const accessToken = authState?.accessToken?.accessToken;

    const executeRequest = useCallback(
        async (params: RequestParameters) => {
            if (!accessToken) {
                return;
            }
            setError(undefined);
            setLoading(true);
            const result = await requestFunction(params)(accessToken);
            setLoading(false);
            setStatus(result.status);
            if (result.isFailure) {
                const errorMessage = displayHttpStatusCode(result.status);
                const errorDisplay = createErrorMessage(result, errorMessage);
                setError(errorDisplay);
                if (failureCallbackFunction) {
                    failureCallbackFunction(result);
                }
                return;
            }
            setData(result.data);
            if (successCallbackFunction) {
                successCallbackFunction(params, result.data);
            }
        },
        [
            requestFunction,
            successCallbackFunction,
            failureCallbackFunction,
            accessToken,
            displayHttpStatusCode
        ]
    );

    return { executeRequest, data, setData, loading, status, error };
};
