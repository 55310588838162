import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useProtocolEnum } from '../../../hooks/enumHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import {
    DeviceDefinitionCreateRequestNoConfig,
    LocalDeviceDefinition,
    LocalMoxaModbusRegister,
    OpcUANode,
    Protocol,
    Signal,
    Topic
} from '../../../types/deviceDefinitionTypes';
import ExportToFileButton from '../../common/exportToFileButton';
import ImportFromFileButton from '../../common/importFromFileButton';

const DeviceDefinitionImportExportButtons = ({
    setError
}: {
    setError: (error?: string) => void;
}) => {
    const form = useFormContext<LocalDeviceDefinition>();
    const { deviceDefinitionScreen } = useLanguage();
    const { validateProtocol } = useProtocolEnum();

    const parseJsonDefinition = (content: string) => {
        const parsedDefinition: LocalDeviceDefinition = JSON.parse(content);

        const protocol = parsedDefinition.protocol;
        const protocolError = validateProtocol(protocol);
        if (protocolError) {
            throw new Error(protocolError);
        }

        form.setValue('name', parsedDefinition.name, { shouldDirty: true });
        form.setValue('manufacturer', parsedDefinition.manufacturer, { shouldDirty: true });
        form.setValue('protocol', protocol, { shouldDirty: true });
        form.setValue('assetInfo', parsedDefinition.assetInfo, { shouldDirty: true });
        form.setValue('moxaModbusSlaveId', parsedDefinition.moxaModbusSlaveId, {
            shouldDirty: true
        });
        form.setValue('moxaModbusRegisters', parsedDefinition.moxaModbusRegisters, {
            shouldDirty: true
        });
        form.setValue('mqttSignals', parsedDefinition.mqttSignals, {
            shouldDirty: true
        });
        form.setValue('mqttTopics', parsedDefinition.mqttTopics, {
            shouldDirty: true
        });
        form.setValue('opcUASessionRenewalRequired', parsedDefinition.opcUASessionRenewalRequired, {
            shouldDirty: true
        });
        form.setValue('opcUASessionRenewalMinutes', parsedDefinition.opcUASessionRenewalMinutes, {
            shouldDirty: true
        });
        form.setValue('opcUASecurityEnabled', parsedDefinition.opcUASecurityEnabled, {
            shouldDirty: true
        });
        form.setValue(
            'opcUAApplicationCertificatePath',
            parsedDefinition.opcUAApplicationCertificatePath,
            {
                shouldDirty: true
            }
        );
        form.setValue('opcUANodes', parsedDefinition.opcUANodes, {
            shouldDirty: true
        });
    };

    const protocol = form.watch('protocol');
    const deviceDefinition = {
        name: form.watch('name'),
        manufacturer: form.watch('manufacturer'),
        assetInfo: form.watch('assetInfo'),
        protocol: protocol
    };

    let definitionData:
        | DeviceDefinitionCreateRequestNoConfig
        | (
              | DeviceDefinitionCreateRequestNoConfig
              | { moxaModbusSlaveId: number; moxaModbusRegisters: LocalMoxaModbusRegister[] }
              | { mqttSignals: Signal[]; mqttTopics: Topic[] }
              | {
                    opcUASessionRenewalRequired: boolean;
                    opcUASessionRenewalMinutes?: number | null;
                    opcUASecurityEnabled: boolean;
                    opcUAApplicationCertificatePath: string | null;
                    opcUANodes: OpcUANode[];
                }
          );

    switch (protocol) {
        case Protocol.MoxaModbus:
            definitionData = {
                ...deviceDefinition,
                moxaModbusSlaveId: form.watch('moxaModbusSlaveId'),
                moxaModbusRegisters: form.watch('moxaModbusRegisters')
            };
            break;
        case Protocol.Mqtt:
            definitionData = {
                ...deviceDefinition,
                mqttSignals: form.watch('mqttSignals'),
                mqttTopics: form.watch('mqttTopics')
            };
            break;
        case Protocol.OpcUA:
            definitionData = {
                ...deviceDefinition,
                opcUASessionRenewalRequired: form.watch('opcUASessionRenewalRequired'),
                opcUASessionRenewalMinutes: form.watch('opcUASessionRenewalMinutes'),
                opcUASecurityEnabled: form.watch('opcUASecurityEnabled'),
                opcUAApplicationCertificatePath: form.watch('opcUAApplicationCertificatePath'),
                opcUANodes: form.watch('opcUANodes')
            };
            break;
        default:
            definitionData = deviceDefinition;
    }

    return (
        <Stack direction="row" spacing={3} paddingTop={5} paddingBottom={5}>
            <ImportFromFileButton
                setError={setError}
                handleJsonData={parseJsonDefinition}
                confirmDialogContent={deviceDefinitionScreen.importOverwriteWarning}
            />
            <ExportToFileButton
                data={definitionData}
                fileName={
                    deviceDefinition.name !== ''
                        ? deviceDefinition.name
                        : deviceDefinitionScreen.defaultFileExportName
                }
            />
        </Stack>
    );
};

export default DeviceDefinitionImportExportButtons;
