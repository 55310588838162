import { v4 as uuidv4 } from 'uuid';
import { SignalUnitsMapping } from './constants/signalUnits';
import { SignalUnitType } from './types/signalUnitTypes';
import {
    SignalDataType,
    Scaling,
    Signal,
    LocalMoxaModbusRegister,
    Topic,
    Swap,
    OpcUANodeIdType,
    OpcUANode
} from './types/deviceDefinitionTypes';

export const defaultMoxaModbusRegister: LocalMoxaModbusRegister = {
    configurationElementId: uuidv4(),
    name: '',
    dataType: SignalDataType.Boolean,
    address: 0,
    quantity: 1,
    eventClass: null,
    readOnly: true,
    dataSize: undefined,
    dataUnit: SignalUnitsMapping[SignalUnitType.Unitless][0],
    offset: 0,
    swap: Swap.None,
    scaling: Scaling.Disabled,
    slope: 1.0,
    interceptOffset: 0.0,
    pointSourceMin: 0.0,
    pointSourceMax: 0.0,
    pointTargetMin: 0.0,
    pointTargetMax: 0.0
};

export const defaultSignal: Signal = {
    configurationElementId: uuidv4(),
    name: '',
    dataType: SignalDataType.Boolean,
    eventClass: null,
    readOnly: true,
    dataUnit: SignalUnitsMapping[SignalUnitType.Unitless][0]
};

export const defaultTopic: Topic = {
    name: '',
    route: '',
    groupId: 'rpedge',
    isCommand: false,
    mqttSignalConfigurationElementIds: []
};

export const defaultOpcUANode: OpcUANode = {
    configurationElementId: uuidv4(),
    name: '',
    dataType: SignalDataType.Boolean,
    readOnly: true,
    eventClass: null,
    dataUnit: SignalUnitsMapping[SignalUnitType.Unitless][0],
    namespaceId: 1,
    nodeIdType: OpcUANodeIdType.Integer,
    nodeId: ''
};
