import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useColorMode } from '../../hooks/useColorMode';
import { darkThemeColors, lightThemeColors, themeColors } from '../../theme';
import { ColorStatus } from '../../types/displayTypes';

const NumericDisplay = ({
    value,
    label,
    status
}: {
    value: number;
    label: string;
    status: ColorStatus;
}) => {
    const { isLightMode } = useColorMode();

    const getStatusColor = () => {
        switch (status) {
            case ColorStatus.Ok:
                return isLightMode ? lightThemeColors.success : darkThemeColors.success;
            case ColorStatus.Warning:
                return isLightMode ? lightThemeColors.warning : darkThemeColors.warning;
            case ColorStatus.Error:
                return isLightMode ? lightThemeColors.error : darkThemeColors.error;
            case ColorStatus.Undefined:
                return isLightMode
                    ? themeColors.colorPalettePrimary04
                    : themeColors.colorPalettePrimary05;
            default:
                return undefined;
        }
    };
    return (
        <Stack alignItems="center">
            <Typography variant="numericIndicator" color={getStatusColor()}>
                {value}
            </Typography>
            <Typography variant="labelXLarge" textAlign="center">
                {label}
            </Typography>
        </Stack>
    );
};

export default NumericDisplay;
