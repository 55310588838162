import { FormControlLabel, RadioGroup } from '@mui/material';
import { ControllerRenderProps, Path } from 'react-hook-form';
import { useLanguage } from '../../hooks/useLanguage';
import { RadioControl } from './radioControl';

export const formLabelId = (fieldName: string) => `${fieldName}Label`;
export const formFieldId = (fieldName: string) => `${fieldName}Input`;

export const RenderBinaryInput = <T1, T2 extends Path<T1>>({
    field,
    trueLabel,
    falseLabel,
    disabled,
    onFieldChange
}: {
    field: ControllerRenderProps<T1, T2>;
    trueLabel?: string;
    falseLabel?: string;
    disabled?: boolean;
    onFieldChange?: (newValue: boolean) => void;
}) => {
    const text = useLanguage();

    const handleBooleanChange = (
        _event: React.ChangeEvent<HTMLInputElement>,
        stringValue: string
    ) => {
        const isTrue = stringValue === 'true';
        if (onFieldChange !== undefined) {
            onFieldChange(isTrue);
        }
        field.onChange(isTrue);
    };

    return (
        <RadioGroup
            aria-labelledby={formLabelId(field.name)}
            name={field.name}
            value={field.value}
            onChange={handleBooleanChange}
            row
        >
            <FormControlLabel
                value={true}
                control={<RadioControl disabled={disabled ?? false} />}
                componentsProps={{
                    typography: {
                        variant: 'radioLabel'
                    }
                }}
                label={trueLabel ?? text.form.trueOptionLabel}
            />
            <FormControlLabel
                value={false}
                control={<RadioControl disabled={disabled ?? false} />}
                componentsProps={{
                    typography: {
                        variant: 'radioLabel'
                    }
                }}
                label={falseLabel ?? text.form.falseOptionLabel}
            />
        </RadioGroup>
    );
};
