import { Alert } from '@mui/material';
import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import {
    getAffectedGatewaysForDeviceDefinition,
    getDeviceConfiguration,
    updateDeviceDefinition
} from '../../../api/deviceDefinitionApi';
import { serializeDeviceConfiguration } from '../../../helperFunctions';
import { useLoad, useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import { routes } from '../../../pages';
import {
    DeviceDefinitionResponse,
    LocalDeviceDefinition,
    Protocol,
    DeviceDefinitionCreateRequest
} from '../../../types/deviceDefinitionTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import VerticalStack from '../../common/verticalStack';
import { DeviceDefinitionForm } from './deviceDefinitionForm';
import { getSignalUnitType } from "../../../signalHelperFunctions";

const deviceDefinitionPageRoute = `/${routes.deviceDefinitions}`;

const EditDeviceDefinitionPage = () => {
    const { deviceDefinitionEditScreen: editScreen } = useLanguage();

    const { deviceDefinitionId } = useParams();

    const idError =
        deviceDefinitionId !== undefined && uuidValidate(deviceDefinitionId)
            ? ''
            : `${editScreen.idError}${deviceDefinitionId}`;

    return deviceDefinitionId && idError === '' ? (
        <DeviceDefinitionLoad deviceDefinitionId={deviceDefinitionId} />
    ) : (
        <Alert severity="error">{idError}</Alert>
    );
};

const DeviceDefinitionLoad = ({ deviceDefinitionId }: { deviceDefinitionId: string }) => {
    const { deviceDefinitionEditScreen: editScreen } = useLanguage();
    const {
        deviceDefinitionsSource: {
            loading: getLoading,
            error: getError,
            setData: setDeviceDefinitions,
            data: deviceDefinitions
        }
    } = useDataSource();

    const deviceDefinitionData =
        deviceDefinitions &&
        deviceDefinitions.find((deviceDefinition) => deviceDefinition.id === deviceDefinitionId);

    return deviceDefinitionData ? (
        <EditDeviceDefinitionPageLoad
            deviceDefinition={deviceDefinitionData}
            deviceDefinitions={deviceDefinitions}
            setDeviceDefinitions={setDeviceDefinitions}
            deviceDefinitionsLoading={getLoading}
            deviceDefinitionsError={getError}
        />
    ) : (
        <Alert severity="error">{editScreen.deviceDefinitionIdNotFound}</Alert>
    );
};

const EditDeviceDefinitionPageLoad = ({
    deviceDefinition,
    deviceDefinitions,
    setDeviceDefinitions,
    deviceDefinitionsLoading,
    deviceDefinitionsError
}: {
    deviceDefinition: DeviceDefinitionResponse;
    deviceDefinitions: DeviceDefinitionResponse[];
    setDeviceDefinitions: (newData: DeviceDefinitionResponse[]) => void;
    deviceDefinitionsLoading: boolean;
    deviceDefinitionsError?: string;
}) => {
    const { deviceDefinitionEditScreen: editScreen } = useLanguage();
    const navigate = useNavigate();
    const deviceConfigurationLoadFunction = useCallback(
        () =>
            getDeviceConfiguration({
                deviceDefinitionId: deviceDefinition.id,
                protocol: deviceDefinition.protocol
            }),
        [deviceDefinition.id, deviceDefinition.protocol]
    );

    const {
        data: configurationData,
        loading: configurationLoading,
        error: configurationError
    } = useLoad(deviceConfigurationLoadFunction);

    const affectedGatewaysLoadFunction = useCallback(
        () => getAffectedGatewaysForDeviceDefinition(deviceDefinition.id),
        [deviceDefinition.id]
    );
    const affectedGatewaysLoad = useLoad(affectedGatewaysLoadFunction);

    const onSuccess = (
        _: { deviceDefinition: DeviceDefinitionCreateRequest; deviceDefinitionId: string },
        response: DeviceDefinitionResponse | string | undefined
    ) => {
        if (deviceDefinitions && response && !(typeof response === 'string')) {
            const otherDeviceDefinitions = deviceDefinitions.filter(
                (dd) => dd.id !== deviceDefinition.id
            );

            setDeviceDefinitions([...otherDeviceDefinitions, response]);
        }
        navigate(deviceDefinitionPageRoute);
    };

    const onCancel = () => {
        navigate(deviceDefinitionPageRoute);
    };

    const {
        executeRequest,
        loading: updateLoading,
        error: updateError
    } = useRequest<
        {
            deviceDefinition: DeviceDefinitionCreateRequest;
            deviceDefinitionId: string;
        },
        DeviceDefinitionResponse | string
    >(updateDeviceDefinition, onSuccess);

    const loading =
        updateLoading ||
        configurationLoading ||
        affectedGatewaysLoad.loading ||
        deviceDefinitionsLoading;
    const error =
        updateError || configurationError || affectedGatewaysLoad.error || deviceDefinitionsError;

    const onSubmit = (localDeviceDefinition: LocalDeviceDefinition) => {
        // Extract form values including unitType and dataUnit
        const { name, protocol, assetInfo, manufacturer, unitType, dataUnit } = localDeviceDefinition;

        const deviceDefinitionBody = {
            name,
            protocol,
            assetInfo,
            configuration: serializeDeviceConfiguration(localDeviceDefinition),
            manufacturer,
            unitType, // Include unitType
            dataUnit // Include dataUnit
        };

        if (deviceDefinition.id) {
            executeRequest({
                deviceDefinition: deviceDefinitionBody,
                deviceDefinitionId: deviceDefinition.id
            });
        }
    };

    return (
        <DefaultPageLayout pageHeader={editScreen.header} loading={loading} error={error}>
            <VerticalStack>
                {affectedGatewaysLoad.data && affectedGatewaysLoad.data.length > 0 && (
                    <Alert severity="warning">
                        {editScreen.affectedGatewaysWarning}
                        {affectedGatewaysLoad.data.length}
                    </Alert>
                )}
                {deviceDefinitions && configurationData && (
                    <DeviceDefinitionForm
                        deviceDefinitionValues={deviceDefinition}
                        existingDeviceDefinitions={deviceDefinitions}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        moxaModbusConfigurationValues={
                            (configurationData.protocol === Protocol.MoxaModbus &&
                                configurationData.moxaModbusConfiguration) ||
                            undefined
                        }
                        mqttConfigurationValues={
                            (configurationData.protocol === Protocol.Mqtt &&
                                configurationData.mqttConfiguration) ||
                            undefined
                        }
                        opcUAConfigurationValues={
                            (configurationData.protocol === Protocol.OpcUA &&
                                configurationData.opcUAConfiguration) ||
                            undefined
                        }
                        isEdit
                    />
                )}
            </VerticalStack>
        </DefaultPageLayout>
    );
};

export default EditDeviceDefinitionPage;
