import { baseApiUrl } from '../environmentVariables';
import { ApiResult, HttpMethod } from '../types/apiTypes';
import {
    CreateAsset,
    CreateUserAssetMapping,
    DeleteAsset,
    UserAssetMappingResponse
} from '../types/userAssetMappingTypes';
import { httpRequest } from './sharedApi';

const userAssetMappingsUrl = `${baseApiUrl}/userAssetMappings`;

export const getCurrentUserAssetMappings = () =>
    httpRequest<undefined, UserAssetMappingResponse[]>({
        url: `${userAssetMappingsUrl}/user`
    });

export const getUserAssetMappings = (userId: string) =>
    httpRequest<undefined, UserAssetMappingResponse[]>({
        url: `${userAssetMappingsUrl}/user/${userId}`
    });

export const setUserAssetMapping = (createUserAssetMapping: CreateUserAssetMapping) =>
    httpRequest<CreateUserAssetMapping, UserAssetMappingResponse>({
        method: HttpMethod.Put,
        url: `${userAssetMappingsUrl}/user`,
        body: createUserAssetMapping
    });

export const deleteUserAssetMapping = (assetId: string) =>
    httpRequest<undefined, undefined>({
        method: HttpMethod.Delete,
        url: `${userAssetMappingsUrl}/${assetId}`
    });

export const updateUserAssetMappings =
    (userId: string, assetsToAdd: CreateAsset[], assetsToDelete: DeleteAsset[]) =>
    async (authToken: string) => {
        const deletionPromises: Promise<ApiResult<undefined>>[] = assetsToDelete.map((a) =>
            deleteUserAssetMapping(a.id)(authToken)
        );
        const deletionResults =
            deletionPromises.length > 0 ? await Promise.all(deletionPromises) : [];
        const failedDeletion = deletionResults.find((r) => r.isFailure);
        if (failedDeletion) {
            return failedDeletion;
        }

        const additionPromises: Promise<ApiResult<UserAssetMappingResponse>>[] = assetsToAdd.map(
            (a) =>
                setUserAssetMapping({ assetId: a.assetId, assetType: a.assetType, userId: userId })(
                    authToken
                )
        );
        if (additionPromises.length === 0) {
            return deletionResults[0];
        }
        const additionResults = await Promise.all(additionPromises);

        const failedAddition = additionResults.find((r) => r.isFailure);
        if (failedAddition) {
            return failedAddition;
        }

        return additionResults[0];
    };
