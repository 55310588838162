import { AuthenticationType, BubbleLayerOptions } from 'azure-maps-control';
import { IAzureMapOptions } from 'react-azure-maps';
import { azureMapsSubscriptionKey } from '../environmentVariables';
import { lightThemeColors } from '../theme';

export const dataSourceId = 'MapDisplay AzureMapDataSourceProvider';

export const azureMapOptions: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: azureMapsSubscriptionKey
    },
    center: [-93.75, 29.75],
    zoom: 7,
    view: 'Auto'
};

export const customMarkerImageSprite = {
    id: 'custom-marker',
    templateName: 'marker',
    color: lightThemeColors.halliburtonRed,
    secondaryColor: lightThemeColors.halliburtonRed
};

export const bubbleLayerOptions: BubbleLayerOptions = {
    // scale the size of the clustered bubble based on the number of points in the cluster
    radius: 10,
    color: lightThemeColors.halliburtonRed,
    strokeWidth: 2,
    filter: ['has', 'point_count'] // only rendered data points which have a point_count property, which clusters do
};
