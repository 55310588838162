import { Stack, RadioGroup, FormControlLabel, Select, MenuItem } from '@mui/material';
import { ControllerRenderProps, Path } from 'react-hook-form';
import { useLanguage } from '../../../../hooks/useLanguage';
import { SignalEventClass } from '../../../../types/deviceDefinitionTypes';
import { FormElement } from '../../../common/formElement';
import { formLabelId } from '../../../common/formInputRenderFunctions';
import { RadioControl } from '../../../common/radioControl';

export const EventClassSelector = <T1, T2 extends Path<T1>>({
    field
}: {
    field: ControllerRenderProps<T1, T2>;
}) => {
    const { registerForm, moxaRegisterValidationErrors, enumDisplay, ...text } = useLanguage();
    const isEvent = field.value !== null;

    const handleIsEventChange = (_: React.ChangeEvent<HTMLInputElement>, stringValue: string) => {
        const setToTrue = stringValue === 'true';
        if (setToTrue) {
            field.onChange(SignalEventClass.Notify);
        } else {
            field.onChange(null);
        }
    };

    return (
        <Stack spacing={3}>
            <FormElement fieldName="" label={registerForm.isEventFieldLabel}>
                <RadioGroup
                    aria-labelledby="isEvent"
                    name="isEvent"
                    value={isEvent}
                    onChange={handleIsEventChange}
                    row
                >
                    <FormControlLabel
                        value={true}
                        control={<RadioControl />}
                        componentsProps={{
                            typography: {
                                variant: 'radioLabel'
                            }
                        }}
                        label={text.form.trueOptionLabel}
                    />
                    <FormControlLabel
                        value={false}
                        control={<RadioControl />}
                        componentsProps={{
                            typography: {
                                variant: 'radioLabel'
                            }
                        }}
                        label={text.form.falseOptionLabel}
                    />
                </RadioGroup>
            </FormElement>
            {isEvent && (
                <FormElement
                    fieldName="eventClass"
                    label={registerForm.eventClassFieldLabel}
                    tooltipTitle={registerForm.eventClassTooltip}
                >
                    <Select
                        value={field.value}
                        onChange={field.onChange}
                        labelId={formLabelId(field.name)}
                    >
                        <MenuItem value={SignalEventClass.Notify}>
                            {enumDisplay.eventClass.notify}
                        </MenuItem>
                        <MenuItem value={SignalEventClass.Warning}>
                            {enumDisplay.eventClass.warning}
                        </MenuItem>
                        <MenuItem value={SignalEventClass.Alarm}>
                            {enumDisplay.eventClass.alarm}
                        </MenuItem>
                        <MenuItem value={SignalEventClass.Fault}>
                            {enumDisplay.eventClass.fault}
                        </MenuItem>
                    </Select>
                </FormElement>
            )}
        </Stack>
    );
};
