import { faPen, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Stack, Button } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPslNameFromId } from '../../../helperFunctions';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { LayoutResponse } from '../../../types/layoutTypes';
import { PslResponse } from '../../../types/pslTypes';
import SearchBar from '../../common/searchBar';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';

const LayoutsList = ({
    layouts,
    psls,
    deleteLayout
}: {
    layouts: LayoutResponse[];
    psls: PslResponse[];
    deleteLayout: (layoutId: string) => void;
}) => {
    const { layoutsListScreen, sortableTable } = useLanguage();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState('');

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: layoutsListScreen.nameColumn,
            flex: 1
        },
        {
            field: 'assetInfo',
            headerName: layoutsListScreen.pslColumn,
            valueGetter: (params) => getPslNameFromId(psls, params.value.pslId),
            flex: 1
        },
        {
            field: 'notes',
            headerName: layoutsListScreen.notesColumn,
            flex: 1
        }
    ];

    const filteredLayouts = layouts.filter((l) =>
        l.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const deleteRow = (rowId: GridRowId) => {
        deleteLayout(rowId.toString());
    };

    const editRow = (rowId: GridRowId) => {
        navigate(`../${rowId.toString()}`);
    };

    return (
        <VerticalStack paddingTop={8} spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <Button variant="contained" onClick={() => navigate(`../${extensions.create}`)}>
                    {layoutsListScreen.newLayoutButton}
                </Button>
            </Stack>
            <SortableTable
                rows={filteredLayouts}
                columns={columns}
                getRowHeight={(params) => {
                    if (params.model.notes.length > 145) {
                        return 'auto';
                    }
                }}
                rowActions={[
                    { label: sortableTable.editButton, onClick: editRow, icon: faPen },
                    { label: sortableTable.deleteButton, onClick: deleteRow, icon: faCalendarMinus }
                ]}
            />
        </VerticalStack>
    );
};

export default LayoutsList;
