import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingDisplay from '../common/loadingDisplay';
import { routes } from '../../pages';

// Adapted from https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx

export const RequiredAuth = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    useEffect(() => {
        // if there's been an error loading the user's auth state (i.e. they haven't been granted access
        // to the app, redirect them to an error page)
        if (error && errorDescription) {
            navigate(`/${routes.oktaError}?error=${error}&errorDescription=${errorDescription}`, {
                replace: true
            });
        }

        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth, authState, error, errorDescription, navigate]);

    if (!authState || !authState?.isAuthenticated) {
        return <LoadingDisplay />;
    }

    return <Outlet />;
};
