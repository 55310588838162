import { Button, Stack, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useLanguage } from '../../hooks/useLanguage';

const LoggedOutDisplay = () => {
    const { oktaAuth } = useOktaAuth();
    const { logoutScreen } = useLanguage();

    const handleLogin = () => {
        oktaAuth.signInWithRedirect({ originalUri: window.location.origin });
    };

    return (
        <Stack paddingRight={4} paddingLeft={4} paddingTop={6} spacing={4}>
            <Typography variant="h5">{logoutScreen.description}</Typography>
            <Button variant="contained" sx={{ maxWidth: '25%' }} onClick={handleLogin}>
                {logoutScreen.logInButton}
            </Button>
        </Stack>
    );
};

export default LoggedOutDisplay;
