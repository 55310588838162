import { Autocomplete, TextField } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { pageSize } from '../../../../constants/styling';
import { useLanguage } from '../../../../hooks/useLanguage';
import { Signal } from '../../../../types/deviceDefinitionTypes';
import VerticalStack from '../../../common/verticalStack';
import TopicSignalsTable from './topicSignalsTable';

const TopicSignalsEditor = ({
    signalIdsForTopic,
    allSignals,
    onTopicSignalIdsChange,
    setError
}: {
    signalIdsForTopic: string[];
    allSignals: Signal[];
    onTopicSignalIdsChange: (topicSignalIds: string[]) => void;
    setError: (error: string) => void;
}) => {
    const { topicSignalsTable, topicValidationErrors } = useLanguage();
    const [page, setPage] = useState(0);

    const signalsForTopic = signalIdsForTopic
        .map((id) => {
            var signal = allSignals.find((s) => s.configurationElementId === id);
            if (signal === undefined) {
                setError(`${topicValidationErrors.noSignalFoundWithId}${id}`);
            }
            return signal ?? null;
        })
        .filter((s): s is Signal => s !== null); // user defined type guard to disallow null values

    const pagedSignals = signalsForTopic.slice(page * pageSize, page * pageSize + pageSize);

    const addSignalId = (signalId: string) => {
        if (!allSignals.some((s) => s.configurationElementId === signalId)) {
            setError(`${topicValidationErrors.noSignalFoundWithId}${signalId}`);
        } else {
            !signalIdsForTopic.some((id) => id === signalId) &&
                onTopicSignalIdsChange([...signalIdsForTopic, signalId]);
        }
    };

    const deleteRow = (rowId: GridRowId) => {
        onTopicSignalIdsChange(signalIdsForTopic.filter((id) => id !== rowId.toString()));
    };

    return (
        <VerticalStack spacing={3}>
            <Autocomplete
                size="small"
                options={allSignals.map((s) => ({ label: s.name, id: s.configurationElementId }))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size={'small'}
                        placeholder={topicSignalsTable.searchBoxSampleText}
                    />
                )}
                onChange={(_e, value) => {
                    if (!value) {
                        return;
                    }
                    addSignalId(value.id);
                }}
                isOptionEqualToValue={(option, value) => {
                    return value.id === option.id && value.label === option.label;
                }}
            />
            <TopicSignalsTable
                rows={pagedSignals}
                deleteRow={deleteRow}
                getRowId={(row) => row.configurationElementId}
                pagedData={{
                    page,
                    setPage,
                    pageSize: pageSize,
                    rowNumber: page * pageSize + 2 * pageSize
                }}
            />
        </VerticalStack>
    );
};

export default TopicSignalsEditor;
