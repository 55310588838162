import { useNavigate } from 'react-router-dom';
import { createDeviceDefinition } from '../../../api/deviceDefinitionApi';
import { getDefaultPsl, serializeDeviceConfiguration } from '../../../helperFunctions';
import { useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import { routes } from '../../../pages';
import {
    DeviceDefinitionCreateRequest,
    Protocol,
    DeviceDefinitionResponse,
    LocalDeviceDefinition
} from '../../../types/deviceDefinitionTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import { DeviceDefinitionForm } from './deviceDefinitionForm';

const deviceDefinitionPageRoute = `/${routes.deviceDefinitions}`;

const CreateDeviceDefinitionPage = () => {
    const { deviceDefinitionCreateScreen: createScreen } = useLanguage();
    const navigate = useNavigate();
    const {
        deviceDefinitionsSource: {
            loading: getLoading,
            error: getError,
            data: deviceDefinitions,
            setData: setDeviceDefinitions
        },
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();

    const onSuccess = (
        _: DeviceDefinitionCreateRequest,
        response: DeviceDefinitionResponse | string | undefined
    ) => {
        if (deviceDefinitions && response && !(typeof response === 'string')) {
            setDeviceDefinitions([...deviceDefinitions, response]);
        }
        navigate(deviceDefinitionPageRoute);
    };

    const onCancel = () => {
        navigate(deviceDefinitionPageRoute);
    };

    const {
        executeRequest,
        loading: createLoading,
        error: createError
    } = useRequest(createDeviceDefinition, onSuccess);

    const loading = getLoading || pslsLoading || createLoading;
    const error = getError || pslsError || createError;

    const onSubmit = (deviceDefinition: LocalDeviceDefinition) => {
        const createDeviceBody = {
            name: deviceDefinition.name,
            protocol: deviceDefinition.protocol,
            assetInfo: deviceDefinition.assetInfo,
            configuration: serializeDeviceConfiguration(deviceDefinition),
            manufacturer: deviceDefinition.manufacturer
        };
        executeRequest(createDeviceBody);
    };

    return (
        <DefaultPageLayout pageHeader={createScreen.header} loading={loading} error={error}>
            {deviceDefinitions && psls && (
                <DeviceDefinitionForm
                    deviceDefinitionValues={{
                        name: '',
                        protocol: Protocol.MoxaModbus,
                        assetInfo: { pslId: getDefaultPsl(psls) },
                        manufacturer: undefined
                    }}
                    existingDeviceDefinitions={deviceDefinitions}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )}
        </DefaultPageLayout>
    );
};

export default CreateDeviceDefinitionPage;
