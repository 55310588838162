import { Box } from '@mui/material';
import {
    DataGrid,
    GridRowsProp,
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowIdGetter,
    GridValidRowModel,
    GridRowParams,
    GridRowHeightParams,
    GridRowHeightReturnValue,
    GridSelectionModel
} from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSortDown,
    faAngleDown,
    faAngleUp,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { themeColors } from '../../theme';
import { useLanguage } from '../../hooks/useLanguage';
import LoadingDisplay from './loadingDisplay';
import CustomPagination from './customPagination';
import { useUser } from '../../hooks/useUser';
import { UserRole } from '../../types/authorizationTypes';
import { tableHeaderHeightPx } from '../../constants/styling';

interface RowAction {
    label: string;
    icon: IconDefinition;
    onClick: (rowId: GridRowId) => void;
    enable?: UserRole;
    visible?: UserRole;
}

interface PagingConfiguration {
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    rowNumber: number;
    isLoading: boolean;
}

interface SortableTableProps {
    rows: GridRowsProp;
    columns: GridColDef[];
    rowActions: RowAction[];
    pagedData?: PagingConfiguration;
    getRowId?: GridRowIdGetter<GridValidRowModel>;
    getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
    onRowSelect?: (selectionModel: GridSelectionModel) => void;
}

const SortableTable = ({
    rows,
    columns,
    rowActions,
    pagedData,
    getRowId,
    getRowHeight,
    onRowSelect
}: SortableTableProps) => {
    const text = useLanguage();
    const user = useUser();

    const actionColumn =
        rowActions.length <= 0
            ? undefined
            : {
                  field: 'actions',
                  type: 'actions',
                  width: 40,
                  getActions: (params: GridRowParams<GridValidRowModel>) =>
                      rowActions
                          .filter(
                              (ra) =>
                                  ra.visible === undefined || (user && ra.visible <= user.userRole)
                          )
                          .map((ra) => (
                              <GridActionsCellItem
                                  icon={
                                      <FontAwesomeIcon
                                          icon={ra.icon}
                                          color={themeColors.colorPalettePrimary06}
                                      />
                                  }
                                  label={ra.label}
                                  onClick={() => ra.onClick(params.id)}
                                  showInMenu
                                  disabled={
                                      ra.enable !== undefined && user && ra.enable > user.userRole
                                  }
                              />
                          ))
              };

    const gridColumns = actionColumn ? [...columns, actionColumn] : columns;

    const handlePageChange = (newPage: number) => {
        if (!pagedData || pagedData.isLoading) {
            return;
        }
        pagedData.setPage(newPage);
    };

    return (
        <Box height="100%" minHeight={`${tableHeaderHeightPx + 52}px`}>
            <DataGrid
                getRowId={getRowId}
                rows={pagedData?.isLoading ? [] : rows}
                columns={gridColumns}
                disableColumnSelector
                disableColumnMenu
                showColumnRightBorder
                headerHeight={tableHeaderHeightPx}
                getRowHeight={getRowHeight}
                checkboxSelection={onRowSelect !== undefined}
                onSelectionModelChange={onRowSelect}
                loading={pagedData?.isLoading}
                page={pagedData?.page ?? 0}
                paginationMode={pagedData ? 'server' : undefined}
                rowsPerPageOptions={pagedData ? [pagedData.pageSize] : undefined}
                pageSize={pagedData?.pageSize}
                rowCount={pagedData?.rowNumber ?? rows.length}
                hideFooter={!pagedData || (!pagedData && rows.length <= 100)}
                onPageChange={handlePageChange}
                components={{
                    ColumnUnsortedIcon: (_) => (
                        <Box>
                            <Box marginBottom="-8px">
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    size="xs"
                                    color={themeColors.colorPalettePrimary06}
                                />
                            </Box>
                            <Box>
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    size="xs"
                                    color={themeColors.colorPalettePrimary06}
                                />
                            </Box>
                        </Box>
                    ),
                    ColumnSortedAscendingIcon: (_) => <FontAwesomeIcon icon={faAngleUp} />,
                    ColumnSortedDescendingIcon: (_) => <FontAwesomeIcon icon={faAngleDown} />,
                    MoreActionsIcon: (_) => (
                        <FontAwesomeIcon
                            icon={faSortDown}
                            color={themeColors.colorPalettePrimary06}
                        />
                    ),
                    NoRowsOverlay: (_) => (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            {text.sortableTable.noRows}
                        </Box>
                    ),
                    LoadingOverlay: (_) => (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            <LoadingDisplay />
                        </Box>
                    ),
                    Pagination: CustomPagination
                }}
                componentsProps={{
                    pagination: { rowCount: rows.length }
                }}
            />
        </Box>
    );
};

export default SortableTable;
