export enum RequiredStatus {
    Required = 0,
    Optional = 1
}

export enum ColorStatus {
    Ok = 0,
    Warning = 1,
    Error = 2,
    Undefined = 3
}

export interface PieChartOption {
    label: string;
    value: number;
    lightModeColor: string;
    darkModeColor: string;
}
