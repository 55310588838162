import { faPen, faCalendarMinus, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Dialog, Stack, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';
import { LocalTag } from '../../../types/enrollmentTypes';
import LocalTagForm from './localTagForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LocalTagEditor = ({
    localTags,
    onLocalTagsChange
}: {
    localTags: LocalTag[];
    onLocalTagsChange: (localTags: LocalTag[]) => void;
}) => {
    const { localTagForm, sortableTable } = useLanguage();
    const [isEdit, setIsEdit] = useState(false);
    const [localTagToEdit, setLocalTagToEdit] = useState<LocalTag>();

    const defaultLocalTag = {
        key: '',
        value: ''
    };

    const columns: GridColDef[] = [
        {
            field: 'key',
            headerName: localTagForm.keyFieldLabel,
            flex: 1
        },
        { field: 'value', headerName: localTagForm.valueFieldLabel, flex: 1 }
    ];

    const updateRow = (localTagData: LocalTag) => {
        const otherLocalTags =
            localTagToEdit && isEdit
                ? localTags.filter((localTag) => localTag.key !== localTagToEdit.key)
                : localTags;

        onLocalTagsChange([...otherLocalTags, { ...localTagData }]);
    };

    const startEdit = (rowId: GridRowId) => {
        setIsEdit(true);
        setLocalTagToEdit(localTags.filter((localTag) => rowId === localTag.key)[0]);
    };

    const startAdd = () => {
        setIsEdit(false);
        setLocalTagToEdit(defaultLocalTag);
    };

    const deleteRow = (rowId: GridRowId) => {
        onLocalTagsChange(localTags.filter((localTag) => localTag.key !== rowId.toString()));
    };

    return (
        <VerticalStack marginTop={8}>
            <Stack direction="row" justifyContent="space-between" spacing={2} paddingBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    flexDirection="row"
                    paddingTop={2.5}
                >
                    <Typography variant="h4" display="flex">
                        {localTagForm.gatewayTagsHeader}
                    </Typography>
                    <Box display="flex">
                        <Tooltip title={localTagForm.gatewayTagsTooltip}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </Tooltip>
                    </Box>
                </Stack>
                <Button variant="contained" onClick={startAdd}>
                    {localTagForm.addButton}
                </Button>
            </Stack>
            <SortableTable
                rows={localTags}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    {
                        label: sortableTable.deleteButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                getRowId={(row) => row.key}
            ></SortableTable>
            <Dialog open={localTagToEdit !== undefined}>
                {localTagToEdit && (
                    <LocalTagForm
                        isEdit={isEdit}
                        updateLocalTag={updateRow}
                        handleClose={() => setLocalTagToEdit(undefined)}
                        defaultValues={localTagToEdit}
                        existingLocalTags={localTags}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default LocalTagEditor;
