import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeviceDefinitions } from '../../../hooks/useDeviceDefinitions';
import { useLanguage } from '../../../hooks/useLanguage';
import { LocalDevice } from '../../../types/deviceTypes';
import UnsavedChangesIndicator from '../../common/unsavedChangesIndicator';
import DeviceFormInputs from './deviceFormInputs';

const DeviceForm = ({
    isEdit,
    handleClose,
    updateDevice,
    defaultValues,
    devices
}: {
    isEdit: boolean;
    handleClose: () => void;
    updateDevice: (deviceData: LocalDevice) => void;
    defaultValues: LocalDevice;
    devices: LocalDevice[];
}) => {
    const { deviceForm } = useLanguage();
    const deviceDefinitions = useDeviceDefinitions();
    const form = useForm<LocalDevice>({
        defaultValues
    });
    const [error, setError] = useState<string>();

    const defaultProtocol = deviceDefinitions.find(
        (dd) => dd.id === defaultValues.deviceDefinitionId
    )?.protocol;

    const onSubmit = (device: LocalDevice) => {
        updateDevice(device);
        setError('');
        handleClose();
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    return form.handleSubmit(onSubmit)(e);
                }}
            >
                <Stack spacing={3}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h2">
                            {isEdit ? deviceForm.editDeviceHeader : deviceForm.addDeviceHeader}
                        </Typography>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Tooltip title={deviceForm.headerTooltip}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </Tooltip>
                        </Box>
                    </Stack>
                    {error && <Alert severity="error">{error}</Alert>}

                    <DeviceFormInputs devices={devices} defaultProtocol={defaultProtocol} />
                    <Stack>
                        {form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" justifyContent="end" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {deviceForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {isEdit ? deviceForm.updateButton : deviceForm.addButton}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default DeviceForm;
