import { styled, ToggleButton, ToggleButtonProps } from '@mui/material';
import { themeColors } from '../../theme';

const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>(() => ({
    '&.Mui-selected': {
        backgroundColor: themeColors.colorPalettePrimary07,
        color: themeColors.colorPalettePrimary02
    }
}));

export default StyledToggleButton;
