import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useLanguage } from '../../../../hooks/useLanguage';
import { LocalDeviceDefinition, Topic } from '../../../../types/deviceDefinitionTypes';
import UnsavedChangesIndicator from '../../../common/unsavedChangesIndicator';
import VerticalStack from '../../../common/verticalStack';
import TopicFormInputs from './topicFormInputs';

const TopicForm = ({
    isEdit,
    handleClose,
    updateTopic,
    defaultValues,
    topics
}: {
    isEdit: boolean;
    handleClose: () => void;
    updateTopic: (topic: Topic) => void;
    defaultValues: Topic;
    topics: Topic[];
}) => {
    const { topicForm, editTopicForm, addTopicForm } = useLanguage();
    const form = useForm<Topic>({
        defaultValues
    });

    const deviceDefinitionForm = useFormContext<LocalDeviceDefinition>();
    const allSignals = deviceDefinitionForm.watch('mqttSignals');

    const onSubmit = (topic: Topic) => {
        updateTopic(topic);
        handleClose();
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    form.handleSubmit(onSubmit)(e);
                }}
                style={{ height: '100%' }}
            >
                <VerticalStack spacing={3} marginBottom={5}>
                    <Typography variant="h2">
                        {isEdit ? editTopicForm.header : addTopicForm.header}
                    </Typography>
                    <TopicFormInputs topics={topics} allSignals={allSignals} />
                    <Stack spacing={2}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" justifyContent="end" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {topicForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {isEdit ? topicForm.updateButton : topicForm.addButton}
                            </Button>
                        </Stack>
                    </Stack>
                </VerticalStack>
            </form>
        </FormProvider>
    );
};

export default TopicForm;
