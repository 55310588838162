import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeExternalUserFromGroup } from '../../../api/externalUserApi';
import { pageSize } from '../../../constants/styling';
import { useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { User } from '../../../types/userTypes';
import SearchBar from '../../common/searchBar';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';

const ExternalUsersList = ({
    externalUsers,
    setExternalUsers
}: {
    externalUsers: User[];
    setExternalUsers: (users: User[]) => void;
}) => {
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { externalUserListScreen } = useLanguage();
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'name',
            valueGetter: (params) => {
                return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
            headerName: externalUserListScreen.nameColumn,
            flex: 1
        },
        {
            field: 'email',
            headerName: externalUserListScreen.emailColumn,
            flex: 1
        }
    ];

    const pagedExternalUsers = useMemo(
        () => externalUsers.slice(page * pageSize, page * pageSize + pageSize),
        [page, externalUsers]
    );

    const onDeleteSuccess = (body: string, _: undefined) => {
        setExternalUsers(externalUsers.filter((u) => u.id !== body));
    };
    const {
        executeRequest: deleteRequest,
        error: deleteError,
        loading: deleteLoading
    } = useRequest(removeExternalUserFromGroup, onDeleteSuccess);

    const deleteRow = (rowId: GridRowId) => {
        deleteRequest(rowId.toString());
    };

    const externalUsersToDisplay = pagedExternalUsers.filter(
        (eu) =>
            eu.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            eu.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <VerticalStack spacing={3} paddingTop={8}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <Button variant="contained" onClick={() => navigate(extensions.add)}>
                    {externalUserListScreen.addUserButton}
                </Button>
            </Stack>
            {deleteError && <Alert severity="error">{deleteError}</Alert>}
            <SortableTable
                columns={columns}
                rows={externalUsersToDisplay ?? []}
                rowActions={[
                    {
                        label: externalUserListScreen.removeUserActionButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                pagedData={{
                    page,
                    setPage,
                    pageSize,
                    rowNumber: page * pageSize + 2 * pageSize,
                    isLoading: deleteLoading
                }}
            />
        </VerticalStack>
    );
};

export default ExternalUsersList;
