import {
    Alert,
    Button,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTimezoneEnum } from '../../../hooks/enumHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { GatewayCreateRequest } from '../../../types/gatewayTypes';
import { LayoutResponse } from '../../../types/layoutTypes';
import { FormElement } from '../../common/formElement';
import { formFieldId, formLabelId } from '../../common/formInputRenderFunctions';
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid';
import { typography, themeValues } from '../../../theme';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { UserRole } from '../../../types/authorizationTypes';
import ConfirmAssetInfoEditor from '../../common/confirmAssetInfoEditor';
import { dateDataFormat } from '../../../constants/formatting';
import { mediumString, shortString } from '../../../constants/validation';
import { httpRequest } from '../../../api/sharedApi';
import { ApiResult, HttpMethod } from '../../../types/apiTypes';
import { baseApiUrl } from '../../../environmentVariables';



const GatewayFormInputs = ({
    layouts,
    isEdit
}: {
    layouts: LayoutResponse[];
    isEdit?: boolean;
}) => {
    const { register, control, setValue, watch, setError, clearErrors  } = useFormContext<GatewayCreateRequest>();
    const { gatewayForm, form } = useLanguage();
    const { displayTimezone, timezones } = useTimezoneEnum();
    const { disabled, visibility } = useAuthorization({
        enable: UserRole.InternalUser,
        visible: UserRole.InternalUser
    });

    const layout = layouts.find((l) => l.id === watch('layoutId'));

    const enableRtsForwarding = watch('enableRtsForwarding');
    const requiredIfRtsForwardingEnabled = enableRtsForwarding ? form.required : '';

    const handleGenerateGuid = async () => {
        const wellName = watch('wellName');
        const params = {
            url: `${baseApiUrl}/guid`,
            method: HttpMethod.Post,
            body: {
                SEED: wellName,
                objecttype: 'com.halliburton.domain.well'
            }
        };

        try {
            const fetchGuid = httpRequest<typeof params.body, { guid: string; guidStatus: string; seed: string }>(params);
            const response: ApiResult<{ guid: string; guidStatus: string; seed: string }> = await fetchGuid('your_auth_token'); // Replace 'your_auth_token' with the actual token
            if (response.isSuccess && response.data) {
                if (response.data.guidStatus === "IN_USE") {
                    setError('wellId', { type: 'manual', message: 'Well name in use.' });
                    setValue('wellId', response.data.guid, { shouldDirty: true });
                } else {
                    clearErrors('wellId');
                    setValue('wellId', response.data.guid, { shouldDirty: true });
                }
            } else {
                throw new Error(`Error fetching GUID: ${response.errorCode}`);
            }
        } catch (error) {
            console.error('Error fetching GUID:', error);
        }
    };



    return (
        <Stack spacing={2} maxWidth="85%">
            <Stack spacing={2} maxWidth="50%">
                <FormElement<GatewayCreateRequest>
                    fieldName="serialNumber"
                    label={gatewayForm.serialNumberFieldLabel}
                    tooltipTitle={gatewayForm.serialNumberTooltip}
                >
                    <OutlinedInput
                        {...register('serialNumber', { required: form.required })}
                        disabled
                        id={formFieldId('serialNumber')}
                    />
                </FormElement>
                <FormElement<GatewayCreateRequest>
                    fieldName="azureDeviceId"
                    label={gatewayForm.azureDeviceIdFieldLabel}
                    tooltipTitle={gatewayForm.azureIdTooltip}
                >
                    <OutlinedInput
                        {...register('azureDeviceId', { required: form.required })}
                        disabled
                        id={formFieldId('azureDeviceId')}
                    />
                </FormElement>
                <FormElement<GatewayCreateRequest>
                    fieldName="name"
                    label={gatewayForm.nameFieldLabel}
                    tooltipTitle={gatewayForm.nameTooltip}
                >
                    <OutlinedInput
                        {...register('name', {
                            required: form.required,
                            maxLength: { value: mediumString, message: form.tooLong }
                        })}
                        disabled={disabled}
                        id={formFieldId('name')}
                    />
                </FormElement>
                <FormElement<GatewayCreateRequest>
                    fieldName="equipmentNumber"
                    label={gatewayForm.equipmentNumberFieldLabel}
                    tooltipTitle={gatewayForm.equipmentNumberTooltip}
                >
                    <OutlinedInput
                        {...register('equipmentNumber', {
                            required: form.required,
                            maxLength: { value: mediumString, message: form.tooLong }
                        })}
                        disabled={disabled}
                        id={formFieldId('equipmentNumber')}
                    />
                </FormElement>

                <FormElement<GatewayCreateRequest>
                    fieldName="costCenter"
                    label={gatewayForm.costCenterFieldLabel}
                    tooltipTitle={gatewayForm.costCenterTooltip}
                >
                    <OutlinedInput
                        {...register('costCenter', {
                            required: form.required,
                            maxLength: { value: mediumString, message: form.tooLong }
                        })}
                        disabled={disabled}
                        id={formFieldId('costCenter')}
                    />
                </FormElement>
                
                <FormElement<GatewayCreateRequest> fieldName="assetInfo" label="">
                    <Controller
                        control={control}
                        name="assetInfo"
                        render={({ field }) => (
                            <ConfirmAssetInfoEditor
                                assetInfo={field.value}
                                onAssetInfoChange={field.onChange}
                                disabled={isEdit}
                                confirmChange={{
                                    dialogText: gatewayForm.pslChangeWarning,
                                    secondaryAction: () => setValue('layoutId', '')
                                }}
                            />
                        )}
                    />
                </FormElement>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Stack spacing={6} width="50%">
                    {layouts.length === 0 && (
                        <Alert severity="warning">{gatewayForm.noLayoutError}</Alert>
                    )}
                    <FormElement<GatewayCreateRequest>
                        fieldName="layoutId"
                        label={gatewayForm.layoutFieldLabel}
                        tooltipTitle={gatewayForm.layoutTooltip}
                    >
                        <Controller
                            control={control}
                            name="layoutId"
                            render={({ field }) => (
                                <Select
                                    value={field.value}
                                    onChange={field.onChange}
                                    disabled={disabled}
                                    labelId={formLabelId(field.name)}
                                >
                                    {layouts
                                        .filter(
                                            (l) => l.assetInfo.pslId === watch('assetInfo.pslId')
                                        )
                                        .map((l) => (
                                            <MenuItem key={l.id} value={l.id}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                        />
                    </FormElement>
                    {/* RTS section */}
                    <Stack>
                        <FormElement<GatewayCreateRequest> fieldName="enableRtsForwarding" label="">
                            <Controller
                                control={control}
                                name="enableRtsForwarding"
                                render={({ field }) => (
                                    <FormControlLabel
                                        label={gatewayForm.enableRtsForwardingFieldLabel}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                ...typography.labelForm
                                            }
                                        }}
                                        control={
                                            <Switch
                                                value={field.value}
                                                checked={field.value}
                                                onChange={field.onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                        </FormElement>
                        {enableRtsForwarding && (
                            <Stack>
                                <FormElement<GatewayCreateRequest>
                                    fieldName="wellName"
                                    label={gatewayForm.wellNameFieldLabel}
                                    tooltipTitle={gatewayForm.wellNameTooltip}
                                >
                                    <OutlinedInput
                                        {...register('wellName', {
                                            required: requiredIfRtsForwardingEnabled,
                                            maxLength: {
                                                value: mediumString,
                                                message: form.tooLong
                                            }
                                        })}
                                        disabled={disabled}
                                        id={formFieldId('wellName')}
                                    />
                                </FormElement>

                                <Stack direction="row" spacing={2}>
                                    <FormElement<GatewayCreateRequest>
                                        fieldName="wellId"
                                        label={gatewayForm.wellIdFieldLabel}
                                        tooltipTitle={gatewayForm.wellIdTooltip}
                                        maxWidthPx={170}
                                    >
                                        <OutlinedInput
                                            {...register('wellId', {
                                                required: requiredIfRtsForwardingEnabled,
                                                validate: (v) =>
                                                    !v ||
                                                    uuidValidate(v) ||
                                                    gatewayForm.guidValidationError
                                            })}
                                            disabled={disabled}
                                            id={formFieldId('wellId')}
                                        />
                                    </FormElement>
                                    <Button
                                        variant="contained"
                                        onClick={handleGenerateGuid}
                                        disabled={disabled || !watch('wellName')}
                                        sx={{
                                            // Goal: bump the button down to align with input
                                            // Challenge: Stack has a pretty specific margin rule
                                            // Solution: beat Stack's specificity with this selector
                                            '&&': { marginTop: themeValues.formLabelHeightPx },
                                            visibility
                                        }}
                                    >
                                        {gatewayForm.generateWellIdButton}
                                    </Button>
                                </Stack>

                                <FormElement<GatewayCreateRequest>
                                    fieldName="timezone"
                                    label={gatewayForm.timezoneFieldLabel}
                                    tooltipTitle={gatewayForm.timezoneTooltip}
                                >
                                    <Controller
                                        control={control}
                                        name="timezone"
                                        rules={{ required: requiredIfRtsForwardingEnabled }}
                                        render={({ field }) => (
                                            <Select
                                                value={field.value}
                                                onChange={field.onChange}
                                                disabled={disabled}
                                                labelId={formLabelId(field.name)}
                                            >
                                                {timezones.map((tz) => (
                                                    <MenuItem key={tz} value={tz}>
                                                        {displayTimezone(tz)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormElement>

                                <Stack direction="row" spacing={4}>
                                    <FormElement<GatewayCreateRequest>
                                        fieldName="latitude"
                                        label={gatewayForm.latitudeFieldLabel}
                                        tooltipTitle={gatewayForm.latitudeTooltip}
                                        maxWidthPx={170}
                                    >
                                        <OutlinedInput
                                            {...register('latitude', {
                                                required: requiredIfRtsForwardingEnabled,
                                                maxLength: {
                                                    value: shortString,
                                                    message: form.tooLong
                                                }
                                            })}
                                            disabled={disabled}
                                            id={formFieldId('latitude')}
                                        />
                                    </FormElement>
                                    <FormElement<GatewayCreateRequest>
                                        fieldName="longitude"
                                        label={gatewayForm.longitudeFieldLabel}
                                        tooltipTitle={gatewayForm.longitudeTooltip}
                                        maxWidthPx={170}
                                    >
                                        <OutlinedInput
                                            {...register('longitude', {
                                                required: requiredIfRtsForwardingEnabled,
                                                maxLength: {
                                                    value: shortString,
                                                    message: form.tooLong
                                                }
                                            })}
                                            disabled={disabled}
                                            id={formFieldId('longitude')}
                                        />
                                    </FormElement>
                                </Stack>
                                <FormElement<GatewayCreateRequest>
                                    fieldName="operator"
                                    label={gatewayForm.operatorFieldLabel}
                                    tooltipTitle={gatewayForm.operatorTooltip}
                                >
                                    <OutlinedInput
                                        {...register('operator', {
                                            required: requiredIfRtsForwardingEnabled
                                        })}
                                        disabled={disabled}
                                        id={formFieldId('operator')}
                                    />
                                </FormElement>
                                <FormElement<GatewayCreateRequest>
                                    fieldName="spudDate"
                                    label={gatewayForm.spudDateFieldLabel}
                                    tooltipTitle={gatewayForm.spudDateTooltip}
                                >
                                    <Controller
                                        control={control}
                                        name="spudDate"
                                        rules={{
                                            required: requiredIfRtsForwardingEnabled
                                        }}
                                        render={({ field }) => (
                                            <DatePicker
                                                label=""
                                                value={dayjs(field.value)}
                                                onChange={(value) =>
                                                    value &&
                                                    field.onChange(value.format(dateDataFormat))
                                                }
                                                disabled={disabled}
                                                renderInput={(p) => (
                                                    <TextField
                                                        {...p}
                                                        InputLabelProps={{ shrink: false }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FormElement>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                {layout?.notes !== undefined && (
                    <Stack spacing={2} width="50%" paddingTop={3}>
                        <Typography fontSize="small" paddingLeft={2}>
                            <Typography variant="labelForm">
                                {gatewayForm.layoutNotesLabel}
                            </Typography>
                            {layout?.notes}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default GatewayFormInputs;
