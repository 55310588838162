import { faPen, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Dialog, Stack, Typography } from '@mui/material';
import { GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { defaultSignal } from '../../../../defaultValues';
import { useSignalDataTypeEnum } from '../../../../hooks/enumHooks';
import { useLanguage } from '../../../../hooks/useLanguage';
import { LocalDeviceDefinition, Signal } from '../../../../types/deviceDefinitionTypes';
import SortableTable from '../../../common/sortableTable';
import VerticalStack from '../../../common/verticalStack';
import SignalForm from './signalForm';
import SignalImportExportButtons from './signalImportExportButtons';

const SignalEditor = ({
    signals,
    onSignalsChange
}: {
    signals: Signal[];
    onSignalsChange: (signals: Signal[]) => void;
}) => {
    const { signalValidationErrors, signalDefinition, sortableTable } = useLanguage();

    const form = useFormContext<LocalDeviceDefinition>();

    const { dataTypeValueFormatter } = useSignalDataTypeEnum();
    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [signalToEdit, setSignalToEdit] = useState<Signal>();
    const [signalIdsToDelete, setSignalIdsToDelete] = useState<GridRowId[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: signalDefinition.nameColumn,
            flex: 1
        },
        {
            field: 'dataType',
            headerName: signalDefinition.dataTypeColumn,
            valueFormatter: (params) => dataTypeValueFormatter(params.value),
            flex: 1
        }
    ];

    const updateRow = (signal: Signal) => {
        const otherSignals =
            signalToEdit && isEdit ? signals.filter((s) => s.name !== signalToEdit.name) : signals;

        const signalIds = otherSignals.map((s) => s.name);
        if (signalIds.includes(signal.name)) {
            setError(signalValidationErrors.namesNotUnique);
            return;
        }

        onSignalsChange([...otherSignals, { ...signal }]);
        setError('');
    };

    const startEdit = (rowId: GridRowId) => {
        setIsEdit(true);
        setSignalToEdit(signals.find((s) => rowId === s.name));
    };

    const startAdd = () => {
        setIsEdit(false);
        setSignalToEdit({ ...defaultSignal, configurationElementId: uuidv4() });
    };

    const deleteRow = (rowId: GridRowId) => {
        const allTopicSignalIds = form
            .watch('mqttTopics')
            .map((t) => t.mqttSignalConfigurationElementIds)
            .flat();
        const signal = signals.find((s) => s.name === rowId);

        if (allTopicSignalIds.some((id) => id === signal?.configurationElementId)) {
            setError(
                `${signalValidationErrors.cannotDeleteSignalUsedByTopicErrorPrefix}${rowId}${signalValidationErrors.cannotDeleteSignalUsedByTopicErrorPostfix}`
            );
            return;
        }

        setError('');
        onSignalsChange(signals.filter((s) => s.name !== rowId.toString()));
    };

    const onRowSelect = (selectionModel: GridSelectionModel) => {
        setSignalIdsToDelete(selectionModel);
    };

    const deleteMultipleRows = () => {
        onSignalsChange(signals.filter((s) => !signalIdsToDelete.includes(s.name)));
    };

    return (
        <VerticalStack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">{signalDefinition.header}</Typography>
                <Stack direction="row" spacing={2} marginBottom={3} marginTop={2}>
                    <SignalImportExportButtons
                        signals={signals}
                        onSignalsChange={onSignalsChange}
                        setError={setError}
                    />
                    <Button variant="contained" onClick={startAdd}>
                        {signalDefinition.addSignalButton}
                    </Button>
                    <Button variant="contained" onClick={deleteMultipleRows}>
                        {signalDefinition.deleteSelectedSignalsButton}
                    </Button>
                </Stack>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={signals}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    {
                        label: sortableTable.deleteButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                getRowId={(row) => row.name}
                onRowSelect={onRowSelect}
            />
            <Dialog open={signalToEdit !== undefined}>
                {signalToEdit && (
                    <SignalForm
                        isEdit={isEdit}
                        updateSignal={updateRow}
                        handleClose={() => setSignalToEdit(undefined)}
                        defaultValues={signalToEdit}
                        signals={signals}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default SignalEditor;
