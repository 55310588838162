export enum HttpMethod {
    Post = 'POST',
    Get = 'GET',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE'
}

export enum HttpStatusCode {
    BackendUnreachable = 0,
    OK = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    ServerError = 500
}

export interface ApiResult<DataType> {
    status: HttpStatusCode;
    errorCode?: string;
    isSuccess: boolean;
    isFailure: boolean;
    data?: DataType;
}

export interface ApiFailure {
    error: string;
    errorCode?: string;
}

export interface RequestParameters<BodyType> {
    url: string;
    method?: HttpMethod; // default 'get'
    contentType?: string; // default application/json
    body?: BodyType;
}

export interface FullEntityFields {
    id: string;
    externalId: string;
    createdDate: string;
    createdBy: string;
    updatedDate: string;
    updatedBy: string;
}

export interface SourceData<T> {
    loading: boolean;
    setData: (newData?: T) => void;
    error?: string;
    data?: T;
}
