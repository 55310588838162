import { Stack, Typography, OutlinedInput } from '@mui/material';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { useLanguage } from '../../../../hooks/useLanguage';
import { LocalDeviceDefinition, OpcUANode } from '../../../../types/deviceDefinitionTypes';
import { FormElement } from '../../../common/formElement';
import { formFieldId, RenderBinaryInput } from '../../../common/formInputRenderFunctions';
import VerticalStack from '../../../common/verticalStack';
import {
    filepathString,
    mediumString,
    shortString,
    xLongString
} from '../../../../constants/validation';
import { isFloat } from '../../../../helperFunctions';
import OpcUANodeEditor from './opcUANodeEditor';
import { useNodeValidation } from '../../../../hooks/useNodeValidation';
import { validateDataUnit } from '../../../../signalHelperFunctions';
import { floatInputProps } from '../../../../constants/formatting';

const minSessionRenewalMinutes = 0;

const OpcUAConfigurationEditor = () => {
    const {
        form: formText,
        deviceDefinitionForm,
        opcUACommunicationDefinition,
        opcUANodeFields,
        opcUANodeValidationErrors
    } = useLanguage();
    const form = useFormContext<LocalDeviceDefinition>();
    const { validateNodeId, validateNamespaceId, getNodeAddressString } = useNodeValidation();

    const sessionRenewalRequired = form.watch('opcUASessionRenewalRequired');

    const validateSessionRenewalMinutes = (sessionRenewalMinutes?: number | null | undefined) => {
        const errorMessage: string[] = [];

        if (!sessionRenewalRequired) return true;

        // If session renewal is required, then sessionRenewalMinutes should be a float greater than 0
        if (sessionRenewalMinutes === undefined || sessionRenewalMinutes === null)
            return opcUANodeValidationErrors.sessionRenewalMinutesRequired;

        if (!isFloat(sessionRenewalMinutes)) {
            errorMessage.push(formText.floatTypeRequired);
        }
        if (sessionRenewalMinutes <= minSessionRenewalMinutes) {
            errorMessage.push(
                `${opcUANodeValidationErrors.invalidSessionRenewalMinutesPrefix}${minSessionRenewalMinutes}${opcUANodeValidationErrors.invalidSessionRenewalMinutesSuffix}`
            );
        }

        const finalMessage = errorMessage.join(', ');
        if (finalMessage === '') {
            return true;
        }
        return `${opcUANodeValidationErrors.nodeErrors}${finalMessage}`;
    };

    const validateNodeLengths = (node: OpcUANode) => {
        const errorMessage: string[] = [];

        if (!node.name || node.name === '') {
            errorMessage.push(`${opcUANodeFields.name}${opcUANodeValidationErrors.required}`);
        }
        if (node.name.length > mediumString) {
            errorMessage.push(
                `${opcUANodeFields.name}${opcUANodeValidationErrors.maximumLength}${mediumString}`
            );
        }
        if (node.dataUnit.length > shortString) {
            errorMessage.push(
                `${opcUANodeFields.dataUnit}${opcUANodeValidationErrors.maximumLength}${shortString}`
            );
        }
        if (node.nodeId.length > xLongString) {
            errorMessage.push(
                `${opcUANodeFields.nodeId}${opcUANodeValidationErrors.maximumLength}${xLongString}`
            );
        }

        const finalMessage = errorMessage.join(', ');
        if (finalMessage === '') {
            return finalMessage;
        }
        return `${node.name}${opcUANodeValidationErrors.nodeErrors}${finalMessage}`;
    };

    const validateNodeUnit = (node: OpcUANode) => {
        const unitIsValid = validateDataUnit(node.dataUnit);
        return unitIsValid
            ? ''
            : `${opcUANodeValidationErrors.invalidDataUnitPrefix}${node.dataUnit}${opcUANodeValidationErrors.invalidDataUnitSuffix}${node.name}`;
    };

    const validateNodes = (nodes: OpcUANode[]) => {
        var errors = [];

        // Ensure at least 1 node
        if (nodes.length === 0) errors.push(opcUANodeValidationErrors.mustHaveAtLeastOneNode);

        // Validate uniqueness of node address by turning into Set and ensuring length matches what is expected
        var nodeAddressSet = new Set(nodes.map((n) => getNodeAddressString(n)));
        if (nodeAddressSet.size !== nodes.length)
            errors.push(opcUANodeValidationErrors.nodeAddressesNotUnique);

        // Validate other fields
        errors.push(...nodes.map((n) => validateNodeId(n)).filter((e) => e !== ''));
        errors.push(...nodes.map((n) => validateNodeLengths(n)).filter((e) => e !== ''));
        // errors.push(...nodes.map((n) => validateNodeUnit(n)).filter((e) => e !== ''));
        errors.push(...nodes.map((n) => validateNamespaceId(n)).filter((e) => e !== ''));

        if (errors.length > 0) {
            return errors.join('. ');
        }
        return;
    };

    const handleSessionRenewalRequiredChange = (sessionRenewalRequired: boolean) => {
        if (sessionRenewalRequired) {
            return;
        }
        form.setValue('opcUASessionRenewalMinutes', null, { shouldDirty: true });
    };

    return (
        <VerticalStack>
            <Typography variant="h4" paddingTop={6} paddingBottom={3}>
                {deviceDefinitionForm.communicationDefinitionHeader}
            </Typography>
            <FormProvider {...form}>
                <Stack maxWidth="33%">
                    <Stack direction="row">
                        <FormElement<LocalDeviceDefinition>
                            fieldName="opcUASessionRenewalRequired"
                            label={opcUACommunicationDefinition.sessionRenewalRequiredLabel}
                            tooltipTitle={
                                opcUACommunicationDefinition.sessionRenewalRequiredTooltip
                            }
                        >
                            <Controller
                                control={form.control}
                                name="opcUASessionRenewalRequired"
                                render={({ field }) => (
                                    <RenderBinaryInput
                                        field={field}
                                        onFieldChange={handleSessionRenewalRequiredChange}
                                    />
                                )}
                            />
                        </FormElement>
                        <FormElement<LocalDeviceDefinition>
                            fieldName="opcUASessionRenewalMinutes"
                            label={opcUACommunicationDefinition.sessionRenewalMinutesLabel}
                            tooltipTitle={opcUACommunicationDefinition.sessionRenewalMinutesTooltip}
                        >
                            <Controller
                                control={form.control}
                                name="opcUASessionRenewalMinutes"
                                rules={{
                                    validate: validateSessionRenewalMinutes
                                }}
                                render={({ field }) => (
                                    <OutlinedInput
                                        id={formFieldId('opcUASessionRenewalMinutes')}
                                        value={field.value !== null ? field.value : ''}
                                        onChange={(e) =>
                                            field.onChange(
                                                isFloat(e.target.value)
                                                    ? parseFloat(e.target.value)
                                                    : null
                                            )
                                        }
                                        disabled={!sessionRenewalRequired}
                                        type="number"
                                        slotProps={floatInputProps}
                                    />
                                )}
                            />
                        </FormElement>
                    </Stack>
                    <Stack direction="row">
                        <FormElement<LocalDeviceDefinition>
                            fieldName="opcUASecurityEnabled"
                            label={opcUACommunicationDefinition.securityEnabledLabel}
                            tooltipTitle={opcUACommunicationDefinition.securityEnabledTooltip}
                        >
                            <Controller
                                control={form.control}
                                name="opcUASecurityEnabled"
                                render={({ field }) => (
                                    <RenderBinaryInput field={field} disabled={true} />
                                )}
                            />
                        </FormElement>
                        <FormElement<LocalDeviceDefinition>
                            fieldName="opcUAApplicationCertificatePath"
                            label={opcUACommunicationDefinition.applicationCertificatePathLabel}
                            tooltipTitle={
                                opcUACommunicationDefinition.applicationCertificatePathTooltip
                            }
                        >
                            <OutlinedInput
                                {...form.register('opcUAApplicationCertificatePath', {
                                    maxLength: filepathString
                                })}
                                id={formFieldId('opcUAApplicationCertificatePath')}
                                disabled
                            />
                        </FormElement>
                    </Stack>
                </Stack>
                <FormElement<LocalDeviceDefinition> fieldName="opcUANodes" label="">
                    <Controller
                        control={form.control}
                        name="opcUANodes"
                        rules={{ validate: validateNodes }}
                        render={({ field }) => (
                            <OpcUANodeEditor nodes={field.value} onNodesChange={field.onChange} />
                        )}
                    />
                </FormElement>
            </FormProvider>
        </VerticalStack>
    );
};

export default OpcUAConfigurationEditor;
