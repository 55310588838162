import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { getSignalUnitType } from '../../../../signalHelperFunctions';
import { useLanguage } from '../../../../hooks/useLanguage';
import { OpcUANode } from '../../../../types/deviceDefinitionTypes';
import UnsavedChangesIndicator from '../../../common/unsavedChangesIndicator';
import NodeFormInputs from './nodeFormInputs';

const NodeForm = ({
    isEdit,
    updateNode,
    handleClose,
    defaultValues,
    nodes
}: {
    isEdit: boolean;
    updateNode: (node: OpcUANode) => void;
    handleClose: () => void;
    defaultValues: OpcUANode;
    nodes: OpcUANode[];
}) => {
    const { addNodeForm, editNodeForm, opcUANodeForm } = useLanguage();
    const form = useForm<OpcUANode>({
        defaultValues: {
            ...defaultValues,
            uomClass: defaultValues.uomClass || '',
            unitType: defaultValues.unitType || '',
            dataUnit: defaultValues.dataUnit || ''
        }
    });

    const onSubmit = (nodeData: OpcUANode) => {
        updateNode(nodeData);
        handleClose();
    };
    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    return form.handleSubmit(onSubmit)(e);
                }}
            >
                <Stack spacing={3}>
                    <Typography variant="h2">
                        {isEdit ? editNodeForm.header : addNodeForm.header}
                    </Typography>
                    <NodeFormInputs nodes={nodes}/>
                    <Stack spacing={2}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" justifyContent="end" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {opcUANodeForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {isEdit ? opcUANodeForm.updateButton : opcUANodeForm.addButton}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default NodeForm;
