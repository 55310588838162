import React, { useState, useEffect } from 'react';
import { Stack, OutlinedInput, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { FormElement } from '../../../common/formElement';
import {
    formFieldId,
    formLabelId,
    RenderBinaryInput
} from '../../../common/formInputRenderFunctions';
import { mediumString, xLongString } from '../../../../constants/validation';
import { useLanguage } from '../../../../hooks/useLanguage';
import { SignalUnitType } from '../../../../types/signalUnitTypes';
import {OpcUANode, OpcUANodeIdType, Signal} from '../../../../types/deviceDefinitionTypes';
import { opcUANodeIdTypes } from '../../../../constants/enums';
import { useOpcUANodeIdTypeEnum } from '../../../../hooks/enumHooks';
import { DataUnitSelectors } from '../sharedFormInputs/dataUnitSelectors';
import { EventClassSelector } from '../sharedFormInputs/eventClassSelector';
import DataTypeSelector from '../sharedFormInputs/dataTypeSelector';
import { useNodeValidation } from '../../../../hooks/useNodeValidation';
import { validateDataUnit, validateName } from '../../../../signalHelperFunctions';
import { ChangeEvent } from 'react';
import {UomClass} from "../../../../types/uomTypes";

const NodeFormInputs = ({
    nodes,
    defaultUnitType
}: {
    nodes: OpcUANode[];
    defaultUnitType?: SignalUnitType;
}) => {
    const { form: formText, opcUANodeForm, opcUANodeValidationErrors } = useLanguage();
    const { opcUANodeIdDisplay } = useOpcUANodeIdTypeEnum();
    const { validateNodeId, validateNamespaceId, validateNodeAddress } = useNodeValidation();

    const form = useFormContext<OpcUANode>();
    const defaultNodeValue = form.formState.defaultValues as OpcUANode;

    const [uomData, setUomData] = useState<UomClass[]>([]);
    const [unitTypes, setUnitTypes] = useState<string[]>([]);
    const [unitItems, setUnitItems] = useState<any[]>([]);

    const uomClass = form.watch('uomClass');
    const unitType = form.watch('unitType');

    useEffect(() => {
        const storedUomData = localStorage.getItem('uomData');
        if (storedUomData) {
            setUomData(JSON.parse(storedUomData));
        }
    }, []);

    useEffect(() => {
        // Initialize unit types based on the default UOM class, if applicable
        if (uomClass) {
            const selectedUomClassData = uomData.find(uom => uom.uomClassName === uomClass);
            if (selectedUomClassData) {
                setUnitTypes(selectedUomClassData.unitTypes);
                setUnitItems([]);
            }
        }
    }, [uomClass, uomData]);

    useEffect(() => {
        if (unitType) {
            const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(unitType));
            if (selectedUomClassData) {
                const selectedUnitItems = selectedUomClassData.unitItems;
                setUnitItems(selectedUnitItems);
            }
        }
    }, [unitType, uomData]);

    const handleUomClassChange = (e: SelectChangeEvent<string>) => {
        const selectedUomClass = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.uomClassName === selectedUomClass);
        if (selectedUomClassData) {
            setUnitTypes(selectedUomClassData.unitTypes);
            setUnitItems([]);
            form.setValue('unitType', ''); // Reset unitType field value
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('uomClass', selectedUomClass); // Set the UOM class in the form state
    };

    const handleUnitTypeChange = (e: SelectChangeEvent<string>) => {
        const selectedUnitType = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(selectedUnitType));
        if (selectedUomClassData) {
            const selectedUnitItems = selectedUomClassData.unitItems;
            setUnitItems(selectedUnitItems);
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('unitType', selectedUnitType); // Set the Unit Type in the form state
    };

    // Clears out "duplicate node address" error messages on all node address fields when one of them changes
    const handleNodeAddressItemChange = (_: ChangeEvent<HTMLInputElement>) => {
        form.clearErrors('nodeId');
        form.clearErrors('nodeIdType');
        form.clearErrors('namespaceId');
    };

    const handleNodeIdTypeChange = (e: SelectChangeEvent<OpcUANodeIdType>) => {
        const nodeIdType = e.target.value;
        if (typeof nodeIdType === 'string') {
            return;
        }
        form.setValue('nodeIdType', nodeIdType, { shouldDirty: true });

        form.clearErrors('nodeId');
        form.clearErrors('nodeIdType');
        form.clearErrors('namespaceId');
    };

    return (
        <Stack spacing={3}>
            <FormElement<OpcUANode>
                fieldName="name"
                label={opcUANodeForm.nameFieldLabel}
                tooltipTitle={opcUANodeForm.nameTooltip}
            >
                <OutlinedInput
                    {...form.register('name', {
                        required: formText.required,
                        maxLength: { value: mediumString, message: formText.tooLong },
                        validate: (name) =>
                            validateName(name, nodes, form.formState.defaultValues?.name) ||
                            opcUANodeForm.duplicateNameError
                    })}
                    id={formFieldId('name')}
                />
            </FormElement>
            <FormElement<OpcUANode>
                fieldName="namespaceId"
                label={opcUANodeForm.namespaceIdFieldLabel}
                tooltipTitle={opcUANodeForm.namespaceIdTooltip}
            >
                <OutlinedInput
                    {...form.register('namespaceId', {
                        valueAsNumber: true,
                        required: formText.required,
                        validate: (_, node) =>
                            validateNamespaceId(node) ||
                            validateNodeAddress(node, nodes, defaultNodeValue)
                    })}
                    onChange={handleNodeAddressItemChange}
                    id={formFieldId('namespaceId')}
                    type="number"
                />
            </FormElement>
            <FormElement<OpcUANode>
                fieldName="nodeId"
                label={opcUANodeForm.nodeIdFieldLabel}
                tooltipTitle={opcUANodeForm.nodeIdTooltip}
            >
                <OutlinedInput
                    {...form.register('nodeId', {
                        required: formText.required,
                        maxLength: { value: xLongString, message: formText.tooLong },
                        validate: (_, node) =>
                            validateNodeId(node) ||
                            validateNodeAddress(node, nodes, defaultNodeValue)
                    })}
                    onChange={handleNodeAddressItemChange}
                    id={formFieldId('nodeId')}
                />
            </FormElement>
            <FormElement<OpcUANode>
                fieldName="nodeIdType"
                label={opcUANodeForm.nodeIdTypeFieldLabel}
                tooltipTitle={opcUANodeForm.nodeIdTypeTooltip}
            >
                <Controller
                    control={form.control}
                    name="nodeIdType"
                    render={({ field }) => (
                        <Select
                            {...form.register('nodeIdType', {
                                validate: (_, node) =>
                                    validateNodeAddress(node, nodes, defaultNodeValue)
                            })}
                            value={field.value}
                            onChange={handleNodeIdTypeChange}
                            labelId={formLabelId(field.name)}
                        >
                            {opcUANodeIdTypes.map((it) => (
                                <MenuItem key={it} value={it}>
                                    {opcUANodeIdDisplay[it]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                ></Controller>
            </FormElement>
            <Controller control={form.control} name="dataType" render={DataTypeSelector} />
            <FormElement<OpcUANode>
                fieldName="readOnly"
                label={opcUANodeForm.isReadOnlyFieldLabel}
                tooltipTitle={opcUANodeForm.isReadOnlyTooltip}
            >
                <Controller control={form.control} name="readOnly" render={RenderBinaryInput} />
            </FormElement>
            <FormElement<Signal>
                fieldName="uomClass"
                label="UOM Class"
                tooltipTitle="Select UOM Class"
            >
                <Controller
                    control={form.control}
                    name="uomClass"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUomClassChange}>
                            {uomData.map((uomClass) => (
                                <MenuItem key={uomClass.uomClassName} value={uomClass.uomClassName}>
                                    {uomClass.uomClassName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<Signal>
                fieldName="unitType"
                label="Unit Type"
                tooltipTitle="Select Unit Type"
            >
                <Controller
                    control={form.control}
                    name="unitType"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUnitTypeChange} disabled={!unitTypes.length}>
                            {unitTypes.map((unitType) => (
                                <MenuItem key={unitType} value={unitType}>
                                    {unitType}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<Signal>
                fieldName="dataUnit"
                label="Unit"
                tooltipTitle="Select Unit"
            >
                <Controller
                    control={form.control}
                    name="dataUnit"
                    render={({ field }) => (
                        <Select {...field} disabled={!unitItems.length}>
                            {unitItems.map((unit) => (
                                <MenuItem key={unit.shortName} value={unit.shortName}>
                                    {unit.longName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
        </Stack>
    );
};

export default NodeFormInputs;
