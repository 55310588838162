import { FormControlLabel, Checkbox } from '@mui/material';
import { themeColors } from '../../theme';

const CheckboxWithLabel = ({
    id,
    label,
    checked,
    handleChange
}: {
    id: string;
    label: string;
    checked: boolean;
    handleChange: (id: string, checked: boolean) => void;
}) => {
    const checkboxAndLabelColor = themeColors.colorPalettePrimary05;

    return (
        <FormControlLabel
            key={id}
            control={
                <Checkbox
                    onChange={(_, checked) => handleChange(id, checked)}
                    checked={checked}
                    sx={{
                        color: checkboxAndLabelColor,
                        padding: 0,
                        marginLeft: 2,
                        marginRight: 2,
                        '&.Mui-checked': {
                            color: checkboxAndLabelColor
                        }
                    }}
                />
            }
            slotProps={{ typography: { variant: 'action', color: checkboxAndLabelColor } }}
            label={label}
            sx={{ marginBottom: 1 }}
        />
    );
};

export default CheckboxWithLabel;
