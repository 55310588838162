import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import CommissionedGatewaysList from './commissionedGatewaysList';

const CommissionedGatewaysListPage = () => {
    const { commissionedGatewaysListScreen } = useLanguage();
    const { layoutsSource, pslsSource } = useDataSource();

    return (
        <DefaultPageLayout
            pageHeader={commissionedGatewaysListScreen.header}
            loading={layoutsSource.loading || pslsSource.loading}
            error={layoutsSource.error || pslsSource.error}
        >
            {layoutsSource.data && pslsSource.data && (
                <CommissionedGatewaysList layouts={layoutsSource.data} psls={pslsSource.data} />
            )}
        </DefaultPageLayout>
    );
};

export default CommissionedGatewaysListPage;
