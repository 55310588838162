import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useColorMode } from '../../../hooks/useColorMode';
import { useLanguage } from '../../../hooks/useLanguage';
import { darkThemeColors, lightThemeColors, themeColors } from '../../../theme';
import { GatewayFilter } from '../../../types/gatewayStatusTypes';
import { PslResponse } from '../../../types/pslTypes';
import CheckboxWithLabel from '../../common/checkboxWithLabel';

const FilteringPopup = ({
    psls,
    selectedFilters,
    setSelectedFilters,
    handleClose
}: {
    psls: PslResponse[];
    handleClose: () => void;
    setSelectedFilters: (filters?: GatewayFilter) => void;
    selectedFilters?: GatewayFilter;
}) => {
    const { gatewayFilteringPopup } = useLanguage();
    const { isLightMode } = useColorMode();

    const handlePslFilterChange = (id: string, checked: boolean) => {
        if (checked) {
            const newFilters: GatewayFilter =
                selectedFilters?.psls !== undefined
                    ? {
                          psls: [...selectedFilters.psls, id],
                          gatewaysOffline: selectedFilters.gatewaysOffline,
                          gatewaysOnlineWithFieldDevicesDisconnected:
                              selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected,
                          gatewaysOnlineWithModulesInErrorState:
                              selectedFilters.gatewaysOnlineWithModulesInErrorState
                      }
                    : {
                          psls: [id],
                          gatewaysOffline: selectedFilters?.gatewaysOffline,
                          gatewaysOnlineWithFieldDevicesDisconnected:
                              selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
                          gatewaysOnlineWithModulesInErrorState:
                              selectedFilters?.gatewaysOnlineWithModulesInErrorState
                      };
            setSelectedFilters(newFilters);
            return;
        }
        // reaches this point if the checkbox has been unchecked
        const reducedFilters: GatewayFilter | undefined =
            selectedFilters?.psls !== undefined
                ? {
                      psls: selectedFilters.psls.filter((p) => p !== id),
                      gatewaysOffline: selectedFilters.gatewaysOffline,
                      gatewaysOnlineWithFieldDevicesDisconnected:
                          selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected,
                      gatewaysOnlineWithModulesInErrorState:
                          selectedFilters.gatewaysOnlineWithModulesInErrorState
                  }
                : {
                      psls: undefined,
                      gatewaysOffline: selectedFilters?.gatewaysOffline,
                      gatewaysOnlineWithFieldDevicesDisconnected:
                          selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
                      gatewaysOnlineWithModulesInErrorState:
                          selectedFilters?.gatewaysOnlineWithModulesInErrorState
                  };
        setSelectedFilters(
            reducedFilters !== undefined
                ? reducedFilters.psls?.length === 0
                    ? {
                          psls: undefined,
                          gatewaysOffline: reducedFilters?.gatewaysOffline,
                          gatewaysOnlineWithFieldDevicesDisconnected:
                              reducedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
                          gatewaysOnlineWithModulesInErrorState:
                              reducedFilters?.gatewaysOnlineWithModulesInErrorState
                      }
                    : reducedFilters
                : undefined
        );
    };

    const handleSetGatewaysOnlineWithFieldDevicesDisconnected = (
        gatewaysOnlineWithFieldDevicesDisconnected: boolean,
        checked: boolean
    ) => {
        if (checked) {
            setSelectedFilters({
                psls: selectedFilters?.psls,
                gatewaysOffline: undefined, // checking this box is mutually exclusive with displaying offline gateways, so clear this filter
                gatewaysOnlineWithFieldDevicesDisconnected:
                    gatewaysOnlineWithFieldDevicesDisconnected,
                gatewaysOnlineWithModulesInErrorState:
                    selectedFilters?.gatewaysOnlineWithModulesInErrorState
            });
            return;
        }
        // reaches this point if the checkbox has been unchecked
        setSelectedFilters({
            psls: selectedFilters?.psls,
            gatewaysOffline: selectedFilters?.gatewaysOffline,
            gatewaysOnlineWithFieldDevicesDisconnected: undefined,
            gatewaysOnlineWithModulesInErrorState:
                selectedFilters?.gatewaysOnlineWithModulesInErrorState
        });
    };

    const handleSetGatewaysOnlineWithModulesInErrorState = (
        gatewaysOnlineWithModulesInErrorState: boolean,
        checked: boolean
    ) => {
        if (checked) {
            setSelectedFilters({
                psls: selectedFilters?.psls,
                gatewaysOffline: undefined, // checking this box is mutually exclusive with displaying offline gateways, so clear this filter
                gatewaysOnlineWithFieldDevicesDisconnected:
                    selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
                gatewaysOnlineWithModulesInErrorState: gatewaysOnlineWithModulesInErrorState
            });
            return;
        }
        // reaches this point if the checkbox has been unchecked
        setSelectedFilters({
            psls: selectedFilters?.psls,
            gatewaysOffline: selectedFilters?.gatewaysOffline,
            gatewaysOnlineWithFieldDevicesDisconnected:
                selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
            gatewaysOnlineWithModulesInErrorState: undefined
        });
    };

    const handleSetGatewaysOffline = (gatewaysOffline: boolean, checked: boolean) => {
        if (checked) {
            setSelectedFilters({
                psls: selectedFilters?.psls,
                gatewaysOffline: gatewaysOffline,
                gatewaysOnlineWithFieldDevicesDisconnected: undefined, // checking this box is mutually exclusive with displaying online gateways, so clear this filter
                gatewaysOnlineWithModulesInErrorState: undefined // checking this box is mutually exclusive with displaying online gateways, so clear this filter
            });
            return;
        }
        // reaches this point if the checkbox has been unchecked
        setSelectedFilters({
            psls: selectedFilters?.psls,
            gatewaysOffline: undefined,
            gatewaysOnlineWithFieldDevicesDisconnected:
                selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected,
            gatewaysOnlineWithModulesInErrorState:
                selectedFilters?.gatewaysOnlineWithModulesInErrorState
        });
    };

    return (
        <Stack marginLeft={4} marginTop={3} marginRight={3} marginBottom={3} spacing={5}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                <Stack direction="row" spacing={3} alignItems="center">
                    <Typography variant="popoverHeader">{gatewayFilteringPopup.header}</Typography>
                    <Typography
                        variant="labelForm"
                        color={isLightMode ? lightThemeColors.link : darkThemeColors.link}
                        onClick={() => setSelectedFilters(undefined)}
                    >
                        <Link color="inherit">{gatewayFilteringPopup.clearAll}</Link>
                    </Typography>
                </Stack>
                <IconButton size="small" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faX}
                        color={
                            isLightMode
                                ? themeColors.colorPalettePrimary09
                                : themeColors.colorPalettePrimary02
                        }
                    />
                </IconButton>
            </Stack>
            <Stack direction="row">
                <Stack>
                    {psls.map((p) => (
                        <CheckboxWithLabel
                            key={p.id}
                            id={p.id}
                            label={p.name}
                            checked={selectedFilters?.psls?.includes(p.id) ?? false}
                            handleChange={handlePslFilterChange}
                        />
                    ))}
                </Stack>
                <Stack spacing={3}>
                    <Stack>
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.onlineLabel}
                            id={gatewayFilteringPopup.onlineLabel}
                            label={gatewayFilteringPopup.onlineLabel}
                            checked={
                                selectedFilters?.gatewaysOffline !== undefined &&
                                selectedFilters.gatewaysOffline === false
                            }
                            // Need to pass in both false and checked here because while the "false" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all ONLINE gateways, not the opposite
                            handleChange={(_, checked) => handleSetGatewaysOffline(false, checked)}
                        />
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.offlineLabel}
                            id={gatewayFilteringPopup.offlineLabel}
                            label={gatewayFilteringPopup.offlineLabel}
                            checked={
                                selectedFilters?.gatewaysOffline !== undefined &&
                                selectedFilters.gatewaysOffline === true
                            }
                            // Need to pass in both true and checked here because while the "true" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all OFFLINE gateways, not the opposite
                            handleChange={(_, checked) => handleSetGatewaysOffline(true, checked)}
                        />
                    </Stack>
                    <Stack>
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.allDevicesReportingLabel}
                            id={gatewayFilteringPopup.allDevicesReportingLabel}
                            label={gatewayFilteringPopup.allDevicesReportingLabel}
                            checked={
                                selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected !==
                                    undefined &&
                                selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected === false
                            }
                            // Need to pass in both false and checked here because while the "false" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all online gateways with field devices reporting, not the opposite
                            handleChange={(_, checked) =>
                                handleSetGatewaysOnlineWithFieldDevicesDisconnected(false, checked)
                            }
                        />
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.notAllDevicesReportingLabel}
                            id={gatewayFilteringPopup.notAllDevicesReportingLabel}
                            label={gatewayFilteringPopup.notAllDevicesReportingLabel}
                            checked={
                                selectedFilters?.gatewaysOnlineWithFieldDevicesDisconnected !==
                                    undefined &&
                                selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected === true
                            }
                            // Need to pass in both true and checked here because while the "true" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all online gateways with field device(s) NOT reporting, not the opposite
                            handleChange={(_, checked) =>
                                handleSetGatewaysOnlineWithFieldDevicesDisconnected(true, checked)
                            }
                        />
                    </Stack>
                    <Stack>
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.allModulesRunningLabel}
                            id={gatewayFilteringPopup.allModulesRunningLabel}
                            label={gatewayFilteringPopup.allModulesRunningLabel}
                            checked={
                                selectedFilters?.gatewaysOnlineWithModulesInErrorState !==
                                    undefined &&
                                selectedFilters.gatewaysOnlineWithModulesInErrorState === false
                            }
                            // Need to pass in both false and checked here because while the "false" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all online gateways with all modules running, not the opposite
                            handleChange={(_, checked) =>
                                handleSetGatewaysOnlineWithModulesInErrorState(false, checked)
                            }
                        />
                        <CheckboxWithLabel
                            key={gatewayFilteringPopup.notAllModulesRunningLabel}
                            id={gatewayFilteringPopup.notAllModulesRunningLabel}
                            label={gatewayFilteringPopup.notAllModulesRunningLabel}
                            checked={
                                selectedFilters?.gatewaysOnlineWithModulesInErrorState !==
                                    undefined &&
                                selectedFilters.gatewaysOnlineWithModulesInErrorState === true
                            }
                            // Need to pass in both true and checked here because while the "true" doesn't matter when unchecking,
                            // if checking the box we need to specify that we want all online gateways with NOT all modules running, not the opposite
                            handleChange={(_, checked) =>
                                handleSetGatewaysOnlineWithModulesInErrorState(true, checked)
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FilteringPopup;
