import { Button, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { useLanguage } from '../../../hooks/useLanguage';
import { UserRole } from '../../../types/authorizationTypes';
import { GatewayCreateRequest } from '../../../types/gatewayTypes';
import { LayoutResponse } from '../../../types/layoutTypes';
import UnsavedChangesIndicator from '../../common/unsavedChangesIndicator';
import GatewayFormInputs from './gatewayFormInputs';

const GatewayForm = ({
    defaultValues,
    onSubmit,
    submitButtonText,
    onCancel,
    layouts,
    isEdit
}: {
    defaultValues: GatewayCreateRequest;
    onSubmit: (gateway: GatewayCreateRequest) => void;
    submitButtonText: string;
    onCancel: () => void;
    layouts: LayoutResponse[];
    isEdit?: boolean;
}) => {
    const form = useForm<GatewayCreateRequest>({ defaultValues });
    const { gatewayForm } = useLanguage();
    const { visibility } = useAuthorization({
        visible: UserRole.InternalUser
    });

    const onFormSubmit = (formGateway: GatewayCreateRequest) => {
        // If RTS forwarding is disabled, pass "undefined" for all RTS fields regardless of user input
        onSubmit({
            ...formGateway,
            wellName: formGateway.enableRtsForwarding ? formGateway.wellName : undefined,
            wellId: formGateway.enableRtsForwarding ? formGateway.wellId : undefined,
            timezone: formGateway.enableRtsForwarding ? formGateway.timezone : undefined,
            operator: formGateway.enableRtsForwarding ? formGateway.operator : undefined,
            spudDate: formGateway.enableRtsForwarding ? formGateway.spudDate : undefined,
            latitude: formGateway.enableRtsForwarding ? formGateway.latitude : undefined,
            longitude: formGateway.enableRtsForwarding ? formGateway.longitude : undefined
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onFormSubmit)}>
                <Stack spacing={12} paddingTop={5}>
                    <GatewayFormInputs layouts={layouts} isEdit={isEdit} />
                    <Stack spacing={2}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={onCancel}>
                                {gatewayForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained" sx={{ visibility }}>
                                {submitButtonText}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default GatewayForm;
