import { faCalendarMinus, faPen } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Dialog, Stack } from '@mui/material';
import { GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { defaultOpcUANode } from '../../../../defaultValues';
import { useSignalDataTypeEnum } from '../../../../hooks/enumHooks';
import { useLanguage } from '../../../../hooks/useLanguage';
import { useNodeValidation } from '../../../../hooks/useNodeValidation';
import { OpcUANode } from '../../../../types/deviceDefinitionTypes';
import SortableTable from '../../../common/sortableTable';
import VerticalStack from '../../../common/verticalStack';
import NodeForm from './nodeForm';
import OpcUANodeImportExportButtons from './opcUANodeImportExportButtons';

const OpcUANodeEditor = ({
    nodes,
    onNodesChange
}: {
    nodes: OpcUANode[];
    onNodesChange: (nodes: OpcUANode[]) => void;
}) => {
    const { opcUACommunicationDefinition, opcUANodeValidationErrors, sortableTable } =
        useLanguage();
    const { dataTypeValueFormatter } = useSignalDataTypeEnum();
    const { getNodeAddressString } = useNodeValidation();

    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [nodeToEdit, setNodeToEdit] = useState<OpcUANode>();
    const [nodeIdsToDelete, setNodeIdsToDelete] = useState<GridRowId[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: opcUACommunicationDefinition.nameColumn,
            flex: 1
        },
        {
            field: 'dataType',
            headerName: opcUACommunicationDefinition.dataTypeColumn,
            valueFormatter: (params) => dataTypeValueFormatter(params.value),
            flex: 1
        },
        { field: 'nodeId', headerName: opcUACommunicationDefinition.nodeIdColumn, flex: 1 },
        {
            field: 'nodeAddress',
            headerName: opcUACommunicationDefinition.nodeAddressColumn,
            valueGetter: (params) => {
                const nodeAddress = getNodeAddressString(params.row as OpcUANode); // The data we are passing in is an array of OpcUANode, so this cast should succeed
                if (nodeAddress === null || nodeAddress === undefined) {
                    setError(
                        `${opcUANodeValidationErrors.invalidNodeForNodeAddressPrefix}${params.row.name}`
                    );
                    return null;
                }
                return nodeAddress;
            },
            flex: 1
        }
    ];

    const updateNode = (nodeData: OpcUANode) => {
        const otherNodes =
            nodeToEdit && isEdit ? nodes.filter((node) => node.name !== nodeToEdit.name) : nodes;

        const nodeNames = otherNodes.map((node) => node.name);
        if (nodeNames.includes(nodeData.name)) {
            setError(opcUANodeValidationErrors.namesNotUnique);
            return;
        }

        onNodesChange([...otherNodes, { ...nodeData }]);
        setError('');
    };

    const startEdit = (rowId: GridRowId) => {
        setIsEdit(true);
        setNodeToEdit(nodes.filter((node) => rowId === node.name)[0]);
    };

    const startAdd = () => {
        setIsEdit(false);
        setNodeToEdit({ ...defaultOpcUANode, configurationElementId: uuidv4() });
    };

    const deleteRow = (rowId: GridRowId) => {
        onNodesChange(nodes.filter((node) => node.name !== rowId.toString()));
    };

    const onRowSelect = (selectionModel: GridSelectionModel) => {
        setNodeIdsToDelete(selectionModel);
    };

    const deleteMultipleRows = () => {
        onNodesChange(nodes.filter((node) => !nodeIdsToDelete.includes(node.name)));
    };

    return (
        <VerticalStack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                paddingBottom={3}
                paddingTop={2}
            >
                <OpcUANodeImportExportButtons
                    nodes={nodes}
                    onNodesChange={onNodesChange}
                    setError={setError}
                />
                <Button variant="contained" onClick={startAdd}>
                    {opcUACommunicationDefinition.addNodeButton}
                </Button>
                <Button variant="contained" onClick={deleteMultipleRows}>
                    {opcUACommunicationDefinition.deleteSelectedNodesButton}
                </Button>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={nodes}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    { label: sortableTable.deleteButton, onClick: deleteRow, icon: faCalendarMinus }
                ]}
                getRowId={(row) => row.name}
                onRowSelect={onRowSelect}
            />
            <Dialog open={nodeToEdit !== undefined}>
                {nodeToEdit && (
                    <NodeForm
                        isEdit={isEdit}
                        updateNode={updateNode}
                        handleClose={() => setNodeToEdit(undefined)}
                        defaultValues={nodeToEdit}
                        nodes={nodes}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default OpcUANodeEditor;
