import { deleteLayoutIfNotUsedByGateways } from '../../../api/layoutApi';
import { useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import LayoutsList from './layoutsList';

const LayoutsListPage = () => {
    const { layoutsListScreen } = useLanguage();
    const {
        layoutsSource: { loading: getLoading, error: getError, data, setData },
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();

    const deleteLocalLayout = (layoutId: string): void => {
        if (data) {
            setData(data.filter((layout) => layout.id !== layoutId));
        }
    };

    const {
        executeRequest,
        loading: deleteLoading,
        error: deleteError
    } = useRequest(deleteLayoutIfNotUsedByGateways, deleteLocalLayout);

    const loading = getLoading || pslsLoading || deleteLoading;
    const error = getError || pslsError || deleteError;

    return (
        <DefaultPageLayout pageHeader={layoutsListScreen.header} loading={loading} error={error}>
            {data && psls && (
                <LayoutsList layouts={data} psls={psls} deleteLayout={executeRequest} />
            )}
        </DefaultPageLayout>
    );
};

export default LayoutsListPage;
