import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Typography } from '@mui/material';

interface InvalidRowsModalProps {
    open: boolean;
    onClose: () => void;
    rows: string[];
}

const InvalidRowsModal: React.FC<InvalidRowsModalProps> = ({ open, onClose, rows }) => {
    const recordText = rows.length === 1 ? 'record' : 'records';

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Invalid {recordText} Found:</DialogTitle>
            <DialogContent dividers>
                <List style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {rows.map((row, index) => (
                        <ListItem key={index} style={{ wordWrap: 'break-word' }}>
                            <ListItemText
                                primary={row}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Typography variant="body2" color="error" style={{ flex: 1, marginLeft: '16px' }}>
                    Note: Only valid records will be saved upon clicking the save button.
                </Typography>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvalidRowsModal;
