import { baseApiUrl } from '../environmentVariables';
import { httpRequest } from './sharedApi';
import { UomClass } from '../types/uomTypes';
import { RequestParameters } from '../types/apiTypes';

const uomUrl = `${baseApiUrl}/uom`;

export const getUomData = async (): Promise<UomClass[]> => {
    const params: RequestParameters<undefined> = {
        url: uomUrl
    };

    const authToken = '';

    const response = await httpRequest<undefined, UomClass[]>(params)(authToken);

    if (response.isSuccess && response.data) {
        return response.data;
    } else {
        throw new Error(`Failed to fetch UOM data: ${response.errorCode}`);
    }
};
