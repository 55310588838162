import { baseApiUrl } from '../environmentVariables';
import { HttpMethod } from '../types/apiTypes';
import { User } from '../types/userTypes';
import { httpRequest } from './sharedApi';

const externalUsersUrl = `${baseApiUrl}/externalUsers`;

export const getExternalUsers = () => {
    return httpRequest<undefined, User[]>({
        url: externalUsersUrl
    });
};

export const searchExternalUser = (email: string) =>
    httpRequest<string, User>({
        url: `${externalUsersUrl}/search/${email}`
    });

export const addExternalUserToGroup = (userId: string) =>
    httpRequest<undefined, undefined>({
        method: HttpMethod.Put,
        url: `${externalUsersUrl}/${userId}`
    });

export const removeExternalUserFromGroup = (userId: string) =>
    httpRequest<undefined, undefined>({
        method: HttpMethod.Delete,
        url: `${externalUsersUrl}/${userId}`
    });
