import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { defaultOpcUANode } from '../../../../defaultValues';
import { useLanguage } from '../../../../hooks/useLanguage';
import { useSignalCsvImport } from '../../../../hooks/useSignalCsvImport';
import { useStringConverters } from '../../../../hooks/useStringConverters';
import { convertSignalToRow, updateSignals } from '../../../../signalHelperFunctions';
import { DeviceDefinitionCreateRequest, OpcUANode } from '../../../../types/deviceDefinitionTypes';
import ExportToFileButton from '../../../common/exportToFileButton';
import ImportFromFileButton from '../../../common/importFromFileButton';
import { signalCsvHeaders } from '../mqttConfiguration/signalImportExportButtons';
import {constructUnitList} from "../../../../helperFunctions";
import InvalidRowsModal from "../../../common/invalidRowsModal";

const expectedCsvHeaders = signalCsvHeaders.concat(',namespaceId,nodeIdType,nodeId');
const OpcUANodeImportExportButtons = ({
    nodes,
    onNodesChange,
    setError
}: {
    nodes: OpcUANode[];
    onNodesChange: (nodes: OpcUANode[]) => void;
    setError: (error: string) => void;
}) => {
    const { opcUACommunicationDefinition, opcUANodeValidationErrors, deviceDefinitionScreen } =
        useLanguage();
    const deviceDefinitionForm = useFormContext<DeviceDefinitionCreateRequest>();
    const { convertInt } = useStringConverters();
    const { convertRowToSignal } = useSignalCsvImport();
    const [invalidRows, setInvalidRows] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const parseCsvNodes = (data: string[]) => {
        const invalidRowsTemp: string[] = [];
        const unitList = constructUnitList();

        // last row could be empty - don't parse if it is
        if (data[data.length - 1] === '') {
            data.pop();
        }        

        // Remove empty rows and rows with critical missing data
        const filteredData = data.filter(row => {
            const trimmedRow = row.trim();
            const splitRow = trimmedRow.split(',');

            // Ensure the row has the correct number of columns
            if (splitRow.length !== 12) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with incorrect column counts
            }

            // Ensure critical dataUnit column is not empty
            if (!unitList.includes(splitRow[8])) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with invalid critical fields
            }

            return true; // Include rows that pass all checks
        });

        // Set invalid rows to state
        setInvalidRows(invalidRowsTemp);

        // Show modal if there are invalid rows
        if (invalidRowsTemp.length > 0) {
            setModalOpen(true);
        }

        // Convert each filtered row to an OpcUANode object
        const parsedNodes = filteredData.map(row => {
            const splitRow = row.split(',');

            const signal = convertRowToSignal(splitRow.slice(0, 9));
            return {
                ...signal,
                // enums are entered as their integer values
                namespaceId: splitRow[9] ? convertInt(splitRow[9]) : defaultOpcUANode.namespaceId,
                nodeIdType: splitRow[10] ? convertInt(splitRow[10]) : defaultOpcUANode.nodeIdType,
                nodeId: splitRow[11]
            };
        });

        // Update nodes if parsed correctly
        if (parsedNodes.length > 0) {
            updateSignals(parsedNodes as OpcUANode[], opcUANodeValidationErrors.namesNotUnique, onNodesChange);
            setError(''); // Clear any previous errors
        }
    };

    const parseJsonNodes = (content: string) => {
        try {
            const parsedData = JSON.parse(content);

            // Ensure the parsed data is an array
            if (!Array.isArray(parsedData)) {
                throw new Error('Parsed data is not an array');
            }

            const unitList = constructUnitList();
            const invalidRowsTemp: string[] = [];

            // Validate each node object
            const validNodes: OpcUANode[] = parsedData.filter((node, index) => {
                // Ensure the node has all the required fields and valid dataUnit
                const hasRequiredFields = node.hasOwnProperty('configurationElementId') &&
                    node.hasOwnProperty('name') &&
                    node.hasOwnProperty('dataType') &&
                    node.hasOwnProperty('eventClass') &&
                    node.hasOwnProperty('readOnly') &&
                    node.hasOwnProperty('uomClass') &&
                    node.hasOwnProperty('unitType') &&
                    node.hasOwnProperty('dataUnit') &&
                    node.hasOwnProperty('namespaceId') &&
                    node.hasOwnProperty('nodeIdType') &&
                    node.hasOwnProperty('nodeId');

                const isValidDataUnit = unitList.includes(node.dataUnit);

                if (!hasRequiredFields || !isValidDataUnit) {
                    // Store the invalid node as a JSON string for displaying in the modal
                    invalidRowsTemp.push(JSON.stringify(node));
                    return false;
                }
                return true;
            });

            // Set invalid rows to state
            setInvalidRows(invalidRowsTemp);

            // Show modal if there are invalid rows
            if (invalidRowsTemp.length > 0) {
                setModalOpen(true);
            }

            // Update nodes if parsed correctly
            if (validNodes.length > 0) {
                updateSignals(validNodes, opcUANodeValidationErrors.namesNotUnique, onNodesChange);
                setError(''); // Clear any previous errors
            } else if (invalidRowsTemp.length === 0) {
                // If no valid nodes and no invalid rows, set a general error
                setError('No valid nodes found in the JSON data');
            }
        } catch (error) {
            // Handle JSON parsing errors
            setError('Error parsing JSON data: ' + error);
        }
    };


    const translateNodesToCsv = (nodes: OpcUANode[]) => {
        const csvRows = nodes.map((n) =>
            [...convertSignalToRow(n), n.namespaceId, n.nodeIdType, n.nodeId].join(',')
        );
        return [expectedCsvHeaders, ...csvRows];
    };

    return (
        <Stack direction="row" spacing={2}>
            <ImportFromFileButton
                setError={setError}
                handleJsonData={parseJsonNodes}
                handleCsvData={parseCsvNodes}
                expectedCsvHeaders={expectedCsvHeaders}
                confirmDialogContent={opcUACommunicationDefinition.importOverwriteWarning}
            />
            <ExportToFileButton
                data={nodes}
                fileName={`${
                    deviceDefinitionForm.watch('name') !== ''
                        ? deviceDefinitionForm.watch('name')
                        : deviceDefinitionScreen.defaultFileExportName
                }${opcUACommunicationDefinition.exportNodesFilenameSuffix}`}
                formatCsvData={translateNodesToCsv}
            />
            <InvalidRowsModal open={modalOpen} onClose={() => setModalOpen(false)} rows={invalidRows} />
        </Stack>
    );
};

export default OpcUANodeImportExportButtons;
