import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import {
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { Stack, Box } from '@mui/system';
import { useColorMode } from '../../hooks/useColorMode';
import { PieChartOption } from '../../types/displayTypes';
import { useLanguage } from '../../hooks/useLanguage';

const LegendEntry = ({ option }: { option: PieChartOption }) => {
    const { isLightMode } = useColorMode();
    const getLabelWithValue = (label: string, value: number) => {
        return `${label} (${value})`;
    };

    return (
        <ListItem disablePadding>
            <ListItemIcon sx={{ marginRight: -6 }}>
                <FontAwesomeIcon
                    icon={faSquare}
                    color={isLightMode ? option.lightModeColor : option.darkModeColor}
                />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'caption' }}>
                {getLabelWithValue(option.label, option.value)}
            </ListItemText>
        </ListItem>
    );
};

const circularProgressSize = 175;
const circularProgressOffset = 12;
const circleLabelWidthOffset = 80;
const TwoColorPieChart = ({
    chartLabel,
    option1,
    option2
}: {
    chartLabel: string;
    option1: PieChartOption;
    option2: PieChartOption;
}) => {
    const { isLightMode } = useColorMode();
    const { punctuation } = useLanguage();
    const option2PercentageFill = (option2.value / (option1.value + option2.value)) * 100;

    return (
        <Stack direction="row" justifyContent="center" spacing={4}>
            <Stack direction="column" justifyContent="center" alignItems="center">
                <Box sx={{ position: 'relative' }}>
                    {/* Thin, decorative outer circle */}
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        thickness={1}
                        size={circularProgressSize + 24}
                    />
                    {/* Option 1 */}
                    <CircularProgress
                        variant="determinate"
                        value={100} // this value will always be 100
                        thickness={6}
                        size={circularProgressSize}
                        sx={{
                            position: 'absolute',
                            left: circularProgressOffset,
                            top: circularProgressOffset,
                            color: isLightMode ? option1.lightModeColor : option1.darkModeColor
                        }}
                    />
                    {/* Option 2 */}
                    <CircularProgress
                        variant="determinate"
                        value={option2PercentageFill}
                        thickness={6}
                        size={circularProgressSize}
                        sx={{
                            position: 'absolute',
                            left: circularProgressOffset,
                            top: circularProgressOffset,
                            color: isLightMode ? option2.lightModeColor : option2.darkModeColor
                        }}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            textAlign="center"
                            width={circularProgressSize - circleLabelWidthOffset}
                        >
                            {chartLabel}
                            {punctuation.openParenthesis}
                            {option1.value + option2.value}
                            {punctuation.closedParenthesis}
                        </Typography>
                    </Box>
                </Box>
            </Stack>
            <List sx={{ alignSelf: 'center' }}>
                <LegendEntry option={option1} />
                <LegendEntry option={option2} />
            </List>
        </Stack>
    );
};

export default TwoColorPieChart;
