import { Button } from '@mui/material';
import { useRef, useState } from 'react';
import { csvLineSeparator } from '../../constants/formatting';
import { useLanguage } from '../../hooks/useLanguage';
import { AcceptedFileTypes, FileInformation } from '../../types/fileOperationTypes';
import ConfirmDialog from './confirmDialog';
import VerticalStack from './verticalStack';

const ImportFromFileButton = ({
    setError,
    handleJsonData,
    confirmDialogContent,
    handleCsvData,
    expectedCsvHeaders
}: {
    setError: (error: string) => void;
    handleJsonData: (content: string) => void;
    confirmDialogContent: string;
    handleCsvData?: (data: string[]) => void;
    expectedCsvHeaders?: string;
}) => {
    const { utilities, importErrors } = useLanguage();
    const [dialogOpen, setDialogOpen] = useState(false);
    const fileData = useRef<File | null>(null);
    let fileReader: FileReader;

    const acceptCsv = handleCsvData ? `, ${AcceptedFileTypes.csv}` : '';
    const acceptFileType = `${AcceptedFileTypes.json}${acceptCsv}`;

    const formatError = (error: string) => {
        return `${importErrors.errorLabel}${error}`;
    };

    const parseCsv = (content: string | ArrayBuffer) => {
        if (typeof content !== 'string') {
            setError(formatError(importErrors.csvCouldNotBeTranslated));
            return;
        }
        const rows = content.split(csvLineSeparator); // split the string based on new lines
        const headers = rows[0];

        if (expectedCsvHeaders && headers.toLowerCase() !== expectedCsvHeaders.toLowerCase()) {
            setError(formatError(importErrors.csvColumnNamesDoNotMatch));
            return;
        }

        if (rows.length > 1 && handleCsvData) {
            const data = rows.slice(1); // csv appends final new line, need to ignore last entry
            try {
                handleCsvData(data);
                setError('');
                return;
            } catch (error) {
                setError(formatError(`${importErrors.csvConversionFailed}${error}`));
                return;
            }
        }
        setError(formatError(importErrors.csvDataImproperlyFormatted));
        return;
    };

    const parseJson = (content: string | ArrayBuffer) => {
        if (typeof content !== 'string') {
            setError(formatError(importErrors.jsonCouldNotBeTranslated));
            return;
        }
        try {
            handleJsonData(content);
        } catch (error) {
            setError(formatError(`${importErrors.jsonConversionFailed}${error}`));
            return;
        }
        setError('');
        return;
    };

    const handleFileRead = (e: ProgressEvent, fileDetails: FileInformation) => {
        const content = fileReader.result;
        if (content) {
            if (fileDetails.type === AcceptedFileTypes.csv) {
                parseCsv(content);
                return;
            }
            if (fileDetails.type === AcceptedFileTypes.json) {
                parseJson(content);
                return;
            }
        }
        setError(formatError(importErrors.fileCouldNotBeRead));
    };

    const handleFileChosen = (file: File | null) => {
        if (file) {
            fileReader = new FileReader();
            const fileDetails: FileInformation = { name: file.name, type: file.type };
            fileReader.onloadend = (e) => handleFileRead(e, fileDetails); // triggers when readAsText operation completes
            fileReader.readAsText(file);
        }
    };

    return (
        <VerticalStack>
            {dialogOpen && (
                <ConfirmDialog
                    isOpen={dialogOpen}
                    content={confirmDialogContent}
                    onConfirm={() => {
                        handleFileChosen(fileData.current);
                        fileData.current = null;
                        setDialogOpen(false);
                    }}
                    onClose={() => {
                        fileData.current = null;
                        setDialogOpen(false);
                    }}
                />
            )}
            <Button variant="contained" component="label">
                <input
                    type="file"
                    id="fileImport"
                    accept={acceptFileType}
                    onChange={(e) => {
                        if (e.target.files && e.target.files.length === 1) {
                            // saves a copy of the file data to current ref, for use on dialog confirm
                            const file = new File([e.target.files[0]], e.target.files[0].name, {
                                type: e.target.files[0].type
                            });
                            fileData.current = file;
                            setDialogOpen(true);
                        }
                        e.target.value = ''; // resets file chosen so user can re-upload file if necessary
                    }}
                    hidden
                />
                {utilities.importButton}
            </Button>
        </VerticalStack>
    );
};

export default ImportFromFileButton;
