import { baseApiUrl } from '../environmentVariables';
import { PslResponse } from '../types/pslTypes';
import { httpRequest } from './sharedApi';

const pslUrl = `${baseApiUrl}/psls`;

export const getPsls = () =>
    httpRequest<undefined, PslResponse[]>({
        url: pslUrl
    });
