import { Button, OutlinedInput, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { themeValues } from '../../../theme';
import { ApiResult, HttpStatusCode } from '../../../types/apiTypes';
import { User, UserSearch } from '../../../types/userTypes';
import { FormElement } from '../../common/formElement';
import { formFieldId } from '../../common/formInputRenderFunctions';

const UserSearchForm = ({
    searchUser,
    setUser
}: {
    searchUser: (email: string) => (authToken: string) => Promise<ApiResult<User>>;
    setUser: (user: User) => void;
}) => {
    const { form: formText, userSearchForm } = useLanguage();
    const form = useForm<UserSearch>({ defaultValues: { email: '' } });
    const onUserLoad = (_: string, data?: User) => {
        if (data) {
            setUser(data);
        }
    };

    const onUserLoadFailure = (result: ApiResult<User>) => {
        if (result.status === HttpStatusCode.NotFound) {
            form.setError('email', { message: userSearchForm.userNotFoundError });
        } else {
            form.setError('email', { message: searchRequest.error });
        }
    };

    const searchRequest = useRequest(searchUser, onUserLoad, onUserLoadFailure);

    const onSubmit = (userSearch: UserSearch) => {
        const encodedEmail = encodeURIComponent(userSearch.email);
        searchRequest.executeRequest(encodedEmail);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={2}>
                    <FormElement<UserSearch>
                        fieldName="email"
                        label={userSearchForm.searchFieldLabel}
                    >
                        <OutlinedInput
                            {...form.register('email', {
                                required: formText.required
                            })}
                            id={formFieldId('email')}
                        />
                    </FormElement>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{
                            // Goal: bump the button down to align with input
                            // Challenge: Stack has a pretty specific margin rule
                            // Solution: beat Stack's specificity with this selector
                            '&&': { marginTop: themeValues.formLabelHeightPx },
                            minWidth: '90px'
                        }}
                    >
                        {userSearchForm.searchButton}
                    </Button>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default UserSearchForm;
