import { Alert, Typography } from '@mui/material';
import { useCallback } from 'react';
import { validate as uuidValidate } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { DeviceDefinitionContext } from '../../../contexts/deviceDefinitionContext';
import { useLanguage } from '../../../hooks/useLanguage';
import { routes } from '../../../pages';
import { SaveLayout, LayoutResponse } from '../../../types/layoutTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import LayoutForm from './layoutForm';
import { useLoad, useRequest } from '../../../hooks/requestHooks';
import { updateLayoutAndDevices } from '../../../api/layoutApi';
import { getDevices } from '../../../api/deviceApi';
import { LayoutContext } from '../../../contexts/layoutsContext';
import { useDataSource } from '../../../hooks/useDataSource';
import { getGateways } from '../../../api/gatewayApi';
import VerticalStack from '../../common/verticalStack';

const layoutPageRoute = `/${routes.layouts}`;

const EditLayoutPage = () => {
    const { editLayoutScreen: editScreen } = useLanguage();

    const { layoutId } = useParams();

    const idError =
        layoutId !== undefined && uuidValidate(layoutId) ? '' : `${editScreen.idError}${layoutId}`;

    return layoutId && idError === '' ? (
        <EditLayoutPageLoad layoutId={layoutId} />
    ) : (
        <Alert severity="error">{idError}</Alert>
    );
};

const EditLayoutPageLoad = ({ layoutId }: { layoutId: string }) => {
    const { editLayoutScreen: editScreen } = useLanguage();
    const navigate = useNavigate();
    const affectedGatewaysLoadFunction = useCallback(
        () => getGateways({ layoutIds: [layoutId] }),
        [layoutId]
    );
    const devicesLoadFunction = useCallback(() => getDevices({ layoutId }), [layoutId]);

    const { layoutsSource, deviceDefinitionsSource } = useDataSource();
    const devicesLoad = useLoad(devicesLoadFunction);
    const affectedGatewaysLoad = useLoad(affectedGatewaysLoadFunction);

    const layouts = layoutsSource?.data;
    const layout = layouts && layouts.filter((l) => l.id === layoutId)[0];

    const onSuccess = (
        _: Parameters<typeof updateLayoutAndDevices>[0],
        response: LayoutResponse | undefined
    ) => {
        if (layouts && response) {
            const otherLayouts = layouts.filter((layout) => layout.id !== layoutId);

            layoutsSource.setData([...otherLayouts, response]);
        }

        navigate(layoutPageRoute);
    };

    const onCancel = () => {
        navigate(layoutPageRoute);
    };

    const updateRequest = useRequest(updateLayoutAndDevices, onSuccess);

    const loading =
        layoutsSource.loading ||
        deviceDefinitionsSource.loading ||
        updateRequest.loading ||
        affectedGatewaysLoad.loading ||
        devicesLoad.loading;
    const error =
        layoutsSource.error ||
        deviceDefinitionsSource.error ||
        updateRequest.error ||
        affectedGatewaysLoad.error ||
        devicesLoad.error;

    const onSubmit = (layout: SaveLayout) => {
        updateRequest.executeRequest({ layout, layoutId });
    };

    return (
        <DefaultPageLayout pageHeader={editScreen.header} loading={loading} error={error}>
            <VerticalStack spacing={2} paddingTop={2}>
                <Typography variant="h5">{editScreen.description}</Typography>
                {affectedGatewaysLoad.data && affectedGatewaysLoad.data.length > 0 && (
                    <Alert severity="warning">
                        {editScreen.affectedGatewaysWarning}
                        {affectedGatewaysLoad.data.length}
                    </Alert>
                )}
                {deviceDefinitionsSource.data && (
                    <DeviceDefinitionContext.Provider value={deviceDefinitionsSource.data}>
                        {layoutsSource.data && layout && devicesLoad.data && (
                            <LayoutContext.Provider value={layoutsSource.data}>
                                <LayoutForm
                                    defaultValues={{ ...layout, devices: devicesLoad.data }}
                                    onSubmit={onSubmit}
                                    onCancel={onCancel}
                                    isEdit
                                />
                            </LayoutContext.Provider>
                        )}
                    </DeviceDefinitionContext.Provider>
                )}
            </VerticalStack>
        </DefaultPageLayout>
    );
};

export default EditLayoutPage;
