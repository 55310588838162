import { PaletteMode } from '@mui/material';
import { createContext, ReactNode, useMemo } from 'react';
import { lightMode } from '../theme';

interface ColorModeType {
    colorMode: string;
    isLightMode: boolean;
    updateColorMode: (newMode: PaletteMode) => void;
}

export const ColorModeContext = createContext({} as ColorModeType);

export function ColorModeProvider({
    colorMode,
    updateColorMode,
    children
}: {
    colorMode: PaletteMode;
    updateColorMode: (newMode: PaletteMode) => void;
    children: ReactNode;
}) {
    const isLightMode = useMemo(() => colorMode === lightMode, [colorMode]);

    return (
        <ColorModeContext.Provider value={{ colorMode, isLightMode, updateColorMode }}>
            {children}
        </ColorModeContext.Provider>
    );
}
