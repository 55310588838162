import { useAuthorization } from '../../hooks/useAuthorization';
import { AuthComponentProps, AuthorizationProps } from '../../types/authorizationTypes';

export function withAuthorizationDisplay<OriginalComponentProps extends AuthComponentProps>(
    WrappedComponent: React.ComponentType<OriginalComponentProps>
) {
    const ComponentWithAuthorization = (props: AuthorizationProps<OriginalComponentProps>) => {
        const { visibility, disabled } = useAuthorization(props.interaction);

        return <WrappedComponent {...props} disabled={disabled} sx={{ ...props.sx, visibility }} />;
    };
    return ComponentWithAuthorization;
}
