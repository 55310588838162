import { Button, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import { SaveLayout } from '../../../types/layoutTypes';
import UnsavedChangesIndicator from '../../common/unsavedChangesIndicator';
import VerticalStack from '../../common/verticalStack';
import LayoutCopy from './layoutCopy';
import LayoutFormInputs from './layoutFormInputs';

const LayoutForm = ({
    defaultValues,
    onSubmit,
    onCancel,
    isEdit
}: {
    defaultValues: SaveLayout;
    onSubmit: (layout: SaveLayout) => void;
    onCancel: () => void;
    isEdit?: boolean;
}) => {
    const { layoutForm } = useLanguage();

    const form = useForm<SaveLayout>({ defaultValues });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <VerticalStack>
                    {!isEdit && <LayoutCopy />}
                    <LayoutFormInputs isEdit={isEdit} />
                    <Stack>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" spacing={2} paddingTop={12}>
                            <Button color="secondary" variant="contained" onClick={onCancel}>
                                {layoutForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {layoutForm.saveButton}
                            </Button>
                        </Stack>
                    </Stack>
                </VerticalStack>
            </form>
        </FormProvider>
    );
};

export default LayoutForm;
