import { createContext } from 'react';
import { DeviceDefinitionResponse } from '../types/deviceDefinitionTypes';

// This context contains the literal value for the device definitions
// maintained by the app. It should only be set up on a page that's
// already confirmed they're available and only consumed further down
// in the tree.
const defaultValue = [] as DeviceDefinitionResponse[];

export const DeviceDefinitionContext = createContext(defaultValue);
