import { Button, Link, Stack, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../hooks/useLanguage';
import { routes } from '../../pages';
import DefaultPageLayout from '../common/defaultPageLayout';

const OktaProviderErrorDisplay = () => {
    const { oktaAuth } = useOktaAuth();
    const { oktaProvider } = useLanguage();
    const [searchParams] = useSearchParams();

    const handleLogin = async () => {
        await oktaAuth.signOut({
            postLogoutRedirectUri: `${window.location.origin}/${routes.loggedOut}`
        });
    };

    const error = searchParams.get('error');
    const errorDescription = searchParams.get('errorDescription');
    return (
        <DefaultPageLayout loading={false} pageHeader={`${oktaProvider.errorPrefix}${error}`}>
            <Stack marginTop={4}>
                <Typography>{errorDescription}</Typography>
                <Typography>
                    {oktaProvider.userActionPrefix}
                    <Link href="mailto:rpedge@halliburton.com">rpedge@halliburton.com</Link>
                    {oktaProvider.userActionSuffix}
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleLogin}
                    sx={{ marginTop: 5, maxWidth: '25%' }}
                >
                    {oktaProvider.logoutUser}
                </Button>
            </Stack>
        </DefaultPageLayout>
    );
};

export default OktaProviderErrorDisplay;
