import { Button, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import { LocalEnrollment } from '../../../types/enrollmentTypes';
import VerticalStack from '../../common/verticalStack';
import EnrollmentFormInputs from './enrollmentFormInputs';

const EnrollmentForm = ({
    defaultValues,
    onSubmit,
    submitButtonText,
    onCancel,
    isEdit
}: {
    defaultValues: LocalEnrollment;
    onSubmit: (enrollment: LocalEnrollment) => void;
    submitButtonText: string;
    onCancel: () => void;
    isEdit?: boolean;
}) => {
    const form = useForm<LocalEnrollment>({ defaultValues });
    const { enrollmentForm } = useLanguage();

    const onFormSubmit = (formEnrollment: LocalEnrollment) => {
        // pass "undefined" for any empty non required fields
        onSubmit({
            ...formEnrollment,
            localTags: formEnrollment.localTags || undefined
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onFormSubmit)} style={{ height: '100%' }}>
                <VerticalStack spacing={12} paddingTop={5}>
                    <EnrollmentFormInputs isEdit={isEdit} />
                    <Stack direction="row" spacing={2}>
                        <Button color="secondary" variant="contained" onClick={onCancel}>
                            {enrollmentForm.cancelButton}
                        </Button>
                        <Button type="submit" variant="contained">
                            {submitButtonText}
                        </Button>
                    </Stack>
                </VerticalStack>
            </form>
        </FormProvider>
    );
};

export default EnrollmentForm;
