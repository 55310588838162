import { Alert } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';

const UnsavedChangesIndicator = () => {
    const { form } = useLanguage();

    return <Alert severity="info">{form.unsavedChanges}</Alert>;
};

export default UnsavedChangesIndicator;
