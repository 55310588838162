import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../../hooks/useLanguage';
import { Signal} from '../../../../types/deviceDefinitionTypes';
import UnsavedChangesIndicator from '../../../common/unsavedChangesIndicator';
import SignalFormInputs from './signalFormInputs';

const SignalForm = ({
    isEdit,
    handleClose,
    updateSignal,
    defaultValues,
    signals
}: {
    isEdit: boolean;
    handleClose: () => void;
    updateSignal: (signal: Signal) => void;
    defaultValues: Signal;
    signals: Signal[];
}) => {
    const { signalForm, editSignalForm, addSignalForm } = useLanguage();
    const form = useForm<Signal>({
        defaultValues: {
            ...defaultValues,
            uomClass: defaultValues.uomClass || '',
            unitType: defaultValues.unitType || '',
            dataUnit: defaultValues.dataUnit || ''
        }
    });

    const onSubmit = (signal: Signal) => {
        updateSignal(signal);
        handleClose();
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    form.handleSubmit(onSubmit)(e);
                }}
            >
                <Stack spacing={3}>
                    <Typography variant="h2">
                        {isEdit ? editSignalForm.header : addSignalForm.header}
                    </Typography>
                    <SignalFormInputs signals={signals}/>
                    <Stack spacing={2}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" justifyContent="end" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {signalForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {isEdit ? signalForm.updateButton : signalForm.addButton}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default SignalForm;
