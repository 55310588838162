import { Stack, Button, Alert } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import {
    DeviceDefinitionResponse,
    DeviceDefinitionCreateRequestNoConfig,
    LocalDeviceDefinition,
    LocalMoxaModbusConfiguration,
    Protocol,
    MqttConfiguration,
    OpcUAConfiguration
} from '../../../types/deviceDefinitionTypes';
import UnsavedChangesIndicator from '../../common/unsavedChangesIndicator';
import VerticalStack from '../../common/verticalStack';
import DeviceDefinitionCopy from './deviceDefinitionCopy';
import { DeviceDefinitionFormInputs } from './deviceDefinitionFormInputs';
import DeviceDefinitionImportExportButtons from './deviceDefinitionImportExportButtons';
import MoxaModbusConfigurationEditor from './moxaModbusConfiguration/moxaModbusConfigurationEditor';
import MqttConfigurationEditor from './mqttConfiguration/mqttConfigurationEditor';
import OpcUAConfigurationEditor from './opcUAConfiguration/opcUAConfigurationEditor';

export const DeviceDefinitionForm = ({
    deviceDefinitionValues,
    onSubmit,
    onCancel,
    moxaModbusConfigurationValues,
    mqttConfigurationValues,
    opcUAConfigurationValues,
    existingDeviceDefinitions,
    isEdit
}: {
    deviceDefinitionValues: DeviceDefinitionCreateRequestNoConfig;
    existingDeviceDefinitions: DeviceDefinitionResponse[];
    onSubmit: (deviceDefinition: LocalDeviceDefinition) => void;
    onCancel: () => void;
    moxaModbusConfigurationValues?: LocalMoxaModbusConfiguration;
    mqttConfigurationValues?: MqttConfiguration;
    opcUAConfigurationValues?: OpcUAConfiguration;
    isEdit?: boolean;
}) => {
    const { deviceDefinitionForm } = useLanguage();
    const [error, setError] = useState<string>();

    const formDefaultValues: LocalDeviceDefinition = {
        ...deviceDefinitionValues,
        moxaModbusRegisters: moxaModbusConfigurationValues?.registers || [],
        moxaModbusSlaveId: moxaModbusConfigurationValues?.moxaSlaveId || 1,
        mqttSignals: mqttConfigurationValues?.signals || [],
        mqttTopics: mqttConfigurationValues?.mqttTopics || [],
        opcUANodes: opcUAConfigurationValues?.nodes || [],
        opcUASessionRenewalRequired: opcUAConfigurationValues?.sessionRenewalRequired || false,
        opcUASessionRenewalMinutes: opcUAConfigurationValues?.sessionRenewalMinutes || null,
        opcUAApplicationCertificatePath:
            opcUAConfigurationValues?.applicationCertificatePath || null,
        opcUASecurityEnabled: opcUAConfigurationValues?.securityEnabled || false
    };
    const form = useForm<LocalDeviceDefinition>({
        defaultValues: formDefaultValues
    });
    const protocol = form.watch('protocol');

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <VerticalStack>
                    <DeviceDefinitionImportExportButtons setError={setError} />
                    {error && (
                        <Alert severity="error" sx={{ marginTop: -3 }}>
                            {error}
                        </Alert>
                    )}
                    {!isEdit && (
                        <DeviceDefinitionCopy deviceDefinitions={existingDeviceDefinitions} />
                    )}
                    <DeviceDefinitionFormInputs
                        deviceDefinitions={existingDeviceDefinitions}
                        isEdit={isEdit}
                    />
                    {protocol === Protocol.MoxaModbus && <MoxaModbusConfigurationEditor />}
                    {protocol === Protocol.Mqtt && <MqttConfigurationEditor />}
                    {protocol === Protocol.OpcUA && <OpcUAConfigurationEditor />}
                    <Stack spacing={2} paddingTop={12}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" spacing={2}>
                            <Button color="secondary" variant="contained" onClick={onCancel}>
                                {deviceDefinitionForm.cancelButton}
                            </Button>
                            <Button type="submit" variant="contained">
                                {deviceDefinitionForm.saveButton}
                            </Button>
                        </Stack>
                    </Stack>
                </VerticalStack>
            </form>
        </FormProvider>
    );
};
