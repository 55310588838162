import { baseApiUrl } from '../environmentVariables';
import { HttpMethod } from '../types/apiTypes';
import {
    EnrollmentCreateRequest,
    EnrollmentResponse,
    EnrollmentUpdateRequest
} from '../types/enrollmentTypes';
import { httpRequest } from './sharedApi';

const enrollmentUrl = `${baseApiUrl}/enrollments`;

export const getEnrollments = ({
    pageNumber,
    pageSize
}: {
    pageNumber?: number;
    pageSize?: number;
}) => {
    const searchParams = new URLSearchParams();
    if (pageNumber !== undefined) {
        searchParams.append('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
        searchParams.append('pageSize', pageSize.toString());
    }
    const queryString = searchParams.toString() !== '' ? `?${searchParams.toString()}` : '';

    return httpRequest<undefined, EnrollmentResponse[]>({
        url: `${enrollmentUrl}${queryString}`
    });
};

export const getEnrollment = (registrationId: string) =>
    httpRequest<undefined, EnrollmentResponse>({
        url: `${enrollmentUrl}/${registrationId}`
    });

export const createEnrollment = (enrollment: EnrollmentCreateRequest) =>
    httpRequest<EnrollmentCreateRequest, EnrollmentResponse>({
        url: enrollmentUrl,
        method: HttpMethod.Post,
        body: enrollment
    });

export const updateEnrollment = ({
    registrationId,
    enrollment
}: {
    enrollment: EnrollmentUpdateRequest;
    registrationId: string;
}) =>
    httpRequest<EnrollmentUpdateRequest, EnrollmentResponse>({
        url: `${enrollmentUrl}/${registrationId}`,
        method: HttpMethod.Patch,
        body: enrollment
    });
