import { MenuItem, Select } from '@mui/material';
import { ControllerRenderProps, Path } from 'react-hook-form';
import { dataTypes } from '../../../../constants/enums';
import { useSignalDataTypeEnum } from '../../../../hooks/enumHooks';
import { useLanguage } from '../../../../hooks/useLanguage';
import { FormElement } from '../../../common/formElement';
import { formLabelId } from '../../../common/formInputRenderFunctions';

const DataTypeSelector = <T1, T2 extends Path<T1>>({
    field
}: {
    field: ControllerRenderProps<T1, T2>;
}) => {
    const { signalForm } = useLanguage();
    const { dataTypeDisplay } = useSignalDataTypeEnum();

    return (
        <FormElement
            fieldName="dataType"
            label={signalForm.dataTypeFieldLabel}
            tooltipTitle={signalForm.dataTypeTooltip}
        >
            <Select value={field.value} onChange={field.onChange} labelId={formLabelId(field.name)}>
                {dataTypes.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                        {dataTypeDisplay[dt]}
                    </MenuItem>
                ))}
            </Select>
        </FormElement>
    );
};

export default DataTypeSelector;
