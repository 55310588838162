import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Stack, Button, Alert } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnrollments } from '../../../api/enrollmentApi';
import { pageSize } from '../../../constants/styling';
import { useEnrollmentStatusEnum } from '../../../hooks/enumHooks';
import { useLoad } from '../../../hooks/requestHooks';
import { useDateStringConverter } from '../../../hooks/useDateStringConverter';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { EnrollmentStatus } from '../../../types/enrollmentTypes';
import SearchBar from '../../common/searchBar';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';

const EnrollmentsList = () => {
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { enrollmentsListScreen, sortableTable } = useLanguage();
    const navigate = useNavigate();
    const { convertEnrollmentStatus } = useEnrollmentStatusEnum();
    const { getFormattedDate } = useDateStringConverter();

    const loadEnrollments = useCallback(
        () => getEnrollments({ pageNumber: page, pageSize }),
        [page]
    );
    const { loading, data, error } = useLoad(loadEnrollments);

    const filteredEnrollments = data
        ? data.filter((e) => e.registrationId.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const columns: GridColDef[] = [
        {
            field: 'registrationId',
            headerName: enrollmentsListScreen.registrationIdColumn,
            flex: 1
        },
        {
            field: 'serialNumber',
            headerName: enrollmentsListScreen.serialNumberColumn,
            flex: 1
        },
        {
            field: 'enrollmentStatus',
            headerName: enrollmentsListScreen.enrollmentStatusColumn,
            flex: 1,
            valueFormatter: (params) => {
                if (!params.value) {
                    return convertEnrollmentStatus(EnrollmentStatus.WaitingOnDevice);
                } else {
                    return convertEnrollmentStatus(params.value as EnrollmentStatus);
                }
            }
        },
        {
            field: 'createdDate',
            headerName: enrollmentsListScreen.enrollmentCreatedDateColumn,
            valueFormatter: (params) => getFormattedDate(params.value),
            flex: 1
        },
        {
            field: 'lastUpdatedDate',
            headerName: enrollmentsListScreen.enrollmentUpdatedDateColumn,
            valueFormatter: (params) => getFormattedDate(params.value),
            flex: 1
        }
    ];

    const editRow = (rowId: GridRowId) => {
        navigate(rowId.toString());
    };

    return (
        <VerticalStack paddingTop={8} spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <Button variant="contained" onClick={() => navigate(extensions.create)}>
                    {enrollmentsListScreen.newEnrollmentButton}
                </Button>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}

            <SortableTable
                rows={filteredEnrollments}
                getRowId={(e) => e.registrationId}
                columns={columns}
                rowActions={[
                    {
                        label: sortableTable.editButton,
                        onClick: editRow,
                        icon: faPen
                    }
                ]}
                pagedData={{
                    page,
                    setPage,
                    pageSize,
                    rowNumber: page * pageSize + 2 * pageSize,
                    isLoading: loading
                }}
            />
        </VerticalStack>
    );
};

export default EnrollmentsList;
