import { RequestParameters, ApiResult } from '../types/apiTypes';

const API_VERSION = '1.0';
const UNCAUGHT_ERROR_CODE = 0;

export function httpRequest<RequestBodyType, DataType>(params: RequestParameters<RequestBodyType>) {
    return async function (authToken: string): Promise<ApiResult<DataType>> {
        try {
            // Construct request
            const request = buildRequest<RequestBodyType>(params, authToken);

            // Get response
            const response = await fetch(request);

            if (response.status < 300 && response.status >= 200) {
                const successResult =
                    response.status === 204 ? undefined : await parseSuccess<DataType>(response);
                return {
                    status: response.status,
                    isSuccess: true,
                    isFailure: false,
                    data: successResult
                };
            } else {
                const errorCode = await parseFailure(response);
                return {
                    status: response.status,
                    isSuccess: false,
                    isFailure: true,
                    errorCode
                };
            }
        } catch (error: any) {
            return {
                status: UNCAUGHT_ERROR_CODE,
                errorCode: error.message,
                isSuccess: false,
                isFailure: true
            };
        }
    };
}

function buildRequest<BodyType>(
    { url, method, contentType, body }: RequestParameters<BodyType>,
    authToken: string
): Request {
    const headers: Headers = new Headers();

    headers.append('Authorization', `Bearer ${authToken}`);
    headers.append('X-Version', API_VERSION);

    let bodyStringified: string | undefined;
    if (body) {
        bodyStringified = JSON.stringify(body);
        headers.append('Content-Type', 'application/json');
    } else if (contentType) {
        headers.append('Accept', contentType);
    }

    return new Request(url, {
        body: bodyStringified,
        headers,
        method
    });
}

function parseSuccess<DataType>(response: Response): Promise<DataType> {
    return response.json() as Promise<DataType>;
}

function parseFailure(response: Response) {
    return response.text();
}
