import { Alert, Paper } from '@mui/material';
import { useCallback, useState } from 'react';
import { getGatewayCount } from '../../../api/gatewayApi';
import { getGatewayStatusCondensedResponses } from '../../../api/gatewayStatusApi';
import { getIotEdgeDeviceCount } from '../../../api/iotEdgeApi';
import { useLoad } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useGatewayConnectedStatusThreshold } from '../../../hooks/useGatewayConnectedStatusThreshold';
import { GatewayFilter } from '../../../types/gatewayStatusTypes';
import LoadingDisplay from '../../common/loadingDisplay';
import VerticalStack from '../../common/verticalStack';
import CumulativeGatewayStatisticsSidebar from './cumulativeGatewayStatisticsSidebar';
import MapDisplayPane from './mapDisplayPane';
import SingleGatewayStatusSidebar from './singleGatewayStatusSidebar';

const panelHeight = '100%';
const panelPadding = '10px';

// The loading of all condensedStatuses (not paginated) will not scale properly,
// and should be removed with the completion of this issue:
// https://dev.azure.com/HAL-HDS/Automation/_backlogs/backlog/RP%20Edge%20Team/Epics/?workitem=257714
const GatewayStatusPage = () => {
    const {
        loading: condensedStatusesLoading,
        data: condensedStatuses,
        error: condensedStatusesError
    } = useLoad(getGatewayStatusCondensedResponses);

    const {
        loading: commissionedGatewayLoading,
        data: commissionedGatewayCount,
        error: commissionedGatewayError
    } = useLoad(getGatewayCount);

    const loadIotEdgeDevices = useCallback(
        () => getIotEdgeDeviceCount({ isCommissioned: false }),
        []
    );
    const {
        loading: availableGatewayLoading,
        data: availableGatewayCount,
        error: availableGatewayError
    } = useLoad(loadIotEdgeDevices);

    const {
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();
    const { isGatewayOnline } = useGatewayConnectedStatusThreshold();

    const [selectedFilters, setSelectedFilters] = useState<GatewayFilter>();
    const [selectedGateway, setSelectedGateway] = useState<string>();

    const loading =
        condensedStatusesLoading ||
        commissionedGatewayLoading ||
        availableGatewayLoading ||
        pslsLoading;
    const error =
        condensedStatusesError || commissionedGatewayError || availableGatewayError || pslsError;

    const gatewaysToDisplay =
        selectedFilters === undefined
            ? condensedStatuses
            : condensedStatuses
                  ?.filter((g) =>
                      selectedFilters.psls !== undefined
                          ? selectedFilters.psls.includes(g.pslId)
                          : true
                  )
                  .filter((g) =>
                      selectedFilters.gatewaysOffline !== undefined
                          ? selectedFilters.gatewaysOffline
                              ? !isGatewayOnline(g.createdDate)
                              : isGatewayOnline(g.createdDate)
                          : true
                  )
                  .filter((g) =>
                      selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected !== undefined
                          ? selectedFilters.gatewaysOnlineWithFieldDevicesDisconnected
                              ? isGatewayOnline(g.createdDate) && g.devicesNotConnected > 0
                              : isGatewayOnline(g.createdDate) && g.devicesNotConnected === 0
                          : true
                  )
                  .filter((g) =>
                      selectedFilters.gatewaysOnlineWithModulesInErrorState !== undefined
                          ? selectedFilters.gatewaysOnlineWithModulesInErrorState
                              ? isGatewayOnline(g.createdDate) && g.modulesNotRunning > 0
                              : isGatewayOnline(g.createdDate) && g.modulesNotRunning === 0
                          : true
                  );

    return (
        <VerticalStack paddingTop={loading || error ? 6 : undefined}>
            {loading && <LoadingDisplay />}
            {error && <Alert severity="error">{error}</Alert>}
            {!loading && !error && gatewaysToDisplay && psls && (
                <VerticalStack direction="row" spacing={2} padding={2}>
                    <Paper
                        elevation={2}
                        sx={{
                            padding: panelPadding,
                            height: panelHeight,
                            width: '25%'
                        }}
                    >
                        {selectedGateway ? (
                            <SingleGatewayStatusSidebar
                                gatewayId={selectedGateway}
                                setSelectedGateway={setSelectedGateway}
                            />
                        ) : (
                            commissionedGatewayCount !== undefined &&
                            availableGatewayCount !== undefined && (
                                <CumulativeGatewayStatisticsSidebar
                                    gatewayCondensedStatuses={gatewaysToDisplay}
                                    availableGatewayCount={availableGatewayCount}
                                    commissionedGatewayCount={commissionedGatewayCount}
                                />
                            )
                        )}
                    </Paper>
                    <Paper
                        elevation={2}
                        sx={{
                            padding: panelPadding,
                            height: panelHeight,
                            width: '75%'
                        }}
                    >
                        <MapDisplayPane
                            gatewayCondensedStatuses={gatewaysToDisplay}
                            setSelectedGateway={setSelectedGateway}
                            psls={psls}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                        />
                    </Paper>
                </VerticalStack>
            )}
        </VerticalStack>
    );
};

export default GatewayStatusPage;
