import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { routes } from '../../pages';
import { UserRole } from '../../types/authorizationTypes';

const ProtectedRoute = ({ auth }: { auth: UserRole }) => {
    const user = useUser();

    if (!user || user.userRole < auth) {
        return <Navigate to={`/${routes.userNotAuthorized}`} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
