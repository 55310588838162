import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { commissionGateway } from '../../../api/gatewayApi';
import { dateDataFormat } from '../../../constants/formatting';
import { getDefaultPsl } from '../../../helperFunctions';
import { useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions, queryStringKeys, routes } from '../../../pages';
import { Timezone } from '../../../types/gatewayTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import GatewayForm from './gatewayForm';

const CommissionGatewayPage = () => {
    const { commissionGatewayScreen } = useLanguage();
    const { layoutsSource, pslsSource } = useDataSource();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const azureDeviceId = useMemo(
        () => searchParams.get(queryStringKeys.azureDeviceId),
        [searchParams]
    );
    const serialNumber = useMemo(
        () => searchParams.get(queryStringKeys.serialNumber),
        [searchParams]
    );

    const onCreateSuccess = () => {
        navigate(`/${routes.gateways}/${extensions.commissioned}`);
    };

    const {
        executeRequest,
        loading: createLoading,
        error: createError
    } = useRequest(commissionGateway, onCreateSuccess);

    const loading = layoutsSource.loading || pslsSource.loading || createLoading;
    const error =
        layoutsSource.error ||
        pslsSource.error ||
        createError ||
        (!azureDeviceId || !serialNumber
            ? commissionGatewayScreen.queryStringDataMissingError
            : undefined);

    const defaultLayout = layoutsSource.data && layoutsSource.data[0];

    return (
        <DefaultPageLayout
            pageHeader={commissionGatewayScreen.header}
            loading={loading}
            error={error}
        >
            {layoutsSource.data && pslsSource.data && azureDeviceId && serialNumber && (
                <GatewayForm
                    defaultValues={{
                        name: '',
                        azureDeviceId,
                        layoutId: defaultLayout?.id ?? '',
                        serialNumber,
                        enableRtsForwarding: true,
                        wellId: '',
                        wellName: '',
                        timezone: Timezone.Central,
                        operator: '',
                        spudDate: dayjs().format(dateDataFormat),
                        longitude: '',
                        latitude: '',
                        assetInfo: {
                            pslId: defaultLayout?.assetInfo.pslId ?? getDefaultPsl(pslsSource.data)
                        },
                        equipmentNumber: '',
                        costCenter: ''
                    }}
                    onSubmit={executeRequest}
                    submitButtonText={commissionGatewayScreen.commissionButton}
                    onCancel={() => navigate(`/${routes.gateways}/${extensions.available}`)}
                    layouts={layoutsSource.data}
                />
            )}
        </DefaultPageLayout>
    );
};

export default CommissionGatewayPage;
