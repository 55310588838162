import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import {
    GridRenderPaginationProps,
    useGridApiContext,
    useGridSelector,
    gridPageSelector,
    gridPageSizeSelector
} from '@mui/x-data-grid';

const CustomPagination = (props: GridRenderPaginationProps & { rowCount: number }) => {
    const { rowCount } = props;
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    const pageStart = page * pageSize; // index of first row displayed on page

    return (
        <Stack direction="row" spacing={2} paddingRight={2} alignItems="center">
            <Typography variant="h3">
                {pageStart + 1} - {pageStart + pageSize}
            </Typography>
            <IconButton disabled={page === 0} onClick={() => apiRef.current.setPage(page - 1)}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
            <IconButton
                disabled={pageSize > rowCount}
                onClick={() => apiRef.current.setPage(page + 1)}
            >
                <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
        </Stack>
    );
};

export default CustomPagination;
