import { SvgIcon } from '@mui/material';
import { SidebarIconProps } from '../components/navigation/sidebarButton';
import { themeColors } from '../theme';

const TachometerIcon = ({ disabled }: SidebarIconProps) => {
    return (
        <SvgIcon
            width="18px"
            height="14px"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 0C4 0 0 4.03125 0 9C0 10.6562 0.4375 12.2188 1.21875 13.5312C1.375 13.8438 1.71875 14 2.0625 14H15.9062C16.25 14 16.5938 13.8438 16.75 13.5312C17.5312 12.2188 18 10.6562 18 9C18 4.03125 13.9688 0 9 0ZM9 2C9.4375 2 9.8125 2.34375 9.9375 2.75C9.90625 2.8125 9.84375 2.875 9.8125 2.96875L9.53125 3.8125C9.375 3.9375 9.1875 4 9 4C8.4375 4 8 3.5625 8 3C8 2.46875 8.4375 2 9 2ZM3 11C2.4375 11 2 10.5625 2 10C2 9.46875 2.4375 9 3 9C3.53125 9 4 9.46875 4 10C4 10.5625 3.53125 11 3 11ZM4.5 6C3.9375 6 3.5 5.5625 3.5 5C3.5 4.46875 3.9375 4 4.5 4C5.03125 4 5.5 4.46875 5.5 5C5.5 5.5625 5.03125 6 4.5 6ZM12.1875 3.75L10.2812 9.5C10.7188 9.875 11 10.4062 11 11C11 11.375 10.875 11.7188 10.7188 12H7.25C7.09375 11.7188 7 11.375 7 11C7 9.96875 7.8125 9.09375 8.84375 9.03125L10.7812 3.28125C10.9062 2.875 11.3125 2.65625 11.7188 2.8125C12.125 2.9375 12.3125 3.375 12.1875 3.75ZM12.6562 5.53125L13.125 4.09375C13.25 4.03125 13.375 4.03125 13.5 4.03125C14.0312 4.03125 14.5 4.46875 14.5 5.03125C14.5 5.5625 14.0312 6.03125 13.5 6.03125C13.125 6 12.8438 5.8125 12.6562 5.53125ZM15 11C14.4375 11 14 10.5625 14 10C14 9.46875 14.4375 9 15 9C15.5312 9 16 9.46875 16 10C16 10.5625 15.5312 11 15 11Z"
                fill={disabled ? themeColors.colorPalettePrimary05 : '#238CAD'}
            />
        </SvgIcon>
    );
};

export default TachometerIcon;
