import { faPen, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Dialog, Stack } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { useProtocolEnum } from '../../../hooks/enumHooks';
import { useDeviceDefinitions } from '../../../hooks/useDeviceDefinitions';
import { useLanguage } from '../../../hooks/useLanguage';
import { Asset } from '../../../types/assetTypes';
import { LocalDevice } from '../../../types/deviceTypes';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';
import DeviceForm from './deviceForm';

const DevicesEditor = ({
    devices,
    onDevicesChange,
    layoutAsset
}: {
    devices: LocalDevice[];
    onDevicesChange: (devices: LocalDevice[]) => void;
    layoutAsset: Asset;
}) => {
    const { devicesEditor, sortableTable } = useLanguage();
    const [deviceToEdit, setDeviceToEdit] = useState<LocalDevice>();
    const [error, setError] = useState<string>();
    const [isEdit, setIsEdit] = useState(false);
    const deviceDefinitions = useDeviceDefinitions();
    const { convertProtocol } = useProtocolEnum();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: devicesEditor.nameColumn,
            flex: 1
        },
        {
            field: 'definitionName',
            headerName: devicesEditor.definitionColumn,
            flex: 1
        },
        {
            field: 'definitionProtocol',
            headerName: devicesEditor.protocolColumn,
            flex: 1
        },
        {
            field: 'address',
            headerName: devicesEditor.addressColumn,
            flex: 1
        }
    ];

    const upsertDevice = (device: LocalDevice) => {
        const unaffectedDevices =
            deviceToEdit && isEdit ? devices.filter((d) => d.name !== deviceToEdit.name) : devices;

        onDevicesChange([...unaffectedDevices, device]);
        setError('');
    };

    const startEdit = (rowId: GridRowId) => {
        if (deviceDefinitions.length === 0) {
            setError(devicesEditor.noDefinitionsError);
            return;
        }
        setIsEdit(true);
        setDeviceToEdit(devices.filter((device) => device.name === rowId)[0]);
    };

    const startAdd = () => {
        if (deviceDefinitions.length === 0) {
            setError(devicesEditor.noDefinitionsError);
            return;
        }
        setIsEdit(false);
        setDeviceToEdit({
            name: '',
            deviceDefinitionId: '',
            address: '',
            assetInfo: layoutAsset
        });
    };

    const deleteRow = (rowId: GridRowId) => {
        const otherDevices = devices.filter((device) => device.name !== rowId.toString());
        const devicesToDelete = devices.filter((device) => device.name === rowId.toString());

        if (devicesToDelete.length === 0) {
            return;
        }

        // there may be two devices with the same name - one local device which has not yet been saved
        // to the database (isDeleted = undefined) and a saved device marked for deletion (isDeleted = true)
        let deviceToDelete: LocalDevice | undefined = undefined;
        if (devicesToDelete.length === 1) {
            // if there's only one device with the name, we're good to proceed
            deviceToDelete = devicesToDelete[0];
        } else {
            // we don't want to delete a device already marked for deletion, and there should only be one
            // local device (isDeleted = undefined)
            deviceToDelete = devicesToDelete.find((device) => !device.isDeleted);
            // we want to preserve devices marked for deletion so that they can be deleted when the layout is saved
            otherDevices.push(...devicesToDelete.filter((device) => device.isDeleted));
        }

        if (!deviceToDelete) {
            return;
        }

        if (!deviceToDelete.id) {
            // if the device does not have an id, it has not been saved to the backend
            // and can be deleted from local state
            onDevicesChange(otherDevices);
            return;
        }
        // if the device has an id, it's been saved to the backend, so it should be marked
        // for deletion (to be deleted when the layout is saved)
        onDevicesChange([...otherDevices, { ...deviceToDelete, isDeleted: true }]);
    };

    const devicesToDisplay = devices
        .map((device) => {
            const deviceDefinition = deviceDefinitions.find(
                (definition) => definition.id === device.deviceDefinitionId
            );

            if (deviceDefinition) {
                return {
                    ...device,
                    definitionName: deviceDefinition.name,
                    definitionProtocol: convertProtocol(deviceDefinition.protocol)
                };
            }

            // if the device definition is invalid, then the data for display
            // cannot be appropriately parsed
            return {
                ...device,
                definitionName: device.deviceDefinitionId,
                definitionProtocol: devicesEditor.definitionLoadError
            };
        })
        .filter((device) => device.isDeleted === undefined);

    return (
        <VerticalStack>
            <Stack direction="row" justifyContent="flex-end" spacing={2} paddingBottom={3}>
                <Button variant="contained" onClick={startAdd}>
                    {devicesEditor.newDeviceButton}
                </Button>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={devicesToDisplay}
                columns={columns}
                getRowId={(row) => row.name}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    { label: sortableTable.deleteButton, onClick: deleteRow, icon: faCalendarMinus }
                ]}
            />
            <Dialog open={deviceToEdit !== undefined}>
                {deviceToEdit && (
                    <DeviceForm
                        defaultValues={deviceToEdit}
                        isEdit={isEdit}
                        updateDevice={upsertDevice}
                        handleClose={() => setDeviceToEdit(undefined)}
                        devices={devices}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default DevicesEditor;
