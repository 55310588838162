import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';

const ConfirmDialog = ({
    isOpen,
    content,
    onConfirm,
    onClose,
    title,
    confirmText
}: {
    isOpen: boolean;
    content: string;
    onConfirm: () => void;
    onClose: () => void;
    title?: string;
    confirmText?: string;
}) => {
    const { dialog } = useLanguage();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>
                    {dialog.cancelButton}
                </Button>
                <Button variant="contained" onClick={onConfirm} autoFocus>
                    {confirmText ?? dialog.confirmButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
