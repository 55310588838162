import { baseApiUrl } from '../environmentVariables';
import { ApiResult, HttpMethod } from '../types/apiTypes';
import {
    DeviceCreateRequest,
    DeviceResponse,
    DeviceUpdateRequest,
    SaveDevice
} from '../types/deviceTypes';
import { httpRequest } from './sharedApi';

const devicesUrl = `${baseApiUrl}/devices`;

export const getDevices = ({
    layoutId,
    deviceDefinitionIds
}: {
    layoutId?: string;
    deviceDefinitionIds?: string[];
}) => {
    const searchParams = new URLSearchParams();
    if (layoutId) {
        searchParams.append('layoutId', layoutId);
    }
    if (deviceDefinitionIds) {
        searchParams.append('deviceDefinitionIds', deviceDefinitionIds.join(','));
    }
    const queryString = searchParams.toString();
    const url = queryString ? `${devicesUrl}?${queryString}` : devicesUrl;
    return httpRequest<undefined, DeviceResponse[]>({
        url
    });
};

export const deleteDevice = (deviceId: string) =>
    httpRequest<undefined, undefined>({
        url: `${devicesUrl}/${deviceId}`,
        method: HttpMethod.Delete
    });

export const createDevice = (device: DeviceCreateRequest) =>
    httpRequest<DeviceCreateRequest, DeviceResponse>({
        method: HttpMethod.Post,
        url: devicesUrl,
        body: device
    });

export const updateDevice = ({ device, id }: { device: DeviceUpdateRequest; id: string }) =>
    httpRequest<DeviceUpdateRequest, DeviceResponse>({
        method: HttpMethod.Patch,
        url: `${devicesUrl}/${id}`,
        body: device
    });

export const saveDevices = (devices: SaveDevice[]) => async (authToken: string) => {
    // assumes that devices.length > 0 is checked in caller

    const devicesToDelete = devices.filter((d) => d.isDeleted);
    const devicesToUpsert = devices.filter((d) => !d.isDeleted);

    const deletionPromises: Promise<ApiResult<undefined>>[] = devicesToDelete.map((device) =>
        deleteDevice(device.id!)(authToken)
    );
    const deletionResults = deletionPromises.length > 0 ? await Promise.all(deletionPromises) : [];
    const failedDeletion = deletionResults.find((r) => r.isFailure);
    if (failedDeletion) {
        return failedDeletion;
    }

    const upsertPromises: Promise<ApiResult<DeviceResponse>>[] = devicesToUpsert.map(
        (deviceToUpsert) => {
            // determine if device already exists or not
            if (deviceToUpsert.id === undefined) {
                return createDevice(deviceToUpsert)(authToken);
            } else {
                const { id, isDeleted, layoutId, ...device } = deviceToUpsert;
                return updateDevice({ device, id })(authToken);
            }
        }
    );
    if (upsertPromises.length === 0) {
        return deletionResults[0];
    }
    const upsertResults = await Promise.all(upsertPromises);

    const firstFailingResult = upsertResults.find((r) => r.isFailure);
    if (firstFailingResult) {
        return firstFailingResult;
    }

    return upsertResults[0];
};
