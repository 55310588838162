import { OpcUANodeIdType, Protocol, SignalDataType, Swap } from '../types/deviceDefinitionTypes';

export const dataTypes = [
    SignalDataType.None,
    SignalDataType.Boolean,
    SignalDataType.Integer,
    SignalDataType.Enum,
    SignalDataType.Double,
    SignalDataType.String,
    SignalDataType.UInt,
    SignalDataType.UsInt
];

export const protocols = [Protocol.MoxaModbus, Protocol.Mqtt, Protocol.OpcUA];

export const swaps = [Swap.None, Swap.Byte, Swap.Word, Swap.ByteWord];

export const opcUANodeIdTypes = [
    OpcUANodeIdType.Base64String,
    OpcUANodeIdType.String,
    OpcUANodeIdType.Integer,
    OpcUANodeIdType.Guid
];
