import { Box, Popover, PopoverPosition } from '@mui/material';
import atlas, { data, MapMouseEvent, Pixel, Shape, SymbolLayerOptions } from 'azure-maps-control';
import { useMemo, useState } from 'react';
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapLayerProvider,
    AzureMapFeature,
    AzureMapsProvider
} from 'react-azure-maps';
import {
    bubbleLayerOptions,
    dataSourceId,
    customMarkerImageSprite,
    azureMapOptions
} from '../../../constants/mapConfiguration';
import { useColorMode } from '../../../hooks/useColorMode';
import { themeColors } from '../../../theme';
import {
    GatewayStatusCondensedResponse,
    Leaf,
    PositionWithMetadata,
    ClusterWithLeaves
} from '../../../types/gatewayStatusTypes';
import MapClusterPopup from './mapClusterPopup';

// the getById function called in handleClusterMouseEvent returns only a SourceEvents object, so calling
// getClusterLeaves would upset typescript without this custom typing
interface CustomSourceEvent extends atlas.source.Source<atlas.source.SourceEvents> {
    getClusterLeaves: (
        clusterId: number,
        limit: number,
        offset: number
    ) => Promise<atlas.data.Feature<atlas.data.Geometry, any> | Array<Shape>>;
}

interface CustomSourceManager extends atlas.SourceManager {
    getById: (id: string) => CustomSourceEvent;
}

interface CustomMap extends atlas.Map {
    sources: CustomSourceManager;
}

interface CustomClusterEvent extends MapMouseEvent {
    map: CustomMap;
}

const parseSymbolId = (shapeOrFeature: data.Feature<data.Geometry, any> | atlas.Shape) => {
    if ('id' in shapeOrFeature) {
        // if id is in the object, it is of type data.Feature<data.Geometry, any>
        return shapeOrFeature.id;
    }
    if ('data' in shapeOrFeature) {
        // if data is in the object, it is of type atlas.Shape
        return shapeOrFeature.getId();
    }
};

const parseLeaves = (e: Shape, gateways: GatewayStatusCondensedResponse[]) => {
    const coordinate = e.getCoordinates();
    if (Object.hasOwn(coordinate, 'isArray')) {
        // The coordinate should be of data.Position (instead of an array) as our shape will be a point
        return;
    }

    // If no gateway is found with gateway ID that matches shape ID, something is funky - return
    const gatewayWithMatchingId = gateways.find((g) => g.gatewayId === e.getId());
    if (!gatewayWithMatchingId) {
        return;
    }

    return {
        coordinate,
        id: e.getId(),
        name: gatewayWithMatchingId.gatewayName
    };
};

const MapDisplay = ({
    gateways,
    setSelectedGateway
}: {
    gateways: GatewayStatusCondensedResponse[];
    setSelectedGateway: (gatewayId?: string) => void;
}) => {
    const { isLightMode } = useColorMode();
    const [selectedCluster, setSelectedCluster] = useState<ClusterWithLeaves>();
    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>();

    const layerOptions: SymbolLayerOptions = {
        textOptions: {
            textField: ['get', 'title'], // specifies the property name of the marker label
            offset: [0, 1.2], // specifies how far the label is offset from the marker
            color: isLightMode
                ? themeColors.colorPalettePrimary11
                : themeColors.colorPalettePrimary00
        },
        iconOptions: {
            image: 'custom-marker'
        },
        filter: ['!', ['has', 'point_count']]
    };

    const positions: PositionWithMetadata[] = useMemo(
        () =>
            gateways.flatMap((g) =>
                g.coordinate !== undefined && g.coordinate !== null
                    ? [
                          {
                              name: g.gatewayName,
                              position: new data.Position(
                                  g.coordinate.gpsLong,
                                  g.coordinate.gpsLat
                              ),
                              id: g.gatewayId
                          }
                      ]
                    : []
            ),
        [gateways]
    );

    const handleSymbolMouseEvent = (e: MapMouseEvent) => {
        if (e.shapes) {
            const symbolId = parseSymbolId(e.shapes[0]);
            const selectedSymbol = positions.find((p) => p.id === symbolId);
            setSelectedGateway(selectedSymbol?.id);
        }
    };

    const handleClusterMouseEvent = (e: CustomClusterEvent) => {
        if (!e || !e.shapes || !(e.shapes.length > 0)) {
            return;
        }
        // Get the clustered point from the event.
        const cluster = e.shapes[0];

        if ('data' in cluster) {
            // if data is in the object, it is of type atlas.Shape
            return;
        }
        if (cluster.properties && cluster.properties.cluster) {
            // Get the points stored in the cluster, save them to SelectedCluster state
            if (cluster.geometry.type !== 'Point') {
                // if the cluster geometry is not type point, we cannot determine the type
                // of its coordinates and must fail out early
                return;
            }
            cluster.geometry.coordinates.forEach((currentValue) => {
                if (Array.isArray(currentValue)) {
                    // if the values in coordinate are arrays, our processing below
                    // will fail, so we return early
                    return;
                }
            });
            // if the cluster geometry is type point, we know the coordinate is of type data.Position
            const coordinate = cluster.geometry.coordinates as data.Position;
            e.map.sources
                .getById(dataSourceId)
                // Retrieve the shapes within the cluster - in our case, this is all the individual points within the cluster
                // Params: clusterId, maximum number of points to return, offset from 0 (for pagination)
                .getClusterLeaves(cluster.properties.cluster_id, Infinity, 0)
                .then((value: atlas.data.Feature<atlas.data.Geometry, any> | Shape[]) => {
                    if (!Array.isArray(value)) {
                        // if the value isn't an array of shapes, return out
                        return;
                    }
                    const leaves = value
                        .map((l) => parseLeaves(l, gateways))
                        // Filter out any empty values from the array
                        .filter((leaf): leaf is Leaf => !!leaf);

                    if (leaves.length === 0) {
                        // if there are no leaves in the cluster after filtering, we don't have anything to display
                        return;
                    }
                    setSelectedCluster({ coordinate, leaves });
                    const mapDomRectangle = e.map.getMapContainer().getBoundingClientRect();
                    // get pixel of cluster that triggered the event
                    const clusterPixel = e.pixel;
                    // if clusterPixel cannot be retrieved, return early because we can't
                    // use it below
                    if (!clusterPixel) {
                        return;
                    }
                    setAnchorPosition({
                        top: mapDomRectangle.top + Pixel.getY(clusterPixel),
                        left: mapDomRectangle.left + Pixel.getX(clusterPixel)
                    });
                });
        }
    };

    return (
        <AzureMapsProvider>
            <Box height="100%">
                <AzureMap
                    options={azureMapOptions}
                    styleOptions={{ style: isLightMode ? 'road' : 'night' }}
                    imageSprites={[customMarkerImageSprite]}
                >
                    <AzureMapDataSourceProvider
                        id={dataSourceId}
                        options={{
                            //Tell the data source to cluster point data.
                            cluster: true,

                            // Set max zoom so that clusters with two devices with the same coordinates
                            // cannot uncluster.
                            maxZoom: 24,

                            //The radius in pixels to cluster points together.
                            clusterRadius: 45,

                            //The maximium zoom level in which clustering occurs.
                            //If you zoom in more than this, all points are rendered as symbols.
                            clusterMaxZoom: 24
                        }}
                    >
                        <AzureMapLayerProvider
                            id={'BubbleLayer AzureMapLayerProvider'}
                            options={bubbleLayerOptions}
                            events={{ click: handleClusterMouseEvent }}
                            type="BubbleLayer"
                        />
                        <AzureMapLayerProvider
                            // required to display the markers mapped below
                            id={'SymbolLayer AzureMapLayerProvider'}
                            options={layerOptions}
                            events={{ click: handleSymbolMouseEvent }}
                            type="SymbolLayer"
                        />
                        {positions.length > 0
                            ? positions.map((c: PositionWithMetadata) => (
                                  <AzureMapFeature
                                      key={c.id}
                                      id={c.id}
                                      type="Point"
                                      coordinate={c.position}
                                      properties={{ title: c.name }}
                                  />
                              ))
                            : null}
                        {selectedCluster ? (
                            <Popover
                                anchorReference="anchorPosition"
                                anchorPosition={anchorPosition}
                                open={selectedCluster !== undefined}
                                onClose={() => setSelectedCluster(undefined)}
                            >
                                <MapClusterPopup
                                    cluster={selectedCluster}
                                    handleClose={() => setSelectedCluster(undefined)}
                                    setSelectedGateway={setSelectedGateway}
                                />
                            </Popover>
                        ) : null}
                    </AzureMapDataSourceProvider>
                </AzureMap>
            </Box>
        </AzureMapsProvider>
    );
};

export default MapDisplay;
