import { Button, ButtonProps, styled, Typography } from '@mui/material';
import { themeColors } from '../../theme';
import { AuthorizationProps } from '../../types/authorizationTypes';
import { useAuthorization } from '../../hooks/useAuthorization';
import { withAuthorizationDisplay } from '../common/withAuthorization';
import { ComponentType } from 'react';
import { useColorMode } from '../../hooks/useColorMode';

interface SidebarButtonProps extends ButtonProps {
    label: string;
    Icon: ComponentType<SidebarIconProps>;
}

export interface SidebarIconProps {
    disabled: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.spacing(0),
    width: theme.spacing(50),
    minHeight: theme.spacing(10),
    justifyContent: 'left',
    '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main
    }
}));

export const SidebarButton = (props: AuthorizationProps<SidebarButtonProps>) => {
    const { Icon, label, ...buttonProps } = props;
    const { disabled } = useAuthorization(props.interaction);
    const { isLightMode } = useColorMode();
    const enabledTextColor = isLightMode
        ? themeColors.colorPalettePrimary09
        : themeColors.colorPalettePrimary04;
    const color = disabled ? themeColors.colorPalettePrimary05 : enabledTextColor;

    const ButtonWithAuth = withAuthorizationDisplay(StyledButton);

    return (
        <ButtonWithAuth disableRipple startIcon={<Icon disabled={disabled} />} {...buttonProps}>
            <Typography variant="h6" sx={{ color, paddingLeft: 5 }}>
                {label}
            </Typography>
        </ButtonWithAuth>
    );
};
