import { SvgIcon } from '@mui/material';
import { useColorMode } from '../hooks/useColorMode';
import { themeColors } from '../theme';

const FilterIcon = () => {
    const { isLightMode } = useColorMode();
    return (
        <SvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.629279 1.2469L0.629252 1.24688C0.35486 0.972483 0.550368 0.5 0.938589 0.5H19.0616C19.4496 0.5 19.6455 0.972307 19.3709 1.24689L19.7245 1.60043L19.3709 1.2469L12.1464 8.47229L12 8.61873V8.82582V19.0617C12 19.4147 11.6006 19.6224 11.3116 19.4201L11.3115 19.4201L8.18664 17.2334C8.18661 17.2334 8.18657 17.2334 8.18654 17.2334C8.0696 17.1515 7.99999 17.0177 7.99999 16.875V8.82582V8.61873L7.85357 8.47229L0.629279 1.2469Z"
                stroke={
                    isLightMode
                        ? themeColors.colorPalettePrimary09
                        : themeColors.colorPalettePrimary03
                }
                fill={
                    isLightMode
                        ? themeColors.colorPalettePrimary00
                        : themeColors.colorPalettePrimary10
                }
            />
        </SvgIcon>
    );
};

export default FilterIcon;
