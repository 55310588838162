import { getExternalUsers } from '../../../api/externalUserApi';
import { useLoad } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import ExternalUsersList from './externalUsersList';

const ExternalUsersListPage = () => {
    const { externalUserListScreen } = useLanguage();
    const { loading, error, data, setData } = useLoad(getExternalUsers);
    return (
        <DefaultPageLayout
            pageHeader={externalUserListScreen.header}
            loading={loading}
            error={error}
        >
            {data && <ExternalUsersList externalUsers={data} setExternalUsers={setData} />}
        </DefaultPageLayout>
    );
};

export default ExternalUsersListPage;
