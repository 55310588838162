import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useLanguage } from '../../hooks/useLanguage';
import { darkThemeColors, lightMode, lightThemeColors, themeColors } from '../../theme';
import { RequiredStatus } from '../../types/displayTypes';
import { formFieldId, formLabelId } from './formInputRenderFunctions';

/*
Used for finding error message within react-hook-form formState.errors, given string matching nested property names.
examples: 
const a = {b:{c:{message:"Fetch Failed"}}};
getNullableNestedPropertyFromString("b.c", a) //returns the string "Fetch Failed"
getNullableNestedPropertyFromString("b.d", a) //returns undefined without throwing error
*/
const getNullableNestedPropertyFromString: (s: string, obj: any) => string | undefined = (
    s: string,
    obj: any
) => s.split('.').reduce((ref: any, property: string) => ref?.[property], obj)?.message;

interface FormGroupProps<FormType> {
    label: string;
    fieldName: keyof FormType;
    children: JSX.Element;
    maxWidthPx?: number; // limit width for form elements which don't take parent width
    tooltipTitle?: string;
    requiredStatus?: RequiredStatus;
}

export const FormElement = <FormType,>({
    children,
    fieldName,
    label,
    maxWidthPx: maxWidth,
    tooltipTitle,
    requiredStatus
}: FormGroupProps<FormType>) => {
    const fieldKey = fieldName.toString();
    const { form } = useLanguage();
    const { formState } = useFormContext<FormType>();
    const errorMessage = getNullableNestedPropertyFromString(fieldKey, formState.errors);
    return (
        <FormControl error={!!errorMessage} sx={{ maxWidth }}>
            <Stack direction="row" spacing={2}>
                <FormLabel htmlFor={formFieldId(fieldKey)} id={formLabelId(fieldKey)}>
                    {requiredStatus === RequiredStatus.Required && (
                        <Typography
                            variant="labelForm"
                            color={(theme) =>
                                theme.palette.mode === lightMode
                                    ? lightThemeColors.error
                                    : darkThemeColors.error
                            }
                        >
                            {'* '}
                        </Typography>
                    )}
                    <Typography variant="labelForm">{label}</Typography>
                    {requiredStatus === RequiredStatus.Optional && (
                        <Typography
                            variant="labelForm"
                            paddingLeft={1}
                            color={themeColors.colorPalettePrimary05}
                            fontWeight="400"
                        >
                            {form.optionalField}
                        </Typography>
                    )}
                </FormLabel>
                {tooltipTitle !== undefined && (
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <Tooltip title={<Box whiteSpace="pre-line">{tooltipTitle}</Box>}>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                size="xs"
                                color={themeColors.colorPalettePrimary05}
                            />
                        </Tooltip>
                    </Box>
                )}
            </Stack>
            {children}
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};
