import { SxProps, Theme } from '@mui/material';

// Enums
export enum UserRole {
    ExternalUser = 0,
    InternalViewer = 1,
    InternalUser = 2,
    Admin = 3
}

// Types
export interface UserInteraction {
    enable?: UserRole;
    visible?: UserRole;
}

export type AuthorizationProps<T> = T & { interaction?: UserInteraction };

export type AuthComponentProps = {
    disabled?: boolean;
    sx?: SxProps<Theme>;
};
