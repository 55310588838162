import { Components, PaletteMode, PaletteOptions, Theme, ThemeOptions } from '@mui/material';
// lab import currently required for data grid, see here: https://github.com/mui/mui-x/issues/1755
import type {} from '@mui/x-data-grid/themeAugmentation';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import '@fontsource/barlow';
import React from 'react';
import { SelectIcon } from './icons/selectIcon';

export const lightMode: PaletteMode = 'light';
export const darkMode: PaletteMode = 'dark';

export const themeColors = {
    colorPalettePrimary00: '#F7F8F9',
    colorPalettePrimary01: '#EFF1F3',
    colorPalettePrimary02: '#E8ECED',
    colorPalettePrimary03: '#E0E3E6',
    colorPalettePrimary04: '#C9CFD4',
    colorPalettePrimary05: '#818F99',
    colorPalettePrimary06: '#3B4957',
    colorPalettePrimary07: '#2B3847',
    colorPalettePrimary08: '#1A2430',
    colorPalettePrimary09: '#141D27',
    colorPalettePrimary10: '#111921',
    colorPalettePrimary11: '#0E1117'
};

// colors are defined in the project Wiki: https://dev.azure.com/HAL-HDS/Automation/_wiki/wikis/Automation.wiki/1259/UI-Reference-Material
export const lightThemeColors = {
    background: '#FFFFFF',

    // indicator colors
    primary: '#3275B8', // blue
    success: '#32B877', // green
    warning: '#D9B100', // yellow
    error: '#C71A2F', // red

    link: '#2A639C', // blue

    availableGateways: '#596166', // darker gray
    commissionedGateways: '#BEC5CA', // lighter gray
    outerRing: '#444A4F', // darkest gray

    // brand colors - shades of red
    drillingAndWellConstruction: '#E5181B',
    halliburtonRed: '#CC0000',
    exploration: '#F87F14'
};

export const darkThemeColors = {
    background: themeColors.colorPalettePrimary11,

    // indicator colors
    primary: '#7B61FF', // bluish purple
    success: '#32B877', // green
    warning: '#D9B100',
    error: '#DD7D89',

    link: '#2A639C', // blue

    availableGateways: '#596166', // darker gray
    commissionedGateways: '#BEC5CA', // lighter gray
    outerRing: '#444A4F' // darkest gray
};

export const themeValues = {
    formLabelHeightPx: '18px'
};

// typography is defined in the project Wiki: https://dev.azure.com/HAL-HDS/Automation/_wiki/wikis/Automation.wiki/1259/UI-Reference-Material
export const typography: TypographyOptions = {
    fontFamily: 'Barlow, Arial, Open Sans, sans-serif',
    button: {
        fontWeight: 700,
        fontSize: 14
    },
    h1: {
        fontSize: 28,
        fontWeight: 600,
        lineHeight: 4.875,
        letterSpacing: 0,
        textAlign: 'left'
    },
    h2: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 3.5,
        letterSpacing: 0,
        textAlign: 'left'
    },
    h3: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        textAlign: 'left'
    },
    h4: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        textAlign: 'left'
    },
    h5: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0
    },
    h6: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.2
    },
    labelXLarge: {
        fontSize: 15,
        fontWeight: 600,
        lineHeight: 1.2,
        textTransform: 'uppercase'
    },
    labelLarge: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 2.125,
        letterSpacing: 0,
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    labelSmall: {
        fontSize: 10,
        fontWeight: 700,
        lineHeight: 1.75,
        letterSpacing: 0,
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    labelForm: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14px',
        letterSpacing: 0,
        textAlign: 'left'
    },
    action: {
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: 0,
        textAlign: 'left'
    },
    tabs: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 2.125,
        letterSpacing: 0,
        textAlign: 'left'
    },
    radioLabel: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.375
    },
    settingsLabel: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: 0,
        textAlign: 'left'
    },
    numericIndicator: {
        fontSize: 60,
        fontWeight: 400,
        lineHeight: 1.2
    },
    popoverHeader: {
        fontSize: 14,
        fontWeight: 600
    }
};

const lightModePalette: PaletteOptions = {
    background: {
        default: lightThemeColors.background
    },
    primary: {
        main: themeColors.colorPalettePrimary07,
        contrastText: themeColors.colorPalettePrimary02
    },
    secondary: {
        main: themeColors.colorPalettePrimary02,
        contrastText: themeColors.colorPalettePrimary06
    },
    error: {
        main: lightThemeColors.error
    },
    warning: {
        main: lightThemeColors.warning
    },
    success: {
        main: lightThemeColors.success
    },
    info: {
        main: lightThemeColors.exploration,
        contrastText: themeColors.colorPalettePrimary03
    },
    text: {
        primary: themeColors.colorPalettePrimary10,
        secondary: themeColors.colorPalettePrimary06
    }
};

const darkModePalette: PaletteOptions = {
    background: {
        default: darkThemeColors.background,
        paper: darkThemeColors.background
    },
    primary: {
        main: themeColors.colorPalettePrimary06,
        contrastText: themeColors.colorPalettePrimary04
    },
    secondary: {
        main: themeColors.colorPalettePrimary08,
        contrastText: themeColors.colorPalettePrimary05
    },
    error: {
        main: darkThemeColors.error
    },
    warning: {
        main: darkThemeColors.warning
    },
    success: {
        main: darkThemeColors.success
    },
    info: {
        main: lightThemeColors.exploration,
        contrastText: themeColors.colorPalettePrimary03
    },
    text: {
        primary: themeColors.colorPalettePrimary04,
        secondary: themeColors.colorPalettePrimary05
    }
};

const getColorModeComponents = (mode: PaletteMode): Components<Omit<Theme, 'components'>> => ({
    MuiDrawer: {
        styleOverrides: {
            paper: {
                backgroundColor:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary00
                        : themeColors.colorPalettePrimary10
            }
        }
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                backgroundColor:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary00
                        : themeColors.colorPalettePrimary10
            }
        }
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                lineHeight: themeValues.formLabelHeightPx,
                '&.Mui-focused': {
                    color: mode === darkMode ? themeColors.colorPalettePrimary05 : undefined
                }
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                fontWeight: 500,
                fontSize: '14px',
                backgroundColor:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary00
                        : themeColors.colorPalettePrimary10
            },
            multiline: {
                padding: '0px 0px'
            },
            input: {
                padding: '6px 12px'
            }
        }
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                minWidth: '530px',
                backgroundColor:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary00
                        : darkThemeColors.background,
                padding: '15px 35px 30px'
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            cell: {
                padding: '20px 0px 20px'
            },
            columnHeader: {
                backgroundColor:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary02
                        : themeColors.colorPalettePrimary07
            },
            columnHeaderTitle: {
                color:
                    mode === lightMode
                        ? themeColors.colorPalettePrimary06
                        : themeColors.colorPalettePrimary05,
                fontSize: typography.labelSmall?.fontSize,
                fontWeight: typography.labelSmall?.fontWeight,
                lineHeight: typography.labelSmall?.lineHeight,
                letterSpacing: typography.labelSmall?.letterSpacing,
                textAlign: typography.labelSmall?.textAlign,
                textTransform: typography.labelSmall?.textTransform,
                paddingLeft: '18px'
            },
            columnSeparator: {
                visibility: 'hidden'
            },
            columnHeaderTitleContainer: {
                justifyContent: 'space-between'
            },
            iconButtonContainer: {
                visibility: 'visible',
                width: 20
            },
            cellContent: {
                paddingLeft: '18px'
            },
            virtualScroller: {
                '&::-webkit-scrollbar': {
                    width: '1em'
                },
                '&::-webkit-scrollbar-track': {
                    background:
                        mode === lightMode
                            ? lightThemeColors.background
                            : darkThemeColors.background
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor:
                        mode === lightMode
                            ? themeColors.colorPalettePrimary05
                            : themeColors.colorPalettePrimary06
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background:
                        mode === lightMode
                            ? themeColors.colorPalettePrimary08
                            : themeColors.colorPalettePrimary06
                }
            }
        }
    }
});

export const getTheme = (mode: PaletteMode) => {
    const themeOptions: ThemeOptions = {
        spacing: 5, //px
        typography,
        palette: {
            mode,
            ...(mode === lightMode ? lightModePalette : darkModePalette)
        },
        components: {
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        height: '100%'
                    }
                }
            },
            MuiSelect: {
                defaultProps: {
                    IconComponent: SelectIcon
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    dense: {
                        minHeight: '0px'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        padding: '6px 24px',
                        boxShadow: 'none',
                        height: '32px'
                    }
                }
            },
            ...getColorModeComponents(mode)
        }
    };
    return themeOptions;
};

declare module '@mui/material/styles' {
    interface TypographyVariants {
        labelXLarge: React.CSSProperties;
        labelLarge: React.CSSProperties;
        labelSmall: React.CSSProperties;
        labelForm: React.CSSProperties;
        action: React.CSSProperties;
        tabs: React.CSSProperties;
        radioLabel: React.CSSProperties;
        settingsLabel: React.CSSProperties;
        numericIndicator: React.CSSProperties;
        popoverHeader: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        labelXLarge?: React.CSSProperties;
        labelLarge?: React.CSSProperties;
        labelSmall?: React.CSSProperties;
        labelForm?: React.CSSProperties;
        action?: React.CSSProperties;
        tabs?: React.CSSProperties;
        radioLabel?: React.CSSProperties;
        settingsLabel?: React.CSSProperties;
        numericIndicator?: React.CSSProperties;
        popoverHeader?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        labelXLarge: true;
        labelLarge: true;
        labelSmall: true;
        labelForm: true;
        action: true;
        tabs: true;
        radioLabel: true;
        settingsLabel: true;
        numericIndicator: true;
        popoverHeader: true;
    }
}
