// Enums

export enum SignalUnitType {
    Angle = 'Angle',
    AngleGradient = 'AngleGradient',
    Area = 'Area',
    ConcentrationMassMass = 'ConcentrationMassMass',
    ConcentrationMassVolume = 'ConcentrationMassVolume',
    ConcentrationVolumeVolume = 'ConcentrationVolumeVolume',
    Currency = 'Currency',
    Current = 'Current',
    DensityMassVolume = 'DensityMassVolume',
    Diameter = 'Diameter',
    Diffusivity = 'Diffusivity',
    ElectricPower = 'ElectricPower',
    ElectricPowerCalibration = 'ElectricPowerCalibration',
    EngineSpeed = 'EngineSpeed',
    FlowIndex = 'FlowIndex',
    FlowmeterCalibration = 'FlowmeterCalibration',
    FluidLossCoefficient = 'FluidLossCoefficient',
    Force = 'Force',
    FormationVolumeFactor = 'FormationVolumeFactor',
    FractureConductivity = 'FractureConductivity',
    FractureToughness = 'FractureToughness',
    Frequency = 'Frequency',
    GForce = 'GForce',
    GasProduction = 'GasProduction',
    InverseLength = 'InverseLength',
    InversePressure = 'InversePressure',
    InverseTemperature = 'InverseTemperature',
    InverseTime = 'InverseTime',
    InverseVolumeGradient = 'InverseVolumeGradient',
    KPrime = 'KPrime',
    Length = 'Length',
    Mass = 'Mass',
    MassGradient = 'MassGradient',
    OilGravity = 'OilGravity',
    OilProduction = 'OilProduction',
    PerforationDensity = 'PerforationDensity',
    Permeability = 'Permeability',
    Ph = 'Ph',
    PhCalibration = 'PhCalibration',
    Power = 'Power',
    Pressure = 'Pressure',
    PressureAbsolute = 'PressureAbsolute',
    PressureCalibration = 'PressureCalibration',
    PressureGradient = 'PressureGradient',
    RateMassTime = 'RateMassTime',
    RateVolumeTime = 'RateVolumeTime',
    RateIncrement = 'RateIncrement',
    Ratio = 'Ratio',
    SpecificHeat = 'SpecificHeat',
    SpurtLossCoefficient = 'SpurtLossCoefficient',
    Temperature = 'Temperature',
    TemperatureCalibration = 'TemperatureCalibration',
    ThermalConductivity = 'ThermalConductivity',
    ThermalGradient = 'ThermalGradient',
    Time = 'Time',
    TimeRelative = 'TimeRelative',
    Torque = 'Torque',
    Unitless = 'Unitless',
    Velocity = 'Velocity',
    Viscosity = 'Viscosity',
    Voltage = 'Voltage',
    Volume = 'Volume',
    VolumeGradient = 'VolumeGradient',
    VolumePerCoupling = 'VolumePerCoupling',
    VolumeRatio = 'VolumeRatio',
    VolumeRatioIncrement = 'VolumeRatioIncrement',
    YieldVolumeMass = 'YieldVolumeMass',
    YieldPoint = 'YieldPoint'
}
