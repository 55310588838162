import { AppBar, Drawer, IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import NavigationTab from './navigationTab';
import { lightMode, themeColors } from '../../theme';
import { routes } from '../../pages';
import { useLanguage } from '../../hooks/useLanguage';
import { useOktaAuth } from '@okta/okta-react';
import { UserRole } from '../../types/authorizationTypes';
import { useState } from 'react';
import UserSettings from '../pages/userSettings';
import { useColorMode } from '../../hooks/useColorMode';
import { useAuthorization } from '../../hooks/useAuthorization';

const getCurrentTabExtension = (fullPath: string) => {
    const pathElements = fullPath.split('/');
    // second element represents the path of the current top-level tab

    const tabPath = pathElements[1];
    if (
        tabPath === routes.dashboard ||
        tabPath === routes.gateways ||
        tabPath === routes.layouts ||
        tabPath === routes.deviceDefinitions
    ) {
        return tabPath;
    }
    return '';
};

const TopNavigationBar = () => {
    const location = useLocation();
    const { oktaAuth } = useOktaAuth();
    const currentTabExtension = getCurrentTabExtension(location.pathname);
    const { isLightMode } = useColorMode();
    const { navigationBar } = useLanguage();
    const { disabled: hideDashboardTab } = useAuthorization({ enable: UserRole.InternalViewer });

    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <Stack height="100vh" width="100vw" margin={0} padding={0}>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === lightMode
                            ? themeColors.colorPalettePrimary00
                            : themeColors.colorPalettePrimary10,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <Stack direction="row">
                    <Stack direction="row" flexGrow={1}>
                        <Tabs
                            value={currentTabExtension}
                            sx={{
                                flexGrow: 0,
                                '.MuiTabs-indicator': {
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === lightMode
                                            ? themeColors.colorPalettePrimary02
                                            : themeColors.colorPalettePrimary08
                                }
                            }}
                        >
                            {!hideDashboardTab && (
                                <NavigationTab
                                    value={routes.dashboard}
                                    label={navigationBar.dashboard}
                                />
                            )}
                            <NavigationTab value={routes.gateways} label={navigationBar.gateways} />
                            <NavigationTab
                                value={routes.layouts}
                                label={navigationBar.layouts}
                                interaction={{ visible: UserRole.InternalUser }}
                            />
                            <NavigationTab
                                value={routes.deviceDefinitions}
                                label={navigationBar.deviceDefinitions}
                                interaction={{ visible: UserRole.InternalUser }}
                            />
                            <Tab value={''} />
                        </Tabs>
                    </Stack>
                    <Tooltip title={navigationBar.logoutTooltip}>
                        <IconButton
                            sx={{
                                color: isLightMode
                                    ? themeColors.colorPalettePrimary08
                                    : themeColors.colorPalettePrimary05
                            }}
                            onClick={async () => {
                                await oktaAuth.signOut({
                                    postLogoutRedirectUri: `${window.location.origin}/${routes.loggedOut}`
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faUser} />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        sx={{
                            color: isLightMode
                                ? themeColors.colorPalettePrimary08
                                : themeColors.colorPalettePrimary05
                        }}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <FontAwesomeIcon icon={faCog} />
                    </IconButton>
                </Stack>
            </AppBar>
            <Drawer open={drawerOpen} anchor="right" elevation={0}>
                <UserSettings setDrawer={setDrawerOpen} />
            </Drawer>
            <Stack component="main" height="100%">
                <Outlet />
            </Stack>
        </Stack>
    );
};

export default TopNavigationBar;
