import { Stack, Typography, OutlinedInput } from '@mui/material';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { useStringConverters } from '../../../../hooks/useStringConverters';
import { useLanguage } from '../../../../hooks/useLanguage';
import {
    LocalDeviceDefinition,
    LocalMoxaModbusRegister,
    Scaling,
    SignalDataType,
    SignalEventClass,
    Swap
} from '../../../../types/deviceDefinitionTypes';
import { FormElement } from '../../../common/formElement';
import { formFieldId } from '../../../common/formInputRenderFunctions';
import MoxaModbusRegisterEditor from './moxaModbusRegisterEditor';
import VerticalStack from '../../../common/verticalStack';

import {
    xShortString,
    shortString,
    moxaNameFormat,
    UIntMinimum
} from '../../../../constants/validation';
import {constructUnitList} from "../../../../helperFunctions";

const MoxaModbusConfigurationEditor = () => {
    const { convertInt } = useStringConverters();
    const {
        form: formText,
        deviceDefinitionForm,
        moxaCommunicationDefinition,
        moxaRegisterFields,
        moxaRegisterValidationErrors
    } = useLanguage();
    const form = useFormContext<LocalDeviceDefinition>();

    const checkRegisterTypes = (register: LocalMoxaModbusRegister) => {
        const errorMessage: string[] = [];

        const ints = [
            [moxaRegisterFields.dataType, register.dataType],
            [moxaRegisterFields.address, register.address],
            [moxaRegisterFields.quantity, register.quantity],
            [moxaRegisterFields.eventClass, register.eventClass],
            [moxaRegisterFields.dataSize, register.dataSize],
            [moxaRegisterFields.offset, register.offset],
            [moxaRegisterFields.scaling, register.scaling],
            [moxaRegisterFields.swap, register.swap]
        ];
        const invalidInts = ints
            .map((item) => {
                if (item[1]) {
                    try {
                        convertInt(String(item[1]));
                        return '';
                    } catch {
                        return item[0];
                    }
                }
                return '';
            })
            .filter((e) => e !== '')
            .map((e) => `${moxaRegisterValidationErrors.invalidInteger}${e}`);
        errorMessage.push(...invalidInts);

        if (!Object.values(SignalDataType).includes(register.dataType)) {
            errorMessage.push(moxaRegisterValidationErrors.invalidDataType);
        }

        if (!Object.values(Scaling).includes(register.scaling)) {
            errorMessage.push(moxaRegisterValidationErrors.invalidScaling);
        }

        if (
            register.eventClass !== null &&
            !Object.values(SignalEventClass).includes(register.eventClass)
        ) {
            errorMessage.push(moxaRegisterValidationErrors.invalidEventClass);
        }

        if (
            register.swap !== null &&
            register.swap !== undefined &&
            !Object.values(Swap).includes(register.swap)
        ) {
            errorMessage.push(moxaRegisterValidationErrors.invalidSwap);
        }

        const booleanValues = [true, false];
        if (!booleanValues.includes(register.readOnly)) {
            errorMessage.push(
                `${moxaRegisterValidationErrors.invalidBooleanPrefix}${moxaRegisterFields.readOnly}${moxaRegisterValidationErrors.invalidBooleanSuffix}`
            );
        }

        const uints = [
            [moxaRegisterFields.dataSize, register.dataSize],
            [moxaRegisterFields.offset, register.offset],
            [moxaRegisterFields.address, register.address],
            [moxaRegisterFields.quantity, register.quantity]
        ];
        const problemUints = uints
            .filter((item) => item[1] && item[1] < 0)
            .map((item) => `${moxaRegisterValidationErrors.invalidUint}${item[0]}`);

        if (problemUints.length > 0) {
            errorMessage.push(...problemUints);
        }

        const finalMessage = errorMessage.join(', ');
        if (finalMessage === '') {
            return finalMessage;
        }

        return `${register.name}${moxaRegisterValidationErrors.registerErrors}${finalMessage}`;
    };

    const checkRegisterLengths = (register: LocalMoxaModbusRegister) => {
        const errorMessage: string[] = [];
        if (register.name.length > xShortString) {
            errorMessage.push(
                `${moxaRegisterFields.name}${moxaRegisterValidationErrors.maximumLength}${xShortString}`
            );
        }

        if (register.dataUnit.length > shortString) {
            errorMessage.push(
                `${moxaRegisterFields.dataUnit}${moxaRegisterValidationErrors.maximumLength}${shortString}`
            );
        }

        const finalMessage = errorMessage.join(', ');
        if (finalMessage === '') {
            return finalMessage;
        }

        return `${register.name}${moxaRegisterValidationErrors.registerErrors}${finalMessage}`;
    };

    const checkRegisterNullability = (register: LocalMoxaModbusRegister) => {
        const errorMessage: string[] = [];
        if (!register.name) {
            errorMessage.push(`${moxaRegisterFields.name}${moxaRegisterValidationErrors.required}`);
        }

        if (
            !register.dataSize &&
            (register.dataType === SignalDataType.String ||
                register.dataType === SignalDataType.None)
        ) {
            errorMessage.push(
                `${moxaRegisterFields.dataSize}${moxaRegisterValidationErrors.required}${moxaRegisterValidationErrors.dataSizeRequiredCondition}`
            );
        }

        if (
            register.dataSize &&
            register.dataType !== SignalDataType.String &&
            register.dataType !== SignalDataType.None
        ) {
            errorMessage.push(
                `${moxaRegisterFields.dataSize}${moxaRegisterValidationErrors.mustBeNull}${moxaRegisterValidationErrors.dataSizeNullCondition}`
            );
        }

        const finalMessage = errorMessage.join(', ');
        if (finalMessage === '') {
            return finalMessage;
        }

        return `${register.name}${moxaRegisterValidationErrors.registerErrors}${finalMessage}`;
    };

    const checkRegisterNames = (register: LocalMoxaModbusRegister) => {
        if (moxaNameFormat.test(register.name)) {
            return '';
        }
        return `${moxaRegisterValidationErrors.invalidNamePrefix}${register.name}${moxaRegisterValidationErrors.invalidNameSuffix}${moxaRegisterValidationErrors.nameInvalidCharacter}`;
    };

    const checkRegisterUnits = (register: LocalMoxaModbusRegister) => {
        const signalUnits = constructUnitList();
        if (signalUnits.includes(register.dataUnit)) {
            return '';
        }
        return `${moxaRegisterValidationErrors.invalidUnitPrefix}${register.dataUnit}${moxaRegisterValidationErrors.invalidUnitSuffix}${register.name}`;
    };

    const checkQuantity = (register: LocalMoxaModbusRegister) => {
        if (
            register.dataType === SignalDataType.Double &&
            !(register.quantity === 2 || register.quantity === 4)
        ) {
            return moxaRegisterValidationErrors.invalidQuantityForDouble;
        } else if (register.quantity < 1) {
            return `${formText.minimum}${1}`;
        }
        return '';
    };

    const validateRegisters = (registers: LocalMoxaModbusRegister[]) => {
        const typeErrors = registers.map((r) => checkRegisterTypes(r)).filter((e) => e !== '');
        const nullabilityErrors = registers
            .map((r) => checkRegisterNullability(r))
            .filter((e) => e !== '');
        const lengthErrors = registers.map((r) => checkRegisterLengths(r)).filter((e) => e !== '');
        const nameErrors = registers.map((r) => checkRegisterNames(r)).filter((e) => e !== '');
        const unitErrors = registers.map((r) => checkRegisterUnits(r)).filter((e) => e !== '');
        const quantityErrors = registers.map((r) => checkQuantity(r)).filter((e) => e !== '');
        const errors = [
            ...typeErrors,
            ...nullabilityErrors,
            ...lengthErrors,
            ...nameErrors,
            ...unitErrors,
            ...quantityErrors
        ];

        if (errors.length > 0) {
            return errors.join('. ');
        }
        return;
    };

    return (
        <VerticalStack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                paddingTop={6}
                paddingBottom={3}
            >
                <Typography variant="h4">
                    {deviceDefinitionForm.communicationDefinitionHeader}
                </Typography>
            </Stack>

            <FormProvider {...form}>
                <Stack maxWidth="33%">
                    <FormElement<LocalDeviceDefinition>
                        fieldName="moxaModbusSlaveId"
                        label={moxaCommunicationDefinition.serverIdLabel}
                        tooltipTitle={moxaCommunicationDefinition.serverIdTooltip}
                    >
                        <OutlinedInput
                            {...form.register('moxaModbusSlaveId', {
                                valueAsNumber: true,
                                required: formText.required,
                                min: {
                                    value: UIntMinimum,
                                    message: `${formText.minimum}${UIntMinimum}`
                                }
                            })}
                            id={formFieldId('moxaModbusSlaveId')}
                            type="number"
                        />
                    </FormElement>
                </Stack>
                <FormElement<LocalDeviceDefinition> fieldName="moxaModbusRegisters" label="">
                    <Controller
                        control={form.control}
                        name="moxaModbusRegisters"
                        rules={{ validate: validateRegisters }}
                        render={({ field }) => (
                            <MoxaModbusRegisterEditor
                                registers={field.value}
                                onRegistersChange={field.onChange}
                            />
                        )}
                    ></Controller>
                </FormElement>
            </FormProvider>
        </VerticalStack>
    );
};

export default MoxaModbusConfigurationEditor;
