import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createLayoutAndDevices } from '../../../api/layoutApi';
import { DeviceDefinitionContext } from '../../../contexts/deviceDefinitionContext';
import { LayoutContext } from '../../../contexts/layoutsContext';
import { getDefaultPsl } from '../../../helperFunctions';
import { useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import { routes } from '../../../pages';
import { SaveLayout, LayoutResponse } from '../../../types/layoutTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import VerticalStack from '../../common/verticalStack';
import LayoutForm from './layoutForm';

const layoutPageRoute = `/${routes.layouts}`;

const CreateLayoutPage = () => {
    const { createLayoutScreen } = useLanguage();
    const navigate = useNavigate();
    const { layoutsSource, deviceDefinitionsSource, pslsSource } = useDataSource();

    const onSuccess = (_: SaveLayout, response: LayoutResponse | undefined) => {
        if (layoutsSource.data && response) {
            layoutsSource.setData([...layoutsSource.data, response]);
        }

        navigate(layoutPageRoute);
    };

    const createRequest = useRequest(createLayoutAndDevices, onSuccess);

    const onCancel = () => {
        navigate(layoutPageRoute);
    };

    const loading =
        layoutsSource.loading ||
        deviceDefinitionsSource.loading ||
        pslsSource.loading ||
        createRequest.loading;
    const error =
        layoutsSource.error ||
        deviceDefinitionsSource.error ||
        pslsSource.error ||
        createRequest.error;

    return (
        <DefaultPageLayout pageHeader={createLayoutScreen.header} loading={loading} error={error}>
            <VerticalStack>
                <Typography variant="h5" paddingBottom={8} paddingTop={2}>
                    {createLayoutScreen.description}
                </Typography>
                {deviceDefinitionsSource.data && (
                    <DeviceDefinitionContext.Provider value={deviceDefinitionsSource.data}>
                        {layoutsSource.data && pslsSource.data && (
                            <LayoutContext.Provider value={layoutsSource.data}>
                                <LayoutForm
                                    defaultValues={{
                                        name: '',
                                        notes: '',
                                        devices: [],
                                        assetInfo: {
                                            pslId: getDefaultPsl(pslsSource.data)
                                        }
                                    }}
                                    onSubmit={createRequest.executeRequest}
                                    onCancel={onCancel}
                                />
                            </LayoutContext.Provider>
                        )}
                    </DeviceDefinitionContext.Provider>
                )}
            </VerticalStack>
        </DefaultPageLayout>
    );
};

export default CreateLayoutPage;
