import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const disconnectedThresholdMinutes = 15;

const isUtcDateStringPastDisconnectedThreshold = (utcDateString: string) => {
    const date = dayjs.utc(utcDateString);
    if (!date.isValid()) {
        // If supplied date is invalid, then return true to indicate that date is "past threshold" (Disconnected)
        return true;
    }

    const now = dayjs.utc();
    return now.diff(date, 'minute') >= disconnectedThresholdMinutes;
};

export function useGatewayConnectedStatusThreshold() {
    const isGatewayOnline = (utcDateString: string | undefined) => {
        // If date string is undefined, then gateway does not have any statuses in DB - return false to indicate Disconnected
        if (utcDateString === undefined) {
            return false;
        }
        return !isUtcDateStringPastDisconnectedThreshold(utcDateString);
    };

    return { isGatewayOnline };
}
