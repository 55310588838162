import { validate as uuidValidate } from 'uuid';
import { opcUANodeIdTypes } from '../constants/enums';
import { checkFieldUniqueness, isInt } from '../helperFunctions';
import { OpcUANode, OpcUANodeIdType } from '../types/deviceDefinitionTypes';
import { useOpcUANodeIdTypeEnum } from './enumHooks';
import { useLanguage } from './useLanguage';
import { Buffer } from 'buffer';

export const useNodeValidation = () => {
    const { opcUANodeValidationErrors } = useLanguage();
    const { opcUANodeIdDisplay } = useOpcUANodeIdTypeEnum();

    const validateNodeId = (node: OpcUANode) => {
        const errorMessage = `${opcUANodeValidationErrors.invalidNodeId}${
            opcUANodeIdDisplay[node.nodeIdType]
        }`;
        switch (node.nodeIdType) {
            case OpcUANodeIdType.Integer:
                if (!isInt(node.nodeId)) return errorMessage;
                break;
            case OpcUANodeIdType.Guid:
                if (!uuidValidate(node.nodeId)) return errorMessage;
                break;
            case OpcUANodeIdType.String:
                // Unconditionally return, since anything is accepted for String type
                return '';
            case OpcUANodeIdType.Base64String:
                // Checks that decoding and re-encoding base 64 string matches original node ID - if it doesn't, then an invalid string was supplied
                // https://stackoverflow.com/questions/32491681/how-to-check-if-a-string-is-plaintext-or-base64-format-in-node-js
                if (Buffer.from(node.nodeId, 'base64').toString('base64') !== node.nodeId)
                    return errorMessage;
                break;
        }
        return '';
    };

    const validateNamespaceId = (node: OpcUANode) => {
        if (!isInt(node.namespaceId) || node.namespaceId <= 0) {
            return opcUANodeValidationErrors.invalidNamespaceId;
        }

        return '';
    };

    const getNodeAddressString = (node?: OpcUANode) => {
        if (node === null || node === undefined || !opcUANodeIdTypes.includes(node.nodeIdType)) {
            return null;
        }

        const nodeIdType = opcUANodeIdDisplay[node.nodeIdType];
        return `ns=${node.namespaceId};${nodeIdType}=${node.nodeId}`;
    };

    const validateNodeAddress = (node: OpcUANode, nodes: OpcUANode[], defaultValue?: OpcUANode) => {
        const nodeAddressUnique = checkFieldUniqueness(
            nodes,
            (n) => getNodeAddressString(n),
            getNodeAddressString(node),
            getNodeAddressString(defaultValue)
        );
        if (!nodeAddressUnique) {
            return opcUANodeValidationErrors.nodeAddressesNotUnique;
        }

        return true;
    };

    return { validateNodeId, validateNamespaceId, getNodeAddressString, validateNodeAddress };
};
