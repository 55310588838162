import { PaletteMode } from '@mui/material';
import { useMemo, useState } from 'react';
import { lightMode, darkMode } from '../theme';

export const useLocalStorage = () => {
    const initialColorMode = useMemo(() => {
        const savedColorMode = localStorage.getItem('colorMode');
        if (!savedColorMode || !(savedColorMode === lightMode || savedColorMode === darkMode)) {
            return lightMode;
        }
        return savedColorMode;
    }, []);
    const [colorMode, setColorMode] = useState<PaletteMode>(initialColorMode);

    const updateColorMode = useMemo(
        () =>
            // The dark mode switch would invoke this method
            (newMode: PaletteMode) => {
                localStorage.setItem('colorMode', newMode);
                setColorMode(newMode);
            },
        []
    );

    return { colorMode, updateColorMode };
};
