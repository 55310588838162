import { FullEntityFields } from './apiTypes';
import { Asset } from './assetTypes';

// Enums

export enum SignalDataType {
    None = 0,
    Boolean = 1,
    Integer = 2,
    Enum = 3,
    Double = 4,
    String = 5,
    UInt = 6,
    UsInt = 7
}

export enum SignalEventClass {
    Notify = 0,
    Warning = 1,
    Alarm = 2,
    Fault = 3
}

export enum Protocol {
    MoxaModbus = 0,
    Mqtt = 1,
    OpcUA = 2
}

export enum Scaling {
    Disabled = 0,
    Intercept = 1,
    Point = 2
}

export enum Swap {
    None = 0,
    Byte = 1,
    Word = 2,
    ByteWord = 3
}

export enum OpcUANodeIdType {
    String = 0,
    Integer = 1,
    Guid = 2,
    Base64String = 3
}

// Device Definition API Types

export interface DeviceDefinitionCreateRequestNoConfig {
    name: string;
    protocol: Protocol;
    assetInfo: Asset;
    manufacturer?: string;
}

export interface DeviceDefinitionCreateRequest extends DeviceDefinitionCreateRequestNoConfig {
    configuration: object;
}

export type DeviceDefinitionResponse = DeviceDefinitionCreateRequestNoConfig & FullEntityFields;

// Internal Types

// Device Configuration is intended to be a discriminated type union, where the type of the configuration
// is dependent on the protocol. Future protocols/configurations will be supported by adding to this
// definition with | {protocol: Protocol.NewProtocol; newProtocolConfig: NewProtocolConfigType}
export type DeviceConfiguration =
    | {
          protocol: Protocol.MoxaModbus;
          moxaModbusConfiguration: MoxaModbusConfiguration;
      }
    | { protocol: Protocol.Mqtt; mqttConfiguration: MqttConfiguration }
    | { protocol: Protocol.OpcUA; opcUAConfiguration: OpcUAConfiguration };

export interface LocalDeviceDefinition extends DeviceDefinitionCreateRequestNoConfig {
    moxaModbusSlaveId: number;
    moxaModbusRegisters: LocalMoxaModbusRegister[];
    mqttSignals: Signal[];
    mqttTopics: Topic[];
    opcUANodes: OpcUANode[];
    opcUASessionRenewalRequired: boolean;
    opcUASessionRenewalMinutes?: number | null;
    opcUAApplicationCertificatePath?: string | null;
    opcUASecurityEnabled: boolean;
    uomClass?: string;
    unitType?: string;
    dataUnit?: string;
}

export interface Signal {
    configurationElementId: string;
    name: string;
    dataType: SignalDataType;
    readOnly: boolean;
    eventClass: SignalEventClass | null;
    dataUnit: string;
    uomClass?: string;
    unitType?: string;
}

// MQTT Types
export interface Topic {
    name: string;
    route: string;
    groupId: string;
    isCommand: boolean;
    mqttSignalConfigurationElementIds: string[];
}

export interface MqttConfiguration {
    signals: Signal[];
    mqttTopics: Topic[];
}

// Moxa Modbus Types

export interface MoxaModbusRegisterBase extends Signal {
    address: number;
    quantity: number;
    dataSize?: number | null;
    offset: number;
    scaling: Scaling;
    swap?: Swap | null;
}

export interface InterceptScaling {
    slope?: number | null;
    interceptOffset?: number | null;
}

export interface PointScaling {
    pointSourceMin?: number | null;
    pointSourceMax?: number | null;
    pointTargetMin?: number | null;
    pointTargetMax?: number | null;
}

export type LocalMoxaModbusRegister = MoxaModbusRegisterBase & InterceptScaling & PointScaling;

export type MoxaModbusRegisterInterceptScaling = MoxaModbusRegisterBase & InterceptScaling;

export type MoxaModbusRegisterPointScaling = MoxaModbusRegisterBase & PointScaling;

export type SerializableRegisters =
    | MoxaModbusRegisterBase
    | MoxaModbusRegisterInterceptScaling
    | MoxaModbusRegisterPointScaling;

export interface LocalMoxaModbusConfiguration {
    moxaSlaveId: number;
    registers: LocalMoxaModbusRegister[];
}

export interface MoxaModbusConfiguration {
    moxaSlaveId: number;
    registers: SerializableRegisters[];
}

// OpcUA Types

export interface OpcUANode extends Signal {
    namespaceId: number;
    nodeIdType: OpcUANodeIdType;
    nodeId: string;
}

export interface OpcUAConfiguration {
    sessionRenewalRequired: boolean;
    sessionRenewalMinutes?: number | null;
    nodes: OpcUANode[];
    applicationCertificatePath?: string | null;
    securityEnabled: boolean;
}
