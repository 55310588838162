import { createContext, ReactNode } from 'react';
import { getDeviceDefinitions } from '../api/deviceDefinitionApi';
import { useLoad } from '../hooks/requestHooks';
import { DeviceDefinitionResponse } from '../types/deviceDefinitionTypes';
import { getLayouts } from '../api/layoutApi';
import { LayoutResponse } from '../types/layoutTypes';
import { SourceData } from '../types/apiTypes';
import { PslResponse } from '../types/pslTypes';
import { getPsls } from '../api/pslApi';

type SourceState = {
    deviceDefinitionsSource: SourceData<DeviceDefinitionResponse[]>;
    layoutsSource: SourceData<LayoutResponse[]>;
    pslsSource: SourceData<PslResponse[]>;
};

export const DataSource = createContext({
    deviceDefinitionsSource: {},
    layoutsSource: {},
    pslsSource: {}
} as SourceState);

export function DataSourceProvider({ children }: { children: ReactNode }) {
    const deviceDefinitionsSource = useLoad(getDeviceDefinitions);
    const layoutsSource = useLoad(getLayouts);
    const pslsSource = useLoad(getPsls);

    return (
        <DataSource.Provider value={{ deviceDefinitionsSource, layoutsSource, pslsSource }}>
            {children}
        </DataSource.Provider>
    );
}
