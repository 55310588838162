import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { faCalendarMinus, faPen } from '@fortawesome/free-solid-svg-icons';
import SortableTable from '../../common/sortableTable';
import { useLanguage } from '../../../hooks/useLanguage';
import { DeviceDefinitionResponse } from '../../../types/deviceDefinitionTypes';
import { useProtocolEnum } from '../../../hooks/enumHooks';
import { extensions } from '../../../pages';
import SearchBar from '../../common/searchBar';
import VerticalStack from '../../common/verticalStack';
import { useDateStringConverter } from '../../../hooks/useDateStringConverter';

interface DeviceDefinitionsListProps {
    deviceDefinitions: DeviceDefinitionResponse[];
    deleteDeviceDefinition: (id: string) => void;
}

const DeviceDefinitionsList = ({
    deviceDefinitions,
    deleteDeviceDefinition
}: DeviceDefinitionsListProps) => {
    const { deviceDefinitionScreen: definitionScreenText, sortableTable } = useLanguage();
    const navigate = useNavigate();
    const { convertProtocol } = useProtocolEnum();
    const { getFormattedDate } = useDateStringConverter();

    const [searchTerm, setSearchTerm] = useState('');

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: definitionScreenText.definitionNameColumn,
            flex: 1
        },
        {
            field: 'protocol',
            headerName: definitionScreenText.protocolColumn,
            valueFormatter: (params) => convertProtocol(params.value),
            flex: 1
        },
        {
            field: 'updatedDate',
            type: 'date',
            headerName: definitionScreenText.lastUpdatedColumn,
            valueFormatter: (params) => getFormattedDate(params.value),
            flex: 1
        },
        { field: 'updatedBy', headerName: definitionScreenText.updatedByColumn, flex: 1 }
    ];

    const filteredDefinitions = deviceDefinitions.filter((row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const deleteRow = (rowId: GridRowId) => {
        deleteDeviceDefinition(rowId.toString());
    };

    const editRow = (rowId: GridRowId) => {
        navigate(`../${rowId.toString()}`);
    };

    return (
        <VerticalStack>
            <Typography variant="h5" paddingBottom={8} paddingTop={2}>
                {definitionScreenText.description}
            </Typography>
            <Typography variant="h4" paddingBottom={2}>
                {definitionScreenText.directoryHeader}
            </Typography>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={3}
            >
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <Button variant="contained" onClick={() => navigate(`../${extensions.create}`)}>
                    {definitionScreenText.defineDeviceButton}
                </Button>
            </Stack>
            <SortableTable
                rows={filteredDefinitions}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: editRow, icon: faPen },
                    { label: sortableTable.deleteButton, onClick: deleteRow, icon: faCalendarMinus }
                ]}
            />
        </VerticalStack>
    );
};

export default DeviceDefinitionsList;
