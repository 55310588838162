import { Alert } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEnrollment, updateEnrollment } from '../../../api/enrollmentApi';
import { getIdScope } from '../../../api/idScopeApi';
import {
    enrollmentEnvironmentTagKey,
    enrollmentSerialNumberTagKey
} from '../../../constants/formatting';
import { getTagsJsonFromLocalTags } from '../../../helperFunctions';
import { useLoad, useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions, routes } from '../../../pages';
import { EnrollmentResponse, LocalEnrollment, LocalTag } from '../../../types/enrollmentTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import EnrollmentForm from './enrollmentForm';
import IdScopeDisplay from './idScopeDisplay';

const EditEnrollmentPage = () => {
    const { registrationId } = useParams();
    const { editEnrollmentScreen } = useLanguage();

    return registrationId ? (
        <EditEnrollmentPageLoad registrationId={registrationId} />
    ) : (
        <Alert severity="error">{`${editEnrollmentScreen.idError}${registrationId}`}</Alert>
    );
};

const EditEnrollmentPageLoad = ({ registrationId }: { registrationId: string }) => {
    const { editEnrollmentScreen } = useLanguage();
    const navigate = useNavigate();
    const enrollmentLoadFunction = useCallback(
        () => getEnrollment(registrationId),
        [registrationId]
    );

    const returnToEnrolledList = () => {
        navigate(`/${routes.gateways}/${extensions.enrolled}`);
    };

    const {
        data: enrollmentData,
        loading: enrollmentLoading,
        error: enrollmentError
    } = useLoad(enrollmentLoadFunction);
    const { data: idScopeData, loading: idScopeLoading, error: idScopeError } = useLoad(getIdScope);
    const {
        executeRequest,
        loading: updateLoading,
        error: updateError
    } = useRequest(updateEnrollment, returnToEnrolledList);

    const getDefaultValuesFromEnrollmentResponse = (enrollment: EnrollmentResponse) => {
        var localTags: LocalTag[] = [];
        if (enrollment.tagsJson) {
            let parsedJson = JSON.parse(enrollment.tagsJson);
            localTags = Object.keys(parsedJson)
                .filter(function (key: string) {
                    return (
                        key.toLowerCase() !== enrollmentSerialNumberTagKey.toLowerCase() &&
                        key.toLowerCase() !== enrollmentEnvironmentTagKey.toLowerCase()
                    );
                })
                .map((key: string) => ({ key, value: parsedJson[key] }));
        }

        return {
            registrationId: enrollment.registrationId,
            serialNumber: enrollment.serialNumber,
            tpmEndorsementKey: enrollment.tpmEndorsementKey,
            localTags: localTags
        };
    };

    const onSubmit = (localEnrollment: LocalEnrollment) => {
        executeRequest({
            registrationId: localEnrollment.registrationId,
            enrollment: {
                serialNumber: localEnrollment.serialNumber,
                tpmEndorsementKey: localEnrollment.tpmEndorsementKey,
                tagsJson: getTagsJsonFromLocalTags(localEnrollment.localTags)
            }
        });
    };

    const loading = enrollmentLoading || updateLoading || idScopeLoading;
    const error = enrollmentError || updateError || idScopeError;
    return (
        <Stack direction="row" spacing={7}>
            <DefaultPageLayout
                pageHeader={editEnrollmentScreen.header}
                loading={loading}
                error={error}
            >
                {enrollmentData && (
                    <EnrollmentForm
                        defaultValues={getDefaultValuesFromEnrollmentResponse(enrollmentData)}
                        onSubmit={onSubmit}
                        submitButtonText={editEnrollmentScreen.saveButton}
                        onCancel={returnToEnrolledList}
                        isEdit={true}
                    />
                )}
            </DefaultPageLayout>
            {idScopeData?.idScope && (
                <Stack paddingTop={7}>
                    <IdScopeDisplay idScope={idScopeData} />
                </Stack>
            )}
        </Stack>
    );
};

export default EditEnrollmentPage;
