import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Alert,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getDevices } from '../../../api/deviceApi';
import { useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { useLayouts } from '../../../hooks/useLayouts';
import { DeviceResponse } from '../../../types/deviceTypes';
import { SaveLayout } from '../../../types/layoutTypes';

const LayoutCopy = () => {
    const form = useFormContext<SaveLayout>();
    const { lifecycle, layoutForm } = useLanguage();
    const layoutsData = useLayouts();

    // layout Id must be initialized as an empty string to prevent an undefined error from
    // the select component
    const [layoutId, setLayoutId] = useState('');
    const [error, setError] = useState<string>();

    const onDevicesLoad = ({ layoutId }: { layoutId?: string }, devices?: DeviceResponse[]) => {
        const layout = layoutsData.find((l) => l.id === layoutId);

        if (!layout) {
            setError(`${layoutForm.layoutIdError}${layoutId}`);
            return;
        }

        form.setValue('name', layout.name);
        form.setValue('notes', layout.notes);
        form.setValue('assetInfo.pslId', layout.assetInfo.pslId);
        if (devices) {
            form.setValue('devices', devices);
        }
    };

    const devicesRequest = useRequest(getDevices, onDevicesLoad);

    const handleChange = (e: SelectChangeEvent<string>) => {
        setError(undefined);
        setLayoutId(e.target.value);
    };

    const copyLayout = () => {
        if (!layoutId) {
            // layoutId should be populated, the button to trigger this is disabled if it's not
            return;
        }

        const layout = layoutsData.find((l) => l.id === layoutId);
        if (!layout) {
            setError(`${layoutForm.layoutIdError}${layoutId}`);
            return;
        }

        devicesRequest.executeRequest({ layoutId: layout.id });
    };

    return (
        <Stack paddingBottom={8} spacing={2}>
            {error && <Alert severity="error">{error || devicesRequest.error}</Alert>}
            <Stack direction="row" spacing={2}>
                <Typography variant="h5">{layoutForm.copyLayoutFieldLabel}</Typography>
                <Tooltip title={layoutForm.copyLayoutTooltip}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </Tooltip>
            </Stack>
            <Stack direction="row" alignItems="flex-end" spacing={2}>
                <FormControl sx={{ maxWidth: '33%' }}>
                    <Select
                        value={layoutId}
                        onChange={handleChange}
                        disabled={devicesRequest.loading}
                    >
                        {layoutsData.map((l) => (
                            <MenuItem value={l.id} key={l.id}>
                                {l.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    disabled={devicesRequest.loading || layoutId === ''}
                    onClick={copyLayout}
                >
                    {devicesRequest.loading ? lifecycle.loadingLabel : layoutForm.copyLayout}
                </Button>
            </Stack>
        </Stack>
    );
};

export default LayoutCopy;
