import { Drawer, Stack } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { navBarMinHeight } from '../../../constants/styling';
import { useLanguage } from '../../../hooks/useLanguage';
import TachometerIcon from '../../../icons/tachometerIcon';
import { extensions } from '../../../pages';
import { UserRole } from '../../../types/authorizationTypes';
import { SidebarButton } from '../../navigation/sidebarButton';

const GatewaysPage = () => {
    const drawerWidth = 250;
    const navigate = useNavigate();
    const { gatewayNavigationSidebar } = useLanguage();

    return (
        <Stack direction="row" height="100%">
            <Drawer
                hideBackdrop
                variant="permanent"
                sx={{
                    width: drawerWidth
                }}
            >
                <Stack
                    sx={{
                        paddingTop: navBarMinHeight // this is the height of the nav bar tab
                    }}
                >
                    <SidebarButton
                        label={gatewayNavigationSidebar.enrolledGateways}
                        onClick={() => navigate(extensions.enrolled)}
                        interaction={{ visible: UserRole.ExternalUser }}
                        Icon={TachometerIcon}
                    />
                    <SidebarButton
                        label={gatewayNavigationSidebar.availableGateways}
                        onClick={() => navigate(extensions.available)}
                        interaction={{ visible: UserRole.InternalViewer }}
                        Icon={TachometerIcon}
                    />
                    <SidebarButton
                        label={gatewayNavigationSidebar.commissionedGateways}
                        onClick={() => navigate(extensions.commissioned)}
                        interaction={{ visible: UserRole.InternalViewer }}
                        Icon={TachometerIcon}
                    />
                </Stack>
            </Drawer>

            <Stack component="main" flexGrow={1}>
                <Outlet />
            </Stack>
        </Stack>
    );
};

export default GatewaysPage;
