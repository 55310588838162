import { Radio, RadioProps } from '@mui/material';
import { styled } from '@mui/material';
import { themeColors } from '../../theme';

export const RadioButtonChecked = styled('span')({
    width: '13px',
    height: '13px',
    background: themeColors.colorPalettePrimary09,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: themeColors.colorPalettePrimary09,
    borderRadius: '6.5px'
});

export const RadioButtonUnchecked = styled(RadioButtonChecked)({
    background: 'transparent'
});

export const RadioControl = (props: RadioProps) => (
    <Radio icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} {...props} />
);
