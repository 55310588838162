import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { LocalDeviceDefinition } from '../../../../types/deviceDefinitionTypes';
import { FormElement } from '../../../common/formElement';
import VerticalStack from '../../../common/verticalStack';
import SignalEditor from './signalEditor';
import TopicEditor from './topicEditor';

const MqttConfigurationEditor = () => {
    const form = useFormContext<LocalDeviceDefinition>();
    return (
        <VerticalStack marginTop={10}>
            <FormProvider {...form}>
                <VerticalStack spacing={10}>
                    <FormElement<LocalDeviceDefinition> fieldName="mqttSignals" label="">
                        <Controller
                            control={form.control}
                            name="mqttSignals"
                            render={({ field }) => (
                                <SignalEditor
                                    signals={field.value}
                                    onSignalsChange={field.onChange}
                                />
                            )}
                        />
                    </FormElement>
                    <FormElement<LocalDeviceDefinition> fieldName="mqttTopics" label="">
                        <Controller
                            control={form.control}
                            name="mqttTopics"
                            render={({ field }) => (
                                <TopicEditor topics={field.value} onTopicsChange={field.onChange} />
                            )}
                        />
                    </FormElement>
                </VerticalStack>
            </FormProvider>
        </VerticalStack>
    );
};

export default MqttConfigurationEditor;
