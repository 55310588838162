import { faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getIotEdgeDevices } from '../../../api/iotEdgeApi';
import { pageSize } from '../../../constants/styling';
import { useLoad } from '../../../hooks/requestHooks';
import { useColorMode } from '../../../hooks/useColorMode';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions, queryStringKeys } from '../../../pages';
import { darkThemeColors, lightThemeColors } from '../../../theme';
import { UserRole } from '../../../types/authorizationTypes';
import SearchBar from '../../common/searchBar';
import SortableTable from '../../common/sortableTable';
import VerticalStack from '../../common/verticalStack';

const AvailableGatewaysList = () => {
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { isLightMode } = useColorMode();
    const { availableGatewayScreen } = useLanguage();
    const navigate = useNavigate();

    const loadIotEdgeDevices = useCallback(
        () => getIotEdgeDevices({ pageNumber: page, pageSize, isCommissioned: false }),
        [page]
    );
    const { loading, error, data } = useLoad(loadIotEdgeDevices);

    const columns: GridColDef[] = [
        {
            field: 'serialNumber',
            headerName: availableGatewayScreen.serialNumberColumn,
            flex: 1
        },
        { field: 'azureDeviceId', headerName: availableGatewayScreen.azureDeviceIdColumn, flex: 1 },
        {
            field: 'isConnected',
            headerName: availableGatewayScreen.connectedColumn,
            flex: 1,
            renderCell: (params: GridRenderCellParams<boolean>) =>
                params.value && (
                    <Box paddingLeft={4}>
                        <FontAwesomeIcon
                            icon={faCircle}
                            color={isLightMode ? lightThemeColors.success : darkThemeColors.success}
                        />
                    </Box>
                )
        }
    ];

    const navigateToCommission = (azureDeviceId: string) => {
        const selectedIotEdgeDevice = data?.find((g) => g.azureDeviceId === azureDeviceId);
        if (!selectedIotEdgeDevice) {
            return;
        }

        navigate({
            pathname: `../${extensions.create}`,
            search: `?${createSearchParams({
                [queryStringKeys.azureDeviceId]: selectedIotEdgeDevice.azureDeviceId,
                [queryStringKeys.serialNumber]: selectedIotEdgeDevice.serialNumber
            })}`
        });
    };

    const filteredGateways = data?.filter((gateway) =>
        gateway.serialNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <VerticalStack spacing={3} paddingTop={8}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={filteredGateways ?? []}
                columns={columns}
                rowActions={[
                    {
                        label: 'Commission',
                        onClick: (rowId) =>
                            typeof rowId === 'string' && navigateToCommission(rowId),
                        icon: faPlus,
                        enable: UserRole.InternalUser
                    }
                ]}
                getRowId={(row) => row.azureDeviceId}
                pagedData={{
                    page: page,
                    setPage: setPage,
                    pageSize: pageSize,
                    rowNumber: page * pageSize + 2 * pageSize,
                    isLoading: loading
                }}
            />
        </VerticalStack>
    );
};

export default AvailableGatewaysList;
