import { baseApiUrl } from '../environmentVariables';
import { useLanguage } from '../hooks/useLanguage';
import { ApiResult, HttpMethod } from '../types/apiTypes';
import {
    DeviceConfiguration,
    DeviceDefinitionCreateRequest,
    DeviceDefinitionResponse,
    MoxaModbusConfiguration,
    MqttConfiguration,
    OpcUAConfiguration,
    Protocol
} from '../types/deviceDefinitionTypes';
import { GatewayResponse } from '../types/gatewayTypes';
import { getDevices } from './deviceApi';
import { getGateways } from './gatewayApi';
import { httpRequest } from './sharedApi';

const deviceDefinitionsUrl = `${baseApiUrl}/deviceDefinitions`;
const configurationPath = 'configuration';

export const getDeviceDefinitions = () =>
    httpRequest<undefined, DeviceDefinitionResponse[]>({
        url: deviceDefinitionsUrl
    });

export const createDeviceDefinition = (deviceDefinition: DeviceDefinitionCreateRequest) =>
    httpRequest<DeviceDefinitionCreateRequest, DeviceDefinitionResponse>({
        method: HttpMethod.Post,
        url: deviceDefinitionsUrl,
        body: deviceDefinition
    });

export const updateDeviceDefinition = ({
    deviceDefinition,
    deviceDefinitionId
}: {
    deviceDefinition: DeviceDefinitionCreateRequest;
    deviceDefinitionId: string;
}) =>
    httpRequest<DeviceDefinitionCreateRequest, DeviceDefinitionResponse>({
        method: HttpMethod.Put,
        url: `${deviceDefinitionsUrl}/${deviceDefinitionId}`,
        body: deviceDefinition
    });

export const deleteDeviceDefinition = (deviceDefinitionId: string) =>
    httpRequest<undefined, undefined>({
        method: HttpMethod.Delete,
        url: `${deviceDefinitionsUrl}/${deviceDefinitionId}`
    });

export const getDeviceConfiguration = ({
    deviceDefinitionId,
    protocol
}: {
    deviceDefinitionId: string;
    protocol: Protocol;
}): ((authToken: string) => Promise<ApiResult<DeviceConfiguration>>) => {
    const url = `${deviceDefinitionsUrl}/${deviceDefinitionId}/${configurationPath}`;
    switch (protocol) {
        case Protocol.MoxaModbus:
            return async (authToken: string) => {
                const httpResult = await httpRequest<undefined, MoxaModbusConfiguration>({
                    url
                })(authToken);

                return {
                    ...httpResult,
                    data: httpResult.data
                        ? {
                              protocol: Protocol.MoxaModbus,
                              moxaModbusConfiguration: httpResult.data
                          }
                        : httpResult.data
                };
            };
        case Protocol.Mqtt:
            return async (authToken: string) => {
                const httpResult = await httpRequest<undefined, MqttConfiguration>({
                    url
                })(authToken);

                return {
                    ...httpResult,
                    data: httpResult.data
                        ? {
                              protocol: Protocol.Mqtt,
                              mqttConfiguration: httpResult.data
                          }
                        : httpResult.data
                };
            };
        case Protocol.OpcUA:
            return async (authToken: string) => {
                const httpResult = await httpRequest<undefined, OpcUAConfiguration>({
                    url
                })(authToken);

                return {
                    ...httpResult,
                    data: httpResult.data
                        ? {
                              protocol: Protocol.OpcUA,
                              opcUAConfiguration: httpResult.data
                          }
                        : httpResult.data
                };
            };
    }
};

export const getAffectedGatewaysForDeviceDefinition =
    (deviceDefinitionId: string) => async (authToken: string) => {
        const devicesResult = await getDevices({ deviceDefinitionIds: [deviceDefinitionId] })(
            authToken
        );

        if (devicesResult.isFailure || !devicesResult.data || devicesResult.data.length === 0) {
            return {
                ...devicesResult,
                data: undefined
            } as ApiResult<GatewayResponse[]>;
        }

        const layoutIds = devicesResult.data.map((d) => d.layoutId);

        const gatewaysResult = await getGateways({ layoutIds })(authToken);

        if (gatewaysResult.isFailure || !gatewaysResult.data) {
            return gatewaysResult;
        }

        return gatewaysResult;
    };

export const deleteDeviceDefinitionIfNotUsedByGateways =
    (deviceDefinitionId: string) => async (authToken: string) => {
        const { deviceDefinitionScreen, punctuation } = useLanguage();

        const gatewaysResult = await getAffectedGatewaysForDeviceDefinition(deviceDefinitionId)(
            authToken
        );
        if (gatewaysResult.data && gatewaysResult.data.length > 0) {
            return {
                isSuccess: false,
                isFailure: true,
                status: 409,
                data: undefined,
                errorCode:
                    deviceDefinitionScreen.cannotDeleteDeviceDefinitionError +
                    gatewaysResult.data.map((g) => g.name).join(punctuation.commaDelimiter)
            };
        }

        return await deleteDeviceDefinition(deviceDefinitionId)(authToken);
    };
