import { SignalUnitType } from '../types/signalUnitTypes';

export const SignalUnitsMapping = {
    [SignalUnitType.Angle]: ['°', 'rad', 'deg'],
    [SignalUnitType.AngleGradient]: ['°/(100*ft)', '°/(100*m)', 'deg/(100*ft)', 'deg/(100*m)'],
    [SignalUnitType.Area]: ['acre', 'hare', 'ft²', 'ha', 'm²', 'yd²', 'in²', 'cm²', 'mm²'],
    [SignalUnitType.ConcentrationMassMass]: [
        'lb/sack',
        'kg/sack',
        'lb/sk94',
        'kg/sk94',
        'kg/(100*kg)',
        'kg/tonne',
        'wt %',
        'ppm'
    ],
    [SignalUnitType.ConcentrationMassVolume]: [
        'lb/Mgal',
        'kg/m³',
        'g/cm³',
        'kg/L',
        'lb/gal',
        'lb/ft³',
        'lb/sk',
        'SG',
        'g/L'
    ],
    [SignalUnitType.ConcentrationVolumeVolume]: [
        'gal/Mgal',
        'L/m³',
        'gal/sk',
        'ft³/sk',
        'gal/(100*sk)',
        'gal/(10*bbl)',
        'L/sk',
        'vol %'
    ],
    [SignalUnitType.Currency]: [
        'US Dollar',
        'British Pound Sterling',
        'Canada Dollar',
        'France Franc',
        'Australia Dollar',
        'European Currency Unit',
        'Germany Deutsche Mark',
        'Hong Kong Dollar',
        'Japan Yen',
        'Sweden Krona',
        'Switzerland Franc',
        'Italy Lira',
        'Netherlands Guilder',
        'Russia Ruble',
        'Spain Peseta'
    ],
    [SignalUnitType.Current]: ['Amps', 'mA'],
    [SignalUnitType.DensityMassVolume]: [
        'lb/Mgal',
        'kg/m³',
        'g/cm³',
        'kg/L',
        'lb/gal',
        'lb/ft³',
        'lb/sk',
        'SG',
        'g/L'
    ],
    [SignalUnitType.Diameter]: ['in', 'mm', 'cm', 'dm', 'ft', 'm'],
    [SignalUnitType.Diffusivity]: [
        'ft²/s',
        'm²/s',
        'cm²/s',
        'mm²/s',
        'ft²/min',
        'm²/min',
        'cm²/min',
        'mm²/min',
        'ft²/hr',
        'm²/hr',
        'cm²/hr',
        'mm²/hr'
    ],
    [SignalUnitType.ElectricPower]: ['volt'],
    [SignalUnitType.ElectricPowerCalibration]: ['volt/mA'],
    [SignalUnitType.EngineSpeed]: ['rpm', 'rad/s'],
    [SignalUnitType.FlowIndex]: ['bbl/(psi*day)', 'm³/(kPa*day)', 'm³/(Pa*sec)'],
    [SignalUnitType.FlowmeterCalibration]: [
        'pul/gal',
        'pul/L',
        'pul/bbl',
        'pul/acf',
        'pul/acm',
        'pul/ft³',
        'pul/in³',
        'pul/m³',
        'pul/Mgal',
        'pul/scf',
        'pul/scm',
        'pul/yd³',
        'pul/sk',
        'pul/cm³',
        'pul/mL',
        'pul/Mscf'
    ],
    [SignalUnitType.FluidLossCoefficient]: ['ft/min^0.5', 'm/min^0.5', 'm/s^0.5'],
    [SignalUnitType.Force]: ['lbf', 'kgf', 'newton', 'tonf'],
    [SignalUnitType.FormationVolumeFactor]: ['RBL/STB', 'm³/scm', 'ft³/scf'],
    [SignalUnitType.FractureConductivity]: ['md*ft', 'md*m', 'µm²*m'],
    [SignalUnitType.FractureToughness]: ['psi*in^0.5', 'MPa*m^0.5', 'ksi*in^0.5'],
    [SignalUnitType.Frequency]: ['Hz'],
    [SignalUnitType.GForce]: ['g'],
    [SignalUnitType.GasProduction]: ['scf/day', 'scm/day', 'Mscf/day', 'MMscf/day'],
    [SignalUnitType.InverseLength]: ['1/ft', '1/m'],
    [SignalUnitType.InversePressure]: ['1/psi', '1/kPa', '1/Pa', '1/MPa', '1/bar'],
    [SignalUnitType.InverseTemperature]: ['1/°F', '1/°C', '1/K', '1/°R'],
    [SignalUnitType.InverseTime]: ['1/s', '1/min', '1/hr', '1/ms', '1/d', '1/wk', '1/mon', '1/yr'],
    [SignalUnitType.InverseVolumeGradient]: ['ft/gal', 'm/m³', 'ft/bbl', 'm/L', 'ft/ft³'],
    [SignalUnitType.KPrime]: ["lb*s^n'/ft²", "Pa*s^n'"],
    [SignalUnitType.Length]: ['ft', 'm', 'km', 'mile', 'yd', 'furlong', 'in', 'cm'],
    [SignalUnitType.Mass]: ['lb', 'kg', 'sack', 'sk94', 'ton', 'tonne', 'oz', 'g', '100*lb'],
    [SignalUnitType.MassGradient]: ['lb/ft', 'kg/m'],
    [SignalUnitType.OilGravity]: ['°API'],
    [SignalUnitType.OilProduction]: ['bpd', 'm³/day', 'bph', 'm³/hr', 'm³/min', 'm³/s'],
    [SignalUnitType.PerforationDensity]: ['spf', 'shots/m'],
    [SignalUnitType.Permeability]: ['md', 'darcy', 'µm²/1000', 'µm²', 'm²'],
    [SignalUnitType.Ph]: ['pH'],
    [SignalUnitType.PhCalibration]: ['pH/mA'],
    [SignalUnitType.Power]: ['hp', 'kW', 'hhp', 'watt', 'PS'],
    [SignalUnitType.Pressure]: [
        'psi',
        'kPa',
        'atm',
        'bar',
        'kgf/cm²',
        'kgf/m²',
        'MPa',
        'Pa',
        'psf',
        'lbf/(100*ft²)',
        'GPa'
    ],
    [SignalUnitType.PressureAbsolute]: [
        'psia',
        'kPa (a)',
        'atma',
        'bara',
        'kgf/cm² (a)',
        'kgf/m² (a)',
        'MPa (a)',
        'Pa (a)',
        'psfa',
        'GPa (a)'
    ],
    [SignalUnitType.PressureCalibration]: [
        'psi/mA',
        'kPa/mA',
        'atm/mA',
        'bar/mA',
        'kgf/cm²/mA',
        'kgf/m²/mA',
        'MPa/mA',
        'Pa/mA',
        'psf/mA',
        'lbf/(100*ft²)/mA',
        'GPa/mA'
    ],
    [SignalUnitType.PressureGradient]: [
        'psi/ft',
        'kPa/m',
        'psi/(100*ft)',
        'bar/m',
        'bar/(10*m)',
        'Pa/m'
    ],
    [SignalUnitType.RateMassTime]: ['lb/s', 'kg/s', 'lb/min', 'kg/min'],
    [SignalUnitType.RateVolumeTime]: [
        'bpm',
        'L/min',
        'gpm',
        'acfm',
        'acmm',
        'bph',
        'bpd',
        'ft³/min',
        'ft³/s',
        'in³/min',
        'm³/min',
        'm³/s',
        'Mgal/min',
        'scfmN2',
        'scfmCO2',
        'scmmN2',
        'scmmCO2',
        'yd³/min',
        'm³/hr',
        'm³/day',
        'L/s',
        'L/hr',
        'scf/dayN2',
        'scf/dayCO2',
        'scm/dayN2',
        'scm/dayCO2',
        'Mscf/dayN2',
        'Mscf/dayCO2',
        'kscm/dayN2',
        'kscm/dayCO2',
        'sbpmN2',
        'sbpmCO2'
    ],
    [SignalUnitType.RateIncrement]: [
        'bpm/s',
        'm³/min/s',
        'L/min/s',
        'gpm/s',
        'acfm/s',
        'acmm/s',
        'bph/s',
        'bpd/s',
        'ft³/min/s',
        'ft³/s/s',
        'in³/min/s',
        'm³/s/s',
        'Mgal/min/s',
        'scfm/s',
        'scmm/s',
        'yd³/min/s',
        'm³/hr/s',
        'm³/day/s',
        'L/s/s',
        'L/hr/s',
        'scf/day/s',
        'Mscf/day/s'
    ],
    [SignalUnitType.Ratio]: ['%'],
    [SignalUnitType.SpecificHeat]: ['Btu/(lbm*F)', 'J/(kg*K)'],
    [SignalUnitType.SpurtLossCoefficient]: ['gal/ft²', 'm³/m²', 'ft³/ft²'],
    [SignalUnitType.Temperature]: ['°F', '°C', 'K', '°R'],
    [SignalUnitType.TemperatureCalibration]: ['°F/mA', '°C/mA', 'K/mA', '°R/mA'],
    [SignalUnitType.ThermalConductivity]: ['Btu/(hr*ft*°F)', 'W/(m*K)'],
    [SignalUnitType.ThermalGradient]: ['°F/(100*ft)', 'mK/m', '°C/(100*m)', '°F/ft'],
    [SignalUnitType.Time]: [
        'yyyymmddhhmmss',
        'dd-mmm-yyyy hh:mm:ss',
        'dd-mmm-yyyy hh:mm:ss.ttt',
        'yyyy-mm-dd hh:mm:ss',
        'yyyy-mm-dd hh:mm:ss.ttt',
        'yymmddhhnnssttt',
        'yy-mm-dd-hh:mm:ss',
        'dd:mm:yy:hh:mm:ss',
        'seconds1970',
        'secmsec1970',
        'seconds1900',
        'fractjulian1900',
        'fractjulian1900msec',
        'fractjulianaccess',
        'hhmmss',
        'hh:mm:ss',
        'hh mm ss',
        'hoursmidnight',
        'hoursdelta',
        'minutesmidnight',
        'minutesdelta',
        'secondsmidnight',
        'secondsdelta',
        'yyyymmdd',
        'dd-mmm-yyyy',
        'mm/dd/yy'
    ],
    [SignalUnitType.TimeRelative]: ['hr', 'min', 's', 'ms', 'd', 'wk', 'mon', 'yr', 'fortnight'],
    [SignalUnitType.Torque]: ['ft*lbf', 'N*m', 'in*lbf'],
    [SignalUnitType.Unitless]: [''],
    [SignalUnitType.Velocity]: [
        'ft/s',
        'm/s',
        'ft/min',
        'mph',
        'km/hr',
        'furlongs/fortnight',
        'm/min',
        'ft/hr'
    ],
    [SignalUnitType.Viscosity]: [
        'cp',
        'mPa*s',
        'Pa*s',
        'lb/ft*hr',
        'lb/ft*s',
        'lbf*s/ft²',
        'lbf*s/in²',
        'poise'
    ],
    [SignalUnitType.Voltage]: ['V'],
    [SignalUnitType.Volume]: [
        'gal',
        'L',
        'bbl',
        'acf',
        'acm',
        'ft³',
        'in³',
        'm³',
        'Mgal',
        'scfN2',
        'scfCO2',
        'scmN2',
        'scmCO2',
        'yd³',
        'sk',
        'cm³',
        'mL',
        'MscfN2',
        'MscfCO2',
        'kscmN2',
        'kscmCO2',
        'Mscf',
        'sbblN2',
        'sbblCO2'
    ],
    [SignalUnitType.VolumeGradient]: ['gal/ft', 'm³/m', 'bbl/ft', 'L/m', 'ft³/ft', 'gal/(1000*ft)'],
    [SignalUnitType.VolumePerCoupling]: ['gal/cplg', 'm³/cplg', 'ft³/cplg', 'L/cplg'],
    [SignalUnitType.VolumeRatio]: ['scf/bbl', 'scm/m³', 'scf/gal'],
    [SignalUnitType.VolumeRatioIncrement]: ['scf/bbl/bbl', 'scm/m³/m³', 'scf/gal/gal'],
    [SignalUnitType.YieldVolumeMass]: [
        'gal/lb',
        'L/kg',
        'ft³/lb',
        'm³/kg',
        'gal/sack',
        'gal/sk94',
        'ft³/sack',
        'ft³/sk94',
        'm³/tonne',
        'L/sack',
        'L/sk94',
        'L/(100*kg)',
        'L/tonne'
    ],
    [SignalUnitType.YieldPoint]: [
        'lbf/(100*ft²)',
        'Pa',
        'psf',
        'kgf/m²',
        'psi',
        'kPa',
        'atm',
        'bar',
        'kgf/cm²',
        'MPa'
    ]
};

export const signalUnitTypes = Object.keys(SignalUnitsMapping) as Array<SignalUnitType>;

export const signalUnits = [
    '',
    ...Array.from(new Set(signalUnitTypes.map((key) => SignalUnitsMapping[key]).flat()))
];
