import { faPen, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Dialog, Stack } from '@mui/material';
import { GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { defaultMoxaModbusRegister } from '../../../../defaultValues';
import { useSignalDataTypeEnum } from '../../../../hooks/enumHooks';
import { useLanguage } from '../../../../hooks/useLanguage';
import { LocalMoxaModbusRegister } from '../../../../types/deviceDefinitionTypes';
import SortableTable from '../../../common/sortableTable';
import VerticalStack from '../../../common/verticalStack';
import RegisterForm from './registerForm';
import MoxaModbusRegisterImportExportButtons from './moxaModbusRegisterImportExportButtons';

const MoxaModbusRegisterEditor = ({
    registers,
    onRegistersChange
}: {
    registers: LocalMoxaModbusRegister[];
    onRegistersChange: (registers: LocalMoxaModbusRegister[]) => void;
}) => {
    const {
        moxaRegisterValidationErrors,
        booleanDisplay,
        moxaCommunicationDefinition,
        sortableTable
    } = useLanguage();

    const { dataTypeValueFormatter } = useSignalDataTypeEnum();
    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [registerToEdit, setRegisterToEdit] = useState<LocalMoxaModbusRegister>();
    const [registerIdsToDelete, setRegisterIdsToDelete] = useState<GridRowId[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: moxaCommunicationDefinition.nameColumn,
            flex: 1
        },
        { field: 'address', headerName: moxaCommunicationDefinition.addressColumn, flex: 1 },
        {
            field: 'dataType',
            headerName: moxaCommunicationDefinition.dataTypeColumn,
            valueFormatter: (params) => dataTypeValueFormatter(params.value),
            flex: 1
        },
        {
            field: 'readOnly',
            headerName: moxaCommunicationDefinition.readonlyColumn,
            valueFormatter: (params) => (params.value ? booleanDisplay.true : booleanDisplay.false),
            flex: 1
        }
    ];

    const updateRow = (registerData: LocalMoxaModbusRegister) => {
        const otherRegisters =
            registerToEdit && isEdit
                ? registers.filter((register) => register.name !== registerToEdit.name)
                : registers;

        const registerIds = otherRegisters.map((register) => register.name);
        if (registerIds.includes(registerData.name)) {
            setError(moxaRegisterValidationErrors.namesNotUnique);
            return;
        }

        onRegistersChange([...otherRegisters, { ...registerData }]);
        setError('');
    };

    const startEdit = (rowId: GridRowId) => {
        setIsEdit(true);
        setRegisterToEdit(registers.filter((register) => rowId === register.name)[0]);
    };

    const startAdd = () => {
        setIsEdit(false);
        setRegisterToEdit({ ...defaultMoxaModbusRegister, configurationElementId: uuidv4() });
    };

    const deleteRow = (rowId: GridRowId) => {
        onRegistersChange(registers.filter((register) => register.name !== rowId.toString()));
    };

    const onRowSelect = (selectionModel: GridSelectionModel) => {
        setRegisterIdsToDelete(selectionModel);
    };

    const deleteMultipleRows = () => {
        onRegistersChange(registers.filter((r) => !registerIdsToDelete.includes(r.name)));
    };

    return (
        <VerticalStack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                paddingBottom={3}
                paddingTop={2}
            >
                <MoxaModbusRegisterImportExportButtons
                    registers={registers}
                    onRegistersChange={onRegistersChange}
                    setError={setError}
                />
                <Button variant="contained" onClick={startAdd}>
                    {moxaCommunicationDefinition.addRegisterButton}
                </Button>
                <Button variant="contained" onClick={deleteMultipleRows}>
                    {moxaCommunicationDefinition.deleteSelectedRegistersButton}
                </Button>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={registers}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    {
                        label: sortableTable.deleteButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                getRowId={(row) => row.name}
                onRowSelect={onRowSelect}
            />
            <Dialog open={registerToEdit !== undefined}>
                {registerToEdit && (
                    <RegisterForm
                        isEdit={isEdit}
                        updateRegister={updateRow}
                        handleClose={() => setRegisterToEdit(undefined)}
                        defaultValues={registerToEdit}
                        registers={registers}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default MoxaModbusRegisterEditor;
