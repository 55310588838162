import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { PslResponse } from '../../../types/pslTypes';
import { InternalUser } from '../../../types/userTypes';
import UnsavedChangesIndicator from '../../common/unsavedChangesIndicator';
import InternalUserFormInputs from './internalUserFormInputs';
import UserReadOnlyInputs from './userReadOnlyFormInputs';

const InternalUserForm = ({
    defaultValues,
    psls,
    submitLoading,
    onSubmit,
    isEdit
}: {
    defaultValues: InternalUser;
    psls: PslResponse[];
    submitLoading: boolean;
    onSubmit: (user: InternalUser) => void;
    isEdit?: boolean;
}) => {
    const { internalUserForm, lifecycle } = useLanguage();
    const navigate = useNavigate();
    const form = useForm<InternalUser>({ defaultValues });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack paddingTop={isEdit ? 8 : undefined}>
                    <UserReadOnlyInputs name="name" email="email" />
                    <InternalUserFormInputs psls={psls} />
                    <Stack spacing={2} paddingTop={12}>
                        {isEdit && form.formState.isDirty && <UnsavedChangesIndicator />}
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate(`../${extensions.internal}`)}
                            >
                                {internalUserForm.cancelButton}
                            </Button>
                            <Button variant="contained" disabled={submitLoading} type="submit">
                                {submitLoading
                                    ? lifecycle.loadingLabel
                                    : internalUserForm.saveButton}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default InternalUserForm;
