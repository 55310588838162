import { Stack, OutlinedInput, Select, MenuItem, Alert, SelectChangeEvent } from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { protocols } from '../../../constants/enums';
import {
    ipAddressFormat,
    ipAddressWithPortFormat,
    mediumString,
    moxaNameFormat
} from '../../../constants/validation';
import { checkFieldUniqueness } from '../../../helperFunctions';
import { useProtocolEnum } from '../../../hooks/enumHooks';
import { useDeviceDefinitions } from '../../../hooks/useDeviceDefinitions';
import { useLanguage } from '../../../hooks/useLanguage';
import { Protocol } from '../../../types/deviceDefinitionTypes';
import { LocalDevice } from '../../../types/deviceTypes';
import AssetInfoEditor from '../../common/assetInfoEditor';
import { FormElement } from '../../common/formElement';
import { formFieldId, formLabelId } from '../../common/formInputRenderFunctions';

const DeviceFormInputs = ({
    devices,
    defaultProtocol
}: {
    devices: LocalDevice[];
    defaultProtocol?: Protocol;
}) => {
    const { deviceForm, form } = useLanguage();
    const formContext = useFormContext<LocalDevice>();
    const deviceDefinitions = useDeviceDefinitions();
    const { protocolDisplay } = useProtocolEnum();

    const [selectedProtocol, setSelectedProtocol] = useState(
        defaultProtocol ?? Protocol.MoxaModbus
    );
    const [error, setError] = useState<string>();

    // address label updates to match the associated protocol
    const addressFieldLabel = useMemo(() => {
        switch (selectedProtocol) {
            case Protocol.MoxaModbus:
                return deviceForm.ipAddressFieldLabel;
            case Protocol.Mqtt:
                return deviceForm.ipAddressFieldLabel;
            case Protocol.OpcUA:
                return deviceForm.ipAddressAndPortFieldLabel;
            default:
                return deviceForm.addressFieldLabel;
        }
    }, [
        selectedProtocol,
        deviceForm.ipAddressFieldLabel,
        deviceForm.ipAddressAndPortFieldLabel,
        deviceForm.addressFieldLabel
    ]);

    const validateAddress = (address: string) => {
        const addressUnique = checkFieldUniqueness(
            devices.filter((d) => !d.isDeleted),
            (d) => d.address,
            address,
            formContext.formState.defaultValues?.address
        );

        if (!addressUnique) {
            return deviceForm.duplicateAddressError;
        }

        switch (selectedProtocol) {
            case Protocol.MoxaModbus:
                return (
                    ipAddressWithPortFormat.test(address) ||
                    ipAddressFormat.test(address) ||
                    deviceForm.invalidAddressMoxaModbusError
                );
            case Protocol.Mqtt:
                return ipAddressFormat.test(address) || deviceForm.invalidAddressMqttError;
            case Protocol.OpcUA:
                return (
                    ipAddressWithPortFormat.test(address) ||
                    ipAddressFormat.test(address) ||
                    deviceForm.invalidAddressOpcUAError
                );
            default:
                return true;
        }
    };

    const validateName = (name: string) => {
        const nameUnique = checkFieldUniqueness(
            devices.filter((d) => !d.isDeleted),
            (d) => d.name,
            name,
            formContext.formState.defaultValues?.name
        );
        if (!nameUnique) {
            return deviceForm.duplicateNameError;
        }

        switch (selectedProtocol) {
            case Protocol.MoxaModbus:
                return moxaNameFormat.test(name) || deviceForm.nameInvalidCharacterError;
            case Protocol.Mqtt:
                break; // No special name formatting for MQTT
            case Protocol.OpcUA:
                break; // No special name formatting for OpcUA
            default:
                return true;
        }
    };

    const handleProtocolChange = (e: SelectChangeEvent<Protocol>) => {
        if (typeof e.target.value === 'string') {
            return;
        }

        formContext.setValue('deviceDefinitionId', '', { shouldDirty: true });
        setSelectedProtocol(e.target.value);
    };

    const definitionsToDisplay = useMemo(() => {
        const filteredDefinitions = deviceDefinitions
            .filter((dd) => dd.protocol === selectedProtocol)
            .filter((dd) => dd.assetInfo.pslId === formContext.watch('assetInfo.pslId'));
        if (filteredDefinitions.length > 0) {
            setError(undefined);
        } else {
            setError(deviceForm.noDeviceDefinitionsError);
        }
        return filteredDefinitions;
    }, [selectedProtocol, deviceDefinitions, deviceForm.noDeviceDefinitionsError, formContext]);

    return (
        <Stack spacing={3}>
            <FormElement<LocalDevice>
                fieldName="name"
                label={deviceForm.nameFieldLabel}
                tooltipTitle={deviceForm.nameTooltip}
            >
                <OutlinedInput
                    {...formContext.register('name', {
                        required: form.required,
                        maxLength: mediumString,
                        validate: (n) => validateName(n)
                    })}
                    id={formFieldId('name')}
                />
            </FormElement>
            {/* The value displayed by this FormElement (Protocol) is NOT controlled by the form - used as supplementary information */}
            <FormElement
                fieldName="protocol"
                label={deviceForm.protocolFieldLabel}
                tooltipTitle={deviceForm.protocolTooltip}
            >
                <Select
                    labelId={formLabelId('protocol')}
                    value={selectedProtocol}
                    onChange={handleProtocolChange}
                >
                    {protocols.map((p) => (
                        <MenuItem key={p} value={p}>
                            {protocolDisplay[p]}
                        </MenuItem>
                    ))}
                </Select>
            </FormElement>
            {error && <Alert severity="error">{error}</Alert>}
            <FormElement<LocalDevice>
                fieldName="deviceDefinitionId"
                label={deviceForm.definitionFieldLabel}
                tooltipTitle={deviceForm.definitionTooltip}
            >
                <Controller
                    control={formContext.control}
                    name="deviceDefinitionId"
                    rules={{
                        required: form.required
                    }}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={field.onChange}
                            labelId={formLabelId(field.name)}
                            disabled={error !== undefined}
                        >
                            {definitionsToDisplay.map((dd) => (
                                <MenuItem value={dd.id} key={dd.id}>
                                    {dd.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalDevice>
                fieldName="address"
                label={addressFieldLabel}
                tooltipTitle={deviceForm.addressTooltip}
            >
                <OutlinedInput
                    {...formContext.register('address', {
                        required: form.required,
                        validate: (a) => validateAddress(a)
                    })}
                    id={formFieldId('address')}
                />
            </FormElement>
            <FormElement<LocalDevice> fieldName="assetInfo" label="">
                <Controller
                    control={formContext.control}
                    name="assetInfo"
                    render={({ field }) => (
                        <AssetInfoEditor
                            assetInfo={field.value}
                            onAssetInfoChange={field.onChange}
                            disabled
                        />
                    )}
                />
            </FormElement>
        </Stack>
    );
};

export default DeviceFormInputs;
