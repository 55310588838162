import { data } from 'azure-maps-control';

// Enums
export enum ConnectionStatus {
    Unknown,
    Connected,
    Disconnected
}

export enum ModuleRuntimeStatus {
    Unknown,
    Backoff,
    Running,
    Unhealthy,
    Stopped,
    Failed
}

// API types

export interface Coordinate {
    gpsLat: number;
    gpsLong: number;
}

export interface GatewayStatusCondensedResponse {
    gatewayId: string;
    gatewayName: string;
    gatewayCommissionedDate: string;
    pslId: string;
    coordinate: Coordinate;
    devicesNotConnected: number;
    modulesNotRunning: number;
    createdDate?: string;
}

export interface GatewayStatusFullResponse {
    id: string;
    gatewayId: string;
    azureDeviceId: string;
    gatewayName: string;
    gatewayCommissionedDate: string;
    createdDate: string;
    coordinate: Coordinate;
    gpsMode?: string;
    hasConfiguration: boolean;
    deviceConnectionStatuses: DeviceConnectionStatus[];
    devicesNotConnected: number;
    moduleConnectionStatuses: ModuleConnectionStatus[];
    modulesNotRunning: number;
}

export interface DeviceConnectionStatus {
    deviceExternalId: string;
    name: string;
    status: ConnectionStatus;
}

export interface ModuleConnectionStatus {
    moduleId: string;
    moduleRuntimeStatus: ModuleRuntimeStatus;
}

// Local Types

export interface GatewayFilter {
    psls?: string[];
    gatewaysOffline?: boolean;
    gatewaysOnlineWithFieldDevicesDisconnected?: boolean;
    gatewaysOnlineWithModulesInErrorState?: boolean;
}

export interface PositionWithMetadata {
    name: string;
    position: data.Position;
    id: string;
}

export interface Leaf {
    coordinate: data.Position;
    id: string | number;
    name: string;
}

export interface ClusterWithLeaves {
    coordinate: data.Position;
    leaves: Leaf[];
}
