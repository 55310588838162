import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { defaultMoxaModbusRegister } from '../../../../defaultValues';
import { useLanguage } from '../../../../hooks/useLanguage';
import { useSignalCsvImport } from '../../../../hooks/useSignalCsvImport';
import { useStringConverters } from '../../../../hooks/useStringConverters';
import { convertSignalToRow, updateSignals } from '../../../../signalHelperFunctions';
import {
    LocalMoxaModbusRegister,
    DeviceDefinitionCreateRequest
} from '../../../../types/deviceDefinitionTypes';
import ExportToFileButton from '../../../common/exportToFileButton';
import ImportFromFileButton from '../../../common/importFromFileButton';
import { signalCsvHeaders } from '../mqttConfiguration/signalImportExportButtons';
import InvalidRowsModal from "../../../common/invalidRowsModal";
import {constructUnitList} from "../../../../helperFunctions";

const expectedCsvHeaders = signalCsvHeaders.concat(
    ',dataSize,address,quantity,offset,endianSwap,scaling,slope,interceptOffset,pointSourceMin,pointSourceMax,pointTargetMin,pointTargetMax'
);
const MoxaModbusRegisterImportExportButtons = ({
    registers,
    onRegistersChange,
    setError
}: {
    registers: LocalMoxaModbusRegister[];
    onRegistersChange: (registers: LocalMoxaModbusRegister[]) => void;
    setError: (error: string) => void;
}) => {
    const { moxaCommunicationDefinition, deviceDefinitionScreen } = useLanguage();
    const deviceDefinitionForm = useFormContext<DeviceDefinitionCreateRequest>();
    const { convertInt, convertFloat } = useStringConverters();
    const { convertRowToSignal } = useSignalCsvImport();
    const [invalidRows, setInvalidRows] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const parseCsvRegisters = (data: string[]) => {
        const invalidRowsTemp: string[] = [];
        const unitList = constructUnitList();
        
        // last row could be empty - don't parse if it is
        if (data[data.length - 1] === '') {
            data.pop();
        }

        // Remove empty rows and rows with critical missing data
        const filteredData = data.filter(row => {
            const trimmedRow = row.trim();
            const splitRow = trimmedRow.split(',');

            // Ensure the row has the correct number of columns
            if (splitRow.length !== 21) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with incorrect column counts
            }

            if (!unitList.includes(splitRow[8])) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with empty critical fields
            }

            return true; // Include rows that pass all checks
        });

        // Set invalid rows to state
        setInvalidRows(invalidRowsTemp);

        // Show modal if there are invalid rows
        if (invalidRowsTemp.length > 0) {
            setModalOpen(true);
        }

        // Convert each filtered row to a LocalMoxaModbusRegister object
        const parsedRegisters = filteredData.map(row => {
            const splitRow = row.split(',');

            const signal = convertRowToSignal(splitRow.slice(0, 9));
            return {
                ...signal,
                dataSize: splitRow[9] ? convertInt(splitRow[9]) : defaultMoxaModbusRegister.dataSize,
                address: convertInt(splitRow[10]),
                quantity: convertInt(splitRow[11]),
                offset: convertInt(splitRow[12]),
                swap: splitRow[13] ? convertInt(splitRow[13]) : defaultMoxaModbusRegister.swap,
                scaling: splitRow[14] ? convertInt(splitRow[14]) : defaultMoxaModbusRegister.scaling,
                slope: splitRow[15] ? convertFloat(splitRow[15]) : defaultMoxaModbusRegister.slope,
                interceptOffset: splitRow[16] ? convertFloat(splitRow[16]) : defaultMoxaModbusRegister.interceptOffset,
                pointSourceMin: splitRow[17] ? convertFloat(splitRow[17]) : defaultMoxaModbusRegister.pointSourceMin,
                pointSourceMax: splitRow[18] ? convertFloat(splitRow[18]) : defaultMoxaModbusRegister.pointSourceMax,
                pointTargetMin: splitRow[19] ? convertFloat(splitRow[19]) : defaultMoxaModbusRegister.pointTargetMin,
                pointTargetMax: splitRow[20] ? convertFloat(splitRow[20]) : defaultMoxaModbusRegister.pointTargetMax
            };
        });

        // Update registers if parsed correctly
        if (parsedRegisters.length > 0) {
            updateSignals(
                parsedRegisters as LocalMoxaModbusRegister[],
                moxaCommunicationDefinition.registerNamesNotUnique,
                onRegistersChange
            );
            setError(''); // Clear any previous errors
        }
    };

    const parseJsonRegisters = (content: string) => {
        try {
            const parsedData = JSON.parse(content);

            // Ensure the parsed data is an array
            if (!Array.isArray(parsedData)) {
                throw new Error('Parsed data is not an array');
            }

            const unitList = constructUnitList();
            const invalidRowsTemp: string[] = [];

            // Validate each register object
            const validRegisters: LocalMoxaModbusRegister[] = parsedData.filter((register, index) => {
                // Ensure the register has all the required fields and valid dataUnit
                const hasRequiredFields = register.hasOwnProperty('configurationElementId') &&
                    register.hasOwnProperty('name') &&
                    register.hasOwnProperty('dataType') &&
                    register.hasOwnProperty('address') &&
                    register.hasOwnProperty('quantity') &&
                    register.hasOwnProperty('isEvent') &&
                    register.hasOwnProperty('eventClass') &&
                    register.hasOwnProperty('readOnly') &&
                    register.hasOwnProperty('dataSize') &&
                    register.hasOwnProperty('dataUnit') &&
                    register.hasOwnProperty('offset') &&
                    register.hasOwnProperty('scaling') &&
                    register.hasOwnProperty('unitType') &&
                    register.hasOwnProperty('uomClass');

                const isValidDataUnit = unitList.includes(register.dataUnit);

                if (!hasRequiredFields || !isValidDataUnit) {
                    // Store the invalid register as a JSON string for displaying in the modal
                    invalidRowsTemp.push(JSON.stringify(register));
                    return false;
                }
                return true;
            });

            // Set invalid rows to state
            setInvalidRows(invalidRowsTemp);

            // Show modal if there are invalid rows
            if (invalidRowsTemp.length > 0) {
                setModalOpen(true);
            }

            // Update registers if parsed correctly
            if (validRegisters.length > 0) {
                updateSignals(validRegisters, moxaCommunicationDefinition.registerNamesNotUnique, onRegistersChange);
                setError(''); // Clear any previous errors
            } else if (invalidRowsTemp.length === 0) {
                // If no valid registers and no invalid rows, set a general error
                setError('No valid registers found in the JSON data');
            }
        } catch (error) {
            // Handle JSON parsing errors
            setError('Error parsing JSON data: ' + error);
        }
    };


    const translateRegistersToCsv = (registers: LocalMoxaModbusRegister[]) => {
        const csvRows = registers.map((r) =>
            [
                ...convertSignalToRow(r),
                r.dataSize,
                r.address,
                r.quantity,
                r.offset,
                r.swap,
                r.scaling,
                r.slope,
                r.interceptOffset,
                r.pointSourceMin,
                r.pointSourceMax,
                r.pointTargetMin,
                r.pointTargetMax
            ].join(',')
        );
        return [expectedCsvHeaders, ...csvRows];
    };

    return (
        <Stack direction="row" spacing={2}>
            <ImportFromFileButton
                setError={setError}
                handleJsonData={parseJsonRegisters}
                handleCsvData={parseCsvRegisters}
                expectedCsvHeaders={expectedCsvHeaders}
                confirmDialogContent={moxaCommunicationDefinition.importOverwriteWarning}
            />
            <ExportToFileButton
                data={registers}
                fileName={`${
                    deviceDefinitionForm.watch('name') !== ''
                        ? deviceDefinitionForm.watch('name')
                        : deviceDefinitionScreen.defaultFileExportName
                }${moxaCommunicationDefinition.exportRegistersFilenameSuffix}`}
                formatCsvData={translateRegistersToCsv}
            />
            <InvalidRowsModal open={modalOpen} onClose={() => setModalOpen(false)} rows={invalidRows} />
        </Stack>
    );
};

export default MoxaModbusRegisterImportExportButtons;
