import { useMemo } from 'react';
import { AssetType } from '../types/assetTypes';
import { UserRole } from '../types/authorizationTypes';
import { OpcUANodeIdType, Protocol, SignalDataType, Swap } from '../types/deviceDefinitionTypes';
import { EnrollmentStatus } from '../types/enrollmentTypes';
import { ConnectionStatus, ModuleRuntimeStatus } from '../types/gatewayStatusTypes';
import { Timezone } from '../types/gatewayTypes';
import { useLanguage } from './useLanguage';
import { useStringConverters } from './useStringConverters';

export function useProtocolEnum() {
    const {
        enumDisplay: { protocol: protocolText },
        protocolErrors
    } = useLanguage();
    const { convertInt } = useStringConverters();

    const convertProtocol = (protocol: Protocol) => {
        switch (protocol) {
            case Protocol.MoxaModbus:
                return protocolText.moxaModbus;
            case Protocol.Mqtt:
                return protocolText.mqtt;
            case Protocol.OpcUA:
                return protocolText.opcUA;
            default:
                return protocol;
        }
    };

    const validateProtocol = (protocol: Protocol) => {
        let protocolInt: number | undefined;
        try {
            protocolInt = convertInt(String(protocol));
        } catch (error) {
            return protocolErrors.intError;
        }

        if (!Object.values(Protocol).includes(protocolInt)) {
            return protocolErrors.valueError;
        }

        return;
    };

    const protocolDisplay = useMemo(
        () => ({
            [Protocol.MoxaModbus]: protocolText.moxaModbus,
            [Protocol.Mqtt]: protocolText.mqtt,
            [Protocol.OpcUA]: protocolText.opcUA
        }),
        [protocolText]
    );

    return { convertProtocol, validateProtocol, protocolDisplay };
}

export function useSignalDataTypeEnum() {
    const {
        enumDisplay: { dataType }
    } = useLanguage();

    const dataTypeValueFormatter = (registerDataType: SignalDataType) => {
        switch (registerDataType) {
            case SignalDataType.None:
                return dataType.none;
            case SignalDataType.Boolean:
                return dataType.boolean;
            case SignalDataType.Integer:
                return dataType.integer;
            case SignalDataType.Enum:
                return dataType.enum;
            case SignalDataType.Double:
                return dataType.double;
            case SignalDataType.String:
                return dataType.string;
            case SignalDataType.UInt:
                return dataType.uint;
            case SignalDataType.UsInt:
                return dataType.usint;
        }
    };

    const dataTypeDisplay = useMemo(
        () => ({
            [SignalDataType.None]: dataType.none,
            [SignalDataType.Boolean]: dataType.boolean,
            [SignalDataType.Integer]: dataType.integer,
            [SignalDataType.Enum]: dataType.enum,
            [SignalDataType.Double]: dataType.double,
            [SignalDataType.String]: dataType.string,
            [SignalDataType.UInt]: dataType.uint,
            [SignalDataType.UsInt]: dataType.usint
        }),
        [dataType]
    );

    return { dataTypeValueFormatter, dataTypeDisplay };
}

export function useSwapEnum() {
    const {
        enumDisplay: { swap }
    } = useLanguage();

    const swapDisplay = useMemo(
        () => ({
            [Swap.None]: swap.none,
            [Swap.Byte]: swap.byte,
            [Swap.Word]: swap.word,
            [Swap.ByteWord]: swap.byteWord
        }),
        [swap]
    );

    return { swapDisplay };
}

export function useTimezoneEnum() {
    const {
        enumDisplay: { timezone }
    } = useLanguage();

    const timezones = [
        Timezone.Hawaii,
        Timezone.Alaska,
        Timezone.Pacific,
        Timezone.Mountain,
        Timezone.Central,
        Timezone.Eastern
    ];

    const displayTimezone = (value: Timezone) => {
        switch (value) {
            case Timezone.Hawaii:
                return timezone.hawaii;
            case Timezone.Alaska:
                return timezone.alaska;
            case Timezone.Pacific:
                return timezone.pacific;
            case Timezone.Mountain:
                return timezone.mountain;
            case Timezone.Central:
                return timezone.central;
            case Timezone.Eastern:
                return timezone.eastern;
        }
    };

    return { displayTimezone, timezones };
}

export function useEnrollmentStatusEnum() {
    const {
        enumDisplay: { enrollmentStatus }
    } = useLanguage();

    const convertEnrollmentStatus = (value: EnrollmentStatus) => {
        switch (value) {
            case EnrollmentStatus.Unassigned:
                return enrollmentStatus.unassigned;
            case EnrollmentStatus.Assigning:
                return enrollmentStatus.assigning;
            case EnrollmentStatus.Assigned:
                return enrollmentStatus.assigned;
            case EnrollmentStatus.Disabled:
                return enrollmentStatus.disabled;
            case EnrollmentStatus.Failed:
                return enrollmentStatus.failed;
            case EnrollmentStatus.WaitingOnDevice:
                return enrollmentStatus.waitingOnDevice;
            default:
                return enrollmentStatus.unknown;
        }
    };

    return { convertEnrollmentStatus };
}

export function useUserRoleEnum() {
    const {
        enumDisplay: { userRole },
        userErrors
    } = useLanguage();

    const convertUserRole = (value?: UserRole) => {
        switch (value) {
            case UserRole.ExternalUser:
                return userRole.externalUser;
            case UserRole.InternalViewer:
                return userRole.internalViewer;
            case UserRole.InternalUser:
                return userRole.internalUser;
            case UserRole.Admin:
                return userRole.admin;
            case undefined:
                return userErrors.userUndefined;
            default:
                return value;
        }
    };

    return { convertUserRole };
}

export function useAssetEnums() {
    const {
        enumDisplay: { assetType }
    } = useLanguage();

    const convertAssetType = (value: AssetType) => {
        switch (value) {
            case AssetType.PSL:
                return assetType.psl;
        }
    };

    return { convertAssetType };
}

export function useConnectionStatusEnum() {
    const {
        enumDisplay: { connectionStatus }
    } = useLanguage();

    const convertConnectionStatus = (value: ConnectionStatus) => {
        switch (value) {
            case ConnectionStatus.Connected:
                return connectionStatus.connected;
            case ConnectionStatus.Disconnected:
                return connectionStatus.disconnected;
            case ConnectionStatus.Unknown:
                return connectionStatus.unknown;
            default:
                return value;
        }
    };

    return { convertConnectionStatus };
}

export function useModuleRuntimeStatusEnum() {
    const {
        enumDisplay: { moduleRuntimeStatus }
    } = useLanguage();

    const convertModuleRuntimeStatus = (value: ModuleRuntimeStatus) => {
        switch (value) {
            case ModuleRuntimeStatus.Unknown:
                return moduleRuntimeStatus.unknown;
            case ModuleRuntimeStatus.Backoff:
                return moduleRuntimeStatus.backoff;
            case ModuleRuntimeStatus.Running:
                return moduleRuntimeStatus.running;
            case ModuleRuntimeStatus.Unhealthy:
                return moduleRuntimeStatus.unhealthy;
            case ModuleRuntimeStatus.Stopped:
                return moduleRuntimeStatus.stopped;
            case ModuleRuntimeStatus.Failed:
                return moduleRuntimeStatus.failed;
            default:
                return value;
        }
    };

    return { convertModuleRuntimeStatus };
}

export function useOpcUANodeIdTypeEnum() {
    const {
        enumDisplay: { opcUANodeIdType }
    } = useLanguage();

    const dataTypeValueFormatter = (nodeIdType: OpcUANodeIdType) => {
        switch (nodeIdType) {
            case OpcUANodeIdType.Base64String:
                return opcUANodeIdType.base64String;
            case OpcUANodeIdType.String:
                return opcUANodeIdType.string;
            case OpcUANodeIdType.Integer:
                return opcUANodeIdType.integer;
            case OpcUANodeIdType.Guid:
                return opcUANodeIdType.guid;
        }
    };

    const opcUANodeIdDisplay = useMemo(
        () => ({
            [OpcUANodeIdType.Base64String]: opcUANodeIdType.base64String,
            [OpcUANodeIdType.String]: opcUANodeIdType.string,
            [OpcUANodeIdType.Integer]: opcUANodeIdType.integer,
            [OpcUANodeIdType.Guid]: opcUANodeIdType.guid
        }),
        [opcUANodeIdType]
    );

    return { dataTypeValueFormatter, opcUANodeIdDisplay };
}
