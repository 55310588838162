import { OutlinedInput, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import { FormElement } from '../../common/formElement';
import { formFieldId } from '../../common/formInputRenderFunctions';
import { LocalEnrollment } from '../../../types/enrollmentTypes';
import LocalTagEditor from './localTagEditor';

const EnrollmentFormInputs = ({ isEdit }: { isEdit?: boolean }) => {
    const form = useFormContext<LocalEnrollment>();
    const { enrollmentForm, form: formText } = useLanguage();

    return (
        <Stack spacing={2} maxWidth="100%">
            <FormElement<LocalEnrollment>
                fieldName="registrationId"
                label={enrollmentForm.registrationIdFieldLabel}
                tooltipTitle={enrollmentForm.registrationIdTooltip}
            >
                <OutlinedInput
                    {...form.register('registrationId', { required: formText.required })}
                    id={formFieldId('registrationId')}
                    disabled={isEdit}
                />
            </FormElement>
            <FormElement<LocalEnrollment>
                fieldName="serialNumber"
                label={enrollmentForm.serialNumberFieldLabel}
                tooltipTitle={enrollmentForm.serialNumberTooltip}
            >
                <OutlinedInput
                    {...form.register('serialNumber', { required: formText.required })}
                    id={formFieldId('serialNumber')}
                />
            </FormElement>
            <FormElement<LocalEnrollment>
                fieldName="tpmEndorsementKey"
                label={enrollmentForm.tpmEndorsementKeyFieldLabel}
                tooltipTitle={enrollmentForm.tpmEndorsementKeyTooltip}
            >
                <OutlinedInput
                    {...form.register('tpmEndorsementKey', {
                        required: formText.required
                    })}
                    id={formFieldId('tpmEndorsementKey')}
                    multiline
                    rows={5}
                />
            </FormElement>
            <FormElement<LocalEnrollment> fieldName="localTags" label="">
                <Controller
                    control={form.control}
                    name="localTags"
                    render={({ field }) => (
                        <LocalTagEditor
                            localTags={field.value}
                            onLocalTagsChange={field.onChange}
                        />
                    )}
                />
            </FormElement>
        </Stack>
    );
};

export default EnrollmentFormInputs;
