import dayjs from 'dayjs';
import { dateAndTimeDisplayFormat } from '../constants/formatting';
import { useLanguage } from './useLanguage';

export function useDateStringConverter() {
    const { dateText } = useLanguage();

    const getFormattedDate = (dateString: string) => {
        const date = dayjs(dateString);
        if (!date.isValid()) {
            return dateString;
        }

        const now = dayjs();
        const daysSinceDate = now.diff(date, 'day');
        const daysString =
            daysSinceDate === 1
                ? `${daysSinceDate}${dateText.day}`
                : `${daysSinceDate}${dateText.days}`;

        return `${daysString}${dateText.ago}${date.format(dateAndTimeDisplayFormat)}${
            dateText.utc
        }`;
    };

    return { getFormattedDate };
}
