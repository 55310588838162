import { Alert, IconButton, Popover, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';
import FilterIcon from '../../../icons/filterIcon';
import { themeColors } from '../../../theme';
import { GatewayFilter, GatewayStatusCondensedResponse } from '../../../types/gatewayStatusTypes';
import { PslResponse } from '../../../types/pslTypes';
import UserAssetsErrorMessage from '../../common/userAssetsErrorMessage';
import VerticalStack from '../../common/verticalStack';
import FilteringPopup from './filteringPopup';
import MapDisplay from './mapDisplay';

const MapDisplayPane = ({
    gatewayCondensedStatuses,
    setSelectedGateway,
    psls,
    selectedFilters,
    setSelectedFilters
}: {
    gatewayCondensedStatuses: GatewayStatusCondensedResponse[];
    setSelectedGateway: (gatewayId?: string) => void;
    psls: PslResponse[];
    selectedFilters?: GatewayFilter;
    setSelectedFilters: (filters?: GatewayFilter) => void;
}) => {
    const { gatewayMapPanel } = useLanguage();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    return (
        <VerticalStack spacing={2}>
            <Stack direction="row" spacing={4}>
                <Typography variant="action" marginBottom={6}>
                    {gatewayMapPanel.header}
                </Typography>
            </Stack>
            <VerticalStack spacing={2}>
                <IconButton
                    size="small"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                        alignSelf: 'flex-end',
                        width: 55,
                        border: 'solid',
                        borderColor: themeColors.colorPalettePrimary04,
                        borderWidth: 1,
                        borderRadius: 2
                    }}
                >
                    <FilterIcon />
                </IconButton>
                {anchorEl && (
                    <Popover
                        open={anchorEl !== undefined}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(undefined)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <FilteringPopup
                            psls={psls}
                            handleClose={() => setAnchorEl(undefined)}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                        />
                    </Popover>
                )}
                {psls.length === 0 && (
                    <Alert severity="warning">
                        <UserAssetsErrorMessage />
                    </Alert>
                )}
                <MapDisplay
                    gateways={gatewayCondensedStatuses}
                    setSelectedGateway={setSelectedGateway}
                />
            </VerticalStack>
        </VerticalStack>
    );
};

export default MapDisplayPane;
