// API Types

export interface EnrollmentUpdateRequest {
    serialNumber: string;
    tpmEndorsementKey: string;
    tagsJson?: string;
}

export interface EnrollmentCreateRequest extends EnrollmentUpdateRequest {
    registrationId: string;
}

export interface EnrollmentResponse extends EnrollmentCreateRequest {
    enrollmentStatus?: string;
    createdDate?: string;
    lastUpdatedDate?: string;
}

// Internal Types

export type LocalTag = {
    key: string;
    value?: string;
};

export interface LocalEnrollment {
    registrationId: string;
    serialNumber: string;
    tpmEndorsementKey: string;
    localTags: LocalTag[];
}

// Enums

// The first 5 enum values here must match Microsoft's DPS SDK EnrollmentStatus enum value names
// https://learn.microsoft.com/en-us/dotnet/api/microsoft.azure.devices.provisioning.service.enrollmentstatus?view=azure-dotnet
export enum EnrollmentStatus {
    Unassigned = 'Unassigned',
    Assigning = 'Assigning',
    Assigned = 'Assigned',
    Failed = 'Failed',
    Disabled = 'Disabled',
    WaitingOnDevice = 'WaitingOnDevice'
}
