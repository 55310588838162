import { signalUnits, SignalUnitsMapping, signalUnitTypes } from './constants/signalUnits';
import { checkFieldUniqueness } from './helperFunctions';
import { Signal } from './types/deviceDefinitionTypes';
import { SignalUnitType } from './types/signalUnitTypes';

// If unit is found, return its unit type - otherwise return Unitless unit type
export const getSignalUnitType = (unit?: string) => {
    // If supplied unit is null/undefined, default to Unitless type
    if (!unit) {
        return SignalUnitType.Unitless;
    }

    // Note that if a unit is supplied that does NOT exist under a SignalUnitType, this will fail/have unexpected behavior
    return signalUnitTypes.find((unitType: SignalUnitType) =>
        SignalUnitsMapping[unitType].includes(unit)
    );
};

export const updateSignals = <T extends Signal>(
    signals: T[],
    validationError: string,
    onSignalsChange: (signals: T[]) => void
) => {
    const signalNames = signals.map((s) => s.name);
    if (new Set(signalNames).size !== signalNames.length) {
        throw new Error(validationError);
    }
    onSignalsChange(signals);
};

export const convertSignalToRow = <T extends Signal>(signal: T) => [
    signal.configurationElementId,
    signal.name,
    signal.dataType,
    signal.eventClass !== null,
    signal.eventClass,
    signal.readOnly,
    signal.uomClass,
    signal.unitType,
    signal.dataUnit
];

export const validateDataUnit = (dataUnit: string) => signalUnits.includes(dataUnit);

export const validateName = (name: string, signals: Signal[], defaultValue?: string) => {
    const nameUnique = checkFieldUniqueness(signals, (s) => s.name, name, defaultValue);
    return nameUnique;
};
