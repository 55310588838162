import { OutlinedInput, Alert, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { mediumString, mqttTopicFormat, xLongString } from '../../../../constants/validation';
import { checkFieldUniqueness } from '../../../../helperFunctions';
import { useLanguage } from '../../../../hooks/useLanguage';
import { Signal, Topic } from '../../../../types/deviceDefinitionTypes';
import { FormElement } from '../../../common/formElement';
import { formFieldId, RenderBinaryInput } from '../../../common/formInputRenderFunctions';
import VerticalStack from '../../../common/verticalStack';
import TopicSignalsEditor from './topicSignalsEditor';

const TopicFormInputs = ({ topics, allSignals }: { topics: Topic[]; allSignals: Signal[] }) => {
    const { form: formText, topicForm } = useLanguage();
    const form = useFormContext<Topic>();
    const [error, setError] = useState('');

    const validateName = (name: string) => {
        const nameUnique = checkFieldUniqueness(
            topics,
            (t) => t.name,
            name,
            form.formState.defaultValues?.name
        );

        if (!nameUnique) {
            return topicForm.duplicateNameError;
        }
        return true;
    };

    const validateTopicGroupId = (groupId: string) => {
        return mqttTopicFormat.test(groupId) ? true : topicForm.invalidGroupIdError;
    };

    const validateTopicRoute = (route: string) => {
        if (!mqttTopicFormat.test(route)) {
            return topicForm.invalidRouteError;
        }

        const routeUnique = checkFieldUniqueness(
            topics,
            (t) => t.route,
            route,
            form.formState.defaultValues?.route
        );
        if (!routeUnique) {
            return topicForm.duplicateRouteError;
        }

        return true;
    };

    return (
        <VerticalStack spacing={3}>
            {error && <Alert severity="error">{error}</Alert>}
            <Stack spacing={3}>
                <FormElement<Topic>
                    fieldName="name"
                    label={topicForm.nameFieldLabel}
                    tooltipTitle={topicForm.nameTooltip}
                >
                    <OutlinedInput
                        {...form.register('name', {
                            required: formText.required,
                            maxLength: { value: mediumString, message: formText.tooLong },
                            validate: validateName
                        })}
                        id={formFieldId('name')}
                    />
                </FormElement>
                <FormElement<Topic>
                    fieldName="route"
                    label={topicForm.routeFieldLabel}
                    tooltipTitle={topicForm.routeTooltip}
                >
                    <OutlinedInput
                        {...form.register('route', {
                            required: formText.required,
                            maxLength: { value: xLongString, message: formText.tooLong },
                            validate: validateTopicRoute
                        })}
                        id={formFieldId('route')}
                    />
                </FormElement>
                <FormElement<Topic>
                    fieldName="groupId"
                    label={topicForm.groupIdFieldLabel}
                    tooltipTitle={topicForm.groupIdTooltip}
                >
                    <OutlinedInput
                        {...form.register('groupId', {
                            maxLength: { value: mediumString, message: formText.tooLong },
                            validate: validateTopicGroupId
                        })}
                        id={formFieldId('groupId')}
                        disabled
                    />
                </FormElement>
                <FormElement<Topic>
                    fieldName="isCommand"
                    label={topicForm.typeFieldLabel}
                    tooltipTitle={topicForm.typeTooltip}
                >
                    <Controller
                        control={form.control}
                        name="isCommand"
                        render={({ field }) => (
                            <RenderBinaryInput
                                field={field}
                                trueLabel={topicForm.commandOption}
                                falseLabel={topicForm.telemetryOption}
                            />
                        )}
                    />
                </FormElement>
            </Stack>
            <VerticalStack spacing={3}>
                <Typography variant="h3" marginTop={2}>
                    {topicForm.mqttSignalConfigurationElementIdsLabel}
                </Typography>
                <FormElement<Topic>
                    fieldName="mqttSignalConfigurationElementIds"
                    label={topicForm.searchBoxLabel}
                    tooltipTitle={topicForm.mqttSignalConfigurationElementIdsTooltip}
                >
                    <Controller
                        control={form.control}
                        name="mqttSignalConfigurationElementIds"
                        rules={{ required: topicForm.atLeastOneSignalRequiredError }}
                        render={({ field }) => (
                            <TopicSignalsEditor
                                signalIdsForTopic={field.value}
                                allSignals={allSignals}
                                onTopicSignalIdsChange={field.onChange}
                                setError={setError}
                            />
                        )}
                    />
                </FormElement>
            </VerticalStack>
        </VerticalStack>
    );
};

export default TopicFormInputs;
