import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { DataSourceProvider } from '../contexts/dataContext';
import { AppRouter } from './navigation/router';
import { OktaProvider } from './navigation/oktaProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UserProvider } from '../contexts/userContext';
import { useMemo, useEffect } from 'react';
import { getTheme } from '../theme';
import { ColorModeProvider } from '../contexts/colorModeContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {getUomData} from "../api/uomApi";

function App() {
    const { colorMode, updateColorMode } = useLocalStorage();

    useEffect(() => {
        const fetchUomData = async () => {
            const storedUomData = localStorage.getItem('uomData');
            if (!storedUomData) {
                try {
                    const response = await getUomData();
                    console.log(response);
                    localStorage.setItem('uomData', JSON.stringify(response));
                } catch (error) {
                    console.error("Error fetching UOM data", error);
                }
            }
        };

        fetchUomData();
    }, []);

    // Update the theme only if the mode changes
    const theme = useMemo(() => createTheme(getTheme(colorMode)), [colorMode]);

    return (
        <BrowserRouter>
            <OktaProvider>
                <UserProvider>
                    <DataSourceProvider>
                        <ColorModeProvider colorMode={colorMode} updateColorMode={updateColorMode}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <CssBaseline />
                                    <AppRouter />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </ColorModeProvider>
                    </DataSourceProvider>
                </UserProvider>
            </OktaProvider>
        </BrowserRouter>
    );
}

export default App;
