import {
    SelectChangeEvent,
    Stack,
    FormLabel,
    Typography,
    Select,
    FormHelperText,
    MenuItem
} from '@mui/material';
import { useDataSource } from '../../hooks/useDataSource';
import { useLanguage } from '../../hooks/useLanguage';
import { Asset } from '../../types/assetTypes';
import { formLabelId } from './formInputRenderFunctions';

const AssetInfoEditor = ({
    assetInfo,
    onAssetInfoChange,
    disabled
}: {
    assetInfo: Asset;
    onAssetInfoChange: (assetInfo: Asset) => void;
    disabled?: boolean;
}) => {
    const { assetInfoEditor } = useLanguage();

    // No need to check if PSLs length is 0 here for user asset error message, because that is an unreachable state -
    // any user with access to Layouts or Device Definitions pages has had at least one PSL assigned.
    const {
        pslsSource: { loading: getLoading, error: getError, data: psls }
    } = useDataSource();

    const onPslChange = (e: SelectChangeEvent<string>) => {
        onAssetInfoChange({ ...assetInfo, pslId: e.target.value });
    };

    return (
        <Stack>
            <FormLabel id={formLabelId('pslId')}>
                <Typography variant="labelForm">{assetInfoEditor.pslFieldLabel}</Typography>
            </FormLabel>
            {psls && (
                <Select
                    value={assetInfo.pslId}
                    onChange={onPslChange}
                    labelId={formLabelId('pslId')}
                    disabled={disabled || getError !== undefined}
                >
                    {psls.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {getLoading && <FormHelperText>Loading...</FormHelperText>}
            {getError && <FormHelperText error>{getError}</FormHelperText>}
        </Stack>
    );
};

export default AssetInfoEditor;
