import { Stack } from '@mui/system';
import { useState } from 'react';
import { useAssetEnums } from '../../hooks/enumHooks';
import { useLanguage } from '../../hooks/useLanguage';
import { Asset, AssetType } from '../../types/assetTypes';
import { CreateAsset } from '../../types/userAssetMappingTypes';
import AssetInfoEditor from './assetInfoEditor';
import ConfirmDialog from './confirmDialog';

interface ConfirmChange {
    dialogText: string;
    secondaryAction: (newAsset: CreateAsset) => void;
}

const ConfirmAssetInfoEditor = ({
    assetInfo,
    onAssetInfoChange,
    disabled,
    confirmChange
}: {
    assetInfo: Asset;
    onAssetInfoChange: (assetInfo: Asset) => void;
    confirmChange: ConfirmChange;
    disabled?: boolean;
}) => {
    const { assetInfoEditor } = useLanguage();
    const { convertAssetType } = useAssetEnums();
    const [newAsset, setNewAsset] = useState<CreateAsset>();

    const handleCancel = () => {
        setNewAsset(undefined);
    };

    const handleConfirm = () => {
        if (newAsset === undefined) {
            return;
        }

        onAssetInfoChange({ ...assetInfo, pslId: newAsset.assetId });
        confirmChange.secondaryAction(newAsset);
        setNewAsset(undefined);
    };

    const onPslChange = (assetInfo: Asset) => {
        setNewAsset({ assetId: assetInfo.pslId, assetType: AssetType.PSL });
    };

    return (
        <Stack>
            <AssetInfoEditor
                assetInfo={assetInfo}
                onAssetInfoChange={onPslChange}
                disabled={disabled}
            />
            {newAsset && (
                <ConfirmDialog
                    isOpen={newAsset !== undefined}
                    title={`${assetInfoEditor.dialogTitlePrefix}${convertAssetType(
                        newAsset.assetType
                    )}${assetInfoEditor.dialogTitleSuffix}`}
                    content={confirmChange.dialogText}
                    onConfirm={handleConfirm}
                    onClose={handleCancel}
                />
            )}
        </Stack>
    );
};

export default ConfirmAssetInfoEditor;
