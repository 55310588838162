import { useStringConverters } from './useStringConverters';

export const useSignalCsvImport = () => {
    const { convertBool, convertInt } = useStringConverters();

    const convertRowToSignal = (splitRow: string[]) => {
        // isEvent is an internal construct for user-convenience
        // it is not part of the parsed register schema
        const isEvent = convertBool(splitRow[3]);
        return {
            configurationElementId: splitRow[0],
            name: splitRow[1],
            // enums are entered as their integer values
            dataType: convertInt(splitRow[2]),
            eventClass: isEvent ? convertInt(splitRow[4]) : null,
            readOnly: convertBool(splitRow[5]),
            uomClass: splitRow[6],
            unitType: splitRow[7],
            dataUnit: splitRow[8]
        };
    };

    return { convertRowToSignal };
};
