import { OutlinedInput, Stack } from '@mui/material';
import { Path, useFormContext } from 'react-hook-form';
import { UserReadOnlyFields } from '../../../types/userTypes';
import { FormElement } from '../../common/formElement';
import { formFieldId } from '../../common/formInputRenderFunctions';

const UserReadOnlyInputs = <FormType extends UserReadOnlyFields, FieldType extends Path<FormType>>({
    name,
    email
}: {
    name: FieldType;
    email: FieldType;
}) => {
    const form = useFormContext<FormType>();

    return (
        <Stack>
            <FormElement<FormType> fieldName="name" label="Name">
                <OutlinedInput disabled {...form.register(name)} id={formFieldId('name')} />
            </FormElement>
            <FormElement<FormType> fieldName="email" label="Email">
                <OutlinedInput disabled {...form.register(email)} id={formFieldId('email')} />
            </FormElement>
        </Stack>
    );
};

export default UserReadOnlyInputs;
