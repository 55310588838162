import { OutlinedInput, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { checkFieldUniqueness } from '../../../helperFunctions';
import { useLanguage } from '../../../hooks/useLanguage';
import { useLayouts } from '../../../hooks/useLayouts';
import { LocalDevice } from '../../../types/deviceTypes';
import { RequiredStatus } from '../../../types/displayTypes';
import { SaveLayout } from '../../../types/layoutTypes';
import ConfirmAssetInfoEditor from '../../common/confirmAssetInfoEditor';
import { FormElement } from '../../common/formElement';
import { formFieldId } from '../../common/formInputRenderFunctions';
import VerticalStack from '../../common/verticalStack';
import DevicesEditor from './devicesEditor';

const LayoutFormInputs = ({ isEdit }: { isEdit?: boolean }) => {
    const { layoutForm, deviceForm, form: formText } = useLanguage();
    const form = useFormContext<SaveLayout>();
    const layouts = useLayouts();

    const validateDevices = (devices: LocalDevice[]) => {
        const deviceNames = devices.filter((d) => !d.isDeleted).map((d) => d.name);
        if (deviceNames.length === new Set(deviceNames).size) {
            return true;
        }

        return deviceForm.duplicateNameError;
    };

    const currentPslId = form.watch('assetInfo.pslId');
    const validateName = (name: string) => {
        const nameUnique = checkFieldUniqueness(
            layouts.filter((l) => l.assetInfo.pslId === currentPslId),
            (l) => l.name,
            name,
            form.formState.defaultValues?.name
        );

        return nameUnique || layoutForm.duplicateNameError;
    };

    return (
        <VerticalStack>
            <Stack spacing={2} maxWidth="33%">
                <FormElement<SaveLayout>
                    fieldName="name"
                    label={layoutForm.nameFieldLabel}
                    tooltipTitle={layoutForm.nameTooltip}
                >
                    <OutlinedInput
                        {...form.register('name', {
                            required: formText.required,
                            validate: validateName
                        })}
                        id={formFieldId('name')}
                    />
                </FormElement>
                <FormElement<SaveLayout>
                    fieldName="notes"
                    label={layoutForm.notesFieldLabel}
                    tooltipTitle={layoutForm.notesTooltip}
                    requiredStatus={RequiredStatus.Optional}
                >
                    <OutlinedInput
                        {...form.register('notes')}
                        id={formFieldId('notes')}
                        multiline
                        rows={3}
                    />
                </FormElement>
                <FormElement<SaveLayout> fieldName="assetInfo" label="">
                    <Controller
                        control={form.control}
                        name="assetInfo"
                        render={({ field }) => (
                            <ConfirmAssetInfoEditor
                                assetInfo={field.value}
                                onAssetInfoChange={field.onChange}
                                disabled={isEdit}
                                confirmChange={{
                                    dialogText: layoutForm.pslChangeWarning,
                                    secondaryAction: () => form.setValue('devices', [])
                                }}
                            />
                        )}
                    />
                </FormElement>
            </Stack>
            <FormElement<SaveLayout> fieldName="devices" label="">
                <Controller
                    control={form.control}
                    name="devices"
                    rules={{ validate: validateDevices }}
                    render={({ field }) => (
                        <DevicesEditor
                            devices={field.value}
                            onDevicesChange={field.onChange}
                            layoutAsset={form.watch('assetInfo')}
                        />
                    )}
                />
            </FormElement>
        </VerticalStack>
    );
};

export default LayoutFormInputs;
