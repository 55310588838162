import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton } from '@mui/material';
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowId,
    GridRowIdGetter,
    GridValidRowModel,
    GridRenderCellParams
} from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { themeColors } from '../../../../theme';
import { useLanguage } from '../../../../hooks/useLanguage';
import CustomPagination from '../../../common/customPagination';
import { useSignalDataTypeEnum } from '../../../../hooks/enumHooks';
import { tableHeaderHeightPx } from '../../../../constants/styling';

interface PagingConfiguration {
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    rowNumber: number;
}

interface TopicSignalsTableProps {
    rows: GridRowsProp;
    deleteRow: (rowId: GridRowId) => void;
    getRowId: GridRowIdGetter<GridValidRowModel>;
    pagedData: PagingConfiguration;
}

const rowHeightPx = 40;

const TopicSignalsTable = ({ rows, deleteRow, getRowId, pagedData }: TopicSignalsTableProps) => {
    const { topicSignalsTable } = useLanguage();
    const { dataTypeValueFormatter } = useSignalDataTypeEnum();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: topicSignalsTable.signalNameColumnLabel,
            flex: 1
        },
        {
            field: 'dataType',
            headerName: topicSignalsTable.signalDataTypeColumnLabel,
            valueFormatter: (params) => dataTypeValueFormatter(params.value),
            flex: 1
        },
        {
            field: 'delete', // This field name intentionally does not match up with the data type we are listing - it's just here for the delete column
            headerName: '',
            width: 40,
            align: 'center',
            sortable: false,
            renderCell: (params: GridRenderCellParams<string>) => (
                <IconButton size="small" onClick={() => deleteRow(params.id)}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        size="xs"
                        color={themeColors.colorPalettePrimary06}
                    />
                </IconButton>
            )
        }
    ];

    const handlePageChange = (newPage: number) => {
        if (!pagedData) {
            return;
        }
        pagedData.setPage(newPage);
    };

    return (
        <Box height="100%">
            <DataGrid
                getRowId={getRowId}
                rows={rows}
                columns={columns}
                rowHeight={rowHeightPx}
                headerHeight={tableHeaderHeightPx}
                disableColumnSelector
                disableColumnMenu
                showColumnRightBorder
                page={pagedData.page}
                paginationMode={undefined}
                rowsPerPageOptions={[pagedData.pageSize]}
                pageSize={pagedData.pageSize}
                rowCount={pagedData.rowNumber}
                onPageChange={handlePageChange}
                components={{
                    ColumnUnsortedIcon: (_) => (
                        <Box>
                            <Box marginBottom="-8px">
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    size="xs"
                                    color={themeColors.colorPalettePrimary06}
                                />
                            </Box>
                            <Box>
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    size="xs"
                                    color={themeColors.colorPalettePrimary06}
                                />
                            </Box>
                        </Box>
                    ),
                    ColumnSortedAscendingIcon: (_) => <FontAwesomeIcon icon={faAngleUp} />,
                    ColumnSortedDescendingIcon: (_) => <FontAwesomeIcon icon={faAngleDown} />,
                    NoRowsOverlay: (_) => (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            {topicSignalsTable.useSearchBoxMessage}
                        </Box>
                    ),
                    Pagination: CustomPagination
                }}
                componentsProps={{
                    pagination: { rowCount: rows.length }
                }}
            />
        </Box>
    );
};

export default TopicSignalsTable;
