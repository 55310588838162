import { baseApiUrl } from '../environmentVariables';
import { HttpMethod } from '../types/apiTypes';
import { GatewayCreateRequest, GatewayResponse, GatewayUpdateRequest } from '../types/gatewayTypes';
import { httpRequest } from './sharedApi';

const gatewayUrl = `${baseApiUrl}/gateways`;

export const getGateways = ({
    layoutIds,
    pageNumber,
    pageSize
}: {
    pageNumber?: number;
    pageSize?: number;
    layoutIds?: string[];
}) => {
    const searchParams = new URLSearchParams();
    if (layoutIds) {
        searchParams.append('layoutIds', layoutIds.join(','));
    }
    if (pageNumber !== undefined) {
        searchParams.append('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
        searchParams.append('pageSize', pageSize.toString());
    }
    const queryString = searchParams.toString();
    const url = queryString ? `${gatewayUrl}?${queryString}` : gatewayUrl;
    return httpRequest<undefined, GatewayResponse[]>({
        url
    });
};

export const getGatewayCount = () => httpRequest<undefined, number>({ url: `${gatewayUrl}/count` });

export const getGateway = (gatewayId: string) =>
    httpRequest<undefined, GatewayResponse>({
        url: `${gatewayUrl}/${gatewayId}`
    });

export const commissionGateway = (gateway: GatewayCreateRequest) =>
    httpRequest<GatewayCreateRequest, GatewayResponse>({
        url: gatewayUrl,
        method: HttpMethod.Post,
        body: gateway
    });

export const updateGateway = ({
    gateway,
    gatewayId
}: {
    gateway: GatewayUpdateRequest;
    gatewayId: string;
}) =>
    httpRequest<GatewayUpdateRequest, GatewayResponse>({
        url: `${gatewayUrl}/${gatewayId}`,
        method: HttpMethod.Patch,
        body: gateway
    });

export const deleteGateway = (gatewayId: string) =>
    httpRequest<undefined, undefined>({
        url: `${gatewayUrl}/${gatewayId}`,
        method: HttpMethod.Delete
    });
