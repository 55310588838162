import { useMemo, useState } from 'react';
import { Alert, Typography } from '@mui/material';
import { InternalUser, SaveInternalUser, User } from '../../../types/userTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import InternalUserForm from './internalUserForm';
import UserSearchForm from './userSearchForm';
import { useLanguage } from '../../../hooks/useLanguage';
import { searchInternalUser, updateUserRoleAndMappings } from '../../../api/internalUserApi';
import { AssetType } from '../../../types/assetTypes';
import { UserRole } from '../../../types/authorizationTypes';
import {
    createErrorMessage,
    useDisplayHttpStatusCode,
    useRequest
} from '../../../hooks/requestHooks';
import { extensions } from '../../../pages';
import { ApiResult } from '../../../types/apiTypes';
import { useNavigate } from 'react-router-dom';
import { internalEmailFormat } from '../../../constants/validation';
import { useDataSource } from '../../../hooks/useDataSource';
import { PslResponse } from '../../../types/pslTypes';
import { getDefaultPsl } from '../../../helperFunctions';

const AddInternalUserAccessPage = () => {
    const { addInternalUserAccessScreen } = useLanguage();
    const [user, setUser] = useState<User>();
    const [error, setError] = useState<string>();
    const {
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();

    return (
        <DefaultPageLayout
            pageHeader={addInternalUserAccessScreen.header}
            loading={pslsLoading}
            error={error || pslsError}
        >
            <Typography variant="h5" paddingTop={2} paddingBottom={8}>
                {addInternalUserAccessScreen.description}
            </Typography>
            <UserSearchForm searchUser={searchInternalUser} setUser={setUser} />
            {user && psls && <AddInternalUserAccess user={user} psls={psls} setError={setError} />}
        </DefaultPageLayout>
    );
};

const AddInternalUserAccess = ({
    user,
    psls,
    setError
}: {
    user: User;
    psls: PslResponse[];
    setError: (error: string) => void;
}) => {
    const { addInternalUserAccessScreen } = useLanguage();
    const navigate = useNavigate();
    const displayHttpStatusCode = useDisplayHttpStatusCode();

    const userTypeError = useMemo(
        () =>
            internalEmailFormat.test(user.email)
                ? undefined
                : addInternalUserAccessScreen.userTypeError,
        [user.email, addInternalUserAccessScreen.userTypeError]
    );

    const defaultFormValues: InternalUser = {
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        psls: [{ assetId: getDefaultPsl(psls), assetType: AssetType.PSL }],
        userRole: UserRole.InternalViewer
    };

    const onSetSuccess = (_: { updateUserRole: boolean; userAndAssets: SaveInternalUser }) => {
        navigate(`../${extensions.internal}`);
    };
    const onSetFailure = (result: ApiResult<undefined>) => {
        const displayError = createErrorMessage(result, displayHttpStatusCode(result.status));
        setError(displayError);
    };
    const { executeRequest, loading } = useRequest(
        updateUserRoleAndMappings,
        onSetSuccess,
        onSetFailure
    );

    const onSubmit = (internalUser: InternalUser) => {
        const assetsToAdd = internalUser.psls.map((a) => ({
            assetId: a.assetId,
            assetType: a.assetType
        }));
        executeRequest({
            updateUserRole: true,
            userAndAssets: {
                id: internalUser.id,
                userRole: internalUser.userRole,
                assetsToAdd,
                assetsToDelete: []
            }
        });
    };

    return userTypeError ? (
        <Alert severity="error" sx={{ marginTop: 2 }}>
            {userTypeError}
        </Alert>
    ) : (
        <InternalUserForm
            defaultValues={defaultFormValues}
            psls={psls}
            submitLoading={loading}
            onSubmit={onSubmit}
        />
    );
};

export default AddInternalUserAccessPage;
