import { CustomUserClaim } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { createContext, ReactNode } from 'react';
import { getCurrentUserAssetMappings } from '../api/userAssetMappingApi';
import { internalEmailFormat } from '../constants/validation';
import { useLoad } from '../hooks/requestHooks';
import { useStringConverters } from '../hooks/useStringConverters';
import { SourceData } from '../types/apiTypes';
import { UserRole } from '../types/authorizationTypes';
import { UserAssetMappingResponse } from '../types/userAssetMappingTypes';

type UserData = {
    userRole: UserRole;
    oktaId: CustomUserClaim | CustomUserClaim[];
    email: string;
    name: string;
    assetMappings: SourceData<UserAssetMappingResponse[]>;
};

export const UserContext = createContext(undefined as UserData | undefined);

export function UserProvider({ children }: { children: ReactNode }) {
    const auth = useOktaAuth();
    const { convertInt } = useStringConverters();
    const userMappingsData = useLoad(getCurrentUserAssetMappings);

    const convertOktaUserRole = (userRole: string | number | boolean | object) => {
        // if we return an invalid userRole (i.e. the value has been set improperly via Okta)
        // we assign the role of internal viewer
        if (typeof userRole !== 'string') {
            return UserRole.InternalViewer;
        }
        let userRoleInt: number | undefined;
        try {
            userRoleInt = convertInt(userRole);
        } catch (error) {
            return UserRole.InternalViewer;
        }

        // if returned user role not in enum, set role to internal viewer
        if (!Object.values(UserRole).includes(userRoleInt)) {
            return UserRole.InternalViewer;
        }
        return userRoleInt;
    };

    if (
        auth.authState === undefined ||
        // verify tokens are defined
        auth.authState?.accessToken === undefined ||
        auth.authState?.idToken === undefined ||
        // verify claims we're accessing are defined
        auth.authState?.accessToken?.claims.email === undefined ||
        auth.authState?.idToken?.claims.name === undefined
    ) {
        return <UserContext.Provider value={undefined}>{children}</UserContext.Provider>;
    }

    const appRole = auth.authState.accessToken.claims['Approle'];
    const oktaId = auth.authState.accessToken.claims['uid'];
    const email = auth.authState.accessToken.claims.email;
    const name = auth.authState.idToken.claims.name;

    // internal user emails will always have the halliburton.com domain
    const internalUser = internalEmailFormat.test(email ?? '');

    const userRole = internalUser
        ? !(appRole === null || appRole === undefined)
            ? convertOktaUserRole(appRole.valueOf())
            : UserRole.InternalViewer // internal user with app role assigned --> assigned app role
        : UserRole.ExternalUser; // external user (will never have app role assigned) --> external user

    return (
        <UserContext.Provider
            value={{ userRole, email, name, oktaId, assetMappings: userMappingsData }}
        >
            {children}
        </UserContext.Provider>
    );
}
