import { Navigate, Route, Routes } from 'react-router-dom';
import { extensions, routes } from '../../pages';
import NotFoundDisplay from '../pages/notFoundDisplay';
import EditDeviceDefinitionPage from '../pages/deviceDefinition/editDeviceDefinitionPage';
import DeviceDefinitionsListPage from '../pages/deviceDefinition/deviceDefinitionsListPage';
import CreateDeviceDefinitionPage from '../pages/deviceDefinition/createDeviceDefinitionPage';
import TopNavigationBar from './topNavigationBar';
import { RequiredAuth } from './requiredAuth';
import AvailableGatewaysListPage from '../pages/gateway/availableGatewaysListPage';
import CommissionGatewayPage from '../pages/gateway/commissionGatewayPage';
import CommissionedGatewaysListPage from '../pages/gateway/commissionedGatewaysListPage';
import GatewaysPage from '../pages/gateway/gatewaysPage';
import EditGatewayPage from '../pages/gateway/editGatewayPage';
import LayoutsListPage from '../pages/layout/layoutsListPage';
import EditLayoutPage from '../pages/layout/editLayoutPage';
import CreateLayoutPage from '../pages/layout/createLayoutPage';
import LoggedOutDisplay from '../pages/loggedOutDisplay';
import EditEnrollmentPage from '../pages/enrollment/editEnrollmentPage';
import CreateEnrollmentPage from '../pages/enrollment/createEnrollmentPage';
import EnrollmentsListPage from '../pages/enrollment/enrollmentsListPage';
import NotAuthorizedDisplay from '../pages/notAuthorizedDisplay';
import ProtectedRoute from './protectedRoute';
import { UserRole } from '../../types/authorizationTypes';
import UsersPage from '../pages/user/usersPage';
import InternalUsersListPage from '../pages/user/internalUsersListPage';
import ExternalUsersListPage from '../pages/user/externalUsersListPage';
import AddInternalUserAccessPage from '../pages/user/addInternalUserAccessPage';
import AddExternalUserAccessPage from '../pages/user/addExternalUserAccessPage';
import EditInternalUserAccessPage from '../pages/user/editInternalUserAccessPage';
import OktaProviderErrorDisplay from './oktaProviderErrorDisplay';
import GatewayStatusPage from '../pages/gatewayStatus/gatewayStatusPage';
import { useAuthorization } from '../../hooks/useAuthorization';

export const AppRouter = () => {
    const { disabled: hideDashboard } = useAuthorization({ enable: UserRole.InternalViewer });

    return (
        <Routes>
            <Route element={<RequiredAuth />}>
                <Route path="/" element={<TopNavigationBar />}>
                    <Route
                        path="/"
                        element={
                            <Navigate
                                to={
                                    hideDashboard
                                        ? `${routes.gateways}/${extensions.enrolled}`
                                        : `${routes.dashboard}`
                                }
                                replace
                            />
                        }
                    />

                    <Route element={<ProtectedRoute auth={UserRole.InternalViewer} />}>
                        <Route path={routes.dashboard} element={<GatewayStatusPage />} />
                    </Route>

                    <Route path={routes.gateways} element={<GatewaysPage />}>
                        <Route path="" element={<Navigate to={extensions.enrolled} replace />} />
                        <Route path={extensions.enrolled} element={<EnrollmentsListPage />} />
                        <Route
                            path={`${extensions.enrolled}/${extensions.create}`}
                            element={<CreateEnrollmentPage />}
                        />
                        <Route
                            path={`${extensions.enrolled}/:registrationId`}
                            element={<EditEnrollmentPage />}
                        />
                        <Route element={<ProtectedRoute auth={UserRole.InternalViewer} />}>
                            <Route
                                path={extensions.commissioned}
                                element={<CommissionedGatewaysListPage />}
                            />
                            <Route
                                path={extensions.available}
                                element={<AvailableGatewaysListPage />}
                            />
                            <Route path={`:gatewayId`} element={<EditGatewayPage />} />
                        </Route>
                        <Route element={<ProtectedRoute auth={UserRole.InternalUser} />}>
                            <Route path={extensions.create} element={<CommissionGatewayPage />} />
                        </Route>
                        <Route path="*" element={<NotFoundDisplay />} />
                    </Route>

                    <Route
                        path={routes.layouts}
                        element={<ProtectedRoute auth={UserRole.InternalUser} />}
                    >
                        <Route path={extensions.list} element={<LayoutsListPage />} />
                        <Route path={extensions.create} element={<CreateLayoutPage />} />
                        <Route path={`:layoutId`} element={<EditLayoutPage />} />
                        <Route path="" element={<Navigate to={extensions.list} replace />} />
                        <Route path="*" element={<NotFoundDisplay />} />
                    </Route>

                    <Route
                        path={routes.deviceDefinitions}
                        element={<ProtectedRoute auth={UserRole.InternalUser} />}
                    >
                        <Route path={extensions.list} element={<DeviceDefinitionsListPage />} />
                        <Route path={extensions.create} element={<CreateDeviceDefinitionPage />} />
                        <Route
                            path={`:deviceDefinitionId`}
                            element={<EditDeviceDefinitionPage />}
                        />
                        <Route path="" element={<Navigate to={extensions.list} replace />} />
                    </Route>

                    <Route path={routes.users} element={<UsersPage />}>
                        <Route element={<ProtectedRoute auth={UserRole.Admin} />}>
                            <Route
                                path=""
                                element={<Navigate to={extensions.internal} replace />}
                            />
                            <Route path={extensions.internal} element={<InternalUsersListPage />} />
                            <Route
                                path={`${extensions.internal}/${extensions.add}`}
                                element={<AddInternalUserAccessPage />}
                            />
                            <Route
                                path={`${extensions.internal}/:userId`}
                                element={<EditInternalUserAccessPage />}
                            />
                            <Route path={extensions.external} element={<ExternalUsersListPage />} />
                            <Route
                                path={`${extensions.external}/${extensions.add}`}
                                element={<AddExternalUserAccessPage />}
                            />
                        </Route>
                        <Route path="*" element={<NotFoundDisplay />} />
                    </Route>

                    <Route path="*" element={<NotFoundDisplay />} />
                    <Route path={routes.userNotAuthorized} element={<NotAuthorizedDisplay />} />
                </Route>
            </Route>
            <Route path={routes.oktaError} element={<OktaProviderErrorDisplay />} />
            <Route path={routes.loggedOut} element={<LoggedOutDisplay />} />
        </Routes>
    );
};
