import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import { useSignalCsvImport } from '../../../../hooks/useSignalCsvImport';
import { convertSignalToRow, updateSignals } from '../../../../signalHelperFunctions';
import { DeviceDefinitionCreateRequest, Signal } from '../../../../types/deviceDefinitionTypes';
import ExportToFileButton from '../../../common/exportToFileButton';
import ImportFromFileButton from '../../../common/importFromFileButton';
import {constructUnitList} from "../../../../helperFunctions";
import InvalidRowsModal from "../../../common/invalidRowsModal";

export const signalCsvHeaders =
    'configurationElementId,name,dataType,isEvent,eventClass,readOnly,uomClass,unitType,dataUnit';

const SignalImportExportButtons = ({
    signals,
    onSignalsChange,
    setError
}: {
    signals: Signal[];
    onSignalsChange: (signals: Signal[]) => void;
    setError: (error: string) => void;
}) => {
    const { signalDefinition, deviceDefinitionScreen } = useLanguage();
    const deviceDefinitionForm = useFormContext<DeviceDefinitionCreateRequest>();
    const { convertRowToSignal } = useSignalCsvImport();
    const [invalidRows, setInvalidRows] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const parseCsvSignals = (data: string[]) => {
        const invalidRowsTemp: string[] = [];
        const unitList = constructUnitList();
        
        // last row could be empty - don't parse if it is
        if (data[data.length - 1] === '') {
            data.pop();
        }

        // Remove empty rows and rows with critical missing data
        const filteredData = data.filter(row => {
            const trimmedRow = row.trim();
            const splitRow = trimmedRow.split(',');

            // Ensure the row has the correct number of columns
            if (splitRow.length !== 9) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with incorrect column counts
            }

            if (!unitList.includes(splitRow[8])) {
                invalidRowsTemp.push(row);
                return false; // Skip rows with invalid critical fields
            }

            return true; // Include rows that pass all checks
        });

        // Set invalid rows to state
        setInvalidRows(invalidRowsTemp);

        // Show modal if there are invalid rows
        if (invalidRowsTemp.length > 0) {
            setModalOpen(true);
        }

        // Convert each filtered row to a Signal object
        const parsedSignals = filteredData.map(row => {
            const splitRow = row.split(',');

            // Convert the row to a Signal object
            return convertRowToSignal(splitRow);
        });

        // Update signals if parsed correctly
        if (parsedSignals.length > 0) {
            updateSignals(
                parsedSignals as Signal[],
                signalDefinition.signalNamesNotUnique,
                onSignalsChange
            );
            setError(''); // Clear any previous errors
        }
    };

    const parseJsonSignals = (content: string) => {
        try {
            const parsedData = JSON.parse(content);

            // Ensure the parsed data is an array
            if (!Array.isArray(parsedData)) {
                throw new Error('Parsed data is not an array');
            }

            const unitList = constructUnitList();
            const invalidRowsTemp: string[] = [];

            // Validate each signal object
            const validSignals: Signal[] = parsedData.filter((signal, index) => {
                // Ensure the signal has all the required fields and valid dataUnit
                const hasRequiredFields = signal.hasOwnProperty('configurationElementId') &&
                    signal.hasOwnProperty('name') &&
                    signal.hasOwnProperty('dataType') &&
                    signal.hasOwnProperty('eventClass') &&
                    signal.hasOwnProperty('readOnly') &&
                    signal.hasOwnProperty('uomClass') &&
                    signal.hasOwnProperty('unitType') &&
                    signal.hasOwnProperty('dataUnit');

                const isValidDataUnit = unitList.includes(signal.dataUnit);

                if (!hasRequiredFields || !isValidDataUnit) {
                    // Store the invalid signal as a JSON string for displaying in the modal
                    invalidRowsTemp.push(JSON.stringify(signal));
                    return false;
                }
                return true;
            });

            // Set invalid rows to state
            setInvalidRows(invalidRowsTemp);

            // Show modal if there are invalid rows
            if (invalidRowsTemp.length > 0) {
                setModalOpen(true);
            }

            // Update signals if parsed correctly
            if (validSignals.length > 0) {
                updateSignals(validSignals, signalDefinition.signalNamesNotUnique, onSignalsChange);
                setError(''); // Clear any previous errors
            } else if (invalidRowsTemp.length === 0) {
                // If no valid signals and no invalid rows, set a general error
                setError('No valid signals found in the JSON data');
            }
        } catch (error) {
            // Handle JSON parsing errors
            setError('Error parsing JSON data: ' + error);
        }
    };


    const translateSignalsToCsv = (signals: Signal[]) => {
        const csvRows = signals.map((s) => convertSignalToRow(s).join(','));
        return [signalCsvHeaders, ...csvRows];
    };

    return (
        <Stack direction="row" spacing={2}>
            <ImportFromFileButton
                setError={setError}
                handleJsonData={parseJsonSignals}
                handleCsvData={parseCsvSignals}
                expectedCsvHeaders={signalCsvHeaders}
                confirmDialogContent={signalDefinition.importOverwriteWarning}
            />
            <ExportToFileButton
                data={signals}
                fileName={`${
                    deviceDefinitionForm.watch('name') !== ''
                        ? deviceDefinitionForm.watch('name')
                        : deviceDefinitionScreen.defaultFileExportName
                }${signalDefinition.exportSignalsFilenameSuffix}`}
                formatCsvData={translateSignalsToCsv}
            />
            <InvalidRowsModal open={modalOpen} onClose={() => setModalOpen(false)} rows={invalidRows} />
        </Stack>
    );
};

export default SignalImportExportButtons;
