// Enums

export enum AssetType {
    PSL = 0
}

// API Types

export interface Asset {
    pslId: string;
}
