import { baseApiUrl } from '../environmentVariables';
import { IdScopeResponse } from '../types/idScopeTypes';
import { httpRequest } from './sharedApi';

const idScopeUrl = `${baseApiUrl}/idScope`;

export const getIdScope = () => {
    return httpRequest<undefined, IdScopeResponse>({
        url: idScopeUrl
    });
};
