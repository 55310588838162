import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';

const LoadingDisplay = () => {
    const { lifecycle } = useLanguage();
    const size = 75;
    return (
        <Stack direction="row" justifyContent="center">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Box sx={{ position: 'relative' }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{ color: (theme) => theme.palette.info.contrastText }}
                        size={size}
                        value={100}
                    />
                    <CircularProgress
                        color="info"
                        sx={{ position: 'absolute', left: 0 }}
                        size={size}
                    />
                </Box>
                <Typography variant="labelForm">{lifecycle.loadingLabel}</Typography>
            </Stack>
        </Stack>
    );
};

export default LoadingDisplay;
