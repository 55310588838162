import { Alert, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import LoadingDisplay from './loadingDisplay';
import VerticalStack from './verticalStack';

interface DefaultPageLayoutProps {
    pageHeader: string;
    children: ReactNode;
    loading: boolean;
    error?: string;
}

const DefaultPageLayout = ({ pageHeader, children, loading, error }: DefaultPageLayoutProps) => {
    return (
        <VerticalStack
            paddingRight={4}
            paddingLeft={4}
            paddingTop={6}
            paddingBottom={4}
            minWidth="40%"
        >
            <Typography variant="h3">{pageHeader}</Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {loading && <LoadingDisplay />}
            {!loading && !error && <Box height="100%">{children}</Box>}
        </VerticalStack>
    );
};

export default DefaultPageLayout;
