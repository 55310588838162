import { faAnglesRight, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Alert,
    Button,
    Link,
    PaletteMode,
    Stack,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { navBarMinHeight } from '../../constants/styling';
import { getPslNameFromId } from '../../helperFunctions';
import { useUserRoleEnum } from '../../hooks/enumHooks';
import { useColorMode } from '../../hooks/useColorMode';
import { useDataSource } from '../../hooks/useDataSource';
import { useLanguage } from '../../hooks/useLanguage';
import { useUser } from '../../hooks/useUser';
import { routes } from '../../pages';
import { darkMode, lightMode, themeColors } from '../../theme';
import { UserRole } from '../../types/authorizationTypes';
import { PslResponse } from '../../types/pslTypes';
import LoadingDisplay from '../common/loadingDisplay';
import StyledToggleButton from '../common/styledToggleButton';
import UserAssetsErrorMessage from '../common/userAssetsErrorMessage';
import { withAuthorizationDisplay } from '../common/withAuthorization';

const drawerWidth = '400px';
const userInfoHeight = '50px';

const UserSettings = ({ setDrawer }: { setDrawer: (drawerOpen: boolean) => void }) => {
    const {
        pslsSource: { error, loading, data: psls }
    } = useDataSource();

    return (
        <Stack>
            {error && <Alert severity="error">{error}</Alert>} {loading && <LoadingDisplay />}
            {!error && !loading && psls && <UserSettingsLoad setDrawer={setDrawer} psls={psls} />}
        </Stack>
    );
};

const UserSettingsLoad = ({
    setDrawer,
    psls
}: {
    setDrawer: (drawerOpen: boolean) => void;
    psls: PslResponse[];
}) => {
    const { userSettingsMenu } = useLanguage();
    const { oktaAuth } = useOktaAuth();
    const user = useUser();
    const { convertUserRole } = useUserRoleEnum();
    const { colorMode, updateColorMode } = useColorMode();
    const navigate = useNavigate();

    const ButtonWithAuth = withAuthorizationDisplay(Button);

    const userAssets = user?.assetMappings.data;
    const handleColorModeChange = (
        _: React.MouseEvent<HTMLElement>,
        newColorMode: PaletteMode | null
    ) => {
        if (newColorMode !== null) {
            updateColorMode(newColorMode);
        }
    };

    return (
        <Stack width={drawerWidth} marginTop={navBarMinHeight} alignItems="center" spacing={3}>
            <Button
                startIcon={<FontAwesomeIcon icon={faAnglesRight} />}
                onClick={() => setDrawer(false)}
                sx={{
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    color: (theme) =>
                        theme.palette.mode === lightMode
                            ? undefined
                            : themeColors.colorPalettePrimary04
                }}
                fullWidth
            />
            <Typography variant="settingsLabel" alignSelf="flex-start" paddingLeft={4}>
                {userSettingsMenu.header}
            </Typography>
            {user?.userRole !== UserRole.Admin && userAssets && userAssets.length === 0 && (
                <Alert sx={{ width: drawerWidth }} severity="warning">
                    <UserAssetsErrorMessage />
                </Alert>
            )}
            <Stack
                direction="row"
                bgcolor={(theme) =>
                    theme.palette.mode === lightMode
                        ? themeColors.colorPalettePrimary03
                        : themeColors.colorPalettePrimary07
                }
                width="100%"
                height={userInfoHeight}
                alignItems="center"
                paddingLeft={4}
                paddingRight={4}
                justifyContent="space-between"
            >
                <Stack direction="row" alignItems="center" spacing={3}>
                    <FontAwesomeIcon icon={faCircleUser} size="2x" />
                    <Typography>{user?.name}</Typography>
                </Stack>
                <Link
                    color="inherit"
                    onClick={async () => {
                        await oktaAuth.signOut({
                            postLogoutRedirectUri: `${window.location.origin}/${routes.loggedOut}`
                        });
                    }}
                >
                    {userSettingsMenu.logout}
                </Link>
            </Stack>
            <Stack alignSelf="flex-start" paddingLeft={4} spacing={3}>
                <Typography variant="h4">
                    {userSettingsMenu.userRoleLabel}
                    {convertUserRole(user?.userRole)}
                </Typography>
                {user?.userRole !== UserRole.Admin && (
                    <Typography variant="h4">
                        {userSettingsMenu.userAssetsLabel}
                        {userAssets?.map((m) => getPslNameFromId(psls, m.assetId)).join(', ')}
                    </Typography>
                )}
                <Typography variant="settingsLabel" paddingTop={6}>
                    {userSettingsMenu.themeLabel}
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={colorMode}
                    exclusive
                    onChange={handleColorModeChange}
                >
                    <StyledToggleButton value={darkMode}>
                        {userSettingsMenu.darkModeToggleButton}
                    </StyledToggleButton>
                    <StyledToggleButton value={lightMode}>
                        {userSettingsMenu.lightModeToggleButton}
                    </StyledToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <ButtonWithAuth
                interaction={{ visible: UserRole.Admin }}
                onClick={() => {
                    setDrawer(false);
                    navigate(routes.users);
                }}
                variant="contained"
            >
                {userSettingsMenu.manageUsersButton}
            </ButtonWithAuth>
        </Stack>
    );
};

export default UserSettings;
