import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from '../../../hooks/useLanguage';
import { LocalTag } from '../../../types/enrollmentTypes';
import LocalTagFormInputs from './localTagFormInputs';

const LocalTagForm = ({
    isEdit,
    handleClose,
    updateLocalTag,
    defaultValues,
    existingLocalTags
}: {
    isEdit: boolean;
    handleClose: () => void;
    updateLocalTag: (localTagData: LocalTag) => void;
    defaultValues: LocalTag;
    existingLocalTags: LocalTag[];
}) => {
    const { localTagForm, editLocalTagForm, addLocalTagForm } = useLanguage();
    const form = useForm<LocalTag>({
        defaultValues
    });

    const onSubmit = (localTagData: LocalTag) => {
        updateLocalTag(localTagData);
        handleClose();
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    return form.handleSubmit(onSubmit)(e);
                }}
            >
                <Stack spacing={3}>
                    <Typography variant="h2">
                        {isEdit ? editLocalTagForm.header : addLocalTagForm.header}
                    </Typography>
                    <LocalTagFormInputs localTags={existingLocalTags} />
                    <Stack direction="row" justifyContent="end" spacing={2}>
                        <Button color="secondary" variant="contained" onClick={handleClose}>
                            {localTagForm.cancelButton}
                        </Button>
                        <Button type="submit" variant="contained">
                            {isEdit ? localTagForm.updateButton : localTagForm.addButton}
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default LocalTagForm;
