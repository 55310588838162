import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Alert,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getDeviceConfiguration } from '../../../api/deviceDefinitionApi';
import { useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import {
    DeviceConfiguration,
    DeviceDefinitionResponse,
    LocalDeviceDefinition,
    Protocol
} from '../../../types/deviceDefinitionTypes';

const DeviceDefinitionCopy = ({
    deviceDefinitions
}: {
    deviceDefinitions: DeviceDefinitionResponse[];
}) => {
    const form = useFormContext<LocalDeviceDefinition>();
    const { lifecycle, deviceDefinitionForm } = useLanguage();

    // definition Id must be initialized as an empty string to prevent an undefined error from
    // the select component
    const [deviceDefinitionId, setDefinitionId] = useState('');
    const [error, setError] = useState<string>();

    const updateConfig = (
        body: { deviceDefinitionId: string; protocol: Protocol },
        data?: DeviceConfiguration
    ) => {
        if (!data) {
            setError(`${deviceDefinitionForm.configurationLoadError}`);
            return;
        }

        const deviceDefinition = deviceDefinitions.find((dd) => dd.id === body.deviceDefinitionId);
        if (!deviceDefinition) {
            setError(`${deviceDefinitionForm.definitionIdError}${body.deviceDefinitionId}`);
            return;
        }

        form.setValue('name', deviceDefinition.name);
        form.setValue('protocol', deviceDefinition.protocol);
        form.setValue('manufacturer', deviceDefinition.manufacturer);
        form.setValue('assetInfo.pslId', deviceDefinition.assetInfo.pslId);

        if (data.protocol === Protocol.MoxaModbus) {
            form.setValue('moxaModbusRegisters', data.moxaModbusConfiguration.registers);
            form.setValue('moxaModbusSlaveId', data.moxaModbusConfiguration.moxaSlaveId);
        } else if (data.protocol === Protocol.Mqtt) {
            form.setValue('mqttSignals', data.mqttConfiguration.signals);
            form.setValue('mqttTopics', data.mqttConfiguration.mqttTopics);
        } else if (data.protocol === Protocol.OpcUA) {
            form.setValue('opcUANodes', data.opcUAConfiguration.nodes);
            form.setValue(
                'opcUASessionRenewalRequired',
                data.opcUAConfiguration.sessionRenewalRequired
            );
            form.setValue(
                'opcUASessionRenewalMinutes',
                data.opcUAConfiguration.sessionRenewalMinutes
            );
            form.setValue(
                'opcUAApplicationCertificatePath',
                data.opcUAConfiguration.applicationCertificatePath
            );
            form.setValue('opcUASecurityEnabled', data.opcUAConfiguration.securityEnabled);
        }
    };

    const configurationRequest = useRequest(getDeviceConfiguration, updateConfig);

    const handleChange = (e: SelectChangeEvent<string>) => {
        setError(undefined);
        setDefinitionId(e.target.value);
    };

    const copyDeviceDefinition = () => {
        if (!deviceDefinitionId) {
            // deviceDefinitionId should be populated, the button to trigger this is disabled if it's not
            return;
        }

        const deviceDefinition = deviceDefinitions.find((dd) => dd.id === deviceDefinitionId);
        if (!deviceDefinition) {
            setError(`${deviceDefinitionForm.definitionIdError}${deviceDefinitionId}`);
            return;
        }

        configurationRequest.executeRequest({
            deviceDefinitionId,
            protocol: deviceDefinition.protocol
        });
    };

    return (
        <Stack paddingBottom={8} spacing={2}>
            {error && <Alert severity="error">{error || configurationRequest.error}</Alert>}
            <Stack direction="row" spacing={2}>
                <Typography variant="h5">
                    {deviceDefinitionForm.copyDefinitionFieldLabel}
                </Typography>
                <Tooltip title={deviceDefinitionForm.copyDefinitionTooltip}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </Tooltip>
            </Stack>
            <Stack direction="row" alignItems="flex-end" spacing={2}>
                <FormControl sx={{ maxWidth: '33%' }}>
                    <Select
                        value={deviceDefinitionId}
                        onChange={handleChange}
                        disabled={configurationRequest.loading}
                    >
                        {deviceDefinitions.map((dd) => (
                            <MenuItem value={dd.id} key={dd.id}>
                                {dd.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    disabled={configurationRequest.loading || deviceDefinitionId === ''}
                    onClick={copyDeviceDefinition}
                >
                    {configurationRequest.loading
                        ? lifecycle.loadingLabel
                        : deviceDefinitionForm.copyDefinition}
                </Button>
            </Stack>
        </Stack>
    );
};

export default DeviceDefinitionCopy;
