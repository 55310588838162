import { deleteDeviceDefinitionIfNotUsedByGateways } from '../../../api/deviceDefinitionApi';
import { useRequest } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import DeviceDefinitionsList from './deviceDefinitionsList';

const DeviceDefinitionsListPage = () => {
    const { deviceDefinitionScreen: definitionScreenText } = useLanguage();
    const {
        deviceDefinitionsSource: { loading: getLoading, error: getError, data, setData }
    } = useDataSource();

    const deleteLocalDefinition = (deviceDefinitionId: string): void => {
        if (data) {
            setData(data.filter((deviceDefinition) => deviceDefinition.id !== deviceDefinitionId));
        }
    };

    const {
        executeRequest,
        loading: deleteLoading,
        error: deleteError
    } = useRequest(deleteDeviceDefinitionIfNotUsedByGateways, deleteLocalDefinition);

    const loading = getLoading || deleteLoading;
    const error = getError || deleteError;

    return (
        <DefaultPageLayout pageHeader={definitionScreenText.header} loading={loading} error={error}>
            {data && (
                <DeviceDefinitionsList
                    deviceDefinitions={data}
                    deleteDeviceDefinition={executeRequest}
                />
            )}
        </DefaultPageLayout>
    );
};

export default DeviceDefinitionsListPage;
