import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useLanguage } from '../../../hooks/useLanguage';
import { IdScopeResponse } from '../../../types/idScopeTypes';

const IdScopeDisplay = ({ idScope }: { idScope: IdScopeResponse }) => {
    const { idScopeDisplay } = useLanguage();

    return (
        <Stack spacing={1} direction="row">
            <Typography>{`${idScopeDisplay.idScopeLabel}${idScope.idScope}`}</Typography>
            <Box flexDirection="column" justifyContent="center">
                <Tooltip title={idScopeDisplay.idScopeTooltip}>
                    <FontAwesomeIcon icon={faCircleInfo} size="xs" />
                </Tooltip>
            </Box>
        </Stack>
    );
};

export default IdScopeDisplay;
