import { useMemo, useState } from 'react';
import { Alert, Typography } from '@mui/material';
import { User } from '../../../types/userTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import ExternalUserForm from './externalUserForm';
import UserSearchForm from './userSearchForm';
import { useLanguage } from '../../../hooks/useLanguage';
import { searchExternalUser } from '../../../api/externalUserApi';
import { internalEmailFormat } from '../../../constants/validation';

const AddExternalUserAccessPage = () => {
    const { addExternalUserAccessScreen } = useLanguage();
    const [user, setUser] = useState<User>();
    const [error, setError] = useState<string>();

    return (
        <DefaultPageLayout
            pageHeader={addExternalUserAccessScreen.header}
            loading={false}
            error={error}
        >
            <Typography variant="h5" paddingTop={2} paddingBottom={8}>
                {addExternalUserAccessScreen.description}
            </Typography>
            <UserSearchForm searchUser={searchExternalUser} setUser={setUser} />
            {user && <AddExternalUserAccess user={user} setError={setError} />}
        </DefaultPageLayout>
    );
};

const AddExternalUserAccess = ({
    user,
    setError
}: {
    user: User;
    setError: (error: string) => void;
}) => {
    const { addExternalUserAccessScreen } = useLanguage();
    const userTypeError = useMemo(
        () =>
            internalEmailFormat.test(user.email)
                ? addExternalUserAccessScreen.userTypeError
                : undefined,
        [user.email, addExternalUserAccessScreen.userTypeError]
    );
    return userTypeError ? (
        <Alert severity="error" sx={{ marginTop: 2 }}>
            {userTypeError}
        </Alert>
    ) : (
        <ExternalUserForm user={user} setError={setError} />
    );
};

export default AddExternalUserAccessPage;
