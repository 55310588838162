import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { compareAssets } from '../../../helperFunctions';
import { useLanguage } from '../../../hooks/useLanguage';
import { AssetType } from '../../../types/assetTypes';
import { UserRole } from '../../../types/authorizationTypes';
import { PslResponse } from '../../../types/pslTypes';
import { CreateAsset } from '../../../types/userAssetMappingTypes';
import { InternalUser } from '../../../types/userTypes';
import { FormElement } from '../../common/formElement';
import { formLabelId } from '../../common/formInputRenderFunctions';

const InternalUserFormInputs = ({ psls }: { psls: PslResponse[] }) => {
    const { enumDisplay, form: formText, internalUserForm } = useLanguage();
    const form = useFormContext<InternalUser>();

    const handleUserRoleChange = (e: SelectChangeEvent<UserRole>) => {
        const userRole = e.target.value;
        if (typeof userRole === 'string') {
            return;
        }
        form.setValue('userRole', userRole, { shouldDirty: true });
        if (userRole === UserRole.Admin) {
            form.setValue('psls', [], { shouldDirty: true });
        }
    };

    const handlePslChange = (e: SelectChangeEvent<string[]>) => {
        const pslIds = e.target.value;
        if (typeof pslIds === 'string') {
            return;
        }

        form.setValue(
            'psls',
            pslIds.map((p) => ({ assetId: p, assetType: AssetType.PSL })).sort(compareAssets),
            { shouldDirty: true }
        );
    };

    const validatePsl = (Psls: CreateAsset[], formValues: InternalUser) => {
        const noPsls = Psls.length === 0;
        if (formValues.userRole === UserRole.Admin) {
            return noPsls ? true : internalUserForm.adminMappingError;
        }
        return noPsls ? formText.required : true;
    };

    return (
        <Stack>
            <FormElement<InternalUser> fieldName="userRole" label="Role">
                <Controller
                    control={form.control}
                    name="userRole"
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={handleUserRoleChange}
                            labelId={formLabelId(field.name)}
                        >
                            <MenuItem value={UserRole.Admin}>{enumDisplay.userRole.admin}</MenuItem>
                            <MenuItem value={UserRole.InternalUser}>
                                {enumDisplay.userRole.internalUser}
                            </MenuItem>
                            <MenuItem value={UserRole.InternalViewer}>
                                {enumDisplay.userRole.internalViewer}
                            </MenuItem>
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<InternalUser> fieldName="psls" label="PSL">
                <Controller
                    control={form.control}
                    name="psls"
                    rules={{ validate: validatePsl }}
                    render={({ field }) => (
                        <Select
                            value={field.value.map((p) => p.assetId)}
                            onChange={handlePslChange}
                            labelId={formLabelId(field.name)}
                            multiple
                            disabled={form.watch('userRole') === UserRole.Admin}
                        >
                            {psls.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
        </Stack>
    );
};

export default InternalUserFormInputs;
