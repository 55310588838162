import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField } from '@mui/material';

const SearchBar = ({
    searchTerm,
    setSearchTerm
}: {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
}) => {
    return (
        <Box width="357px" height="32px">
            <TextField
                id="search-bar"
                type="search"
                value={searchTerm}
                onChange={(event) => {
                    setSearchTerm(event.target.value);
                }}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={faMagnifyingGlass} />
                }}
            />
        </Box>
    );
};

export default SearchBar;
