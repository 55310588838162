import { Stack, OutlinedInput } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
    enrollmentEnvironmentTagKey,
    enrollmentSerialNumberTagKey
} from '../../../constants/formatting';
import { checkFieldUniqueness } from '../../../helperFunctions';
import { useLanguage } from '../../../hooks/useLanguage';
import { RequiredStatus } from '../../../types/displayTypes';
import { LocalTag } from '../../../types/enrollmentTypes';
import { FormElement } from '../../common/formElement';
import { formFieldId } from '../../common/formInputRenderFunctions';

const LocalTagFormInputs = ({ localTags }: { localTags: LocalTag[] }) => {
    const { localTagForm } = useLanguage();
    const form = useFormContext<LocalTag>();

    const validateTagKey = (key: string) => {
        if (key.toLowerCase() === enrollmentSerialNumberTagKey.toLowerCase()) {
            return `${localTagForm.customTagKeyNotAllowedError}${enrollmentSerialNumberTagKey}`;
        } else if (key.toLowerCase() === enrollmentEnvironmentTagKey.toLowerCase()) {
            return `${localTagForm.customTagKeyNotAllowedError}${enrollmentEnvironmentTagKey}`;
        }

        const keyUnique = checkFieldUniqueness(
            localTags,
            (lt) => lt.key,
            key,
            form.formState.defaultValues?.key
        );

        return keyUnique || localTagForm.keysNotUniqueError;
    };

    return (
        <Stack spacing={3}>
            <FormElement<LocalTag> fieldName="key" label={localTagForm.keyFieldLabel}>
                <Controller
                    control={form.control}
                    name="key"
                    rules={{ validate: validateTagKey }}
                    render={({ field }) => (
                        <OutlinedInput
                            id={formFieldId('key')}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </FormElement>
            <FormElement<LocalTag>
                fieldName="value"
                label={localTagForm.valueFieldLabel}
                requiredStatus={RequiredStatus.Optional}
            >
                <OutlinedInput {...form.register('value')} id={formFieldId('value')} />
            </FormElement>
        </Stack>
    );
};

export default LocalTagFormInputs;
