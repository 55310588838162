import { Typography } from '@mui/material';
import { useGatewayConnectedStatusThreshold } from '../../../hooks/useGatewayConnectedStatusThreshold';
import { useLanguage } from '../../../hooks/useLanguage';
import { darkThemeColors, lightThemeColors } from '../../../theme';
import { ColorStatus } from '../../../types/displayTypes';
import { GatewayStatusCondensedResponse } from '../../../types/gatewayStatusTypes';
import NumericDisplay from '../../common/numericDisplay';
import TwoColorPieChart from '../../common/twoColorPieChart';
import VerticalStack from '../../common/verticalStack';

const CumulativeGatewayStatisticsSidebar = ({
    gatewayCondensedStatuses,
    commissionedGatewayCount,
    availableGatewayCount
}: {
    gatewayCondensedStatuses: GatewayStatusCondensedResponse[];
    commissionedGatewayCount: number;
    availableGatewayCount: number;
}) => {
    const { gatewayStatisticsPanel } = useLanguage();
    const { isGatewayOnline } = useGatewayConnectedStatusThreshold();

    const offlineGateways = gatewayCondensedStatuses.filter((s) => !isGatewayOnline(s.createdDate));
    const onlineGateways = gatewayCondensedStatuses.filter((s) => isGatewayOnline(s.createdDate));

    const onlineGatewaysWithNoErrors = onlineGateways.filter(
        (s) => s.modulesNotRunning === 0 && s.devicesNotConnected === 0
    );
    const onlineGatewaysWithFaultedModules = onlineGateways.filter(
        (s) => s.modulesNotRunning && s.modulesNotRunning !== 0
    );
    const onlineGatewaysWithDisconnectedFieldDevices = onlineGateways.filter(
        (s) => s.devicesNotConnected > 0
    );

    return (
        <VerticalStack>
            <Typography variant="action" marginBottom={6}>
                {gatewayStatisticsPanel.header}
            </Typography>
            <VerticalStack spacing={6} alignItems="center">
                <TwoColorPieChart
                    chartLabel={gatewayStatisticsPanel.gatewaysChartLabel}
                    option1={{
                        value: offlineGateways.length,
                        label: gatewayStatisticsPanel.offlineGatewaysChartLabel,
                        lightModeColor: lightThemeColors.availableGateways,
                        darkModeColor: darkThemeColors.availableGateways
                    }}
                    option2={{
                        value: onlineGateways.length,
                        label: gatewayStatisticsPanel.onlineGatewaysChartLabel,
                        lightModeColor: lightThemeColors.commissionedGateways,
                        darkModeColor: darkThemeColors.commissionedGateways
                    }}
                />
                <NumericDisplay
                    value={onlineGatewaysWithNoErrors.length}
                    label={gatewayStatisticsPanel.onlineGatewaysWithNoErrorsLabel}
                    status={
                        onlineGatewaysWithNoErrors.length === 0
                            ? ColorStatus.Undefined
                            : ColorStatus.Ok
                    }
                />
                <NumericDisplay
                    value={offlineGateways.length}
                    label={gatewayStatisticsPanel.offlineGatewaysLabel}
                    status={
                        offlineGateways.length === 0 ? ColorStatus.Undefined : ColorStatus.Warning
                    }
                />
                <NumericDisplay
                    value={onlineGatewaysWithFaultedModules.length}
                    label={gatewayStatisticsPanel.modulesInErrorStateLabel}
                    status={
                        onlineGatewaysWithFaultedModules.length === 0
                            ? ColorStatus.Undefined
                            : ColorStatus.Error
                    }
                />
                <NumericDisplay
                    value={onlineGatewaysWithDisconnectedFieldDevices.length}
                    label={gatewayStatisticsPanel.modulesWithFieldDevicesNotConnected}
                    status={
                        onlineGatewaysWithDisconnectedFieldDevices.length === 0
                            ? ColorStatus.Undefined
                            : ColorStatus.Error
                    }
                />
            </VerticalStack>
        </VerticalStack>
    );
};

export default CumulativeGatewayStatisticsSidebar;
