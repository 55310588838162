import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { themeColors } from '../theme';

export const SelectIcon = () => (
    <Box display="flex" alignItems="baseline" paddingRight={2}>
        <FontAwesomeIcon icon={faAngleDown} color={themeColors.colorPalettePrimary04} />
    </Box>
);
