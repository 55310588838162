import { baseApiUrl } from '../environmentVariables';
import { useLanguage } from '../hooks/useLanguage';
import { ApiResult, HttpMethod } from '../types/apiTypes';
import { LocalDevice } from '../types/deviceTypes';
import { SaveLayout, LayoutCreateRequest, LayoutResponse } from '../types/layoutTypes';
import { saveDevices } from './deviceApi';
import { getGateways } from './gatewayApi';
import { httpRequest } from './sharedApi';

const layoutUrl = `${baseApiUrl}/layouts`;

export const getLayouts = () =>
    httpRequest<undefined, LayoutResponse[]>({
        url: layoutUrl
    });

export const deleteLayout = (layoutId: string) =>
    httpRequest<undefined, undefined>({
        url: `${layoutUrl}/${layoutId}`,
        method: HttpMethod.Delete
    });

export const createLayout = (layout: LayoutCreateRequest) =>
    httpRequest<LayoutCreateRequest, LayoutResponse>({
        url: layoutUrl,
        method: HttpMethod.Post,
        body: layout
    });

export const updateLayout = ({
    layout,
    layoutId
}: {
    layout: LayoutCreateRequest;
    layoutId: string;
}) =>
    httpRequest<LayoutCreateRequest, LayoutResponse>({
        url: `${layoutUrl}/${layoutId}`,
        method: HttpMethod.Put,
        body: layout
    });

export const createLayoutAndDevices = (layout: SaveLayout) => async (authToken: string) => {
    const layoutResult = await createLayout(layout)(authToken);
    return await handleLayoutSaveResponse(layoutResult, layout.devices)(authToken);
};

export const updateLayoutAndDevices =
    ({ layout: { devices, ...layout }, layoutId }: { layout: SaveLayout; layoutId: string }) =>
    async (authToken: string) => {
        const layoutResult = await updateLayout({
            layout,
            layoutId
        })(authToken);
        return await handleLayoutSaveResponse(layoutResult, devices)(authToken);
    };

export const deleteLayoutIfNotUsedByGateways = (layoutId: string) => async (authToken: string) => {
    const { layoutsListScreen, punctuation } = useLanguage();

    const gatewaysResult = await getGateways({ layoutIds: [layoutId] })(authToken);
    if (gatewaysResult.data && gatewaysResult.data.length > 0) {
        return {
            isSuccess: false,
            isFailure: true,
            status: 409,
            data: undefined,
            errorCode:
                layoutsListScreen.cannotDeleteLayoutError +
                gatewaysResult.data.map((g) => g.name).join(punctuation.commaDelimiter)
        };
    }

    return await deleteLayout(layoutId)(authToken);
};

const handleLayoutSaveResponse =
    (layoutResult: ApiResult<LayoutResponse>, devices: LocalDevice[]) =>
    async (authToken: string) => {
        if (layoutResult.isFailure || !layoutResult.data) {
            return layoutResult;
        }

        if (!(devices.length > 0)) {
            // if no devices need to be created, success is sufficient
            return layoutResult;
        }

        const devicesWithLayoutId = devices.map((d) => ({
            ...d,
            layoutId: layoutResult.data!.id
        }));
        const devicesResult = await saveDevices(devicesWithLayoutId)(authToken);
        if (devicesResult.isFailure) {
            return {
                ...devicesResult,
                data: undefined
            } as ApiResult<LayoutResponse>;
        }
        return layoutResult;
    };
