import { getInternalUsersAndMappings } from '../../../api/internalUserApi';
import { useLoad } from '../../../hooks/requestHooks';
import { useDataSource } from '../../../hooks/useDataSource';
import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import InternalUsersList from './internalUsersList';

const InternalUsersListPage = () => {
    const { internalUserListScreen } = useLanguage();
    const {
        pslsSource: { loading: pslsLoading, error: pslsError, data: psls }
    } = useDataSource();
    const { loading, error, data, setData } = useLoad(getInternalUsersAndMappings);

    return (
        <DefaultPageLayout
            pageHeader={internalUserListScreen.header}
            loading={loading || pslsLoading}
            error={error || pslsError}
        >
            {data && psls && (
                <InternalUsersList internalUsers={data} psls={psls} setInternalUsers={setData} />
            )}
        </DefaultPageLayout>
    );
};

export default InternalUsersListPage;
