import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import EnrolledGatewaysList from './enrollmentsList';

const EnrollmentsListPage = () => {
    const { enrollmentsListScreen } = useLanguage();
    return (
        <DefaultPageLayout
            pageHeader={enrollmentsListScreen.header}
            loading={false}
            error={undefined}
        >
            <EnrolledGatewaysList />
        </DefaultPageLayout>
    );
};

export default EnrollmentsListPage;
