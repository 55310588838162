import { faPen, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { Stack, Typography, Button, Alert, Dialog } from '@mui/material';
import { GridRowId, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { defaultTopic } from '../../../../defaultValues';
import { useLanguage } from '../../../../hooks/useLanguage';
import { Topic } from '../../../../types/deviceDefinitionTypes';
import SortableTable from '../../../common/sortableTable';
import VerticalStack from '../../../common/verticalStack';
import TopicForm from './topicForm';

const TopicEditor = ({
    topics,
    onTopicsChange
}: {
    topics: Topic[];
    onTopicsChange: (topics: Topic[]) => void;
}) => {
    const { topicValidationErrors, topicDefinition, sortableTable } = useLanguage();

    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [topicToEdit, setTopicToEdit] = useState<Topic>();
    const [topicIdsToDelete, setTopicIdsToDelete] = useState<GridRowId[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: topicDefinition.nameColumn,
            flex: 1
        },
        {
            field: 'route',
            headerName: topicDefinition.routeColumn,
            flex: 1
        },
        {
            field: 'isCommand',
            headerName: topicDefinition.typeColumn,
            valueFormatter: (params) =>
                params.value === true
                    ? topicDefinition.commandTypeName
                    : topicDefinition.telemetryTypeName,
            flex: 1
        }
    ];

    const updateRow = (topic: Topic) => {
        const otherTopics =
            topicToEdit && isEdit ? topics.filter((t) => t.name !== topicToEdit.name) : topics;

        const otherTopicNames = otherTopics.map((t) => t.name);
        if (otherTopicNames.includes(topic.name)) {
            setError(topicValidationErrors.namesNotUnique);
            return;
        }

        onTopicsChange([...otherTopics, { ...topic }]);
        setError('');
    };

    const startEdit = (rowId: GridRowId) => {
        setIsEdit(true);
        setTopicToEdit(topics.find((t) => rowId === t.name));
    };

    const startAdd = () => {
        setIsEdit(false);
        setTopicToEdit(defaultTopic);
    };

    const deleteRow = (rowId: GridRowId) => {
        onTopicsChange(topics.filter((t) => t.name !== rowId.toString()));
    };

    const onRowSelect = (selectionModel: GridSelectionModel) => {
        setTopicIdsToDelete(selectionModel);
    };

    const deleteMultipleRows = () => {
        onTopicsChange(topics.filter((t) => !topicIdsToDelete.includes(t.name)));
    };

    return (
        <VerticalStack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">{topicDefinition.header}</Typography>
                <Stack direction="row" spacing={2} marginBottom={3} marginTop={2}>
                    <Button variant="contained" onClick={startAdd}>
                        {topicDefinition.addTopicButton}
                    </Button>
                    <Button variant="contained" onClick={deleteMultipleRows}>
                        {topicDefinition.deleteSelectedTopicsButton}
                    </Button>
                </Stack>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <SortableTable
                rows={topics}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: startEdit, icon: faPen },
                    {
                        label: sortableTable.deleteButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus
                    }
                ]}
                getRowId={(row) => row.name}
                onRowSelect={onRowSelect}
            />
            <Dialog
                open={topicToEdit !== undefined}
                fullWidth={true}
                PaperProps={{
                    sx: {
                        height: 800
                    }
                }}
            >
                {topicToEdit && (
                    <TopicForm
                        isEdit={isEdit}
                        updateTopic={updateRow}
                        handleClose={() => setTopicToEdit(undefined)}
                        defaultValues={topicToEdit}
                        topics={topics}
                    />
                )}
            </Dialog>
        </VerticalStack>
    );
};

export default TopicEditor;
