export const UIntMinimum = 0;

export const xShortString = 25;
export const shortString = 50;
export const mediumString = 150;
export const filepathString = 256;
export const longString = 300;
export const xLongString = 4000;

export const moxaNameFormat = new RegExp(`^[a-zA-Z-._~0-9]+$`);
export const ipAddressWithPortFormat = new RegExp(
    `^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}:([1-9][0-9]{0,2})$`
);
export const ipAddressFormat = new RegExp(`^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$`);
export const mqttTopicFormat = new RegExp('^([^\\/])[^\\s$#+]+(?<!\\/)$');
export const internalEmailFormat = new RegExp(`^[A-Za-z0-9._%+-]+@[Hh]alliburton\\.com$`);
