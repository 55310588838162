import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createEnrollment } from '../../../api/enrollmentApi';
import { getIdScope } from '../../../api/idScopeApi';
import { getTagsJsonFromLocalTags } from '../../../helperFunctions';
import { useLoad, useRequest } from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions, routes } from '../../../pages';
import { LocalEnrollment } from '../../../types/enrollmentTypes';
import DefaultPageLayout from '../../common/defaultPageLayout';
import EnrollmentForm from './enrollmentForm';
import IdScopeDisplay from './idScopeDisplay';

const CreateEnrollmentPage = () => {
    const { createEnrollmentScreen } = useLanguage();
    const navigate = useNavigate();
    const enrolledGatewaysRoute = `/${routes.gateways}/${extensions.enrolled}`;

    const onCreateSuccess = () => {
        navigate(enrolledGatewaysRoute);
    };

    const {
        executeRequest,
        loading: createLoading,
        error: createError
    } = useRequest(createEnrollment, onCreateSuccess);

    const { data: idScopeData, loading: idScopeLoading, error: idScopeError } = useLoad(getIdScope);

    const onSubmit = (localEnrollment: LocalEnrollment) => {
        executeRequest({
            registrationId: localEnrollment.registrationId,
            serialNumber: localEnrollment.serialNumber,
            tpmEndorsementKey: localEnrollment.tpmEndorsementKey,
            tagsJson: getTagsJsonFromLocalTags(localEnrollment.localTags)
        });
    };

    return (
        <Stack direction="row" spacing={7}>
            <DefaultPageLayout
                pageHeader={createEnrollmentScreen.header}
                loading={createLoading || idScopeLoading}
                error={createError || idScopeError}
            >
                <EnrollmentForm
                    defaultValues={{
                        registrationId: '',
                        serialNumber: '',
                        tpmEndorsementKey: '',
                        localTags: []
                    }}
                    onSubmit={onSubmit}
                    submitButtonText={createEnrollmentScreen.saveButton}
                    onCancel={() => navigate(enrolledGatewaysRoute)}
                />
            </DefaultPageLayout>
            {idScopeData?.idScope && (
                <Stack paddingTop={7}>
                    <IdScopeDisplay idScope={idScopeData} />
                </Stack>
            )}
        </Stack>
    );
};

export default CreateEnrollmentPage;
