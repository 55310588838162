import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { oktaIssuer, oktaClientId } from '../../environmentVariables';
import { LoginCallback, Security } from '@okta/okta-react';

const oktaAuth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email', 'AppRole']
});

export const OktaProvider = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    // Auth code returned from Okta. If this is in the URL, Okta has redirected
    // back to us (using PKCE flow) but we haven't processed that redirect yet
    // need to pass to Okta LoginCallback component to redeem code for token
    const code = searchParams.get('code');

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {code ? <LoginCallback /> : children}
        </Security>
    );
};
