// enums

import { FullEntityFields } from './apiTypes';
import { Asset } from './assetTypes';

export enum Timezone {
    Hawaii = 'Pacific/Honolulu',
    Alaska = 'America/Anchorage',
    Pacific = 'America/Los_Angeles',
    Mountain = 'America/Denver',
    Central = 'America/Chicago',
    Eastern = 'America/New_York'
}

// API types

export interface GatewayUpdateRequest {
    name: string;
    layoutId: string;
    assetInfo: Asset;
    enableRtsForwarding: boolean;
    wellId?: string;
    wellName?: string;
    timezone?: string;
    operator?: string;
    spudDate?: string;
    longitude?: string;
    latitude?: string;
}

export interface GatewayCreateRequest extends GatewayUpdateRequest {
    azureDeviceId: string;
    serialNumber: string;
    equipmentNumber: string;
    costCenter: string;
}

export type GatewayResponse = GatewayCreateRequest & FullEntityFields;
