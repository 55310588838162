import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidebarIconProps } from '../components/navigation/sidebarButton';
import { themeColors } from '../theme';

const UsersIcon = ({ disabled }: SidebarIconProps) => {
    return (
        <FontAwesomeIcon
            icon={faUsers}
            color={disabled ? themeColors.colorPalettePrimary05 : '#238CAD'}
        />
    );
};

export default UsersIcon;
