import { baseApiUrl } from '../environmentVariables';
import { IotEdgeDeviceResponse } from '../types/iotEdgeTypes';
import { httpRequest } from './sharedApi';

const iotEdgeUrl = `${baseApiUrl}/iotEdgeDevices`;

export const getIotEdgeDevices = ({
    pageNumber,
    pageSize,
    isCommissioned
}: {
    pageNumber?: number;
    pageSize?: number;
    isCommissioned?: boolean;
}) => {
    const searchParams = new URLSearchParams();
    if (pageNumber !== undefined) {
        searchParams.append('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
        searchParams.append('pageSize', pageSize.toString());
    }
    if (isCommissioned !== undefined) {
        searchParams.append('isCommissioned', isCommissioned.toString());
    }
    const queryString = searchParams.toString() !== '' ? `?${searchParams.toString()}` : '';

    return httpRequest<undefined, IotEdgeDeviceResponse[]>({
        url: `${iotEdgeUrl}${queryString}`
    });
};

export const getIotEdgeDeviceCount = ({ isCommissioned }: { isCommissioned?: boolean }) => {
    const searchParams = new URLSearchParams();
    if (isCommissioned !== undefined) {
        searchParams.append('isCommissioned', isCommissioned.toString());
    }
    const queryString = searchParams.toString() !== '' ? `?${searchParams.toString()}` : '';
    return httpRequest<undefined, number>({ url: `${iotEdgeUrl}/count${queryString}` });
};
