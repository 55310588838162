import {
    LocalDeviceDefinition,
    LocalMoxaModbusRegister,
    MoxaModbusConfiguration,
    MqttConfiguration,
    OpcUAConfiguration,
    Protocol,
    Scaling
} from './types/deviceDefinitionTypes';
import { LocalTag } from './types/enrollmentTypes';
import { PslResponse } from './types/pslTypes';
import { CreateAsset } from './types/userAssetMappingTypes';
import {UomClass} from "./types/uomTypes";

export const convertRegisters = (localRegisters: LocalMoxaModbusRegister[]) => {
    return localRegisters.map((r) => {
        const {
            slope,
            interceptOffset,
            pointSourceMin,
            pointSourceMax,
            pointTargetMin,
            pointTargetMax,
            ...baseRegister
        } = r;

        switch (r.scaling) {
            case Scaling.Disabled:
                return baseRegister;
            case Scaling.Intercept:
                return { ...baseRegister, slope: slope, interceptOffset: interceptOffset };
            case Scaling.Point:
                return {
                    ...baseRegister,
                    pointSourceMin: pointSourceMin,
                    pointSourceMax: pointSourceMax,
                    pointTargetMin: pointTargetMin,
                    pointTargetMax: pointTargetMax
                };
            default:
                return baseRegister;
        }
    });
};

export const getTagsJsonFromLocalTags = (localTags: LocalTag[]) => {
    let formattedKeyValuePairs = localTags.map((t) => `"${t.key}": "${t.value}"`);
    return `{${formattedKeyValuePairs.join(',')}}`;
};

export function checkFieldUniqueness<ObjectType, FieldType>(
    existingObjects: ObjectType[],
    accessObjectField: (o: ObjectType) => FieldType,
    value: FieldType,
    defaultValue: FieldType
) {
    const existingValues = existingObjects.map(accessObjectField);

    // if value equals the default value, then we're editing an existing object
    // rather than trying to add a new object with a duplicate field
    if (existingValues.includes(value) && value !== defaultValue) {
        return false;
    }

    return true;
}

export const compareAssets = (asset1: CreateAsset, asset2: CreateAsset) => {
    if (asset1.assetId < asset2.assetId) {
        return -1;
    }
    if (asset1.assetId > asset2.assetId) {
        return 1;
    }
    return 0;
};

export const serializeDeviceConfiguration = (deviceDefinition: LocalDeviceDefinition) => {
    switch (deviceDefinition.protocol) {
        case Protocol.MoxaModbus: {
            const registersWithScaling = convertRegisters(deviceDefinition.moxaModbusRegisters);

            const moxaConfiguration: MoxaModbusConfiguration = {
                moxaSlaveId: deviceDefinition.moxaModbusSlaveId,
                registers: registersWithScaling
            };
            return moxaConfiguration;
        }
        case Protocol.Mqtt: {
            const mqttConfiguration: MqttConfiguration = {
                signals: deviceDefinition.mqttSignals,
                mqttTopics: deviceDefinition.mqttTopics
            };
            return mqttConfiguration;
        }
        case Protocol.OpcUA:
            const opcUAConfiguration: OpcUAConfiguration = {
                nodes: deviceDefinition.opcUANodes,
                sessionRenewalRequired: deviceDefinition.opcUASessionRenewalRequired,
                sessionRenewalMinutes: deviceDefinition.opcUASessionRenewalMinutes,
                applicationCertificatePath: deviceDefinition.opcUAApplicationCertificatePath,
                securityEnabled: deviceDefinition.opcUASecurityEnabled
            };
            return opcUAConfiguration;
    }
};

export const getDefaultPsl = (psls: PslResponse[]) => (psls.length > 0 ? psls[0].id : '');
export const getPslNameFromId = (psls: PslResponse[], pslId: string) =>
    psls.find((psl) => psl.id === pslId)?.name ?? pslId;

export const isFloat = (value: number | string | null | undefined) => {
    if (value === undefined || value === null) {
        return false;
    }
    return !Object.is(parseFloat(value.toString()), NaN);
};

export const isInt = (value: number | string) => {
    return !Object.is(parseInt(value.toString()), NaN);
};

export const constructUnitList = (): string[] => {
    const localStorageKey = 'uomData';
    const data = localStorage.getItem(localStorageKey);
    if (!data) {
        console.error(`No data found in local storage under key: ${localStorageKey}`);
        return [];
    }

    const uomClasses: UomClass[] = JSON.parse(data);
    const unitList: Set<string> = new Set();

    uomClasses.forEach((uomClass) => {
        uomClass.unitItems.forEach((unitItem) => {
            unitList.add(unitItem.shortName);
        });
    });

    return Array.from(unitList);
};
