import { baseApiUrl } from '../environmentVariables';
import { ApiResult, HttpMethod, HttpStatusCode } from '../types/apiTypes';
import { UserRole } from '../types/authorizationTypes';
import { LoadInternalUser, SaveInternalUser, User, UserWithRole } from '../types/userTypes';
import { httpRequest } from './sharedApi';
import { getUserAssetMappings, updateUserAssetMappings } from './userAssetMappingApi';

const internalUsersUrl = `${baseApiUrl}/internalUsers`;

export const getInternalUsers = () =>
    httpRequest<undefined, UserWithRole[]>({
        url: internalUsersUrl
    });

export const getInternalUser = (userId: string) =>
    httpRequest<undefined, UserWithRole>({
        url: `${internalUsersUrl}/${userId}`
    });

export const searchInternalUser = (email: string) =>
    httpRequest<string, User>({
        url: `${internalUsersUrl}/search/${email}`
    });

export const setInternalUserRole = ({ userId, userRole }: { userId: string; userRole: UserRole }) =>
    httpRequest<undefined, undefined>({
        method: HttpMethod.Put,
        url: `${internalUsersUrl}/${userId}/${userRole}`
    });

export const removeInternalUserRole = (userId: string) =>
    httpRequest<string, undefined>({
        method: HttpMethod.Delete,
        url: `${internalUsersUrl}/${userId}`
    });

export const getInternalUserAndMappings = (userId: string) => async (authToken: string) => {
    const userPromise = getInternalUser(userId)(authToken);
    const mappingsPromise = getUserAssetMappings(userId)(authToken);

    const [userResult, mappingsResult] = await Promise.all([userPromise, mappingsPromise]);

    if (userResult.isFailure || userResult.data === undefined) {
        return { ...userResult, data: undefined } as ApiResult<LoadInternalUser>;
    }
    if (mappingsResult.isFailure || mappingsResult.data === undefined) {
        return { ...userResult, data: undefined } as ApiResult<LoadInternalUser>;
    }

    const result: ApiResult<LoadInternalUser> = {
        ...userResult,
        data: { ...userResult.data, psls: mappingsResult.data }
    };
    return result;
};

export const getInternalUsersAndMappings = () => async (authToken: string) => {
    const usersResult = await getInternalUsers()(authToken);

    if (usersResult.isFailure || usersResult.data === undefined) {
        return { ...usersResult, data: undefined } as ApiResult<LoadInternalUser[]>;
    }

    if (usersResult.data.length === 0) {
        return { ...usersResult, data: [] };
    }

    const assetPromises = usersResult.data.map((u) => getUserAssetMappings(u.id)(authToken));
    const assetResults = await Promise.all(assetPromises);
    const failedAssetGet = assetResults.find((r) => r.isFailure || r.data === undefined);
    if (failedAssetGet) {
        return { ...failedAssetGet, data: undefined } as ApiResult<LoadInternalUser[]>;
    }

    // assetResults will have the same number of entries as usersResult, as it was created using
    // a map on userResult
    const usersWithMappings: LoadInternalUser[] = usersResult.data.map((element, index) => {
        return {
            ...element,
            psls: assetResults[index].data ?? []
        };
    });

    var apiResult: ApiResult<LoadInternalUser[]> = {
        status: HttpStatusCode.OK,
        isSuccess: true,
        isFailure: false,
        data: usersWithMappings
    };

    return apiResult;
};

export const updateUserRoleAndMappings =
    ({
        updateUserRole,
        userAndAssets
    }: {
        updateUserRole: boolean;
        userAndAssets: SaveInternalUser;
    }) =>
    async (authToken: string) => {
        if (updateUserRole) {
            const roleResult = await setInternalUserRole({
                userId: userAndAssets.id,
                userRole: userAndAssets.userRole
            })(authToken);

            if (
                roleResult.isFailure ||
                (userAndAssets.assetsToAdd.length === 0 &&
                    userAndAssets.assetsToDelete.length === 0)
            ) {
                // if there are no assets to update, we should not call the asset endpoints
                return roleResult;
            }
        }

        const assetsResult = await updateUserAssetMappings(
            userAndAssets.id,
            userAndAssets.assetsToAdd,
            userAndAssets.assetsToDelete
        )(authToken);

        // need to make the result match the return of the role result
        return {
            ...assetsResult,
            data: undefined
        } as ApiResult<undefined>;
    };
