import { useLanguage } from '../../../hooks/useLanguage';
import DefaultPageLayout from '../../common/defaultPageLayout';
import AvailableGatewaysList from './availableGatewaysList';

const AvailableGatewaysListPage = () => {
    const { availableGatewayScreen } = useLanguage();
    return (
        <DefaultPageLayout
            pageHeader={availableGatewayScreen.header}
            loading={false}
            error={undefined}
        >
            <AvailableGatewaysList />
        </DefaultPageLayout>
    );
};

export default AvailableGatewaysListPage;
