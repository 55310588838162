import { SvgIcon } from '@mui/material';

const FileIcon = () => {
    return (
        <SvgIcon
            width="14px"
            height="19px"
            viewBox="0 0 14 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.16667 4.95833V0H0.875C0.390104 0 0 0.390104 0 0.875V17.7917C0 18.2766 0.390104 18.6667 0.875 18.6667H13.125C13.6099 18.6667 14 18.2766 14 17.7917V5.83333H9.04167C8.56042 5.83333 8.16667 5.43958 8.16667 4.95833ZM14 4.44427V4.66667H9.33333V0H9.55573C9.78906 0 10.0115 0.0911458 10.1755 0.255208L13.7448 3.82812C13.9089 3.99219 14 4.21458 14 4.44427Z"
                fill="#CE0E2D"
            />
        </SvgIcon>
    );
};

export default FileIcon;
