import { styled, Tab, TabProps } from '@mui/material';
import { lightMode, themeColors } from '../../theme';
import FileIcon from '../../icons/fileIcon';
import { useNavigate } from 'react-router-dom';
import { AuthorizationProps } from '../../types/authorizationTypes';
import { withAuthorizationDisplay } from '../common/withAuthorization';
import { navBarMinHeight } from '../../constants/styling';

const StyledTab = styled(Tab)(({ theme }) => {
    const color =
        theme.palette.mode === lightMode
            ? themeColors.colorPalettePrimary09
            : themeColors.colorPalettePrimary04;
    return {
        borderRadius: theme.spacing(0),
        width: theme.spacing(50),
        minHeight: theme.spacing(navBarMinHeight),
        justifyContent: 'left',
        color,
        '&.MuiTab-labelIcon': {
            display: 'flex',
            gap: theme.spacing(6)
        },
        '&.Mui-selected': {
            color,
            backgroundColor: theme.palette.secondary.main
        }
    };
});

const NavigationTab = (props: AuthorizationProps<TabProps>) => {
    const navigate = useNavigate();
    const TabWithAuth = withAuthorizationDisplay(StyledTab);

    return (
        <TabWithAuth
            {...props}
            disableRipple
            iconPosition="start"
            icon={<FileIcon />}
            onClick={() => navigate(props.value)}
        />
    );
};

export default NavigationTab;
