import { SignalUnitType } from '../types/signalUnitTypes';

const textResource = {
    lifecycle: {
        loadingLabel: 'Loading...',
        notFoundHeader: 'Page Not Found',
        notFoundExplanation: 'Return to the previous page with the back button.',
        notAuthorizedHeader: 'User not authorized to view page.',
        notAuthorizedExplanation: 'Return to the previous page with the back button.'
    },
    oktaProvider: {
        errorPrefix: 'Error: ',
        userActionPrefix: 'Please send an email to ',
        userActionSuffix: ' for further assistance.',
        logoutUser: 'Logout User'
    },
    addLocalTagForm: {
        header: 'Add Tag'
    },
    editLocalTagForm: {
        header: 'Edit Tag'
    },
    localTagForm: {
        keyFieldLabel: 'Key',
        valueFieldLabel: 'Value',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel',
        gatewayTagsHeader: 'Custom Gateway Tags',
        gatewayTagsTooltip:
            'Additional custom tags to add to the device twin of a gateway using this enrollment.',
        keysNotUniqueError:
            'Provided key matches the key of an existing tag. Please choose a different key.',
        customTagKeyNotAllowedError: 'Key is already in use and not available for custom tags: '
    },
    addRegisterForm: {
        header: 'Add Register'
    },
    editRegisterForm: {
        header: 'Edit Register'
    },
    addSignalForm: {
        header: 'Define New Signal'
    },
    editSignalForm: {
        header: 'Edit Signal'
    },
    addTopicForm: {
        header: 'Define New Topic'
    },
    editTopicForm: {
        header: 'Edit Topic'
    },
    addNodeForm: {
        header: 'Add Node'
    },
    editNodeForm: {
        header: 'Edit Node'
    },
    httpErrors: {
        badRequest: 'Invalid data sent. Check data for accuracy.',
        unauthorized: 'Authentication failed. Try logging out and back in.',
        forbidden: 'No permission to access. Ask someone else to perform this action.',
        notFound: 'Not found. Refresh to confirm resource is present.',
        conflict:
            'Requested change conflicts with current state. Refresh to confirm current state.',
        serverError: 'Action failed. Contact technical support.',
        unknown: 'Unknown error. Contact technical support.',
        backendUnreachable: 'Could not reach backend. Contact technical support.',
        success: 'Request succeeded.'
    },
    registerForm: {
        nameFieldLabel: 'Name',
        dataTypeFieldLabel: 'Data Type',
        addressFieldLabel: 'Address',
        quantityFieldLabel: 'Quantity',
        isEventFieldLabel: 'Is Event',
        eventClassFieldLabel: 'Event Class',
        isReadOnlyFieldLabel: 'Read Only',
        swapFieldLabel: 'Endian Swap',
        dataSizeFieldLabel: 'Size',
        dataUnitTypeFieldLabel: 'Unit Type',
        dataUnitFieldLabel: 'Unit',
        offsetFieldLabel: 'Offset',
        scalingFieldLabel: 'Scaling',
        slopeFieldLabel: 'Slope',
        interceptOffsetFieldLabel: 'Intercept Offset',
        pointSourceMinFieldLabel: 'Point Source Min',
        pointSourceMaxFieldLabel: 'Point Source Max',
        pointTargetMinFieldLabel: 'Point Target Min',
        pointTargetMaxFieldLabel: 'Point Target Max',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel',
        duplicateNameError:
            'Provided name matches the name of an existing register. Please choose a different name.',
        nameTooltip: 'Name of this register. Register names must be unique on a device.',
        dataTypeTooltip: 'The type of the value being read.',
        addressTooltip:
            'The register # to access, e.g. 40001. If reading across multiple registers, this should be the # of the first register.\n\nNo prefix: Read or write coil\n10000/100000: Read discrete input (1 bit, read only)\n20000/200000: Unused\n30000/300000: Read input register (16 bit, read only)\n40000/400000: Read or write holding register (16 bit)',
        quantityTooltip:
            'The number of registers to access, starting with the register # set in "Address". For a data type of "Double", set this to either 2 or 4. For all other data types, set this to at least 1.',
        isEventTooltip: 'If a change in this value should trigger a message, set to "True".',
        eventClassTooltip:
            'This determines the type of message sent on value change if "IsEvent" is set to "True".',
        readOnlyTooltip:
            'The access of the register. To enable writing to the register, this must be "False".',
        swapTooltip:
            'Specifies how bytes should be swapped when processing the data from the downstream device.',
        dataSizeTooltip:
            'For a data type of "None" or "String", set this to the appropriate length in bytes for the value. Each character in a string is one byte.',
        dataUnitTooltip: 'The measurement unit for this value.',
        dataUnitTypeTooltip: 'This field is used to filter selectable units.',
        offsetTooltip:
            'The number of bytes to skip before beginning to access the value. In most cases, this will be 0.',
        scalingTooltip: 'If enabled, linearly scales the read value based on entered parameters.',
        pointSourceMinTooltip: 'The minimum read value for point of the linear scaling function.',
        pointSourceMaxTooltip: 'The maximum read value for point of the linear scaling function.',
        pointTargetMinTooltip: 'The minimum scaled value for point of the linear scaling function.',
        pointTargetMaxTooltip: 'The maximum scaled value for point of the linear scaling function.',
        slopeTooltip: 'The slope of the linear scaling function.',
        interceptOffsetTooltip: 'The y-intercept of the linear scaling function.'
    },
    opcUANodeForm: {
        duplicateNameError:
            'Provided name matches the name of an existing node. Please choose a different name.',
        nameFieldLabel: 'Name',
        nodeIdFieldLabel: 'Node ID',
        namespaceIdFieldLabel: 'Namespace ID',
        nodeIdTypeFieldLabel: 'Node ID Type',
        isReadOnlyFieldLabel: 'Read Only',
        dataTypeFieldLabel: 'Data Type',
        isEventFieldLabel: 'Is Event',
        eventClassFieldLabel: 'Event Class',
        dataUnitTypeFieldLabel: 'Unit Type',
        dataUnitFieldLabel: 'Unit',
        nameTooltip:
            "The display name of the node, and the name that will be used for reporting this signal's data. Node names must be unique on a device.",
        nodeIdTooltip:
            'The Id of the node on the server. This can be a string, int, guid, or base64string.',
        namespaceIdTooltip:
            'The integer Id of the expected namespace of the node. 0 for server/system parameters, frequently 2 for other cases.',
        nodeIdTypeTooltip: 'The type of the Node Id entered above.',
        isReadOnlyTooltip:
            'The access of the node. To enable writing to the node, this must be "False".',
        dataTypeTooltip: 'The type of the value being read.',
        isEventTooltip: 'If a change in this value should trigger a message, set to "True".',
        eventClassTooltip:
            'This determines the type of message sent on value change if "IsEvent" is set to "True".',
        dataUnitTooltip: 'The measurement unit for this value.',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel'
    },
    signalForm: {
        nameFieldLabel: 'Name',
        dataTypeFieldLabel: 'Data Type',
        isEventFieldLabel: 'Is Event',
        eventClassFieldLabel: 'Event Class',
        isReadOnlyFieldLabel: 'Read Only',
        dataUnitTypeFieldLabel: 'Unit Type',
        dataUnitFieldLabel: 'Unit',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel',
        duplicateNameError:
            'Provided name matches the name of an existing signal. Please choose a different name.',
        nameTooltip: 'Name of this signal. Signal names must be unique on a device definition.',
        dataTypeTooltip: 'The type of the value being read.',
        isEventTooltip: 'If a change in this value should trigger a message, set to "True".',
        eventClassTooltip:
            'This determines the type of message sent on value change if "IsEvent" is set to "True".',
        readOnlyTooltip:
            'The access of the signal. To enable writing to the signal, this must be "False".',
        dataUnitTooltip: 'The measurement unit for this value.',
        dataUnitTypeTooltip: 'This field is used to filter selectable units.'
    },
    topicForm: {
        nameFieldLabel: 'Name',
        routeFieldLabel: 'Route',
        groupIdFieldLabel: 'Group ID',
        typeFieldLabel: 'Type',
        telemetryOption: 'Telemetry',
        commandOption: 'Command',
        mqttSignalConfigurationElementIdsLabel: 'Payload',
        searchBoxLabel: 'Search',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel',
        nameTooltip: 'Name of this topic. Topic names must be unique on a device definition.',
        routeTooltip:
            'Custom route for this MQTT topic. This will be appended to the end of other route information to form the final MQTT topic route.',
        groupIdTooltip: 'Sparkplug Group ID for this MQTT topic.',
        typeTooltip:
            'The type of MQTT topic. A Telemetry topic denotes read-only data that travels passively from device to cloud, while a Command topic is used to control a device remotely.',
        mqttSignalConfigurationElementIdsTooltip:
            'Search the collection of available variables (signals) to add to this topic.',
        duplicateNameError:
            'Provided name matches the name of an existing topic. Please choose a different name.',
        duplicateRouteError:
            'Provided route matches the route of an existing topic. Please choose a different route.',
        invalidRouteError:
            "Invalid character(s) supplied in route. Route cannot start or end with '/' or contain '$', '#', '+', or spaces.",
        invalidGroupIdError:
            "Invalid character(s) supplied in group ID. Group ID cannot start or end with '/' or contain '$', '#', '+', or spaces.",
        atLeastOneSignalRequiredError:
            'At least one signal is required in the topic payload. If no options are listed, please add a signal to this definition before creating this topic.'
    },
    topicSignalsTable: {
        searchBoxSampleText: 'Temperature 1, Fault 1, Pressure 1',
        signalNameColumnLabel: 'Name',
        signalDataTypeColumnLabel: 'Data Type',
        useSearchBoxMessage: 'Use the Search box to add variables to the payload',
        editButton: 'Edit',
        deleteButton: 'Delete',
        noRows: 'No Rows'
    },
    moxaCommunicationDefinition: {
        serverIdLabel: 'Server ID',
        nameColumn: 'Name',
        addressColumn: 'Address',
        dataTypeColumn: 'Data Type',
        readonlyColumn: 'Read Only',
        addRegisterButton: 'Add Register',
        deleteSelectedRegistersButton: 'Delete Selected Registers',
        serverIdTooltip:
            'Server ID for a MoxaModbusTCP device represented by this device definition.',
        exportRegistersFilenameSuffix: '_Registers',
        registerNamesNotUnique: 'Register names must be unique',
        importOverwriteWarning:
            'Importing registers will overwrite all existing register data. Are you sure you would like to continue?'
    },
    opcUACommunicationDefinition: {
        sessionRenewalRequiredLabel: 'Session Renewal Required',
        sessionRenewalMinutesLabel: 'Session Renewal (Minutes)',
        applicationCertificatePathLabel: 'Application Certificate Path',
        securityEnabledLabel: 'Security Enabled',
        sessionRenewalRequiredTooltip:
            'If it is desired to restart the session after a certain duration, set this to True. May be used to avoid timeouts in some cases, but is not typically required.',
        sessionRenewalMinutesTooltip:
            'The duration in minutes after the session is opened that it should be restarted.',
        applicationCertificatePathTooltip:
            'True if using certificates to authenticate with the server; not currently supported. If you are interested in this feature, please reach out the RPEdge team.',
        securityEnabledTooltip:
            'The path at which the application certificate should be stored on the Edge device. If you are interested in this feature, please reach out to the RPEdge team.',
        nameColumn: 'Name',
        dataTypeColumn: 'Data Type',
        nodeIdColumn: 'Node ID',
        nodeAddressColumn: 'Node Address',
        exportNodesFilenameSuffix: '_Nodes',
        importOverwriteWarning:
            'Importing nodes will overwrite all existing node data. Are you sure you would like to continue?',
        addNodeButton: 'Add Node',
        deleteSelectedNodesButton: 'Delete Selected Nodes'
    },
    signalDefinition: {
        header: 'Signals',
        nameColumn: 'Name',
        dataTypeColumn: 'Data Type',
        addSignalButton: 'Add Signal',
        deleteSelectedSignalsButton: 'Delete Selected Signals',
        importOverwriteWarning:
            'Importing signals will overwrite all existing signal data. Are you sure you would like to continue?',
        signalNamesNotUnique: 'Signal names must be unique',
        exportSignalsFilenameSuffix: '_Signals'
    },
    topicDefinition: {
        header: 'Topics',
        nameColumn: 'Name',
        routeColumn: 'Route',
        typeColumn: 'Type',
        commandTypeName: 'Command',
        telemetryTypeName: 'Telemetry',
        addTopicButton: 'Add Topic',
        deleteSelectedTopicsButton: 'Delete Selected Topics',
        exportTopicsFilenameSuffix: '_Topics'
    },
    moxaRegisterFields: {
        name: 'name',
        dataType: 'dataType',
        dataUnit: 'dataUnit',
        address: 'address',
        quantity: 'quantity',
        eventClass: 'eventClass',
        dataSize: 'dataSize',
        offset: 'offset',
        readOnly: 'readOnly',
        isEvent: 'isEvent',
        scaling: 'scaling',
        swap: 'endianSwap'
    },
    moxaRegisterValidationErrors: {
        invalidInteger: 'Invalid integer value for ',
        invalidDataType: 'Data type not found',
        invalidEventClass: 'Event class not found',
        invalidScaling: 'Scaling not found',
        invalidSwap: 'Endian swap mode not found',
        invalidBooleanPrefix: 'Invalid ',
        invalidBooleanSuffix: ' boolean value',
        invalidUint: 'Invalid uint value for ',
        invalidUnitPrefix: 'Invalid unit ',
        invalidUnitSuffix: ' provided for register ',
        registerErrors: ' errors: ',
        invalidNamePrefix: 'Invalid name: ',
        invalidNameSuffix: '. ',
        nameInvalidCharacter: 'Only the characters a-zA-Z-._~0-9 are acceptable',
        namesNotUnique: 'Register names must be unique',
        required: ' is required',
        mustBeNull: ' cannot be declared ',
        dataSizeNullCondition: ' if dataType is not string or none',
        dataSizeRequiredCondition: ' if dataType is string or none',
        maximumLength: ' maximum length: ',
        invalidQuantityForDouble:
            'For registers with data type Double, quantity must be either 2 or 4',
        invalidDataUnitPrefix: 'Invalid unit '
    },
    signalValidationErrors: {
        namesNotUnique: 'Signal names must be unique',
        invalidDataUnitPrefix: 'Invalid unit ',
        cannotDeleteSignalUsedByTopicErrorPrefix: 'Signal ',
        cannotDeleteSignalUsedByTopicErrorPostfix:
            ' is in use by 1 or more topics. Please remove this signal from all topics before deleting.'
    },
    topicValidationErrors: {
        namesNotUnique: 'Topic names must be unique',
        noSignalFoundWithId: 'No signal found on this device definition with id '
    },
    opcUANodeFields: {
        name: 'name',
        nodeId: 'nodeId',
        namespaceId: 'namespaceId',
        nodeIdType: 'nodeIdType',
        readOnly: 'readOnly',
        dataType: 'dataType',
        isEvent: 'isEvent',
        eventClass: 'eventClass',
        dataUnit: 'dataUnit'
    },
    opcUANodeValidationErrors: {
        namesNotUnique: 'Node names must be unique',
        nodeAddressesNotUnique:
            'Node addresses (i.e. combinations of Namespace ID, Node ID, and Node ID Type) must be unique',
        invalidSessionRenewalMinutesPrefix: 'Session Renewal must be greater than ',
        invalidSessionRenewalMinutesSuffix: ' minutes',
        sessionRenewalMinutesRequired:
            'Session Renewal (Minutes) is required when Session Renewal Required is true',
        invalidNamespaceId: 'Namespace ID must be greater than 0',
        invalidBooleanPrefix: 'Invalid ',
        invalidBooleanSuffix: ' boolean value',
        invalidUint: 'Invalid uint value for ',
        invalidDataUnitPrefix: 'Invalid unit ',
        invalidDataUnitSuffix: ' provided for node ',
        nodeErrors: ' errors: ',
        invalidNamePrefix: 'Invalid name: ',
        invalidNameSuffix: '. ',
        maximumLength: ' maximum length: ',
        required: ' is required',
        invalidNodeId: 'Invalid node ID provided for node ID type ',
        invalidNodeForNodeAddressPrefix:
            'Invalid node supplied when getting node address for node with name ',
        mustHaveAtLeastOneNode: 'At least 1 node must be supplied'
    },
    booleanDisplay: {
        true: 'Yes',
        false: 'No'
    },
    enumDisplay: {
        dataType: {
            none: 'None',
            boolean: 'Boolean',
            integer: 'Integer',
            enum: 'Enum',
            double: 'Double',
            string: 'String',
            uint: 'UInt',
            usint: 'UsInt'
        },
        protocol: {
            moxaModbus: 'MoxaModbusTCP',
            mqtt: 'MQTT',
            opcUA: 'OPC UA'
        },
        eventClass: {
            notify: 'Notify',
            warning: 'Warning',
            alarm: 'Alarm',
            fault: 'Fault'
        },
        swap: {
            none: 'None',
            byte: 'Byte',
            word: 'Word',
            byteWord: 'ByteWord'
        },
        scaling: {
            disabled: 'Disabled',
            intercept: 'Intercept',
            point: 'Point'
        },
        timezone: {
            hawaii: 'Hawaii-Aleutian',
            alaska: 'Alaska',
            pacific: 'Pacific',
            mountain: 'Mountain',
            central: 'Central',
            eastern: 'Eastern'
        },
        enrollmentStatus: {
            unassigned: 'Unassigned',
            assigning: 'Assigning',
            assigned: 'Assigned',
            failed: 'Failed',
            disabled: 'Disabled',
            waitingOnDevice: 'Waiting on Device',
            unknown: 'Unknown'
        },
        userRole: {
            externalUser: 'External User',
            internalViewer: 'Internal Viewer',
            internalUser: 'Internal User',
            admin: 'Admin'
        },
        connectionStatus: {
            unknown: 'Unknown',
            connected: 'Connected',
            disconnected: 'Disconnected'
        },
        moduleRuntimeStatus: {
            unknown: 'Unknown',
            backoff: 'Backoff',
            running: 'Running',
            unhealthy: 'Unhealthy',
            stopped: 'Stopped',
            failed: 'Failed'
        },
        assetType: {
            psl: 'PSL'
        },
        opcUANodeIdType: {
            string: 'String',
            base64String: 'Base 64 String',
            integer: 'Integer',
            guid: 'Guid'
        }
    },
    form: {
        required: 'Required',
        minimum: 'Minimum value is ',
        floatTypeRequired: 'Float type required',
        intTypeRequired: 'Int type required',
        trueOptionLabel: 'True',
        falseOptionLabel: 'False',
        tooLong: 'Maximum length exceeded',
        optionalField: ' (optional)',
        unsavedChanges: 'Unsaved changes'
    },
    navigationBar: {
        dashboard: 'Dashboard',
        gateways: 'Gateways',
        layouts: 'Layouts',
        deviceDefinitions: 'Devices',
        logoutTooltip: 'Logout User'
    },
    gatewayNavigationSidebar: {
        commissionedGateways: 'Commissioned Gateways',
        availableGateways: 'Available Gateways',
        enrolledGateways: 'Enrolled Gateways'
    },
    userNavigationSidebar: {
        internalUsers: 'Internal Users',
        externalUsers: 'External Users'
    },
    sortableTable: {
        editButton: 'Edit',
        deleteButton: 'Delete',
        noRows: 'No Rows'
    },
    logoutScreen: {
        description: 'You have been logged out',
        logInButton: 'Log In'
    },
    availableGatewayScreen: {
        header: 'Available Gateways',
        serialNumberColumn: 'Serial Number',
        azureDeviceIdColumn: 'Azure ID',
        connectedColumn: 'Online'
    },
    deviceDefinitionScreen: {
        header: 'Devices',
        description:
            'The list below contains downstream devices that have been defined by users. The edge gateway is able to connect to these devices.',
        directoryHeader: 'Device Directory',
        defineDeviceButton: 'Define New Device',
        definitionNameColumn: 'Definition Name',
        protocolColumn: 'Protocol',
        lastUpdatedColumn: 'Last Updated',
        updatedByColumn: 'Updated By',
        defaultFileExportName: 'NewDeviceDefinition',
        cannotDeleteDeviceDefinitionError:
            'Cannot delete device definition because it is in use by the following gateways: ',
        importOverwriteWarning:
            'Importing device definition will overwrite existing device definition data. Are you sure you would like to continue?'
    },
    dateText: {
        day: ' day ',
        days: ' days ',
        ago: 'ago, ',
        utc: ' UTC'
    },
    utilities: {
        importButton: 'Import',
        exportButton: 'Export',
        exportJsonButton: 'Export JSON',
        exportCsvButton: 'Export CSV'
    },
    conversionErrors: {
        integerFailed: 'Failed to parse integer value',
        booleanFailed: 'Failed to parse boolean value',
        floatFailed: 'Failed to parse float value'
    },
    protocolErrors: {
        intError: 'Protocol provided not an integer',
        valueError: 'Protocol provided not in list of protocols'
    },
    importErrors: {
        errorLabel: 'IMPORT ERROR: ',
        csvCouldNotBeTranslated: 'CSV data could not be translated into text.',
        csvColumnNamesDoNotMatch: 'CSV columns do not match expected column names.',
        csvConversionFailed: 'Converting CSV data failed with error: ',
        csvDataImproperlyFormatted: 'CSV data improperly formatted.',
        jsonCouldNotBeTranslated: 'JSON data could not be translated into text.',
        jsonConversionFailed: 'Converting JSON data failed with error: ',
        fileCouldNotBeRead: 'File data could not be read.'
    },
    deviceDefinitionForm: {
        communicationDefinitionHeader: 'Communication Definition',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        copyDefinition: 'Copy Device Definition',
        copyDefinitionFieldLabel: 'Start with existing device definition',
        copyDefinitionTooltip:
            'Select an existing device definition and press the Copy Device Definition button to populate the form with the values from the selected device definition.',
        definitionIdError: 'Invalid device definition ID provided: ',
        configurationLoadError: 'Device configuration failed to load',
        nameFieldLabel: 'Name',
        manufacturerFieldLabel: 'Manufacturer',
        protocolSelectorLabel: 'Protocol',
        duplicateNameError:
            'Provided name matches the name of an existing device definition within the selected PSL. Please choose a different name.',
        nameTooltip: 'Name of this device definition.',
        manufacturerTooltip: 'Manufacturer of a device defined by this device definition.',
        protocolSelectorTooltip:
            'Communication protocol used for a device defined by this device definition.'
    },
    deviceDefinitionCreateScreen: {
        header: 'Create Device Definition'
    },
    deviceDefinitionEditScreen: {
        header: 'Edit Device Definition',
        idError: 'Invalid device definition ID provided: ',
        deviceDefinitionIdNotFound: 'Device definition id not found in list of device definitions',
        affectedGatewaysWarning:
            'This definition is currently in use in the field. Updating it will affect the following number of gateways: '
    },
    deviceForm: {
        addDeviceHeader: 'Add A New Secondary Device',
        editDeviceHeader: 'Edit Secondary Device',
        headerTooltip:
            'The secondary device is a downstream device that communicates to the edge computer/gateway.',
        nameFieldLabel: 'Device Name',
        protocolFieldLabel: 'Protocol',
        definitionFieldLabel: 'Definition Name',
        ipAddressFieldLabel: 'IP Address',
        ipAddressAndPortFieldLabel: 'IP Address and Port',
        addressFieldLabel: 'Address',
        addButton: 'Add',
        updateButton: 'Update',
        cancelButton: 'Cancel',
        noDeviceDefinitionsError:
            'No available device definitions. Please define a device definition on the device definition screen.',
        invalidProtocolError: 'Invalid protocol selected. Could not change protocol to value: ',
        duplicateAddressError:
            'Duplicate device address provided. Please provide a unique IP address and port.',
        invalidAddressMoxaModbusError:
            'Invalid address. Expected format is XXX.XXX.XXX.XXX or XXX.XXX.XXX.XXX:XXX',
        invalidAddressMqttError: 'Invalid address. Expected format is XXX.XXX.XXX.XXX.',
        invalidAddressOpcUAError:
            'Invalid address. Expected format is XXX.XXX.XXX.XXX or XXX.XXX.XXX.XXX:XXX',
        nameInvalidCharacterError: 'Only the characters a-zA-Z-._~0-9 are acceptable',
        duplicateNameError: 'Device names must be unique. Please specify a unique name.',
        nameTooltip: 'Name of this device.',
        protocolTooltip:
            'Communication protocol used for device. Cannot be modified here - comes from corresponding device definition.',
        definitionTooltip: 'Device definition to be used to configure this device.',
        addressTooltip:
            'Address of this device. For MoxaModbusTCP and OPC UA, this is the IP address of the device (with or without port number). For MQTT, this is the IP address of the device (without port number).'
    },
    commissionGatewayScreen: {
        header: 'Commission Gateway',
        queryStringDataMissingError: 'Missing information from selected device. Please reselect.',
        commissionButton: 'Commission'
    },
    editGatewayScreen: {
        idError: 'Invalid gateway ID provided: ',
        header: 'Edit Gateway',
        saveButton: 'Save'
    },
    createEnrollmentScreen: {
        header: 'Create Enrollment',
        saveButton: 'Save'
    },
    editEnrollmentScreen: {
        idError: 'Invalid registration ID provided: ',
        header: 'Edit Enrollment',
        saveButton: 'Save'
    },
    idScopeDisplay: {
        idScopeLabel: 'ID Scope: ',
        idScopeTooltip:
            'Unique ID scope for the Azure DPS instance where this enrollment will be created.'
    },
    gatewayForm: {
        cancelButton: 'Cancel',
        serialNumberFieldLabel: 'Serial Number',
        azureDeviceIdFieldLabel: 'Azure ID',
        nameFieldLabel: 'Gateway Name',
        layoutFieldLabel: 'Layout',
        enableRtsForwardingFieldLabel: 'Enable RTS Forwarding',
        wellNameFieldLabel: 'Well Name',
        wellIdFieldLabel: 'Well GUID',
        generateWellIdButton: 'Generate',
        timezoneFieldLabel: 'Time Zone',
        latitudeFieldLabel: 'Latitude',
        longitudeFieldLabel: 'Longitude',
        operatorFieldLabel: 'Operator',
        spudDateFieldLabel: 'Install Date',
        guidValidationError: 'Invalid GUID',
        serialNumberTooltip:
            'Serial number for this gateway. Comes from corresponding provisioned Azure IoT Edge device and cannot be modified here.',
        azureIdTooltip:
            'Azure ID of corresponding provisioned Azure IoT Edge device. Cannot be modified here.',
        nameTooltip: 'Name of this gateway.',
        layoutTooltip: 'Layout to be used to configure this gateway.',
        wellNameTooltip: 'Name of well associated with this gateway.',
        wellIdTooltip:
            'GUID identifier for the well associated with this gateway. If a well ID already exists for this Azure IoT Edge device, enter it here - otherwise, use the "Generate" button to generate a new GUID.',
        timezoneTooltip: 'Timezone where this gateway is located.',
        latitudeTooltip: 'Latitude where this gateway is located.',
        longitudeTooltip: 'Longitude where this gateway is located.',
        operatorTooltip: 'Name of operator of this gateway.',
        spudDateTooltip: 'Date of installation for this gateway.',
        layoutNotesLabel: 'Layout Description: ',
        noLayoutError:
            'No defined layouts for this PSL. Please define a layout in the layouts page.',
        pslChangeWarning:
            'Changing your PSL will remove your selected layout and re-filter the selectable layouts',
        // new fields
        equipmentNumberFieldLabel: 'Equipment Number',
        equipmentNumberTooltip: 'Enter the equipment number.',
        costCenterFieldLabel: 'Cost Center',
        costCenterTooltip: 'Enter the cost center.',
    },
    enrollmentForm: {
        registrationIdFieldLabel: 'Registration ID',
        serialNumberFieldLabel: 'Serial Number',
        tpmEndorsementKeyFieldLabel: 'TPM Endorsement Key',
        cancelButton: 'Cancel',
        registrationIdTooltip:
            'Unique registration ID to assign to this enrollment. A gateway using this enrollment will be given a device ID that matches this registration ID.',
        serialNumberTooltip: 'Serial number to assign to a gateway that uses this enrollment.',
        tpmEndorsementKeyTooltip: 'TPM endorsement key to use for this enrollment.'
    },
    enrollmentsListScreen: {
        header: 'Enrolled Gateways',
        registrationIdColumn: 'Registration ID',
        serialNumberColumn: 'Serial Number',
        enrollmentStatusColumn: 'Enrollment Status',
        enrollmentCreatedDateColumn: 'Created',
        enrollmentUpdatedDateColumn: 'Last Updated',
        newEnrollmentButton: 'New Enrollment',
        cancelButton: 'Cancel'
    },
    commissionedGatewaysListScreen: {
        decommisionActionButton: 'Decommission',
        header: 'Commissioned Gateways',
        nameColumn: 'Gateway Name',
        serialNumberColumn: 'Serial Number',
        layoutColumn: 'Layout',
        pslColumm: 'PSL',
        wellNameColumn: 'Well Name',
        connectedColumn: 'Online',
        decommissionWarningPrefix: 'Are you sure you want to decommission gateway: ',
        decommissionWarningSuffix: '?',
        layoutLoadError: 'Layout load failed'
    },
    layoutsListScreen: {
        header: 'Layouts',
        nameColumn: 'Layout Name',
        pslColumn: 'PSL',
        notesColumn: 'Description',
        newLayoutButton: 'New Layout',
        cannotDeleteLayoutError:
            'Cannot delete layout because it is in use by the following gateways: '
    },
    createLayoutScreen: {
        header: 'New Layout',
        description:
            'Establish layout metadata and configure downstream devices that are used for gateways associated with this layout.'
    },
    editLayoutScreen: {
        header: 'Edit Layout',
        description:
            'Edit layout metadata and configure downstream devices that are used for gateways associated with this layout.',
        idError: 'Invalid layout ID provided: ',
        affectedGatewaysWarning:
            'This layout is currently in use in the field. Updating it will affect the following number of gateways: '
    },
    layoutForm: {
        saveButton: 'Save',
        cancelButton: 'Cancel',
        nameFieldLabel: 'Layout Name',
        notesFieldLabel: 'Description',
        copyLayout: 'Copy Layout',
        copyLayoutFieldLabel: 'Start with existing layout',
        copyLayoutTooltip:
            'Select an existing layout and press the Copy Layout button to populate the form with the values from the selected layout.',
        layoutIdEmptyError: 'Please select a layout before copying.',
        duplicateNameError:
            'Provided name matches the name of an existing layout within the selected PSL. Please choose a different name.',
        layoutIdError: 'Invalid layout ID provided: ',
        nameTooltip: 'Name of this layout.',
        notesTooltip: 'Description/notes for this layout.',
        pslChangeWarning:
            'Changing the selected PSL will delete the currently selected devices. Are you sure you would like to continue?'
    },
    devicesEditor: {
        nameColumn: 'Device Name',
        definitionColumn: 'Definition Name',
        protocolColumn: 'Protocol',
        addressColumn: 'Address',
        newDeviceButton: 'New Device',
        noDefinitionsError: 'No device definitions are defined. Please start by creating one.',
        definitionLoadError: 'Definition load failed'
    },
    userSettingsMenu: {
        header: 'My Profile',
        logout: 'Logout',
        manageUsersButton: 'Manage Users',
        userRoleLabel: 'User Role: ',
        userAssetsLabel: 'User Assets: ',
        themeLabel: 'Theme',
        darkModeToggleButton: 'Dark',
        lightModeToggleButton: 'Light'
    },
    addExternalUserAccessScreen: {
        header: 'New External User',
        userTypeError: 'User is a Halliburton user. Please add them via the internal users screen.',
        description: 'Use the Search field to find an existing user with their email address.'
    },
    addInternalUserAccessScreen: {
        header: 'New Internal User',
        userTypeError:
            'User is not a Halliburton user. Please add them via the external users screen.',
        description: 'Use the Search field to find an existing user with their email address.'
    },
    editInternalUserAccessScreen: {
        header: 'Edit User',
        idError: 'Invalid user ID provided: '
    },
    userSearchForm: {
        searchFieldLabel: 'Search',
        searchButton: 'Search',
        userNotFoundError: 'User not found'
    },
    internalUserForm: {
        saveButton: 'Save',
        cancelButton: 'Cancel',
        adminMappingError: 'Asset mappings cannot be assigned to admin accounts'
    },
    internalUserListScreen: {
        header: 'Internal Users',
        addUserButton: 'Add New User',
        editUserActionButton: 'Edit User',
        removeUserActionButton: 'Remove User',
        nameColumn: 'Name',
        emailColumn: 'Email',
        pslColumn: 'Psl',
        roleColumn: 'Role'
    },
    externalUserForm: {
        saveButton: 'Save',
        cancelButton: 'Cancel'
    },
    externalUserListScreen: {
        header: 'External Users',
        addUserButton: 'Add New User',
        removeUserActionButton: 'Remove User',
        nameColumn: 'Name',
        emailColumn: 'Email'
    },
    assetInfoEditor: {
        pslFieldLabel: 'PSL',
        dialogTitlePrefix: 'Confirm ',
        dialogTitleSuffix: ' Change'
    },
    dialog: {
        cancelButton: 'Cancel',
        confirmButton: 'Confirm'
    },
    userErrors: {
        userUndefined: 'User data could not be loaded',
        userAssetsErrorPrefix: 'Logged-in user has no assigned assets. Please send an email to ',
        userAssetsErrorSuffix: ' for further assistance.'
    },
    gatewayMapPanel: {
        header: 'Commissioned Gateways'
    },
    gatewayStatisticsPanel: {
        header: 'Status',
        offlineGatewaysChartLabel: 'Offline',
        onlineGatewaysChartLabel: 'Online',
        gatewaysChartLabel: 'Total Commissioned Gateways ',
        onlineGatewaysWithNoErrorsLabel: 'Online Gateways With No Errors',
        offlineGatewaysLabel: 'Offline Gateways',
        modulesInErrorStateLabel: 'Online Gateways With Module(s) in Error State',
        modulesWithFieldDevicesNotConnected: 'Online Gateways With Field Device(s) Not Connected'
    },
    gatewayFullStatusPanel: {
        header: ' Status',
        createdDateLabel: 'Status Recorded Date',
        azureDeviceIdLabel: 'Azure Device ID',
        gatewayCommissionedDateLabel: 'Gateway Commissioned Date',
        connectionStatusLabel: 'Edge Device Connection Status',
        configModuleStatusLabel: 'Config Module Status',
        deviceConnectionStatusesLabel: 'Device Connection Statuses',
        moduleConnectionStatusesLabel: 'Module Runtime Statuses',
        hasConfigurationLabel: 'Has Configuration',
        trueLabel: 'True',
        falseLabel: 'False',
        latLabel: 'Latitude',
        longLabel: 'Longitude',
        gpsModeLabel: 'GPS Mode',
        closeButton: 'Close'
    },
    punctuation: {
        labelDelimiter: ': ',
        commaDelimiter: ', ',
        openParenthesis: '(',
        closedParenthesis: ')'
    },
    gatewayFilteringPopup: {
        header: 'Filtering',
        clearAll: 'Clear All',
        allDevicesReportingLabel: 'Online With All Devices Reporting',
        notAllDevicesReportingLabel: 'Online With One or More Devices Not Reporting',
        allModulesRunningLabel: 'Online With All Modules Running',
        notAllModulesRunningLabel: 'Online With One or More Modules Not Running',
        onlineLabel: 'Online',
        offlineLabel: 'Offline'
    },
    signalUnitTypes: {
        [SignalUnitType.Angle]: 'Angle',
        [SignalUnitType.AngleGradient]: 'Angle Gradient',
        [SignalUnitType.Area]: 'Area',
        [SignalUnitType.ConcentrationMassMass]: 'Concentration (mass/mass)',
        [SignalUnitType.ConcentrationMassVolume]: 'Concentration (mass/vol)',
        [SignalUnitType.ConcentrationVolumeVolume]: 'Concentration (vol/vol)',
        [SignalUnitType.Currency]: 'Currency',
        [SignalUnitType.Current]: 'Current',
        [SignalUnitType.DensityMassVolume]: 'Density (mass/vol)',
        [SignalUnitType.Diameter]: 'Diameter',
        [SignalUnitType.Diffusivity]: 'Diffusivity',
        [SignalUnitType.ElectricPower]: 'Electric Power',
        [SignalUnitType.ElectricPowerCalibration]: 'Electric Power Calibration',
        [SignalUnitType.EngineSpeed]: 'Engine Speed',
        [SignalUnitType.FlowIndex]: 'FlowIndex',
        [SignalUnitType.FlowmeterCalibration]: 'Flowmeter Calibration',
        [SignalUnitType.FluidLossCoefficient]: 'Fluid Loss Coefficient',
        [SignalUnitType.Force]: 'Force',
        [SignalUnitType.FormationVolumeFactor]: 'Formation Volume Factor',
        [SignalUnitType.FractureConductivity]: 'Fracture Conductivity',
        [SignalUnitType.FractureToughness]: 'Fracture Toughness',
        [SignalUnitType.Frequency]: 'Frequency',
        [SignalUnitType.GForce]: 'G Force',
        [SignalUnitType.GasProduction]: 'Gas Production',
        [SignalUnitType.InverseLength]: 'Inverse Length',
        [SignalUnitType.InversePressure]: 'Inverse Pressure',
        [SignalUnitType.InverseTemperature]: 'Inverse Temperature',
        [SignalUnitType.InverseTime]: 'Inverse Time',
        [SignalUnitType.InverseVolumeGradient]: 'Inverse Volume Gradient',
        [SignalUnitType.KPrime]: 'K Prime',
        [SignalUnitType.Length]: 'Length',
        [SignalUnitType.Mass]: 'Mass',
        [SignalUnitType.MassGradient]: 'Mass Gradient',
        [SignalUnitType.OilGravity]: 'Oil Gravity',
        [SignalUnitType.OilProduction]: 'Oil Production',
        [SignalUnitType.PerforationDensity]: 'Perforation Density',
        [SignalUnitType.Permeability]: 'Permeability',
        [SignalUnitType.Ph]: 'pH',
        [SignalUnitType.PhCalibration]: 'pH Calibration',
        [SignalUnitType.Power]: 'Power',
        [SignalUnitType.Pressure]: 'Pressure',
        [SignalUnitType.PressureAbsolute]: 'Pressure Absolute',
        [SignalUnitType.PressureCalibration]: 'Pressure Calibration',
        [SignalUnitType.PressureGradient]: 'Pressure Gradient',
        [SignalUnitType.RateMassTime]: 'Rate (mass/time)',
        [SignalUnitType.RateVolumeTime]: 'Rate (vol/time)',
        [SignalUnitType.RateIncrement]: 'Rate Increment',
        [SignalUnitType.Ratio]: 'Ratio',
        [SignalUnitType.SpecificHeat]: 'Specific Heat',
        [SignalUnitType.SpurtLossCoefficient]: 'Spurt Loss Coefficient',
        [SignalUnitType.Temperature]: 'Temperature',
        [SignalUnitType.TemperatureCalibration]: 'Temperature Calibration',
        [SignalUnitType.ThermalConductivity]: 'Thermal Conductivity',
        [SignalUnitType.ThermalGradient]: 'Thermal Gradient',
        [SignalUnitType.Time]: 'Time',
        [SignalUnitType.TimeRelative]: 'Time Relative',
        [SignalUnitType.Torque]: 'Torque',
        [SignalUnitType.Unitless]: 'Unitless',
        [SignalUnitType.Velocity]: 'Velocity',
        [SignalUnitType.Viscosity]: 'Viscosity',
        [SignalUnitType.Voltage]: 'Voltage',
        [SignalUnitType.Volume]: 'Volume',
        [SignalUnitType.VolumeGradient]: 'Volume Gradient',
        [SignalUnitType.VolumePerCoupling]: 'Volume Per Coupling',
        [SignalUnitType.VolumeRatio]: 'Volume Ratio',
        [SignalUnitType.VolumeRatioIncrement]: 'Volume Ratio Increment',
        [SignalUnitType.YieldVolumeMass]: 'Yield (vol/mass)',
        [SignalUnitType.YieldPoint]: 'Yield Point'
    }
};

export function useLanguage() {
    return textResource;
}
