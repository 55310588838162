import { SelectChangeEvent, Stack, OutlinedInput, Select, MenuItem } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { dataTypes, swaps } from '../../../../constants/enums';
import { floatInputProps } from '../../../../constants/formatting';
import { moxaNameFormat, UIntMinimum, xShortString } from '../../../../constants/validation';
import { isFloat, isInt } from '../../../../helperFunctions';
import { useSignalDataTypeEnum, useSwapEnum } from '../../../../hooks/enumHooks';
import { useLanguage } from '../../../../hooks/useLanguage';
import { validateDataUnit, validateName } from '../../../../signalHelperFunctions';
import {
    LocalMoxaModbusRegister,
    SignalDataType,
    Scaling
} from '../../../../types/deviceDefinitionTypes';
import { RequiredStatus } from '../../../../types/displayTypes';
import { SignalUnitType } from '../../../../types/signalUnitTypes';
import { FormElement } from '../../../common/formElement';
import {
    formFieldId,
    formLabelId,
    RenderBinaryInput
} from '../../../common/formInputRenderFunctions';
import { EventClassSelector } from '../sharedFormInputs/eventClassSelector';
import {UomClass} from "../../../../types/uomTypes";

const RegisterFormInputs = ({
    registers
}: {
    registers: LocalMoxaModbusRegister[];
    defaultUnitType?: SignalUnitType;
}) => {
    // const { form: formText, signalForm, signalValidationErrors } = useLanguage();
    const { registerForm, moxaRegisterValidationErrors, enumDisplay, ...text } = useLanguage();
    const { dataTypeDisplay } = useSignalDataTypeEnum();
    const { swapDisplay } = useSwapEnum();
    const form = useFormContext<LocalMoxaModbusRegister>();

    const [uomData, setUomData] = useState<UomClass[]>([]);
    const [unitTypes, setUnitTypes] = useState<string[]>([]);
    const [unitItems, setUnitItems] = useState<any[]>([]);

    const uomClass = form.watch('uomClass');
    const unitType = form.watch('unitType');

    useEffect(() => {
        const storedUomData = localStorage.getItem('uomData');
        if (storedUomData) {
            setUomData(JSON.parse(storedUomData));
        }
    }, []);

    useEffect(() => {
        // Initialize unit types based on the default UOM class, if applicable
        if (uomClass) {
            const selectedUomClassData = uomData.find(uom => uom.uomClassName === uomClass);
            if (selectedUomClassData) {
                setUnitTypes(selectedUomClassData.unitTypes);
                setUnitItems([]);
            }
        }
    }, [uomClass, uomData]);

    useEffect(() => {
        if (unitType) {
            const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(unitType));
            if (selectedUomClassData) {
                const selectedUnitItems = selectedUomClassData.unitItems;
                setUnitItems(selectedUnitItems);
            }
        }
    }, [unitType, uomData]);

    const handleUomClassChange = (e: SelectChangeEvent<string>) => {
        const selectedUomClass = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.uomClassName === selectedUomClass);
        if (selectedUomClassData) {
            setUnitTypes(selectedUomClassData.unitTypes);
            setUnitItems([]);
            form.setValue('unitType', ''); // Reset unitType field value
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('uomClass', selectedUomClass); // Set the UOM class in the form state
    };

    const handleUnitTypeChange = (e: SelectChangeEvent<string>) => {
        const selectedUnitType = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(selectedUnitType));
        if (selectedUomClassData) {
            const selectedUnitItems = selectedUomClassData.unitItems;
            setUnitItems(selectedUnitItems);
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('unitType', selectedUnitType); // Set the Unit Type in the form state
    };    

    const validateRegisterName = (name: string) => {
        const signalNameValid = validateName(name, registers, form.formState.defaultValues?.name);

        if (!signalNameValid) {
            return registerForm.duplicateNameError;
        }
        if (!moxaNameFormat.test(name)) {
            return moxaRegisterValidationErrors.nameInvalidCharacter;
        }
        return true;
    };

    const uintMinValidation = useMemo(() => {
        return { value: UIntMinimum, message: `${text.form.minimum}${UIntMinimum}` };
    }, [text.form.minimum]);

    const validateDataSize = (register: LocalMoxaModbusRegister) => {
        if (
            register.dataSize === null &&
            (register.dataType === SignalDataType.String ||
                register.dataType === SignalDataType.None)
        ) {
            return text.form.required;
        }

        if (
            register.dataSize !== null &&
            register.dataType !== SignalDataType.String &&
            register.dataType !== SignalDataType.None
        ) {
            return `${text.moxaRegisterFields.dataSize}${moxaRegisterValidationErrors.mustBeNull}${moxaRegisterValidationErrors.dataSizeNullCondition}`;
        }

        return true;
    };

    const validateQuantity = (register: LocalMoxaModbusRegister) => {
        if (
            register.dataType === SignalDataType.Double &&
            !(register.quantity === 2 || register.quantity === 4)
        ) {
            return moxaRegisterValidationErrors.invalidQuantityForDouble;
        } else if (register.quantity < 1) {
            return `${text.form.minimum}${1}`;
        }
        return true;
    };

    const handleDataTypeChange = (e: SelectChangeEvent<SignalDataType>) => {
        const dataType = e.target.value;
        if (typeof dataType === 'string') {
            return;
        }
        form.setValue(
            'dataSize',
            dataType !== SignalDataType.None && dataType !== SignalDataType.String ? null : 1,
            { shouldDirty: true }
        );
        form.clearErrors('dataSize');
        form.setValue('dataType', dataType, { shouldDirty: true });
    };

    return (
        <Stack spacing={3}>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="name"
                label={registerForm.nameFieldLabel}
                tooltipTitle={registerForm.nameTooltip}
            >
                <OutlinedInput
                    {...form.register('name', {
                        required: text.form.required,
                        maxLength: { value: xShortString, message: text.form.tooLong },
                        validate: validateRegisterName
                    })}
                    id={formFieldId('name')}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="dataType"
                label={registerForm.dataTypeFieldLabel}
                tooltipTitle={registerForm.dataTypeTooltip}
            >
                <Controller
                    // Does not utilize Signal shared type because of dataSize link
                    control={form.control}
                    name="dataType"
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={handleDataTypeChange}
                            labelId={formLabelId(field.name)}
                        >
                            {dataTypes.map((dt) => (
                                <MenuItem key={dt} value={dt}>
                                    {dataTypeDisplay[dt]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="address"
                label={registerForm.addressFieldLabel}
                tooltipTitle={registerForm.addressTooltip}
            >
                <OutlinedInput
                    {...form.register('address', {
                        valueAsNumber: true,
                        required: text.form.required,
                        min: uintMinValidation
                    })}
                    id={formFieldId('address')}
                    type="number"
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="quantity"
                label={registerForm.quantityFieldLabel}
                tooltipTitle={registerForm.quantityTooltip}
            >
                <Controller
                    control={form.control}
                    name="quantity"
                    rules={{
                        validate: (_, register) => validateQuantity(register)
                    }}
                    render={({ field }) => (
                        <OutlinedInput
                            value={field.value}
                            {...form.register('quantity', {
                                valueAsNumber: true,
                                required: text.form.required
                            })}
                            id={formFieldId('quantity')}
                            type="number"
                        />
                    )}
                />
            </FormElement>
            <Controller control={form.control} name="eventClass" render={EventClassSelector} />
            <FormElement<LocalMoxaModbusRegister>
                fieldName="readOnly"
                label={registerForm.isReadOnlyFieldLabel}
                tooltipTitle={registerForm.readOnlyTooltip}
            >
                <Controller control={form.control} name="readOnly" render={RenderBinaryInput} />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="swap"
                label={registerForm.swapFieldLabel}
            >
                <Controller
                    control={form.control}
                    name="swap"
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={field.onChange}
                            labelId={formLabelId(field.name)}
                        >
                            {swaps.map((p) => (
                                <MenuItem key={p} value={p}>
                                    {swapDisplay[p]}
                                </MenuItem>
                            ))}
                            <MenuItem key="key" sx={{ visibility: 'hidden' }} />
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="dataSize"
                label={registerForm.dataSizeFieldLabel}
                tooltipTitle={registerForm.dataSizeTooltip}
                requiredStatus={
                    form.watch('dataType') !== SignalDataType.None &&
                    form.watch('dataType') !== SignalDataType.String
                        ? RequiredStatus.Optional
                        : undefined
                }
            >
                <Controller
                    control={form.control}
                    name="dataSize"
                    rules={{
                        validate: (_, register) => validateDataSize(register),
                        min: { value: 1, message: `${text.form.minimum}1` }
                    }}
                    render={({ field }) => (
                        <OutlinedInput
                            id={formFieldId('dataSize')}
                            value={field.value !== null ? field.value : ''}
                            onChange={(e) =>
                                field.onChange(
                                    isInt(e.target.value) ? parseInt(e.target.value) : null
                                )
                            }
                            type="number"
                        />
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="uomClass"
                label="UOM Class"
                tooltipTitle="Select UOM Class"
            >
                <Controller
                    control={form.control}
                    name="uomClass"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUomClassChange}>
                            {uomData.map((uomClass) => (
                                <MenuItem key={uomClass.uomClassName} value={uomClass.uomClassName}>
                                    {uomClass.uomClassName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="unitType"
                label="Unit Type"
                tooltipTitle="Select Unit Type"
            >
                <Controller
                    control={form.control}
                    name="unitType"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUnitTypeChange} disabled={!unitTypes.length}>
                            {unitTypes.map((unitType) => (
                                <MenuItem key={unitType} value={unitType}>
                                    {unitType}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="dataUnit"
                label="Unit"
                tooltipTitle="Select Unit"
            >
                <Controller
                    control={form.control}
                    name="dataUnit"
                    render={({ field }) => (
                        <Select {...field} disabled={!unitItems.length}>
                            {unitItems.map((unit) => (
                                <MenuItem key={unit.shortName} value={unit.shortName}>
                                    {unit.longName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="offset"
                label={registerForm.offsetFieldLabel}
                tooltipTitle={registerForm.offsetTooltip}
            >
                <OutlinedInput
                    {...form.register('offset', {
                        valueAsNumber: true,
                        required: text.form.required,
                        min: uintMinValidation
                    })}
                    id={formFieldId('offset')}
                    type="number"
                />
            </FormElement>
            <FormElement<LocalMoxaModbusRegister>
                fieldName="scaling"
                label={registerForm.scalingFieldLabel}
                tooltipTitle={registerForm.scalingTooltip}
            >
                <Controller
                    control={form.control}
                    name="scaling"
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={field.onChange}
                            labelId={formLabelId(field.name)}
                        >
                            <MenuItem value={Scaling.Disabled}>
                                {enumDisplay.scaling.disabled}
                            </MenuItem>
                            <MenuItem value={Scaling.Intercept}>
                                {enumDisplay.scaling.intercept}
                            </MenuItem>
                            <MenuItem value={Scaling.Point}>{enumDisplay.scaling.point}</MenuItem>
                        </Select>
                    )}
                />
            </FormElement>
            {form.watch('scaling') === Scaling.Intercept && (
                <Stack spacing={3}>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="slope"
                        label={registerForm.slopeFieldLabel}
                        tooltipTitle={registerForm.slopeTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="slope"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('slope')}
                                    value={field.value !== null ? field.value : ''}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="interceptOffset"
                        label={registerForm.interceptOffsetFieldLabel}
                        tooltipTitle={registerForm.interceptOffsetTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="interceptOffset"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('interceptOffset')}
                                    value={field.value !== null ? field.value : ''}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                </Stack>
            )}
            {form.watch('scaling') === Scaling.Point && (
                <Stack spacing={3}>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="pointSourceMin"
                        label={registerForm.pointSourceMinFieldLabel}
                        tooltipTitle={registerForm.pointSourceMinTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="pointSourceMin"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('pointSourceMin')}
                                    value={field.value !== null ? field.value : null}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="pointSourceMax"
                        label={registerForm.pointSourceMaxFieldLabel}
                        tooltipTitle={registerForm.pointSourceMaxTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="pointSourceMax"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('pointSourceMax')}
                                    value={field.value !== null ? field.value : ''}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="pointTargetMin"
                        label={registerForm.pointTargetMinFieldLabel}
                        tooltipTitle={registerForm.pointTargetMinTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="pointTargetMin"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('pointTargetMin')}
                                    value={field.value !== null ? field.value : ''}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                    <FormElement<LocalMoxaModbusRegister>
                        fieldName="pointTargetMax"
                        label={registerForm.pointTargetMaxFieldLabel}
                        tooltipTitle={registerForm.pointTargetMaxTooltip}
                    >
                        <Controller
                            control={form.control}
                            name="pointTargetMax"
                            rules={{
                                validate: (v) => isFloat(v) || text.form.floatTypeRequired,
                                required: text.form.required
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    id={formFieldId('pointTargetMax')}
                                    value={field.value !== null ? field.value : ''}
                                    onChange={(e) =>
                                        field.onChange(
                                            isFloat(e.target.value)
                                                ? parseFloat(e.target.value)
                                                : null
                                        )
                                    }
                                    type="number"
                                    slotProps={floatInputProps}
                                />
                            )}
                        />
                    </FormElement>
                </Stack>
            )}
        </Stack>
    );
};

export default RegisterFormInputs;
