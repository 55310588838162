import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addExternalUserToGroup } from '../../../api/externalUserApi';
import {
    createErrorMessage,
    useDisplayHttpStatusCode,
    useRequest
} from '../../../hooks/requestHooks';
import { useLanguage } from '../../../hooks/useLanguage';
import { extensions } from '../../../pages';
import { ApiResult } from '../../../types/apiTypes';
import { User, UserReadOnlyFields } from '../../../types/userTypes';
import UserReadOnlyInputs from './userReadOnlyFormInputs';

const ExternalUserForm = ({
    user,
    setError
}: {
    user: User;
    setError: (error: string) => void;
}) => {
    const { externalUserForm, lifecycle } = useLanguage();
    const displayHttpStatusCode = useDisplayHttpStatusCode();
    const navigate = useNavigate();
    const form = useForm<UserReadOnlyFields>({
        defaultValues: {
            id: user.id,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`
        }
    });

    const onAddSuccess = (_: string) => {
        navigate(`../${extensions.external}`);
    };
    const onAddFailure = (result: ApiResult<undefined>) => {
        const displayError = createErrorMessage(result, displayHttpStatusCode(result.status));
        setError(displayError);
    };
    const { executeRequest, loading } = useRequest(
        addExternalUserToGroup,
        onAddSuccess,
        onAddFailure
    );

    const onSubmit = (externalUser: UserReadOnlyFields) => {
        executeRequest(externalUser.id);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack>
                    <UserReadOnlyInputs name="name" email="email" />
                    <Stack direction="row" spacing={2} paddingTop={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate(`../${extensions.external}`)}
                        >
                            {externalUserForm.cancelButton}
                        </Button>
                        <Button variant="contained" disabled={loading} type="submit">
                            {loading ? lifecycle.loadingLabel : externalUserForm.saveButton}
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default ExternalUserForm;
