import { Drawer, Stack } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { navBarMinHeight } from '../../../constants/styling';
import { useLanguage } from '../../../hooks/useLanguage';
import UsersIcon from '../../../icons/usersIcon';
import { extensions } from '../../../pages';
import { SidebarButton } from '../../navigation/sidebarButton';

const drawerWidthPx = 250;

const UsersPage = () => {
    const navigate = useNavigate();
    const { userNavigationSidebar } = useLanguage();

    return (
        <Stack direction="row" height="100%">
            <Drawer
                hideBackdrop
                variant="permanent"
                sx={{
                    width: drawerWidthPx
                }}
            >
                <Stack
                    sx={{
                        paddingTop: navBarMinHeight // this is the height of the nav bar tab
                    }}
                >
                    <SidebarButton
                        label={userNavigationSidebar.internalUsers}
                        onClick={() => navigate(extensions.internal)}
                        Icon={UsersIcon}
                    />
                    <SidebarButton
                        label={userNavigationSidebar.externalUsers}
                        onClick={() => navigate(extensions.external)}
                        Icon={UsersIcon}
                    />
                </Stack>
            </Drawer>

            <Stack component="main" flexGrow={1}>
                <Outlet />
            </Stack>
        </Stack>
    );
};

export default UsersPage;
