import { Link, Typography } from '@mui/material';
import { useLanguage } from '../../hooks/useLanguage';

const halliburtonContactEmail = 'rpedge@halliburton.com';

const UserAssetsErrorMessage = () => {
    const { userErrors } = useLanguage();

    return (
        <Typography>
            {userErrors.userAssetsErrorPrefix}
            <Link href={'mailto:' + halliburtonContactEmail}>{halliburtonContactEmail}</Link>
            {userErrors.userAssetsErrorSuffix}
        </Typography>
    );
};

export default UserAssetsErrorMessage;
