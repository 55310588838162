import React, { useState, useEffect } from 'react';
import { Stack, OutlinedInput, SelectChangeEvent, Select, MenuItem } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { xShortString } from '../../../../constants/validation';
import { useLanguage } from '../../../../hooks/useLanguage';
import { validateDataUnit, validateName } from '../../../../signalHelperFunctions';
import { Signal } from '../../../../types/deviceDefinitionTypes';
import { SignalUnitType } from '../../../../types/signalUnitTypes';
import { FormElement } from '../../../common/formElement';
import { formFieldId, RenderBinaryInput } from '../../../common/formInputRenderFunctions';
import DataTypeSelector from '../sharedFormInputs/dataTypeSelector';
import { EventClassSelector } from '../sharedFormInputs/eventClassSelector';
import {UomClass} from "../../../../types/uomTypes";

const SignalFormInputs = ({
    signals
}: {
    signals: Signal[];
    defaultUnitType?: SignalUnitType;
}) => {
    const { form: formText, signalForm, signalValidationErrors } = useLanguage();
    const form = useFormContext<Signal>();

    const [uomData, setUomData] = useState<UomClass[]>([]);
    const [unitTypes, setUnitTypes] = useState<string[]>([]);
    const [unitItems, setUnitItems] = useState<any[]>([]);

    const uomClass = form.watch('uomClass');
    const unitType = form.watch('unitType');

    useEffect(() => {
        const storedUomData = localStorage.getItem('uomData');
        if (storedUomData) {
            setUomData(JSON.parse(storedUomData));
        }
    }, []);
    
    useEffect(() => {
        // Initialize unit types based on the default UOM class, if applicable
        if (uomClass) {
            const selectedUomClassData = uomData.find(uom => uom.uomClassName === uomClass);
            if (selectedUomClassData) {
                setUnitTypes(selectedUomClassData.unitTypes);
                setUnitItems([]);
            }
        }
    }, [uomClass, uomData]);

    useEffect(() => {
        if (unitType) {
            const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(unitType));
            if (selectedUomClassData) {
                const selectedUnitItems = selectedUomClassData.unitItems;
                setUnitItems(selectedUnitItems);
            }
        }
    }, [unitType, uomData]);

    const handleUomClassChange = (e: SelectChangeEvent<string>) => {
        const selectedUomClass = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.uomClassName === selectedUomClass);
        if (selectedUomClassData) {
            setUnitTypes(selectedUomClassData.unitTypes);
            setUnitItems([]);
            form.setValue('unitType', ''); // Reset unitType field value
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('uomClass', selectedUomClass); // Set the UOM class in the form state
    };

    const handleUnitTypeChange = (e: SelectChangeEvent<string>) => {
        const selectedUnitType = e.target.value;
        const selectedUomClassData = uomData.find(uom => uom.unitTypes.includes(selectedUnitType));
        if (selectedUomClassData) {
            const selectedUnitItems = selectedUomClassData.unitItems;
            setUnitItems(selectedUnitItems);
            form.setValue('dataUnit', ''); // Reset unit field value
        }
        form.setValue('unitType', selectedUnitType); // Set the Unit Type in the form state
    };


    return (
        <Stack spacing={3}>
            <FormElement<Signal>
                fieldName="name"
                label={signalForm.nameFieldLabel}
                tooltipTitle={signalForm.nameTooltip}
            >
                <OutlinedInput
                    {...form.register('name', {
                        required: formText.required,
                        maxLength: { value: xShortString, message: formText.tooLong },
                        validate: (name) =>
                            validateName(name, signals, form.formState.defaultValues?.name) ||
                            signalForm.duplicateNameError
                    })}
                    id={formFieldId('name')}
                />
            </FormElement>
            <Controller control={form.control} name="dataType" render={DataTypeSelector} />
            <FormElement<Signal>
                fieldName="readOnly"
                label={signalForm.isReadOnlyFieldLabel}
                tooltipTitle={signalForm.readOnlyTooltip}
            >
                <Controller control={form.control} name="readOnly" render={RenderBinaryInput} />
            </FormElement>
            <Controller control={form.control} name="eventClass" render={EventClassSelector} />
            <FormElement<Signal>
                fieldName="uomClass"
                label="UOM Class"
                tooltipTitle="Select UOM Class"
            >
                <Controller
                    control={form.control}
                    name="uomClass"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUomClassChange}>
                            {uomData.map((uomClass) => (
                                <MenuItem key={uomClass.uomClassName} value={uomClass.uomClassName}>
                                    {uomClass.uomClassName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<Signal>
                fieldName="unitType"
                label="Unit Type"
                tooltipTitle="Select Unit Type"
            >
                <Controller
                    control={form.control}
                    name="unitType"
                    render={({ field }) => (
                        <Select {...field} onChange={handleUnitTypeChange} disabled={!unitTypes.length}>
                            {unitTypes.map((unitType) => (
                                <MenuItem key={unitType} value={unitType}>
                                    {unitType}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
            <FormElement<Signal>
                fieldName="dataUnit"
                label="Unit"
                tooltipTitle="Select Unit"
            >
                <Controller
                    control={form.control}
                    name="dataUnit"
                    render={({ field }) => (
                        <Select {...field} disabled={!unitItems.length}>
                            {unitItems.map((unit) => (
                                <MenuItem key={unit.shortName} value={unit.shortName}>
                                    {unit.longName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormElement>
        </Stack>
    );
};

export default SignalFormInputs;
