import { useEffect, useState } from 'react';
import { faCalendarMinus, faPen } from '@fortawesome/free-solid-svg-icons';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useLanguage } from '../../../hooks/useLanguage';
import SortableTable from '../../common/sortableTable';
import SearchBar from '../../common/searchBar';
import { GatewayResponse } from '../../../types/gatewayTypes';
import { LayoutResponse } from '../../../types/layoutTypes';
import VerticalStack from '../../common/verticalStack';
import { deleteGateway, getGateways } from '../../../api/gatewayApi';
import { useRequest } from '../../../hooks/requestHooks';
import { UserRole } from '../../../types/authorizationTypes';
import { pageSize } from '../../../constants/styling';
import { PslResponse } from '../../../types/pslTypes';
import { getPslNameFromId } from '../../../helperFunctions';
import UserAssetsErrorMessage from '../../common/userAssetsErrorMessage';
import ConfirmDialog from '../../common/confirmDialog';

const CommissionedGatewaysList = ({
    layouts,
    psls
}: {
    layouts: LayoutResponse[];
    psls: PslResponse[];
}) => {
    const { sortableTable, commissionedGatewaysListScreen } = useLanguage();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [gatewayToDecommission, setGatewayToDecommission] = useState<GatewayResponse>();
    const [page, setPage] = useState(0);

    const {
        executeRequest,
        loading,
        error: getError,
        data: commissionedGateways
    } = useRequest(getGateways);

    useEffect(() => {
        executeRequest({ pageNumber: page, pageSize });
    }, [executeRequest, page]);

    const deleteLocalDefinition = (): void => {
        executeRequest({ pageNumber: page, pageSize });
    };

    const {
        executeRequest: decommissionGateway,
        loading: deleteLoading,
        error: deleteError
    } = useRequest(deleteGateway, deleteLocalDefinition);

    const columns: GridColDef[] = [
        { field: 'name', headerName: commissionedGatewaysListScreen.nameColumn, flex: 1 },
        {
            field: 'serialNumber',
            headerName: commissionedGatewaysListScreen.serialNumberColumn,
            flex: 1
        },
        { field: 'layoutId', headerName: commissionedGatewaysListScreen.layoutColumn, flex: 1 },
        {
            field: 'assetInfo',
            headerName: commissionedGatewaysListScreen.pslColumm,
            valueGetter: (params) => getPslNameFromId(psls, params.value.pslId),
            flex: 1
        },
        {
            field: 'wellName',
            headerName: commissionedGatewaysListScreen.wellNameColumn,
            flex: 1
        }
    ];

    const filteredGateways = commissionedGateways?.filter((g) =>
        g.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const gatewaysToDisplay = filteredGateways?.map((gateway) => {
        const layout = layouts.find((layout) => layout.id === gateway.layoutId);
        const wellName = gateway.enableRtsForwarding ? gateway.wellName : '';

        if (layout) {
            return {
                id: gateway.id,
                name: gateway.name,
                serialNumber: gateway.serialNumber,
                assetInfo: gateway.assetInfo,
                layoutId: layout.name,
                wellName: wellName
            };
        }

        // if the layout definition is invalid, then the data for display
        // cannot be appropriately parsed
        return {
            id: gateway.id,
            name: gateway.name,
            serialNumber: gateway.serialNumber,
            assetInfo: gateway.assetInfo,
            layoutId: gateway.layoutId,
            wellName: wellName,
            application: commissionedGatewaysListScreen.layoutLoadError
        };
    });

    const deleteRow = (rowId: GridRowId) => {
        setGatewayToDecommission(commissionedGateways?.filter((g) => g.id === rowId.toString())[0]);
    };

    const editRow = (rowId: GridRowId) => {
        navigate(`../${rowId.toString()}`);
    };

    const error = getError || deleteError;

    return (
        <VerticalStack spacing={3} paddingTop={8}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            {error && <Alert severity="error">{error}</Alert>}
            {psls.length === 0 && (
                <Alert severity="warning">
                    <UserAssetsErrorMessage />
                </Alert>
            )}
            <SortableTable
                rows={gatewaysToDisplay ?? []}
                columns={columns}
                rowActions={[
                    { label: sortableTable.editButton, onClick: editRow, icon: faPen },
                    {
                        label: commissionedGatewaysListScreen.decommisionActionButton,
                        onClick: deleteRow,
                        icon: faCalendarMinus,
                        visible: UserRole.InternalUser
                    }
                ]}
                pagedData={{
                    page: page,
                    setPage: setPage,
                    pageSize: pageSize,
                    rowNumber: page * pageSize + 2 * pageSize,
                    isLoading: loading || deleteLoading
                }}
            />

            {gatewayToDecommission && (
                <ConfirmDialog
                    isOpen={gatewayToDecommission !== undefined}
                    content={`${commissionedGatewaysListScreen.decommissionWarningPrefix}${gatewayToDecommission.name}${commissionedGatewaysListScreen.decommissionWarningSuffix}`}
                    onConfirm={() => {
                        decommissionGateway(gatewayToDecommission.id);
                        setGatewayToDecommission(undefined);
                    }}
                    onClose={() => setGatewayToDecommission(undefined)}
                    confirmText={commissionedGatewaysListScreen.decommisionActionButton}
                />
            )}
        </VerticalStack>
    );
};

export default CommissionedGatewaysList;
